import { featureEquals, featureEnabled } from "../../../../Feature";
export function isFeatureEnabled(features, query) {
    return featureEnabled(features, query);
}
export function isFeatureDisabled(features, feature) {
    return featureEquals(features, feature, false);
}
export function doesFeatureEqual(features, flag, value) {
    return featureEquals(features, flag, value);
}
