var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from "@narmi/design_system";
import React from "react";
import Option from "./Option";
var Options = function (_a) {
    var children = _a.children, className = _a.className, _b = _a.wrapperDisplay, wrapperDisplay = _b === void 0 ? "inline-flex" : _b, _c = _a.matchTriggerWidth, matchTriggerWidth = _c === void 0 ? false : _c, _d = _a.offset, offset = _d === void 0 ? 0 : _d, _e = _a.float, float = _e === void 0 ? "none" : _e, _f = _a.width, width = _f === void 0 ? "auto" : _f, _g = _a.position, position = _g === void 0 ? "relative" : _g;
    var _h = __read(React.useState(false), 2), open = _h[0], setOpen = _h[1];
    return (_jsx("div", { className: "options", style: { width: width, position: position }, children: _jsx(Popover, { closeOnContentClick: true, wrapperDisplay: wrapperDisplay, matchTriggerWidth: matchTriggerWidth, offset: offset, content: _jsxs(_Fragment, { children: [_jsx("div", { role: "button", tabIndex: 0, onKeyUp: function (_a) {
                            var key = _a.key;
                            if (key === "Enter")
                                setOpen(!open);
                        }, onClick: setOpen.bind(null, false) }), _jsx("div", { className: "container ".concat(className), children: children })] }), type: "plain", children: _jsx("div", { className: "options-traffic-light ".concat(open ? " open" : "", " "), children: _jsx("span", { className: "clickable narmi-icon-more-vertical", style: { float: float } }) }) }) }));
};
Options.Option = Option;
export default Options;
