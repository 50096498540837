var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState, useRef, forwardRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown, DropdownListItem, SearchBar, Button } from "cerulean";
import styles from "./RecipientSelector.module.scss";
var Highlight = function (_a) {
    var value = _a.value, searchTerm = _a.searchTerm;
    if (!searchTerm) {
        return _jsx(_Fragment, { children: value });
    }
    var regex = new RegExp("(".concat(searchTerm, ")"), "gi");
    var parts = value.split(regex);
    return (_jsx(_Fragment, { children: parts.map(function (part, index) {
            return regex.test(part) ? _jsx("b", { children: part }, index) : part;
        }) }));
};
var RecipientListItem = forwardRef(function Item(_a, ref) {
    var recipient = _a.recipient, value = _a.value, isIndented = _a.isIndented, onChange = _a.onChange, searchTerm = _a.searchTerm, closeDropdown = _a.closeDropdown;
    return (_jsx(DropdownListItem, { anyItemSelected: !!value, isSelected: value === recipient.id, isIndented: isIndented, closeDropdown: closeDropdown, onClick: function () {
            onChange(recipient.id);
        }, ariaLabel: "Recipient: ".concat(recipient.name), ref: ref, children: _jsx(Highlight, { value: recipient.name, searchTerm: searchTerm }) }));
});
var RecipientSelector = function (_a) {
    var value = _a.value, recipients = _a.recipients, label = _a.label, onChange = _a.onChange, error = _a.error;
    var navigate = useNavigate();
    var location = useLocation();
    var _b = __read(useState(false), 2), isDropdownOpen = _b[0], setIsDropdownOpen = _b[1];
    var _c = __read(useState(""), 2), searchTerm = _c[0], setSearchTerm = _c[1];
    var _d = __read(useState(-1), 2), indexFocused = _d[0], setIndexFocused = _d[1];
    var listRef = useRef(null);
    var addNewRecipientButtonRef = useRef(null);
    var searchInputRef = useRef(null);
    useEffect(function () {
        var _a;
        if (isDropdownOpen) {
            (_a = searchInputRef === null || searchInputRef === void 0 ? void 0 : searchInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [isDropdownOpen]);
    var onSearchTermUpdate = function (term) {
        setIndexFocused(-1);
        setSearchTerm(term);
    };
    var filteredRecipients = recipients.filter(function (recipient) {
        return recipient.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
    });
    var getListRefMap = useCallback(function () {
        if (!listRef.current) {
            listRef.current = new Map();
        }
        return listRef.current;
    }, [listRef]);
    var closeDropdown = function () { return setIsDropdownOpen(false); };
    var getRefNodeByIndex = useCallback(function (index) {
        var _a;
        var listRefs = getListRefMap();
        if (filteredRecipients.length === index) {
            return addNewRecipientButtonRef.current;
        }
        return listRefs.get((_a = filteredRecipients[index]) === null || _a === void 0 ? void 0 : _a.id);
    }, [getListRefMap, filteredRecipients]);
    var onKeyDown = useCallback(function (event) {
        var _a, _b;
        var list = getListRefMap();
        var lowestIndex = 0;
        var highestIndex = filteredRecipients.length;
        switch (event.key) {
            case "Enter": {
                event.preventDefault();
                if ((_a = filteredRecipients[indexFocused]) === null || _a === void 0 ? void 0 : _a.id) {
                    onChange(filteredRecipients[indexFocused].id);
                }
                return;
            }
            case "ArrowDown": {
                if (!isDropdownOpen) {
                    setIsDropdownOpen(true);
                }
                setIndexFocused(function (prevIndexFocused) {
                    event.preventDefault();
                    var nextIndex;
                    if (prevIndexFocused + 1 > highestIndex) {
                        nextIndex = lowestIndex;
                    }
                    else {
                        nextIndex = prevIndexFocused + 1;
                    }
                    var listItem = getRefNodeByIndex(nextIndex);
                    listItem === null || listItem === void 0 ? void 0 : listItem.focus();
                    return nextIndex;
                });
                return;
            }
            case "ArrowUp": {
                if (!isDropdownOpen) {
                    setIsDropdownOpen(true);
                }
                event.preventDefault();
                setIndexFocused(function (prevIndexFocused) {
                    var nextIndex;
                    if (prevIndexFocused - 1 < lowestIndex) {
                        nextIndex = highestIndex;
                    }
                    else {
                        nextIndex = prevIndexFocused - 1;
                    }
                    var listItem = getRefNodeByIndex(nextIndex);
                    listItem === null || listItem === void 0 ? void 0 : listItem.focus();
                    return nextIndex;
                });
                return;
            }
            case "tab": {
                var listItem = list.get((_b = filteredRecipients[indexFocused]) === null || _b === void 0 ? void 0 : _b.id);
                listItem === null || listItem === void 0 ? void 0 : listItem.blur();
                setIndexFocused(-1);
                break;
            }
            default:
        }
    }, [
        indexFocused,
        setIndexFocused,
        filteredRecipients,
        onChange,
        isDropdownOpen,
        getRefNodeByIndex,
        getListRefMap,
    ]);
    var addNewRecipientButton = (_jsx(DropdownListItem, { anyItemSelected: !!value, closeDropdown: closeDropdown, onClick: function () {
            navigate("/wires/templates/recipients/new", {
                state: { from: location.pathname },
            });
        }, ariaLabel: "Add a new recipient", ref: addNewRecipientButtonRef, children: _jsx(Button, { kind: "plain", label: "+ Add a new recipient" }) }));
    var selectedRecipient = recipients.find(function (recipient) { return recipient.id === value; });
    var recipientList = filteredRecipients.map(function (recipient) { return (_jsx(RecipientListItem, { recipient: recipient, value: value, searchTerm: searchTerm, isIndented: false, closeDropdown: closeDropdown, onChange: onChange, ref: function (node) {
            var listRefMap = getListRefMap();
            if (node) {
                listRefMap === null || listRefMap === void 0 ? void 0 : listRefMap.set(recipient.id, node);
            }
            else {
                listRefMap === null || listRefMap === void 0 ? void 0 : listRefMap.delete(recipient.id);
            }
        } }, recipient.id)); });
    return (_jsx("div", { onKeyDown: onKeyDown, className: styles.recipientSelector, children: _jsxs(Dropdown, { triggerLabel: label, triggerValue: selectedRecipient ? selectedRecipient.name : "", isOpen: isDropdownOpen, setIsOpen: setIsDropdownOpen, error: error, children: [_jsx(SearchBar, { searchTerm: searchTerm, setSearchTerm: onSearchTermUpdate, ref: searchInputRef }), recipientList, addNewRecipientButton] }) }));
};
export default RecipientSelector;
