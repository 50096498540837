var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import ApiHttp from "byzantine/src/ApiHttp";
import { useLocalization } from "@fluent/react";
import { nanoid } from "nanoid";
import { useLoadingContext, useNotificationContext } from "cerulean";
import useActions from "../hooks/useActions";
var Zelle = function () {
    var l10n = useLocalization().l10n;
    var sendNotification = useNotificationContext().sendNotification;
    var setIsLoading = useLoadingContext().setIsLoading;
    var goToPrevious = useActions().goToPrevious;
    var formRef = useRef(null);
    var fiIdInputRef = useRef(null);
    var pIdInputRef = useRef(null);
    var tokenInputRef = useRef(null);
    var closeIframe = function (event) {
        if (event.data.event === "jha:ztk:message.frame.close") {
            setIsLoading(true);
            goToPrevious();
        }
    };
    useEffect(function () {
        var deviceId = window.localStorage.getItem("device-id");
        if (!deviceId) {
            deviceId = nanoid();
            window.localStorage.setItem("device-id", deviceId);
        }
        var getToken = function (id) { return __awaiter(void 0, void 0, void 0, function () {
            var tokenResponse, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(formRef === null || formRef === void 0 ? void 0 : formRef.current) ||
                            !(fiIdInputRef === null || fiIdInputRef === void 0 ? void 0 : fiIdInputRef.current) ||
                            !(pIdInputRef === null || pIdInputRef === void 0 ? void 0 : pIdInputRef.current) ||
                            !(tokenInputRef === null || tokenInputRef === void 0 ? void 0 : tokenInputRef.current)) {
                            return [2 /*return*/];
                        }
                        setIsLoading(true);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, ApiHttp.fetch("zelle/jha/token", { method: "POST" }, { device_id: id })];
                    case 2:
                        tokenResponse = _b.sent();
                        if (!(tokenResponse === null || tokenResponse === void 0 ? void 0 : tokenResponse.jweToken))
                            throw Error("no jwe token returned");
                        formRef.current.action = tokenResponse.url;
                        fiIdInputRef.current.value = tokenResponse.fiId;
                        pIdInputRef.current.value = tokenResponse.pId;
                        tokenInputRef.current.value = tokenResponse.jweToken;
                        formRef.current.submit();
                        return [3 /*break*/, 5];
                    case 3:
                        _a = _b.sent();
                        sendNotification({
                            type: "negative",
                            text: l10n.getString("error-unknown", null, "Something went wrong"),
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        setIsLoading(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        getToken(deviceId);
        window.addEventListener("message", closeIframe);
        return function () { return window.removeEventListener("message", closeIframe); };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx("iframe", { src: "", title: "ztkFrame", id: "ztkFrame", name: "ztkLaunchFrame", className: "zelle-iframe" }), _jsxs("form", { ref: formRef, method: "POST", encType: "application/json", target: "ztkLaunchFrame", children: [_jsx("input", { ref: fiIdInputRef, name: "fiId", type: "hidden" }), _jsx("input", { ref: pIdInputRef, name: "pId", type: "hidden" }), _jsx("input", { name: "activity", value: "", type: "hidden" }), _jsx("input", { ref: tokenInputRef, name: "token", type: "hidden" })] })] }));
};
export default Zelle;
