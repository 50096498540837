var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, Children, isValidElement, cloneElement, useEffect, useCallback, useState, forwardRef, } from "react";
import cc from "classcat";
import { Check, ChevronUp, ChevronDown } from "react-feather";
import { TextInput, Button } from "@narmi/design_system";
var transparentOverlayStyle = {
    position: "fixed",
    inset: 0,
    backgroundColor: "transparent",
    zIndex: 999,
    cursor: "pointer",
};
var cardStyle = {
    zIndex: 1000,
    position: "absolute",
    top: 0,
    left: 0,
    maxHeight: "310px",
    backgroundColor: "var(--color-white)",
    width: "100%",
    boxSizing: "content-box",
    display: "flex",
    flexFlow: "column nowrap",
    border: "1px solid var(--theme-primary)",
    borderRadius: "4px",
};
var bodyStyle = {
    overflowY: "auto",
    maxHeight: "100%",
    width: "100%",
};
var Chevron = function (_a) {
    var open = _a.open, setOpen = _a.setOpen;
    var style = {
        position: "absolute",
        top: "12px",
        right: "6px",
        strokeWidth: "1",
    };
    if (!open) {
        return (_jsx(ChevronDown, { onClick: function (e) {
                // to avoid event listeners above interfering with opening the dropdown
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                setOpen(true);
            }, style: style }));
    }
    return (_jsx(ChevronUp, { onClick: function () { return setOpen(false); }, style: __assign(__assign({}, style), { top: "4px", zIndex: 1000 }) }));
};
export var DropdownListItem = forwardRef(function (_a, ref) {
    var children = _a.children, _b = _a.anyItemSelected, anyItemSelected = _b === void 0 ? false : _b, _c = _a.isSelected, isSelected = _c === void 0 ? false : _c, _d = _a.isIndented, isIndented = _d === void 0 ? false : _d, _e = _a.boldSelected, boldSelected = _e === void 0 ? true : _e, _f = _a.onClick, onClick = _f === void 0 ? function () { return null; } : _f, _g = _a.closeDropdown, closeDropdown = _g === void 0 ? function () { return null; } : _g, _h = _a.ariaLabel, ariaLabel = _h === void 0 ? "" : _h;
    var PreListItemStyling = function () {
        /* if any item has been selected or it should always be indented
         (which happens when it is part of a group), indent a little.
         if this item has been selected, display a checkmark. */
        if (!isIndented && !anyItemSelected && !isSelected)
            return null;
        return (_jsx("span", { className: "alignChild--center--center pre-option-checkmark", children: _jsx(Check, { color: "var(--theme-primary)", width: "20px", height: "20px", style: {
                    marginRight: "8px",
                    opacity: isSelected ? 1 : 0,
                } }) }));
    };
    return (_jsx("div", { role: "option", tabIndex: 0, "aria-label": ariaLabel, "aria-selected": isSelected, className: "dropdown-list-item hoverable clickable", onKeyUp: function (_a) {
            var key = _a.key;
            if (key === "Enter") {
                onClick();
                closeDropdown();
            }
        }, onClick: function () {
            onClick();
            closeDropdown();
        }, ref: ref, children: _jsxs("span", { className: "alignChild--left--center", children: [_jsx(PreListItemStyling, {}), _jsx("span", { className: cc([
                        "dropdown-list-item-content",
                        { "fontWeight--normal": isSelected && boldSelected },
                    ]), color: isSelected && boldSelected
                        ? "var(--color-black)"
                        : "var(--color-grey)", children: children })] }) }));
});
export var DropdownLinkItem = function (_a) {
    var children = _a.children, anyItemSelected = _a.anyItemSelected, _b = _a.isIndented, isIndented = _b === void 0 ? false : _b, setModalOpen = _a.setModalOpen, closeDropdown = _a.closeDropdown, _c = _a.ariaLabel, ariaLabel = _c === void 0 ? "" : _c;
    return (_jsx("div", { role: "option", tabIndex: 0, className: "dropdown-list-item dropdown-link-item hoverable", onKeyDown: function (event) {
            if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();
                setModalOpen(true);
                closeDropdown();
            }
        }, onClick: function () {
            setModalOpen(true);
            closeDropdown();
        }, "aria-label": ariaLabel, children: _jsx("div", { style: { height: "20px", display: "flex", alignItems: "left" }, children: _jsx(Button, { kind: "plain", style: anyItemSelected || isIndented ? { marginLeft: "36px" } : undefined, type: "button", children: children }) }) }));
};
export var DropdownItemGroupHeader = function (_a) {
    var group = _a.group;
    /* for rendering the header for a group of items (e.g. accounts grouped by type) */
    return (_jsx("div", { className: "dropdown-list-item", children: _jsx("span", { className: "fontWeight--semibold", style: { textTransform: "capitalize" }, color: "var(--nds-black)", children: group }) }));
};
export var SearchBar = forwardRef(function (_a, ref) {
    var searchTerm = _a.searchTerm, setSearchTerm = _a.setSearchTerm;
    /* For rendering the input used to search for a specific item */
    return (_jsx("div", { className: "dropdown-search", children: _jsx(TextInput, { search: true, value: searchTerm, icon: "search", placeholder: "Search", className: "dropdown-search-text-input", onChange: function (event) {
                return setSearchTerm(event.target.value);
            }, style: { margin: 0, color: "--color-mediumGrey" }, ref: ref }) }));
});
export var Dropdown = function (_a) {
    var _b, _c;
    var children = _a.children, _d = _a.defaultOpen, defaultOpen = _d === void 0 ? false : _d, triggerLabel = _a.triggerLabel, triggerValue = _a.triggerValue, error = _a.error, isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, _e = _a.ariaLabel, ariaLabel = _e === void 0 ? "" : _e, _f = _a.keyHandler, keyHandler = _f === void 0 ? function (event) { } : _f;
    /*
     * Dropdown menu
     * usage:
     *   <Dropdown
     *     triggerLabel={props.label}
     *     triggerValue={props.value}
     *     {...props}
     *   >
     *     <ContentToShowOnExpand />
     *   </Dropdown>
     */
    var isControlled = typeof isOpen !== "undefined" && typeof setIsOpen !== "undefined";
    var _g = __read(useState(defaultOpen), 2), uncontrolledOpen = _g[0], uncontrolledSetOpen = _g[1];
    var setOpen = isControlled ? setIsOpen : uncontrolledSetOpen;
    var open = isControlled ? isOpen : uncontrolledOpen;
    var openDropdown = function () {
        setOpen(true);
    };
    var closeDropdown = function () {
        setOpen(false);
    };
    var containerElement = useRef(null);
    var handleClick = useCallback(function (event) {
        var _a;
        var isClickInside = (_a = containerElement.current) === null || _a === void 0 ? void 0 : _a.contains(event.target);
        if (!isClickInside) {
            closeDropdown();
        }
    }, []);
    useEffect(function () {
        document.addEventListener("click", handleClick);
        return function () { return document.removeEventListener("click", handleClick); };
    }, []);
    var bodyContainer = useRef(null);
    var bodyChildren = ((_b = bodyContainer.current) === null || _b === void 0 ? void 0 : _b.children)
        ? Array.from((_c = bodyContainer.current) === null || _c === void 0 ? void 0 : _c.children)
        : [];
    return (_jsxs("div", { role: "button", tabIndex: 0, "aria-expanded": open, ref: containerElement, className: "dropdown-container", onKeyDown: function (event) {
            var _a, _b, _c, _d, _e, _f;
            if (["Tab", "Escape"].includes(event.key)) {
                closeDropdown();
            }
            else if (event.key === "Enter") {
                openDropdown();
            }
            else if (event.key === "ArrowDown") {
                var activeElement = document.activeElement;
                if (activeElement && !bodyChildren.includes(activeElement)) {
                    (_b = (_a = bodyChildren[0]) === null || _a === void 0 ? void 0 : _a.focus) === null || _b === void 0 ? void 0 : _b.call(_a);
                }
                else {
                    (_d = (_c = activeElement === null || activeElement === void 0 ? void 0 : activeElement.nextElementSibling) === null || _c === void 0 ? void 0 : _c.focus) === null || _d === void 0 ? void 0 : _d.call(_c);
                }
            }
            else if (event.key === "ArrowUp") {
                var activeElement = document.activeElement;
                if (activeElement && bodyChildren.includes(activeElement)) {
                    (_f = (_e = activeElement.previousElementSibling) === null || _e === void 0 ? void 0 : _e.focus) === null || _f === void 0 ? void 0 : _f.call(_e);
                }
            }
            keyHandler(event);
        }, children: [_jsx(TextInput, { onClick: openDropdown, label: triggerLabel, value: triggerValue, "aria-label": ariaLabel || triggerLabel, error: error, tabIndex: -1, readOnly: true }), open && (_jsxs(_Fragment, { children: [_jsx("div", { role: "button", tabIndex: 0, style: transparentOverlayStyle, onKeyUp: function (_a) {
                            var key = _a.key;
                            if (key === "Enter") {
                                closeDropdown();
                            }
                        }, onClick: closeDropdown }), _jsx("div", { "aria-modal": true, tabIndex: -1, role: "dialog", style: cardStyle, children: _jsx("div", { ref: bodyContainer, style: bodyStyle, children: Children.map(Children.toArray(children), function (child, i) {
                                return isValidElement(child) &&
                                    cloneElement(child, {
                                        closeDropdown: closeDropdown,
                                        key: i,
                                    });
                            }) }) })] })), _jsx(Chevron, { open: open, setOpen: setOpen })] }));
};
