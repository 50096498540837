import { useSelector } from "react-redux";
import _ from "lodash";
import { selectFedwireTemplates, selectOneFedwireTemplate } from "./selectors";
export var useFedwireTemplates = function () {
    return useSelector(selectFedwireTemplates);
};
export var useOneFedwireTemplate = function (fedwireTemplateId) {
    return useSelector(function (state) {
        return selectOneFedwireTemplate(state, fedwireTemplateId);
    }, _.isEqual);
};
