var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState, createContext, useContext, useMemo, } from "react";
import User from "byzantine/src/User";
var CurrentUserContext = createContext({
    currentUser: null,
    setCurrentUser: function () { },
});
var getCurrentUserFromHTML = function () {
    var _a;
    var currentUser = null;
    if (document.getElementById("current_user")) {
        currentUser =
            User.deserialize(JSON.parse(((_a = document.getElementById("current_user")) === null || _a === void 0 ? void 0 : _a.textContent) || "")) || null;
    }
    return currentUser;
};
export var CurrentUserContextProvider = function (_a) {
    var children = _a.children, currentUser = _a.currentUser;
    var _b = __read(useState(currentUser || null), 2), user = _b[0], setUser = _b[1];
    useEffect(function () {
        // Hydrate user from global if it is not provided
        var updatedUser = currentUser || getCurrentUserFromHTML();
        setUser(updatedUser);
    }, [currentUser]);
    var value = useMemo(function () { return ({ currentUser: user, setCurrentUser: setUser }); }, [user, setUser]);
    return (_jsx(CurrentUserContext.Provider, { value: value, children: children }));
};
export var useCurrentUser = function () { return useContext(CurrentUserContext); };
export default CurrentUserContext;
