import React from "react";
import PropTypes from "prop-types";
import EstatementsEnabled from "./EstatementsEnabled";
import EstatementsNotEnabled from "./EstatementsNotEnabled";

const PaperlessStatementContent = ({
  confirmedEstatementAccess,
  estatementsEnabled,
  estatementsWereEnabledInitially,
  setConfirmedEstatementAccess,
  setEstatementsEnabled,
}) => {
  if (!estatementsEnabled)
    return (
      <EstatementsNotEnabled
        confirmedEstatementAccess={confirmedEstatementAccess}
        setConfirmedEstatementAccess={setConfirmedEstatementAccess}
        setEstatementsEnabled={setEstatementsEnabled}
      />
    );
  return (
    <EstatementsEnabled
      estatementsWereEnabledInitially={estatementsWereEnabledInitially}
    />
  );
};
PaperlessStatementContent.propTypes = {
  confirmedEstatementAccess: PropTypes.bool,
  estatementsEnabled: PropTypes.bool,
  estatementsWereEnabledInitially: PropTypes.bool,
  setConfirmedEstatementAccess: PropTypes.func,
  setEstatementsEnabled: PropTypes.func,
};

export default PaperlessStatementContent;
