class TemplateSetting {
  constructor(template) {
    this.template = template;
  }

  evaluate(context) {
    if (
      !this.template ||
      !context ||
      typeof context !== "object" ||
      Object.entries(context).length === 0
    )
      return "";
    let evaluation = this.template;
    Object.entries(context).forEach(([key, value]) => {
      evaluation = evaluation.replace(`{${key}}`, value);
    });
    return evaluation;
  }
}

export default TemplateSetting;
