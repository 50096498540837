import { createDeepEqualSelector } from "../utils/createDeepEqualSelector";
import BAccount from "../../../../Account";
import { createGroups } from "../helpers/accountGroups.helpers";
export var selectAccountSorting = function (state) {
    return state.accountSorting.sorting;
};
export var selectAccountsById = function (state) { return state.accounts.byId; };
export var selectAccountIdWithCertainty = function (_, accountId) { return accountId; };
export var outputBAccount = function (accounts, accountId) { return BAccount.deserialize(accounts[accountId]); };
export var selectBAccount = createDeepEqualSelector([selectAccountsById, selectAccountIdWithCertainty], outputBAccount);
export var selectAllAccounts = function (state) {
    return Object.values(state.accounts.byId);
};
export var selectInternalAccounts = createDeepEqualSelector(selectAllAccounts, function (accounts) {
    return accounts.filter(function (account) { return account.source === "institution"; });
});
export var selectAccounts = createDeepEqualSelector([selectInternalAccounts, function (_, accountIds) { return accountIds; }], function (accounts, accountIds) {
    return accounts.filter(function (_a) {
        var id = _a.id;
        return !(accountIds === null || accountIds === void 0 ? void 0 : accountIds.length) || accountIds.includes(id);
    });
});
export var selectBAccounts = createDeepEqualSelector([selectAllAccounts], function (accounts) {
    return accounts.map(function (a) {
        return BAccount.deserialize(a);
    });
});
export var selectPayableLoanAccounts = createDeepEqualSelector([selectBAccounts], function (accounts) {
    return accounts.filter(function (a) { return a.isInternalLoan() && a.features.includes("transfer_destination"); });
});
export var selectLoansPayableByCard = createDeepEqualSelector([selectBAccounts], function (accounts) { return accounts.filter(function (a) { return a.isPayableByCard(); }); });
export var selectGroupedLoanAccounts = createDeepEqualSelector([selectPayableLoanAccounts, selectAccountSorting], function (accounts, sorting) { return createGroups(accounts, sorting); });
export var selectGroupedLoanAccountsPayableByCard = createDeepEqualSelector([selectLoansPayableByCard, selectAccountSorting], function (accounts, sorting) { return createGroups(accounts, sorting); });
export var selectMinimumPayment = createDeepEqualSelector([
    function (state) { return selectAccounts(state); },
    function (_, toAccountId) { return toAccountId; },
], function (accounts, toAccountId) {
    var _a;
    var loanAccount = accounts.find(function (account) { return account.id === toAccountId; }) || null;
    return ((_a = loanAccount === null || loanAccount === void 0 ? void 0 : loanAccount.loan_details) === null || _a === void 0 ? void 0 : _a.minimum_payment) || null;
});
export var selectMinPaymentDueDate = createDeepEqualSelector([
    function (state) { return selectAccounts(state); },
    function (_, toAccountId) { return toAccountId; },
], function (accounts, toAccountId) {
    var _a;
    var loanAccount = accounts.find(function (account) { return account.id === toAccountId; }) || null;
    return ((_a = loanAccount === null || loanAccount === void 0 ? void 0 : loanAccount.loan_details) === null || _a === void 0 ? void 0 : _a.next_payment_at) || null;
});
export var selectSourceForInternalLoanPayments = createDeepEqualSelector([selectInternalAccounts], function (accounts) {
    return accounts.filter(function (account) { return "transfer_source" in account.features; });
});
export var selectGroupedInternalLoanPaymentSources = createDeepEqualSelector([selectSourceForInternalLoanPayments, selectAccountSorting], function (accounts, sorting) {
    var deserializedAccounts = accounts.map(function (a) {
        return BAccount.deserialize(a);
    });
    return createGroups(deserializedAccounts, sorting);
});
