import React from "react";
import PropTypes from "prop-types";

import mastercardSymbol from "../../../../../assets/images/mastercard.svg";
import visaSymbol from "../../../../../assets/images/visa.svg";
import discoverSymbol from "../../../../../assets/images/discover.svg";
import amexSymbol from "../../../../../assets/images/amex.svg";

const CardLogos = ({ fundCardNetwork }) => {
  if (!fundCardNetwork || fundCardNetwork.length === 0) return null;
  return (
    <div className="alignChild--left--top" style={{ gap: "var(--space-xxs)" }}>
      {fundCardNetwork.includes("visa") && (
        <img alt="VISA card logo" className="card-icon" src={visaSymbol} />
      )}
      {fundCardNetwork.includes("mastercard") && (
        <img
          alt="Mastercard card logo"
          className="card-icon"
          src={mastercardSymbol}
        />
      )}
      {fundCardNetwork.includes("discover") && (
        <img
          alt="Discover card logo"
          className="card-icon"
          src={discoverSymbol}
        />
      )}
      {fundCardNetwork.includes("amex") && (
        <img
          alt="American Express card logo"
          className="card-icon"
          src={amexSymbol}
        />
      )}
    </div>
  );
};
CardLogos.propTypes = {
  fundCardNetwork: PropTypes.array,
};

export default CardLogos;
