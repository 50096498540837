import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { TextInput } from "@narmi/design_system";
var AccountNumberTextInput = function (_a) {
    var onChange = _a.onChange, value = _a.value, error = _a.error, _b = _a.label, label = _b === void 0 ? "Account number" : _b;
    var handleInput = function (event) {
        onChange(event.target.value.replace(/[^\d]/g, ""));
    };
    return (_jsx(TextInput, { id: "account_number", label: label, field: "account_number", onChange: handleInput, value: value, error: error }));
};
export default AccountNumberTextInput;
