import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import { Button } from "@narmi/design_system";
import { ContextForm } from "cerulean";
import ActionReview from "./ActionReview";
import AccountContext from "../contexts/AccountContext";

const M2MTransferActionReview = ({ data, memberFriends, onSubmit, goBack, cancel }) => {
  const { accounts } = useContext(AccountContext);
  const { l10n } = useLocalization();

  const details = [
    {
      label: l10n.getString("label-from", null, "From"),
      info: accounts
        .find((a) => a.id === data.from_account_id)
        .getDescription(),
    },
    {
      label: l10n.getString("label-to", null, "To"),
      info: memberFriends.find((member) => member.id === data.to_member_id)
        .description,
    },

    {
      label: l10n.getString("label-amount", null, "Amount"),
      info: `${data.amount}`,
    },
  ];

  if (data.memo) {
    details.push({
      label: l10n.getString("label-memo", null, "Memo"),
      info: data.memo,
    });
  }

  return (
    <ActionReview
      details={details}
      icon="m2m"
      title={l10n.getString("heading-review", null, "Review")}
      goBack={goBack}
    >
      <ContextForm.ActionBar vertical>
        <ContextForm.Action onSubmit={onSubmit}>
          <Button
            label={l10n.getString("button-send-money", null, "Send money")}
          />
        </ContextForm.Action>
        <div className="transfer-cancel-button">
          <Button
            onClick={() => cancel()}
            kind="negative"
            type="button"
            label={l10n.getString("button-cancel", null, "")}
          />
        </div>
      </ContextForm.ActionBar>
    </ActionReview>
  );
};
M2MTransferActionReview.propTypes = {
  accounts: PropTypes.array,
  memberFriends: PropTypes.array,
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  goBack: PropTypes.func,
  cancel: PropTypes.func,
};

export default M2MTransferActionReview;
