// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttonbar--kT7fI{display:flex;flex-direction:column}@media(min-width: 1280px){.buttonbar--kT7fI{flex-direction:row}}", "",{"version":3,"sources":["webpack://./components/ButtonBar.module.scss"],"names":[],"mappings":"AAAA,kBACE,YAAA,CACA,qBAAA,CAGF,0BACE,kBACE,kBAAA,CAAA","sourcesContent":[".buttonbar {\n  display: flex;\n  flex-direction: column;\n}\n\n@media (min-width: 1280px) {\n  .buttonbar {\n    flex-direction: row;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonbar": "buttonbar--kT7fI"
};
export default ___CSS_LOADER_EXPORT___;
