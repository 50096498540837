import React, { useContext } from "react";
import PropTypes from "prop-types";
import { jsonFromDocument } from "cerulean";

const UserFeaturesContext = React.createContext({});
export const UserFeaturesContextProvider = ({ children }) => {
  const userFeatures = jsonFromDocument("features");
  return (
    <UserFeaturesContext.Provider value={userFeatures}>
      {children}
    </UserFeaturesContext.Provider>
  );
};

export const useUserFeatures = () => useContext(UserFeaturesContext);

UserFeaturesContextProvider.propTypes = {
  children: PropTypes.node,
};

export default UserFeaturesContext;
