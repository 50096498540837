import { jsx as _jsx } from "react/jsx-runtime";
import MarketingImageTargetWeb from "../../marketing";
import { useUserFeatures } from "../../contexts/UserFeaturesContext";
var placementAttributesByAddress = {
    "b3bbe7e6-f68e-45e3-8124-691e18a4a3ca": {
        width: 300,
        height: 250,
        containerClassName: "dashboard-sidebar-card-image alignChild--center--center",
    },
    "fb06f1b7-87ff-4fcf-8a98-51a7fb4ba36d": {
        width: 728,
        height: 90,
        containerClassName: "dashboard-banner-image alignChild--center--center margin--top--xl",
    },
};
var getPlacementSizeByAddress = function (address) {
    return placementAttributesByAddress[address];
};
var MarketingImage = function (_a) {
    var address = _a.address;
    var _b = getPlacementSizeByAddress(address), width = _b.width, height = _b.height, containerClassName = _b.containerClassName;
    var features = useUserFeatures();
    return features.olb_enable_marketing_banners ? (_jsx("div", { className: containerClassName, children: _jsx(MarketingImageTargetWeb, { address: address, debugSize: { width: width, height: height } }) })) : null;
};
export default MarketingImage;
