import React, { useEffect, useState } from "react";
import { useLocalization } from "@fluent/react";
import ApiHttp from "byzantine/src/ApiHttp";
import { useNotificationContext } from "cerulean";
import { LoadingSkeleton } from "@narmi/design_system";
import AccountCard from "./AccountCard";

const CardManagement = () => {
  const { l10n } = useLocalization();
  const [cards, setCards] = useState([]);
  const [fetchingCards, setFetchingCards] = useState(true);
  const { sendNotification } = useNotificationContext();
  useEffect(() => {
    // on initial render send request for cards
    ApiHttp.fetch(
      "cards",
      { method: "GET", headers: { "API-Version": "0016" } },
      {}
    )
      .then((response) => {
        if (!response.cards) return;
        setCards(response.cards);
      })
      .catch(() => {
        sendNotification({
          type: "negative",
          text: l10n.getString("error-unknown", null, "Something went wrong"),
        });
        setCards([]);
      })
      .finally(() => {
        setFetchingCards(false);
      });
  }, []);
  return (
    <LoadingSkeleton
      isLoading={fetchingCards}
      content={"paragraph"}
      showTitle={true}
      lines={4}
      size={"Large"}
    >
      <div className="card-controls-container">
        {cards.length > 0 ? (
          cards.map((card) => (
            <AccountCard
              key={card.id}
              id={card.id}
              svg={card.svg}
              bin={card.bin}
              card_provider={"" || card.card_provider}
              card_text_color={card.card_text_color}
              last_four_digits={card.last_four_digits}
              state={card.state}
            />
          ))
        ) : (
          <div>
            {l10n.getString(
              "empty-no-cards",
              null,
              " You have no cards available for management in online banking at this time."
            )}
          </div>
        )}
      </div>
    </LoadingSkeleton>
  );
};

export default CardManagement;
