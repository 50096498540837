import React from "react";
import PropTypes from "prop-types";
import { Button, Dialog, Row } from "cerulean";

const DeleteRuleDialog = ({ isDeleteDialogOpen, closeDialog, onDelete }) => (
  <Dialog
    isOpen={isDeleteDialogOpen}
    onUserDismiss={closeDialog}
    title={"Delete your savings rule"}
    footer={
      <Row alignItems="center" justifyContent="end">
        <Row.Item shrink>
          <Button onClick={closeDialog} kind="negative" label="Cancel" />
        </Row.Item>
        <Row.Item shrink>
          <Button
            onClick={onDelete}
            kind="primary"
            label="Yes, delete"
            data-testid="delete-rule"
          />
        </Row.Item>
      </Row>
    }
  >
    <div>{"Are you sure you want to delete your automatic savings rule?"}</div>
  </Dialog>
);
DeleteRuleDialog.propTypes = {
  isDeleteDialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteRuleDialog;
