import { paramsToQuery } from "../../../composites";
var resourceUrl = "limits";
export var API = {
    fetch: function (type) { return ({
        url: resourceUrl +
            (type && type !== "all" ? "?".concat(paramsToQuery([["type", type]])) : ""),
        options: {
            method: "GET",
        },
    }); },
};
