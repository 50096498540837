var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ApiHttp from "byzantine/src/ApiHttp";
import { Button, ContextForm, Dialog, SimpleDateInput, TaxIDTextInput, TextInput, useFormData, useLoadingContext, useNotificationContext, } from "cerulean";
import { useTranslation } from "react-i18next";
var LinkInternalAccountModal = function (_a) {
    var linkInternalAccountModalOpen = _a.linkInternalAccountModalOpen, setLinkInternalAccountModalOpen = _a.setLinkInternalAccountModalOpen, fetchMemberships = _a.fetchMemberships;
    var t = useTranslation().t;
    var _b = useFormData(), formData = _b.formData, onChange = _b.onChange, setFormData = _b.setFormData;
    var sendNotification = useNotificationContext().sendNotification;
    var setIsLoading = useLoadingContext().setIsLoading;
    var onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var payload, message, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    payload = __assign(__assign({}, formData), { user_category: "personal" });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, ApiHttp.fetch("accounts/membership", { method: "POST" }, payload)];
                case 2:
                    message = (_a.sent()).message;
                    setLinkInternalAccountModalOpen(false);
                    setIsLoading(false);
                    fetchMemberships();
                    sendNotification({ type: "success", text: message });
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    setLinkInternalAccountModalOpen(false);
                    setIsLoading(false);
                    sendNotification({
                        type: "negative",
                        text: t(err_1),
                    });
                    return [3 /*break*/, 4];
                case 4:
                    setFormData({});
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Dialog, { isOpen: linkInternalAccountModalOpen, footer: _jsxs("div", { style: { textAlign: "right" }, children: [_jsx(Button, { kind: "negative", onClick: function () { return setLinkInternalAccountModalOpen(false); }, children: t("Cancel") }), _jsx("span", { className: "margin--left--s", children: _jsx(Button, { onClick: onSubmit, children: t("Link account") }) })] }), onUserDismiss: function () { return setLinkInternalAccountModalOpen(false); }, title: t("Link another account"), children: [_jsx("div", { children: "Enter your information below and we\u2019ll link your account." }), _jsx("div", { className: "padding--top--l", children: _jsxs(ContextForm, { data: formData, onChange: onChange, children: [_jsx(ContextForm.Field, { required: true, children: _jsx(TextInput, { field: "primary_account_number", label: "Account number" }) }, "primary_account_number"), _jsx(ContextForm.Field, { required: true, children: _jsx(TaxIDTextInput, { field: "ssn", label: "Social security number/ITIN" }) }, "ssn"), _jsx(ContextForm.Field, { required: true, children: _jsx(SimpleDateInput, { field: "date_of_birth", label: "Date of birth" }) }, "date_of_birth")] }) })] }));
};
export default LinkInternalAccountModal;
