import React from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import { TextInput } from "@narmi/design_system";
import {
  ContextForm,
  PhoneTextInput,
  EmailFormField,
  SimpleDateInput,
  TaxIDTextInput,
} from "cerulean";
import BeneficiarySelector from "./BeneficiarySelector";
import BankingUSAddressField from "../address/BankingUSAddressField";

const AddBeneficiaryForm = ({
  allOtherBeneficiaries,
  showNewBeneficiaryForm,
  setShowNewBeneficiaryForm,
  onChange,
  data,
  supportPhoneNumber,
}) => {
  const { l10n } = useLocalization();
  return (
    <>
      <ContextForm.Field required>
        <BeneficiarySelector
          field="beneficiary_id"
          label={l10n.getString("label-beneficiary")}
          beneficiaries={allOtherBeneficiaries}
          setShowNewBeneficiaryForm={setShowNewBeneficiaryForm}
        />
      </ContextForm.Field>
      {showNewBeneficiaryForm && (
        <>
          <ContextForm.Field required>
            <TextInput field="first_name" label={l10n.getString("label-first-name")} />
          </ContextForm.Field>
          <ContextForm.Field required>
            <TextInput field="last_name" label={l10n.getString("label-last-name")} />
          </ContextForm.Field>
          <ContextForm.Field required>
            <SimpleDateInput
              field="birth_date"
              label={l10n.getString("label-dob")}
            />
          </ContextForm.Field>
          <ContextForm.Field required>
            <TaxIDTextInput
              field="tax_id"
              label={l10n.getString("label-tin-or-ssn")}
              tooltipText={l10n.getString("tooltip-beneficiary-ssn", { supportPhoneNumber })}
            />
          </ContextForm.Field>
          <EmailFormField label={l10n.getString("label-email")} required />
          <ContextForm.Field required>
            <PhoneTextInput field="phone" label={l10n.getString("labelPhoneNumber")} />
          </ContextForm.Field>
          <BankingUSAddressField
            onUpdate={onChange}
            data={data || {}}
            onChange={onChange}
            showTitle={false}
            streetAddressLabel={l10n.getString("label-street-address")}
            apartmentLabel={l10n.getString("label-address-2")}
            cityLabel={l10n.getString("label-city")}
            stateLabel={l10n.getString("label-state")}
            zipCodeLabel={l10n.getString("label-postal")}
          />
        </>
      )}
    </>
  );
};
AddBeneficiaryForm.propTypes = {
  value: PropTypes.string,
  allOtherBeneficiaries: PropTypes.array,
  showNewBeneficiaryForm: PropTypes.bool,
  setShowNewBeneficiaryForm: PropTypes.func,
  onChange: PropTypes.func,
  data: PropTypes.object,
  supportPhoneNumber: PropTypes.string,
};

export default AddBeneficiaryForm;
