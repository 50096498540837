import React from "react";
var objectKeys = function (obj) {
    return Object.keys(obj);
};
/**
 * Memoize an object by shallow comparison of its values.
 * Works best with objects constructed from values memoized upstream.
 *
 * def useExample() => {
 *   const data = useSelector(selectData);
 *   const fn = useCallback(() => { ... }, [data]);
 *
 *   return useObjectMemo({ data, fn });
 * }
 *
 */
export function useObjectMemo(obj, ignore) {
    if (ignore === void 0) { ignore = []; }
    var keys = objectKeys(obj).filter(function (k) { return !ignore.includes(k); });
    keys.sort(); // Consistent dependencies order
    var deps = keys.map(function (k) { return obj[k]; });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useMemo(function () { return obj; }, deps);
}
