var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useLocalization } from "@fluent/react";
import { Button, ContentCard, IconButton, LoadingSkeleton, Row, useLoadingContext, useNotificationContext, } from "cerulean";
import MemberFriend from "byzantine/src/MemberFriend";
import DeleteDialog from "./DeleteDialog";
import NewMemberModal from "../../transfer/NewMemberModal";
var MemberFriendCard = function (_a) {
    var memberFriend = _a.memberFriend, fetchMemberFriends = _a.fetchMemberFriends;
    var _b = __read(useState(false), 2), isDeleteMemberDialogOpen = _b[0], setIsDeleteMemberDialogOpen = _b[1];
    var sendNotification = useNotificationContext().sendNotification;
    var setIsLoading = useLoadingContext().setIsLoading;
    var l10n = useLocalization().l10n;
    var deleteMember = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsDeleteMemberDialogOpen(false);
                    setIsLoading(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, memberFriend.delete()];
                case 2:
                    _b.sent();
                    setIsLoading(false);
                    sendNotification({
                        type: "success",
                        text: l10n.getString("member-deleted"),
                    });
                    return [4 /*yield*/, fetchMemberFriends()];
                case 3:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 4:
                    _a = _b.sent();
                    sendNotification({
                        type: "negative",
                        text: l10n.getString("member-not-deleted"),
                    });
                    setIsLoading(false);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsxs(ContentCard, { children: [_jsxs(Row, { children: [_jsx(Row.Item, { children: _jsx("div", { className: "fontColor--heading fontWeight--semibold", children: memberFriend.name }) }), _jsx(Row.Item, { shrink: true, children: _jsx("div", { className: "delete-member-icon", children: _jsx(IconButton, { name: "trash-2", onClick: function () { return setIsDeleteMemberDialogOpen(true); }, label: l10n.getString("member-management"), kind: "action" }) }) })] }), _jsx("div", { className: "margin--top--xxs", children: memberFriend.displayMaskedAccountNumber() })] }), _jsx(DeleteDialog, { recipientName: memberFriend.name, recipientType: l10n.getString("member-or-friend"), onDelete: deleteMember, isOpen: isDeleteMemberDialogOpen, closeDialog: function () { return setIsDeleteMemberDialogOpen(false); } })] }));
};
var MemberFriendSection = function (_a) {
    var accounts = _a.accounts;
    var _b = __read(useState([]), 2), memberFriends = _b[0], setMemberFriends = _b[1];
    var _c = __read(useState(false), 2), isAddMemberDialogOpen = _c[0], setIsAddMemberDialogOpen = _c[1];
    var _d = __read(useState(false), 2), isFetchingMembers = _d[0], setIsFetchingMembers = _d[1];
    var l10n = useLocalization().l10n;
    var sendNotification = useNotificationContext().sendNotification;
    var fetchMemberFriends = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setIsFetchingMembers(true);
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, 4, 5]);
                    _a = setMemberFriends;
                    return [4 /*yield*/, MemberFriend.getMemberFriends()];
                case 2:
                    _a.apply(void 0, [(_c.sent()) || []]);
                    return [3 /*break*/, 5];
                case 3:
                    _b = _c.sent();
                    sendNotification({
                        type: "negative",
                        text: l10n.getString("member-fetch-fail"),
                    });
                    return [3 /*break*/, 5];
                case 4:
                    setIsFetchingMembers(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchMemberFriends();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "margin--top--xl margin--bottom--l", children: _jsxs(Row, { children: [_jsx(Row.Item, { children: _jsx("div", { className: "fontSize--l fontColor--heading fontWeight--bold", children: l10n.getString("members-label") }) }), _jsx(Row.Item, { shrink: true, children: _jsx(Button, { kind: "plain", label: l10n.getString("member-add-label"), onClick: function () { return setIsAddMemberDialogOpen(true); } }) })] }) }), _jsx(LoadingSkeleton, { content: "paragraph", isLoading: isFetchingMembers, children: _jsxs("div", { className: "recipients-container", children: [memberFriends === null || memberFriends === void 0 ? void 0 : memberFriends.map(function (member) { return (_jsx(MemberFriendCard, { memberFriend: member, fetchMemberFriends: fetchMemberFriends }, member.id)); }), !(memberFriends === null || memberFriends === void 0 ? void 0 : memberFriends.length) && (_jsx("div", { children: l10n.getString("member-empty") }))] }) }), _jsx(NewMemberModal, { accounts: accounts, open: isAddMemberDialogOpen, handleClose: function () {
                    setIsAddMemberDialogOpen(false);
                }, newMemberAdded: function () {
                    sendNotification({
                        type: "success",
                        text: l10n.getString("member-added"),
                    });
                    fetchMemberFriends();
                } })] }));
};
export default MemberFriendSection;
