var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
/**
 *
 * Implements a React Portal https://reactjs.org/docs/portals.html
 * in the manner that is compatible with NextJS
 *
 *
 */
var Portal = function (_a) {
    var children = _a.children, rootId = _a.rootId;
    var ref = useRef();
    var _b = __read(useState(false), 2), mounted = _b[0], setMounted = _b[1];
    useEffect(function () {
        ref.current = document.querySelector("#".concat(rootId));
        setMounted(true);
    }, [children]);
    return mounted ? ReactDOM.createPortal(children, ref.current) : null;
};
export default Portal;
