var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useState, useEffect, useCallback, } from "react";
import rafSchd from "raf-schd";
import { characterSize, punctuationSize } from "./characterSizes";
var getContentWidth = function (element) {
    var _a = getComputedStyle(element), paddingLeft = _a.paddingLeft, paddingRight = _a.paddingRight;
    var width = element.clientWidth - parseFloat(paddingLeft) - parseFloat(paddingRight);
    return width;
};
var getSmallestContainingWidth = function (element) {
    var parent = element === null || element === void 0 ? void 0 : element.parentElement;
    var smallestWidth = Number.MAX_SAFE_INTEGER;
    while (parent) {
        var width = getContentWidth(parent);
        if (width < smallestWidth) {
            smallestWidth = width;
        }
        parent = parent === null || parent === void 0 ? void 0 : parent.parentElement;
    }
    return smallestWidth;
};
var estimateTextWidthAtFontSize = function (text, fontSize) {
    var chars = text.split("");
    var width = 0;
    for (var char in chars) {
        var isPunctuation = [",", ".", " "].includes(char);
        var charWidth = (isPunctuation
            ? punctuationSize[fontSize]
            : characterSize[fontSize]).width;
        width += charWidth;
    }
    return width;
};
var calculateMaxFontSize = function (parentWidth, text) {
    if (parentWidth !== null) {
        for (var fontSize = 1; fontSize < 59; fontSize += 1) {
            var nextTextWidth = estimateTextWidthAtFontSize(text, fontSize + 1);
            if (nextTextWidth > parentWidth) {
                return fontSize;
            }
        }
    }
    return 60;
};
var TextFit = function (_a) {
    var text = _a.text, ariaLive = _a.ariaLive;
    var parentRef = useRef(null);
    var _b = __read(useState(null), 2), parentWidth = _b[0], setParentWidth = _b[1];
    var _c = __read(useState(60), 2), fontSize = _c[0], setFontSize = _c[1];
    var windowResize = useCallback(rafSchd(function () {
        if (parentRef === null || parentRef === void 0 ? void 0 : parentRef.current) {
            var width = getSmallestContainingWidth(parentRef.current);
            setParentWidth(width);
        }
    }), [parentRef]);
    useEffect(function () {
        window.addEventListener("resize", windowResize);
        return function () {
            window.removeEventListener("resize", windowResize);
        };
    }, []);
    useEffect(function () {
        windowResize();
    }, [parentRef]);
    useEffect(function () {
        var newFontSize = calculateMaxFontSize(parentWidth, text);
        setFontSize(newFontSize);
    }, [parentWidth, text]);
    return (_jsx("div", { ref: parentRef, "aria-live": ariaLive, style: {
            fontSize: fontSize,
            height: "100%",
            overflow: "hidden",
            transition: "font-size 100ms",
        }, children: text }));
};
export default TextFit;
