import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "cerulean";

const FilterCheckbox = ({ filter, toggleFilter, filters, label }) => (
    <Checkbox
      label={label}
      checked={filters.indexOf(filter) !== -1}
      onChange={() => toggleFilter(filter)}
      testId={`${label}-checkbox`}
    />
  );

FilterCheckbox.propTypes = {
  filter: PropTypes.func,
  toggleFilter: PropTypes.func,
  filters: PropTypes.array,
  label: PropTypes.string,
};

export default FilterCheckbox;
