import { Row } from "@narmi/design_system";
import cc from "classcat";
import React from "react";

const Step = ({ step, isCompleted = false, isActive = false, action }) => {
  return (
    <div
      className={cc([
        "step",
        "alignChild--left--center",
        { "fontWeight--semibold": isActive },
      ])}
      style={{
        color: isActive ? "var(--theme-primary)" : "",
        cursor: isCompleted && action ? "pointer" : "",
      }}
      onClick={() => {
        action ? isCompleted && action(step) : null;
      }}
    >
      {step.label}
      {isCompleted && (
        <span className="narmi-icon-check-circle margin--left--xs" />
      )}
    </div>
  );
};

/**
 * a single line is 20px
 * every line except the last has 20px of padding
 */
const ProgressBar = ({ stepCount = 1, currentStep = 1 }) => {
  const height = stepCount * 40 - 20;
  const doneHeight = currentStep * 40 - 20;
  return (
    <div
      style={{
        position: "relative",
        height: `${height}px`,
        width: "8px",
        borderRadius: "4px",
        backgroundColor:
          "RGBA(var(--theme-rgb-primary), var(--subdued-20-opacity))",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: `${doneHeight}px`,
          width: "100%",
          borderRadius: "4px",
          backgroundColor: "var(--theme-primary)",
        }}
      />
    </div>
  );
};

const beforeUnloadListener = (event) => {
  event.preventDefault();
  return (event.returnValue = "Are you sure you want to exit?");
};

/**
 *
 * Renders only on desktop screen widths (so view in full screen in Storybook)
 *
 * Shows the steps of a DAO application, and highlights the current step
 *
 */
// TODO: activeStep must be of type Step
const Sidebar = ({ activeStep, steps, logo_url, action }) => {
  const currentStepIndex = steps
    .map((route) => route.path)
    .indexOf(activeStep.path);
  const totalSteps = steps.length;

  React.useEffect(() => {
    if (activeStep === "Your business") {
      addEventListener("beforeunload", beforeUnloadListener, { capture: true });
    }
    const remove = () =>
      removeEventListener("beforeunload", beforeUnloadListener, {
        capture: true,
      });
    if (activeStep === "Review") {
      remove();
    }
    return remove;
  }, [activeStep]);
  if (currentStepIndex === -1) return null;

  return (
    <>
      <div
        className="sidebar"
        style={{
          backgroundColor:
            "RGBA(var(--theme-rgb-primary), var(--subdued-10-opacity))",
        }}
      >
        {logo_url && (
          <h1
            className="logo"
            style={{ backgroundImage: `url(${logo_url})` }}
          />
        )}
        <div className="steps margin--top--xl">
          <Row gapSize="m">
            <Row.Item shrink className="">
              <ProgressBar
                stepCount={steps.length}
                currentStep={currentStepIndex + 1}
              />
            </Row.Item>
            <Row.Item>
              {steps.map((step, i) => (
                <Step
                  key={i}
                  step={step}
                  action={action}
                  isCompleted={currentStepIndex > i}
                  isActive={currentStepIndex === i}
                />
              ))}
            </Row.Item>
          </Row>
        </div>
      </div>
      <div className="top-bar">
        <Row gapSize="m" alignItems="center">
          <Row.Item className="">
            <div style={{ display: "flex", alignItems: "center" }}>
              <h1
                className="logo"
                style={{ backgroundImage: `url(${logo_url})` }}
              />
            </div>
          </Row.Item>
          <Row.Item shrink>
            <div style={{ color: "var(--color-mediumGrey)" }}>
              Step {currentStepIndex + 1} of {totalSteps}
            </div>
          </Row.Item>
        </Row>
      </div>
    </>
  );
};

export default Sidebar;
