import React from "react";
import PropTypes from "prop-types";
import { useLocalization, Localized } from "@fluent/react";
import ApiHttp from "byzantine/src/ApiHttp";
import User from "byzantine/src/User";
import { Checkbox, ContextForm, ProgressButtons, useFormData } from "cerulean";

const ReviewDisclosures = ({ user, updateUser, institution }) => {
  const { l10n } = useLocalization();
  const {
    terms_url,
    privacy_url,
    esign_url,
    include_esign_disclosure_link_on_review_disclosures_page,
  } = institution;
  const { formData, onChange } = useFormData({});

  const defaultAcceptTermsText =
    include_esign_disclosure_link_on_review_disclosures_page
      ? "I agree to the Privacy Policy, Terms of Service, and E-Sign Disclosure."
      : "I agree to the Privacy Policy and Terms of Service.";

  const onSubmit = (callback) => {
    ApiHttp.fetch(`terms`, { method: "POST" })
      .then(() => {
        callback();
        const updatedUser = new User({
          ...user,
          has_accepted_latest_terms: true,
        });
        updateUser(updatedUser);
        if (!updatedUser?.is_converting) {
          // if the user is not mid-enrollment, then they were brought here solely to accept updated terms
          // redirect the user to the dashboard upon successful request
          window.location.href = "/dashboard";
        }
      })
      .catch((err) => callback(err));
  };

  return (
    <div className="small">
      <Localized id="heading-review-disclosures">
        <h1>Review disclosures</h1>
      </Localized>
      <div className="margin--top--xs" data-testid="enrollment-page-subtitle">
        <Localized id="subheading-read-disclosures">
          Please click and read through the following disclosures.
        </Localized>
      </div>

      <div className="disclosure-links">
        {esign_url &&
          include_esign_disclosure_link_on_review_disclosures_page && (
            <div className="disclosure-item">
              <a rel="noreferrer" target="_blank" href={esign_url}>
                <Localized id="disclosure-esign">E-sign Disclosure</Localized>
              </a>
            </div>
          )}
        {privacy_url && (
          <div className="disclosure-item">
            <a rel="noreferrer" target="_blank" href={privacy_url}>
              <Localized id="disclosure-privacy">Privacy Policy</Localized>
            </a>
          </div>
        )}
        {terms_url && (
          <div className="disclosure-item">
            <a rel="noreferrer" target="_blank" href={terms_url}>
              <Localized id="disclosure-tos">Terms of Service</Localized>
            </a>
          </div>
        )}
      </div>

      <ContextForm data={formData} onChange={onChange}>
        <ContextForm.Field
          validate={(value) => {
            if (value) {
              return "";
            }
            return l10n.getString(
              "error-accept-terms",
              null,
              "Please review and accept the disclosures."
            );
          }}
        >
          <Checkbox
            field="reviewTermsPrompt"
            label={l10n.getString(
              "label-accept-terms",
              null,
              defaultAcceptTermsText
            )}
          />
        </ContextForm.Field>
        <div className="progress-buttons-container">
          <ProgressButtons
            onNext={onSubmit}
            onBack={() => {
              if (!institution?.logout_redirect) {
                ApiHttp.fetch("logout", { endpointIsUrl: true }).then(() => {
                  window.location.assign("/enroll");
                });
              } else {
                window.open(`/logout`, "_self");
              }
            }}
            backLabel={l10n.getString("button-cancel", null, "Cancel")}
          />
        </div>
      </ContextForm>
    </div>
  );
};

ReviewDisclosures.propTypes = {
  updateUser: PropTypes.func,
  user: PropTypes.object,
  institution: PropTypes.object,
};

export default ReviewDisclosures;
