// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".backButton--AS4xH button.nds-typography.nds-button.nds-button--secondary.resetButton{background-color:rgba(var(--theme-rgb-primary), var(--alpha-10));border:0px;font-weight:400;font-size:12px;padding:0px var(--space-s);min-height:24px}.backButton--AS4xH button.nds-typography.nds-button.nds-button--secondary.resetButton:hover{background-color:rgba(var(--theme-rgb-primary), var(--alpha-20))}.backButton--AS4xH button.nds-typography.nds-button.nds-button--secondary.resetButton:before{background-color:rgba(0,0,0,0)}.backButton--AS4xH button.nds-typography.nds-button.nds-button--secondary.resetButton span.narmi-icon-chevron-left{font-size:16px;padding-right:4px}", "",{"version":3,"sources":["webpack://./src/modules/transfers/wire/templates/TemplatesList/BackButton/BackButton.module.scss"],"names":[],"mappings":"AAGE,sFACE,gEAAA,CACA,UAAA,CACA,eAAA,CACA,cAAA,CACA,0BAAA,CACA,eAAA,CAEA,4FACE,gEAAA,CAGF,6FACE,8BAAA,CAGF,mHACE,cAAA,CACA,iBAAA","sourcesContent":["@import \"../../../../../../../../scss/mixins\";\n\n.backButton {\n  button:global(.nds-typography.nds-button.nds-button--secondary.resetButton) {\n    background-color: rgba(var(--theme-rgb-primary), var(--alpha-10));\n    border: 0px;\n    font-weight: 400;\n    font-size: 12px;\n    padding: 0px var(--space-s);\n    min-height: 24px;\n\n    &:hover {\n      background-color: rgba(var(--theme-rgb-primary), var(--alpha-20));\n    }\n\n    &:before {\n      background-color: transparent;\n    }\n\n    span:global(.narmi-icon-chevron-left) {\n      font-size: 16px;\n      padding-right: 4px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButton": "backButton--AS4xH"
};
export default ___CSS_LOADER_EXPORT___;
