var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useLocalization } from "@fluent/react";
import { Button, Dialog } from "cerulean";
var LoanPaymentTypesDialog = function (_a) {
    var isAmortizedLoan = _a.isAmortizedLoan;
    var l10n = useLocalization().l10n;
    var _b = __read(useState(false), 2), isOpen = _b[0], setIsOpen = _b[1];
    var customPaymentDescription = isAmortizedLoan
        ? l10n.getString("loan-paydown-custom-amount-description-amortized")
        : l10n.getString("loan-paydown-custom-amount-description");
    return (_jsxs("div", { className: "margin--top--l", children: [_jsx(Button, { label: l10n.getString("loan-paydown-payment-types-dialog-launch-button"), kind: "plain", type: "button", size: "s", onClick: function () { return setIsOpen(true); } }), _jsx(Dialog, { onUserDismiss: function () { return setIsOpen(false); }, title: l10n.getString("loan-paydown-payment-types-dialog-title"), isOpen: isOpen, children: _jsxs("div", { className: "margin--top--s", children: [_jsxs("div", { className: "margin--bottom--l", children: [_jsx("div", { className: "attribute-label", children: l10n.getString("loan-paydown-amount-due") }), _jsx("div", { className: "fontColor--secondary", children: l10n.getString("loan-paydown-amount-due-description") })] }), _jsxs("div", { className: "margin--bottom--l", children: [_jsx("div", { className: "attribute-label", children: l10n.getString("loan-paydown-custom-amount") }), _jsx("div", { className: "fontColor--secondary", children: customPaymentDescription })] }), _jsxs("div", { className: "margin--bottom--l", children: [_jsx("div", { className: "attribute-label", children: l10n.getString("loan-paydown-principal") }), _jsx("div", { className: "fontColor--secondary", children: l10n.getString("loan-paydown-principal-only-description") })] })] }) })] }));
};
export default LoanPaymentTypesDialog;
