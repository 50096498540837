/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocalization, Localized } from "@fluent/react";
import {
  ContextForm,
  PreferenceContentCard,
  useFormData,
  useLoadingContext,
  useNotificationContext,
} from "cerulean";
import Account from "byzantine/src/Account";
import ApiHttp from "byzantine/src/ApiHttp";
import AddMfaDevice from "./AddMfaDevice";
import EstatementsManager from "../estatements/EstatementsManager";
import SubmitPreferences from "./SubmitPreferences";

const SetUpYourAccountPreferences = ({ user, institution }) => {
  const { mfa_required } = institution;
  const { l10n } = useLocalization();
  const [automaticSavingsError, setAutomaticSavingsError] = useState();
  const [automaticSavingsAccounts, setAutomaticSavingsAccounts] = useState({});
  const [hasAddedOrReverifiedDevice, setHasAddedOrReverifiedDevice] =
    useState(false);
  const { setIsLoading } = useLoadingContext();
  const { sendNotification } = useNotificationContext();

  const { formData, onChange } = useFormData({});

  useEffect(() => {
    setIsLoading(true);
    ApiHttp.fetch(`automatic_savings_enrollment`)
      .then((res) => {
        setAutomaticSavingsAccounts(res);
        if (res?.automatic_savings_active) {
          onChange({ automaticSavings: true });
        }
      })
      .catch((err) => setAutomaticSavingsError(err))
      .finally(() => setIsLoading(false));
  }, []);

  // default to on if FI has OPT_OUT, user is eligible & personal, and automatic_savings has never been enabled before
  useEffect(() => {
    if (
      !user.isBusiness() &&
      automaticSavingsAccounts?.automatic_savings_active === null &&
      automaticSavingsAccounts?.automatic_savings_eligible &&
      institution.features?.automatic_savings === "OPT_OUT"
    ) {
      ApiHttp.fetch("automatic_savings_enrollment", { method: "POST" }).then(
        () => {
          onChange({ automaticSavings: true });
        }
      );
    }
  }, [automaticSavingsAccounts?.automatic_savings_eligible]);

  useEffect(() => {
    // users who have completed enrollment can simply be redirected
    if (user.is_converting === false) {
      setIsLoading(true);
      window.location.href = "/dashboard";
    }
  }, [user]);

  const sourceAccountDescription = automaticSavingsAccounts?.source_account
    ? Account.deserialize(
        automaticSavingsAccounts?.source_account
      ).getShortDescription(false)
    : "";
  const destinationAccountDescription =
    automaticSavingsAccounts?.destination_account
      ? Account.deserialize(
          automaticSavingsAccounts?.destination_account
        ).getShortDescription(false)
      : "";
  const automaticSavingsSubtitle = (
    <Localized
      id="automatic-savings-terms"
      vars={{ sourceAccountDescription, destinationAccountDescription }}
    >
      {`With automatic savings, we'll automatically transfer 3% of any external
      transfers/deposits from your ${sourceAccountDescription} into your ${destinationAccountDescription}`}
    </Localized>
  );

  const shouldRenderAutomaticSavings = () => (
      !automaticSavingsError &&
      user?.features.automatic_savings &&
      user?.user_category === "personal" &&
      automaticSavingsAccounts?.automatic_savings_eligible
    );

  const submitAutomaticSavings = (val) => {
    // Both endpoints make either POST or DELETE requests to enroll/unenroll
    const httpMethod = val ? "POST" : "DELETE";
    ApiHttp.fetch("automatic_savings_enrollment", { method: httpMethod }).catch(
      (err) => {
        sendNotification({
          type: "negative",
          text: err,
        });
        // even if an error is encountered, the toggle will still try to change, so set it back to where it was
        onChange({ automaticSavings: !val });
      }
    );
  };

  return (
    <div className="medium">
      <Localized id="heading-account-preferences">
        <h1>Set up your account preferences</h1>
      </Localized>
      <div className="margin--top--xs" data-testid="enrollment-page-subtitle">
        <Localized id="subheading-account-preferences"></Localized>
      </div>
      <ContextForm data={formData} onChange={onChange}>
        <div className="preferences-container">
          <AddMfaDevice
            mfa_required={mfa_required}
            user={user}
            data={formData}
            onChange={onChange}
            setHasAddedOrReverifiedDevice={setHasAddedOrReverifiedDevice}
          />
          <EstatementsManager />
          {shouldRenderAutomaticSavings() && (
            <ContextForm.Field testId="automaticSavings">
              <PreferenceContentCard
                field="automaticSavings"
                title={l10n.getString(
                  "label-automatic-savings",
                  null,
                  "Automatic savings"
                )}
                subtitle={automaticSavingsSubtitle}
                subtext={l10n.getString(
                  "description-automatic-savings",
                  null,
                  "Automatic transfers occur nightly and reflect total deposits made on a calendar day."
                )}
                onChange={(val) => {
                  submitAutomaticSavings(val);
                }}
              />
            </ContextForm.Field>
          )}
        </div>
      </ContextForm>
      <SubmitPreferences
        institution={institution}
        mustAddOrReverifyDeviceOnSubmit={
          !hasAddedOrReverifiedDevice &&
          mfa_required &&
          !institution?.permitted_mfa_methods.includes("email") &&
          !user.has_backup_codes
        }
      />
    </div>
  );
};

SetUpYourAccountPreferences.propTypes = {
  user: PropTypes.object,
  institution: PropTypes.object,
};

export default SetUpYourAccountPreferences;
