import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tag } from "@narmi/design_system";
import { Button, useNotificationContext } from "cerulean";
import Account from "byzantine/src/Account";
import ApiHttp from "byzantine/src/ApiHttp";

const AlertsList = ({ accounts, alerts, fetchAlerts, setIsLoading }) => {
  const { sendNotification } = useNotificationContext();
  const [queuedForRemovalAlertId, setQueuedForRemovalAlertId] = useState("");

  const onRemove = (alertId) => {
    if (!queuedForRemovalAlertId || queuedForRemovalAlertId !== alertId) {
      setQueuedForRemovalAlertId(alertId);
      return;
    }

    setIsLoading(true);
    ApiHttp.fetch(`saved_searches/${alertId}`, { method: "DELETE" })
      .then(() => {
        sendNotification({
          type: "success",
          text: "Alert deleted.",
        });
        fetchAlerts();
      })
      .catch(() => {
        sendNotification({
          type: "negative",
          text: "There was an error deleting this alert. Please try again or contact Support.",
        });
      })
      .finally(() => {
        setQueuedForRemovalAlertId("");
        setIsLoading(false);
      });
  };

  const humanFriendlyAlertType = (type) => {
    if (type === "sms") {
      return "text";
    } else if (type === "push") {
      return "push notification";
    } else {
      return "email";
    }
  };

  const humanFriendlyAlertValueDisplay = (value) => {
    if (!value) return "amount";
    if (value === "balance") return "resulting balance";
    return value;
  };

  const QueryItem = ({
    precursor,
    value,
    operationDescription,
    prefix,
    comparator,
  }) => {
    const label = `${precursor} ${humanFriendlyAlertValueDisplay(
      value
    )} ${operationDescription} ${prefix}${comparator}`;
    return (
      <div className="query-item">
        <Tag label={label} />
      </div>
    );
  };
  QueryItem.propTypes = {
    precursor: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    operationDescription: PropTypes.string.isRequired,
    prefix: PropTypes.string.isRequired,
    comparator: PropTypes.string.isRequired,
  };

  const queryList = (alert) => {
    const queryItems = [];
    const queries = alert.query.split(" ");
    const keywords = [];
    queries.forEach((query, i) => {
      const queryArr = query.split(/(:|<|>)/);
      if (!queryArr || !queryArr.every((item) => item)) return;
      if (queryArr.length === 1) {
        // transaction description keyword
        keywords.push(queryArr[0]);
      } else if (queryArr.length === 3) {
        const value = queryArr[0] === "amount" ? "" : queryArr[0];
        const operationDescription = {
          ":": "equal to",
          "<": "less than",
          ">": "greater than",
        }[queryArr[1]];
        const prefix = ["balance", "amount"].includes(queryArr[0]) ? "$" : "";
        const comparator =
          queryArr[0] === "account"
            ? Account.getAccountFromId(
                queryArr[2],
                accounts
              )?.getShortDescription() || "(account removed)"
            : queryArr[2];
        const precursor = queryItems.length > 0 ? " and " : "";
        queryItems.push(
          <QueryItem
            key={i}
            precursor={precursor}
            value={value}
            operationDescription={operationDescription}
            prefix={prefix}
            comparator={comparator}
          />
        );
      }
    });
    if (keywords.length) {
      const precursor = queryItems.length > 0 ? " and " : "";
      const label = `${precursor} description matches "${keywords.join(" ")}"`;
      queryItems.push(
        <div key={label} className="query-item">
          <Tag label={label} />
        </div>
      );
    } else if (queryItems.length === 0) {
      queryItems.push(<Tag label="anything" />);
    }
    return queryItems;
  };

  return alerts.map((alert, index) => (
      <div key={alert.id}>
        <div className="alert-container">
          <div>
            {`Receive ${alert.alert_type === "email" ? "an" : "a"} `}
            <Tag label={humanFriendlyAlertType(alert.alert_type)} />
            {" when a "}
            <Tag label={alert.model} />
            {" matches: "}
            {queryList(alert)}
          </div>
          <div>
            <Button kind="negative" onClick={() => onRemove(alert.id)}>
              {queuedForRemovalAlertId === alert.id
                ? "Are you sure?"
                : "Remove"}
            </Button>
          </div>
        </div>
        {alerts.length - 1 !== index && ( // don't show horizontal rule if it's the last element
          <hr />
        )}
      </div>
    ));
};
AlertsList.propTypes = {
  accounts: PropTypes.array,
  alerts: PropTypes.array.isRequired,
  fetchAlerts: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default AlertsList;
