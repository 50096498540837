import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import Filters from "byzantine/src/filters";
import utils from "../../utils";
import Document from "./Document";
import FilterButton from "./FilterButton";

export const getYearForDocument = (document) =>
  document.created_at.substring(0, 4);

export const isFilterActive = (activeFilters, filterType, value) =>
  activeFilters[filterType].has(value);

export const getFilteredDocuments = (activeFilters, documents) => {
  const activeYearFilters = activeFilters.year;
  const activeTypeFilters = activeFilters.type;

  return documents.filter((d) => {
    const hasYear =
      activeYearFilters.size > 0
        ? activeYearFilters.has(getYearForDocument(d))
        : true;
    const hasType =
      activeTypeFilters.size > 0 ? activeTypeFilters.has(d.type) : true;

    return hasYear && hasType;
  });
};

export const computeNewActiveFilters = (activeFilters, filterType, value) => {
  const newActiveFilters = new Set(activeFilters[filterType]);
  if (newActiveFilters.has(value)) {
    newActiveFilters.delete(value);
  } else {
    newActiveFilters.add(value);
  }

  return { ...activeFilters, [filterType]: newActiveFilters };
};

const DocumentList = ({ documentsJson, isHtmlJson }) => {
  const { l10n } = useLocalization();
  const documents = JSON.parse(utils.unescape(documentsJson));
  const years = [...new Set(documents.map((d) => getYearForDocument(d)))];
  const typeChoices = {};
  documents.forEach((d) => {
    typeChoices[d.type_label] = d.type;
  });

  const [activeFilters, setActiveFilters] = useState({
    year: new Set([]),
    type: new Set([]),
  });

  const filteredDocuments = getFilteredDocuments(activeFilters, documents);

  const toggleActiveFilters = (filterType, value) => {
    setActiveFilters((prevFilters) => computeNewActiveFilters(prevFilters, filterType, value));
  };

  const noDocumentsDisplayedText =
    documents.length === 0
      ? l10n.getString("empty-documents", null, "No statements available.")
      : l10n.getString(
          "empty-filter-documents",
          null,
          "No documents match filters."
        );

  return (
    <>
      <div className="documents-page alignChild--left--top">
        <div>
          {years.length > 1 && (
            <div className="margin--bottom--m">
              <div className="margin--bottom--xs fontWeight--bold">
                {l10n.getString("label-filter-year", null, "Choose year")}
              </div>
              <div className="filter-list alignChild--left--center">
                {years.map((year) => (
                  <FilterButton
                    key={year}
                    filterName={year}
                    toggleActiveFilters={toggleActiveFilters.bind(
                      null,
                      "year",
                      year
                    )}
                    isActive={isFilterActive(activeFilters, "year", year)}
                  />
                ))}
              </div>
            </div>
          )}
          {Object.keys(typeChoices).length > 1 && (
            <div className="margin--bottom--m">
              <div className="margin--bottom--xs fontWeight--bold">
                {l10n.getString("label-filter-type", null, "Choose type")}
              </div>
              <div className="filter-list alignChild--left--center">
                {Object.entries(typeChoices).map(([label, value]) => (
                  <FilterButton
                    key={value}
                    filterName={Filters.titlecase(label)}
                    toggleActiveFilters={toggleActiveFilters.bind(
                      null,
                      "type",
                      value
                    )}
                    isActive={isFilterActive(activeFilters, "type", value)}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="document-list">
          {filteredDocuments.length > 0 ? (
            filteredDocuments.map((d) => (
              <Document
                key={d.id}
                document={d}
                isHtml={JSON.parse(isHtmlJson)}
              />
            ))
          ) : (
            <div className="document-card">{noDocumentsDisplayedText}</div>
          )}
        </div>
      </div>
    </>
  );
};
DocumentList.propTypes = {
  documentsJson: PropTypes.string.isRequired,
  isHtmlJson: PropTypes.oneOf(["true", "false"]).isRequired,
};

export default DocumentList;
