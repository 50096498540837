import { sortBy } from "lodash";
import * as entities from "../../entities";
import WireRecipient from "../../../../WireRecipient";
import Account from "../../../../Account";
export var selectFedwireTemplatesWithRecipients = entities.utils.createDeepEqualSelector([
    entities.fedwireTemplates.selectFedwireTemplates,
    entities.wires.selectWireRecipients,
], function (templates, recipients) {
    var templatesWithRecipients = [];
    sortBy(templates, "last_used")
        .reverse()
        .forEach(function (template) {
        var recipient = recipients.find(function (r) { return r.id === template.recipient; });
        if (!recipient) {
            return;
        }
        templatesWithRecipients.push({
            template: template,
            recipient: recipient,
        });
    });
    return templatesWithRecipients;
});
export var selectFedwireTemplateDetails = entities.utils.createDeepEqualSelector([
    function (state) { return state.fedwireTemplates.byId; },
    function (state) { return state.wires.recipients.byId; },
    function (state) { return state.accounts.byId; },
    function (_, fedwireTemplateId) { return fedwireTemplateId; },
], function (templatesById, recipientsById, accountsById, fedwireTemplateId) {
    if (!fedwireTemplateId) {
        return null;
    }
    var template = templatesById[fedwireTemplateId];
    if (!template) {
        return null;
    }
    var recipient = recipientsById[template.recipient];
    if (!recipient) {
        return null;
    }
    var account = accountsById[template.source_account];
    if (!account) {
        return null;
    }
    try {
        return {
            template: template,
            recipient: new WireRecipient(recipient),
            account: Account.deserialize(account),
        };
    }
    catch (e) {
        return null;
    }
});
