import { useEffect, useRef } from "react";

const useEffectSkipFirstRender = (callback, deps = []) => {
  /*
  custom hook that is basically just an effect hook except it does not run after 1st render
  */
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) return callback();
    didMountRef.current = true;
    return undefined;
  }, deps);
};

export default useEffectSkipFirstRender;
