import WireRecipient from "../../../../WireRecipient";
export var API = {
    fetchRecent: function (accountId) {
        return {
            url: "wires?".concat(encodeURIComponent("account_uuid"), "=").concat(encodeURIComponent(accountId)),
            options: {
                method: "GET",
            },
        };
    },
    saveWireRecipient: function (wireRecipient) { return ({
        url: "wire_recipients/",
        options: {
            method: "POST",
            payload: new WireRecipient(wireRecipient).serialize(),
        },
    }); },
    listWireRecipients: function () { return ({
        url: "wire_recipients/",
        options: {
            method: "GET",
        },
    }); },
    deleteWireRecipient: function (id) { return ({
        url: "wire_recipients/".concat(id, "/"),
        options: {
            method: "DELETE",
            isJsonResponse: false,
        },
    }); },
};
