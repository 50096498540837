import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { TextInput } from "@narmi/design_system";
var ZipCodeTextInput = function (_a) {
    var onChange = _a.onChange, value = _a.value, label = _a.label, error = _a.error, _b = _a.allowExtendedZip, allowExtendedZip = _b === void 0 ? true : _b, _c = _a.onBlur, onBlur = _c === void 0 ? undefined : _c;
    var handleInput = function (event) {
        var parsedValue;
        if (event.target.value.length < 6) {
            parsedValue = event.target.value.substring(0, 5).replace(/[^\d]/g, "");
        }
        else {
            if (allowExtendedZip) {
                parsedValue =
                    event.target.value.substring(0, 5).replace(/[^\d]/g, "") +
                        "-" +
                        event.target.value.substring(5, 10).replace(/[^\d]/g, "");
            }
            else {
                parsedValue =
                    event.target.value.substring(0, 5).replace(/[^\d]/g, "");
            }
        }
        onChange(parsedValue);
    };
    return (_jsx(TextInput, { onChange: handleInput, value: value, inputMode: "numeric", label: label, error: error, onBlur: onBlur }));
};
export default ZipCodeTextInput;
