var _a;
/* eslint-disable no-param-reassign */
import { createSlice, nanoid } from "@reduxjs/toolkit";
var initialState = {
    isFetching: false,
    lastUpdated: null,
    data: {},
};
var name = "limits";
var slice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        request: function (state) {
            state.isFetching = true;
        },
        fail: function (state) {
            state.isFetching = false;
        },
        receive: {
            prepare: function (data) { return ({
                payload: {
                    id: nanoid(),
                    limits: data.limits,
                    receivedAt: Date.now(),
                },
            }); },
            reducer: function (state, action) {
                var _a = action.payload, limits = _a.limits, receivedAt = _a.receivedAt;
                state.isFetching = false;
                state.lastUpdated = receivedAt;
                Object.assign(state.data, limits);
            },
        },
    },
});
export var actions = slice.actions, reducer = slice.reducer;
export default (_a = {},
    _a[name] = slice.reducer,
    _a);
