var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
import { Button, Row } from "@narmi/design_system";
import { useNotificationContext } from "../NotificationContext";
import Dialog from "../Dialog";
export var extractIgnoredWarnings = function (error) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    if (!((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data))
        return [];
    // api can return arrays of errors and warnings
    if (Array.isArray((_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.errors) &&
        Array.isArray((_e = (_d = error === null || error === void 0 ? void 0 : error.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.warnings)) {
        // if we only have warnings, allow overriding
        if (((_h = (_g = (_f = error.response) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.warnings) === null || _h === void 0 ? void 0 : _h.length) &&
            !((_l = (_k = (_j = error.response) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.errors) === null || _l === void 0 ? void 0 : _l.length)) {
            return (_o = (_m = error.response) === null || _m === void 0 ? void 0 : _m.data) === null || _o === void 0 ? void 0 : _o.warnings;
        }
    }
    return [];
};
export var getWarningMessage = function (warnings) {
    // Join array of warnings as sentences
    return warnings
        .map(function (warning) {
        var warningDescription = warning
            .description;
        if (!warningDescription)
            return "";
        // if we already have punctuation, passthrough
        if (/[\.\?\!]$/.test(warningDescription))
            return warningDescription;
        // otherwise add a period
        return "".concat(warningDescription, ".");
    })
        .filter(function (a) { return a; })
        .join(" ");
};
var IgnoreWarningsDialog = function (_a) {
    var open = _a.open, handleClose = _a.handleClose, onSubmit = _a.onSubmit, _b = _a.confirmLabel, confirmLabel = _b === void 0 ? "Confirm" : _b, _c = _a.cancelLabel, cancelLabel = _c === void 0 ? "Cancel" : _c, _d = _a.dialogHeadingLabel, dialogHeadingLabel = _d === void 0 ? "Are you sure?" : _d, children = _a.children;
    var actions = (_jsxs(Row, { justifyContent: "end", alignItems: "center", children: [_jsx(Row.Item, { shrink: true, children: _jsx(Button, { kind: "negative", onClick: handleClose, label: cancelLabel }) }), _jsx(Row.Item, { shrink: true, children: _jsx(Button, { onClick: onSubmit.bind(null), label: confirmLabel }) })] }));
    return (_jsx(Dialog, { isOpen: open, title: dialogHeadingLabel, footer: actions, onUserDismiss: handleClose, children: _jsx("p", { className: "padding--top--s", children: children }) }));
};
var FormReviewWarningsContext = createContext({
    handleWarnings: function (errors, action, callback) {
        callback(errors || undefined);
    },
});
export var useFormReviewWarningsContext = function () {
    return useContext(FormReviewWarningsContext);
};
export var FormReviewWarningsContextProvider = function (_a) {
    var setIgnoreWarnings = _a.setIgnoreWarnings, confirmWithToastMessage = _a.confirmWithToastMessage, children = _a.children;
    var noop = function () { };
    var sendNotification = useNotificationContext().sendNotification;
    var _b = __read(useState(""), 2), dialogWarningMessage = _b[0], setDialogWarningMessage = _b[1];
    var _c = __read(useState(""), 2), dialogConfirmLabel = _c[0], setDialogConfirmLabel = _c[1];
    var _d = __read(useState(false), 2), dialogOpen = _d[0], setDialogOpen = _d[1];
    var _e = __read(useState(function () { return noop; }), 2), dialogAction = _e[0], setDialogAction = _e[1];
    var handleWarningsViaToast = function (warnings, action) {
        var warningMessage = "".concat(getWarningMessage(warnings), " ").concat(action.warningCta || "Double-check your info and submit again to proceed.");
        // we want to send a toast message to confirm re-submit
        sendNotification({
            type: "negative",
            text: warningMessage,
        });
        return Promise.resolve();
    };
    var handleWarningsViaDialog = function (warnings, action, handleErrors) {
        // pop up a confirmation dialog
        var warningMessage = "".concat(getWarningMessage(warnings), " ").concat(action.warningCta || "Are you sure you wish to proceed?");
        setDialogWarningMessage(warningMessage);
        setDialogConfirmLabel(action.label || "");
        setDialogAction(function () { return function () {
            action.onSubmit(function (errors) {
                if (!errors)
                    return dialogHandleClose();
                return handleErrors(errors);
            });
        }; });
        setDialogOpen(true);
        return Promise.resolve();
    };
    var handleWarnings = function (errors, action, handleErrors) {
        var warnings = extractIgnoredWarnings(errors);
        // if we do not handle this error case here, raise so the next handling can trigger
        if (!warnings.length)
            return handleErrors(errors || undefined);
        // the user will trigger another onSubmit when confirming, so mutate the payload
        setIgnoreWarnings(warnings);
        if (confirmWithToastMessage)
            return handleWarningsViaToast(warnings, action);
        return handleWarningsViaDialog(warnings, action, handleErrors);
    };
    var dialogHandleClose = function () {
        // dismiss the warnings we added when dismissing dialog
        setIgnoreWarnings([]);
        setDialogConfirmLabel("");
        setDialogAction(function () { return function () { }; });
        setDialogOpen(false);
    };
    return (_jsxs(FormReviewWarningsContext.Provider, { value: {
            handleWarnings: handleWarnings,
        }, children: [!confirmWithToastMessage && dialogOpen && (_jsx(IgnoreWarningsDialog, { open: dialogOpen, handleClose: dialogHandleClose, onSubmit: dialogAction, confirmLabel: dialogConfirmLabel, children: dialogWarningMessage })), children] }));
};
export default FormReviewWarningsContext;
