import { jsx as _jsx } from "react/jsx-runtime";
import { LoadingContextProvider, NotificationContextProvider } from "cerulean";
import AppLocalizationProvider from "../AppLocalizationProvider";
import { CurrentUserContextProvider, } from "../contexts/CurrentUserContext";
import DBBLLibraryProvider from "../../src/libraries/library.provider";
var AppProviders = function (_a) {
    var currentUser = _a.currentUser, internalName = _a.internalName, s3ImagesBucket = _a.s3ImagesBucket, hasLangSelect = _a.hasLangSelect, preloadedState = _a.preloadedState, children = _a.children;
    return (_jsx(AppLocalizationProvider, { internalName: internalName, s3ImagesBucket: s3ImagesBucket, hasLangSelect: hasLangSelect, children: _jsx(NotificationContextProvider, { children: _jsx(LoadingContextProvider, { children: _jsx(CurrentUserContextProvider, { currentUser: currentUser, children: _jsx(DBBLLibraryProvider, { preloadedState: preloadedState, children: children }) }) }) }) }));
};
export default AppProviders;
