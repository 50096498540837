import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, ContextForm, TextInput, useFormData, useLoadingContext, } from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";
import { useActionContext } from "./contexts/ActionContextProvider";
import { useSelectedDeviceContext } from "./contexts/SelectedDeviceContextProvider";
import HeaderWithSteps from "../HeaderWithSteps";
import fetchCode from "./helpers";
var TfaEnter = function (_a) {
    var totalSteps = _a.totalSteps, step = _a.step;
    var _b = useSelectedDeviceContext(), selectedDeviceId = _b.selectedDeviceId, helpMessage = _b.helpMessage, setHelpMessage = _b.setHelpMessage;
    var goToReview = useActionContext().goToReview;
    var setIsLoading = useLoadingContext().setIsLoading;
    var _c = useFormData(), formData = _c.formData, onChange = _c.onChange;
    var sendCode = function (callback) {
        ApiHttp.fetch("sudo", { method: "POST" }, { one_time_password: formData.one_time_password })
            .then(function () {
            goToReview();
        })
            .catch(function () {
            callback({
                one_time_password: "Incorrect verification code entered, please try again.",
            });
        });
    };
    var requestCodeAgain = function () {
        setIsLoading(true);
        return fetchCode(selectedDeviceId, setHelpMessage).finally(function () {
            setIsLoading(false);
        });
    };
    var subheader = (
    /*
    For most flows that use HeaderWithSteps, spacing b/t header and subheader is 8px on l/m, 4px on s/xs.
    For sudo, it's always 4px, so we need to subtract 4px here
    Also, we only want 20px of margin b/t the resend code button and the content (vs. the typical 40px)
    */
    _jsxs("div", { style: { marginTop: "-4px", marginBottom: "-20px" }, children: [helpMessage || "A verification code was just sent to your device.", _jsx("div", { className: "margin--top--xs", children: _jsx(Button, { kind: "plain", onClick: requestCodeAgain, label: "Resend code", size: "s" }) })] }));
    return (_jsxs(_Fragment, { children: [_jsx(HeaderWithSteps, { step: step, totalSteps: totalSteps, headerText: "Enter verification code", subheaderText: subheader }), _jsxs(ContextForm, { data: formData, onChange: onChange, children: [_jsx(ContextForm.Field, { required: true, children: _jsx(TextInput, { field: "one_time_password", label: "Verification code" }) }), _jsx(ContextForm.Action, { onSubmit: sendCode, dangerouslyDisableShowLoading: true, children: _jsx("div", { className: "margin--top--xl", children: _jsx(Button, { label: "Continue" }) }) })] })] }));
};
export default TfaEnter;
