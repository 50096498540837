import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { modules } from "byzantine";
import TfaVerify from "../../../../../../components/verify/TfaVerify";
import { useSendViaTemplate } from "./SendViaTemplate.container";
import Form from "./Form";
import Review from "./Review";
import "./SendViaTemplate.module.scss";
function SendViaTemplateScreen() {
    var _a = useSendViaTemplate(), uiState = _a.uiState, submitForm = _a.submitForm, complete2FA = _a.complete2FA, submitReview = _a.submitReview, resetFlow = _a.resetFlow, loading = _a.loading;
    var content = useMemo(function () {
        switch (uiState) {
            case "2FA": {
                return _jsx(TfaVerify, { goToReview: complete2FA, cancelAction: resetFlow });
            }
            case "review": {
                return (_jsx(Review, { onSubmit: submitReview, loading: loading, goBack: resetFlow }));
            }
            default: {
                return _jsx(Form, { onSubmit: submitForm });
            }
        }
    }, [uiState, complete2FA, resetFlow, submitReview, loading, submitForm]);
    var showSendViaTemplateUI = modules.wireTemplates.useWirePermissions().showSendViaTemplateUI;
    if (!showSendViaTemplateUI) {
        return _jsx(Navigate, { to: "/", replace: true });
    }
    return (_jsx("div", { className: "wire-via-template-container", children: _jsx("div", { className: "mobile-web-extra-space", children: content }) }));
}
export default memo(SendViaTemplateScreen);
