var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ResponseError } from "byzantine";
import axios from "axios";
import createHmac from "create-hmac";
export var API_VERSION = "v1";
var DEFAULT_MAX_ATTEMPTS = 3;
var EPSILON_SECONDS = 60;
var CSRF_TOKEN_HEADER_NAME = "x-csrftoken";
var getDefaultApiUrl = function () {
    var replaceUrl = function (url) { return url.replace(/(\/v1)?\/?$/, ""); };
    try {
        (function () { return process.env; })();
    }
    catch (_a) {
        return "";
    }
    var _b = process.env, REACT_APP_PUBLIC_API_URL = _b.REACT_APP_PUBLIC_API_URL, NEXT_PUBLIC_PUBLIC_API_URL = _b.NEXT_PUBLIC_PUBLIC_API_URL;
    if (REACT_APP_PUBLIC_API_URL) {
        return replaceUrl(REACT_APP_PUBLIC_API_URL);
    }
    if (NEXT_PUBLIC_PUBLIC_API_URL) {
        return replaceUrl(NEXT_PUBLIC_PUBLIC_API_URL);
    }
    return "";
};
var isTokenExpired = function (token) {
    var payloadBase64 = token === null || token === void 0 ? void 0 : token.split(".")[1];
    if (!payloadBase64)
        return false;
    var decodedJson = Buffer.from(payloadBase64, "base64").toString();
    var decoded = JSON.parse(decodedJson);
    var exp = decoded.exp;
    if (!exp)
        return false;
    var expired = new Date().getTime() >= (exp - EPSILON_SECONDS) * 1000;
    return expired;
};
axios.defaults.headers = {
    Pragma: "no-cache",
    "Cache-Control": "no-store, no-cache",
    Accept: "application/json",
    "Content-Type": "application/json",
};
var defaultApiUrl = getDefaultApiUrl();
var buildAuthHeaders = function (token, secret) {
    if (!token)
        return {};
    var authHeaders = {
        Authorization: "Bearer ".concat(token),
    };
    if (secret) {
        var created = String(Math.floor(new Date().getTime() / 1000));
        var signature = createHmac("sha256", secret)
            .update("created: ".concat(created), "utf8")
            .digest("base64");
        authHeaders.Signature = "keyId=\"".concat(token, "\",algorithm=\"hmac-sha256\",signature=\"").concat(signature, "\",headers=\"created\"");
        authHeaders.created = created;
    }
    return authHeaders;
};
export var defaultApiOptions = {
    method: "GET",
    customHeaders: {},
    timeoutMilliseconds: 30 * 1000,
    isJsonResponse: true,
};
var transformResponse = function (response) {
    var data = response.data, status = response.status, responseHeaders = response.headers;
    return {
        bodyString: undefined,
        data: "",
        headers: responseHeaders,
        status: status,
        url: "",
        json: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, data];
        }); }); },
        text: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, ""];
        }); }); },
    };
};
var fetchCSRFToken = function (attemptsLeft) {
    if (attemptsLeft === void 0) { attemptsLeft = DEFAULT_MAX_ATTEMPTS; }
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (attemptsLeft === 0) {
                return [2 /*return*/, undefined];
            }
            return [2 /*return*/, new Promise(function (resolve) {
                    axios
                        .request({
                        url: "".concat(defaultApiUrl, "/").concat(API_VERSION, "/csrf"),
                        withCredentials: true,
                        method: "GET",
                    })
                        .then(function (response) {
                        resolve(response.headers[CSRF_TOKEN_HEADER_NAME]);
                    })
                        .catch(function () { return __awaiter(void 0, void 0, void 0, function () {
                        var _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    _a = resolve;
                                    return [4 /*yield*/, fetchCSRFToken(attemptsLeft - 1)];
                                case 1:
                                    _a.apply(void 0, [_b.sent()]);
                                    return [2 /*return*/];
                            }
                        });
                    }); });
                })];
        });
    });
};
var storedCSRFToken;
var existingCSRFTokenFetchPromise;
var acquireCSRFToken = function () { return __awaiter(void 0, void 0, void 0, function () {
    var csrfToken;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (storedCSRFToken && !isTokenExpired(storedCSRFToken)) {
                    return [2 /*return*/, storedCSRFToken];
                }
                if (!existingCSRFTokenFetchPromise) {
                    existingCSRFTokenFetchPromise = fetchCSRFToken();
                }
                return [4 /*yield*/, existingCSRFTokenFetchPromise];
            case 1:
                csrfToken = _a.sent();
                storedCSRFToken = csrfToken;
                existingCSRFTokenFetchPromise = undefined;
                if (!csrfToken) {
                    throw new Error("Cannot fetch CSRF token.");
                }
                return [2 /*return*/, csrfToken];
        }
    });
}); };
var isWriteRequest = function (opts) {
    return !!opts.method && ["POST", "PUT"].includes(opts.method);
};
var webNetwork = {
    fetchApi: function (endPoint, optionsWithoutDefaults) { return __awaiter(void 0, void 0, void 0, function () {
        var options, csrfToken, headers, url;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    options = __assign(__assign({}, defaultApiOptions), optionsWithoutDefaults);
                    if (!(options.method !== "GET")) return [3 /*break*/, 2];
                    return [4 /*yield*/, acquireCSRFToken()];
                case 1:
                    csrfToken = _b.sent();
                    _b.label = 2;
                case 2:
                    headers = __assign(__assign(__assign(__assign({}, axios.defaults.headers), buildAuthHeaders(options.token, options.secret)), options.customHeaders), (_a = {}, _a[CSRF_TOKEN_HEADER_NAME] = csrfToken, _a));
                    if (isWriteRequest(options) && options.idempotencyKey) {
                        headers["Idempotency-Key"] = options.idempotencyKey;
                    }
                    try {
                        if (new URL(endPoint).hostname) {
                            url = endPoint;
                        }
                    }
                    catch (_c) {
                        url = "".concat(defaultApiUrl, "/").concat(API_VERSION, "/").concat(endPoint);
                    }
                    return [2 /*return*/, new Promise(function (resolve, reject) {
                            axios
                                .request({
                                url: url,
                                method: options.method,
                                headers: headers,
                                data: options.payload,
                                timeout: options.timeoutMilliseconds,
                            })
                                .then(function (response) {
                                resolve(transformResponse(response));
                            })
                                .catch(function (error) {
                                if (axios.isAxiosError(error) && error.response) {
                                    reject(new ResponseError("bad response code: ".concat(url, " (").concat(error.response.status, ")"), transformResponse(error.response)));
                                }
                                else {
                                    reject(error);
                                }
                            });
                        })];
            }
        });
    }); },
};
export default webNetwork;
