var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { sortAccountGroups, groupAccounts } from "../../../../AccountUtils";
var shortGroupTitle = function (title) {
    return title === "External accounts" ? "External" : title;
};
var flattenGroups = function (groups, sorting) {
    sortAccountGroups(groups, sorting); // updates inplace
    var groupsArray = Array.from(groups);
    return groupsArray.map(function (_a) {
        var _b = __read(_a, 2), title = _b[0], accounts = _b[1];
        return {
            title: shortGroupTitle(title),
            accountIds: accounts.map(function (a) { return a.id; }),
            accounts: accounts,
        };
    });
};
export var createGroups = function (accounts, sorting) { return flattenGroups(groupAccounts(accounts), sorting); };
