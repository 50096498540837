import React from "react";
import PropTypes from "prop-types";
import { ChevronLeft } from "react-feather";
import ActionHeader from "./ActionHeader";

function Information({ label, info }) {
  return (
    <div className="info-card">
      <div className="label">{label}</div>
      <div className="value">{info}</div>
    </div>
  );
}
Information.propTypes = {
  info: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const ActionReview = (props) => {
  const { details } = props;
  const hasSections = Object.prototype.hasOwnProperty.call(
    details[0],
    "sectionLabel"
  );
  const renderSectionDetails = (sectionDetails) => (
    <div className="section">
      {sectionDetails.map((detail, i) => (
        <Information key={i} label={detail.label} info={detail.info} />
      ))}
    </div>
  );
  const content = hasSections
    ? details.map((detail, i) => (
        <React.Fragment key={i}>
          <div
            className={`action-review-section-header ${i ? "margined" : ""}`}
          >
            {detail.sectionLabel}
          </div>
          {renderSectionDetails(detail.items)}
        </React.Fragment>
      ))
    : renderSectionDetails(details);

  return (
    <div>
      <div className="back-icon">
        <ChevronLeft
          color="#4c4c4c"
          onClick={props.goBack}
          onKeyUp={({ key }) => {
            if (key === "Enter") props.goBack();
          }}
          aria-label="Go back"
          role="button"
          tabIndex="0"
        />
      </div>
      <div className="form-card-layout">
        <div className="card">
          <ActionHeader icon={props.icon} title={props.title} />
          {content}
          {props.children}
        </div>
      </div>
    </div>
  );
};

ActionReview.propTypes = {
  children: PropTypes.node,
  goBack: PropTypes.func,
  details: PropTypes.array,
  icon: PropTypes.string,
  onChange: PropTypes.func,
  data: PropTypes.object,
  title: PropTypes.string,
};

export default ActionReview;
