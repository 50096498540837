import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCurrentUser } from "../../../contexts/CurrentUserContext";
import { useUserFeatures } from "../../../contexts/UserFeaturesContext";
import EstatementsManager from "../../../estatements/EstatementsManager";
import LinkedAccountMembershipsCard from "../linked_account_memberships";
import PasswordInfoCard from "../PasswordInfoCard";
import BusinessInfoCard from "./BusinessInfoCard";
import ContactInfoCard from "./ContactInfoCard";
var UserSettingsProfilePage = function (_a) {
    var memberships = _a.memberships;
    var userFeatures = useUserFeatures();
    var currentUser = useCurrentUser().currentUser;
    var isBusinessUser = !!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.isBusiness());
    return (_jsxs("div", { className: "nds-grid margin--top--l", children: [_jsxs("div", { className: "nds-span-6", children: [_jsx("div", { className: "nds-span-12", children: _jsx(ContactInfoCard, {}) }), isBusinessUser && (_jsx("div", { className: "nds-span-12 margin--top--l", children: _jsx(BusinessInfoCard, {}) })), (userFeatures === null || userFeatures === void 0 ? void 0 : userFeatures.account_membership) && (_jsx("div", { className: "nds-span-12 margin--top--l", children: _jsx(LinkedAccountMembershipsCard, { initialMemberships: memberships }) })), _jsx("div", { className: "nds-span-12 margin--top--l", children: _jsx(PasswordInfoCard, {}) })] }), _jsx("div", { className: "nds-span-6", children: _jsx(EstatementsManager, {}) })] }));
};
export default UserSettingsProfilePage;
