import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import { ContextForm, formatNumber, Select } from "cerulean";
import AmountTextInput from "../form/AmountTextInput";
import AccountContext from "../contexts/AccountContext";

const AmountSelector = ({ minimumPayment, onChange, value, error }) => {
  const { l10n } = useLocalization();
  return (
    <Select
      label={l10n.getString("label-amount")}
      onChange={onChange}
      value={value}
      errorText={error}
    >
      <Select.Item key="preset" value="preset">
        {l10n.getString("option-minimum-payment", { minimumPayment })}
      </Select.Item>
      <Select.Item key="custom" value="custom">
        {l10n.getString("option-custom-amount")}
      </Select.Item>
    </Select>
  );
};
AmountSelector.propTypes = {
  minimumPayment: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.string,
};

const LoanAmountField = ({ data, validateAmount }) => {
  const { l10n } = useLocalization();
  const { accounts } = useContext(AccountContext);
  const loanAccount = accounts.find(
    (account) => account.id === data?.to_account_id
  );

  let minimumPayment = loanAccount.loan_details?.minimum_payment;
  if (minimumPayment) {
    minimumPayment = formatNumber(minimumPayment);
  }

  const isAmountInputDisplayed =
    !minimumPayment || data?.preset_amount === "custom";

  // using a ref hook here because the ContextForm does not update its validate functions
  // after initializing, so this gives us the freshest version of minimumPayment
  const minimumPaymentRef = useRef("");

  useEffect(() => {
    minimumPaymentRef.current = minimumPayment;
  }, [data?.to_account_id]);

  return (
    <>
      {minimumPayment && (
        <ContextForm.Field
          required
          style={isAmountInputDisplayed ? {} : { marginBottom: 0 }}
          validate={(value, allFields) => {
            if (!validateAmount || value === "custom") return null;
            const amount =
              value === "preset" ? minimumPaymentRef.current : null;
            return validateAmount(amount, allFields);
          }}
        >
          <AmountSelector
            field="preset_amount"
            minimumPayment={minimumPayment}
          />
        </ContextForm.Field>
      )}
      {isAmountInputDisplayed && (
        <ContextForm.Field
          required
          style={{ marginBottom: 0 }}
          validate={validateAmount}
        >
          <AmountTextInput
            field="amount"
            label={
              minimumPayment
                ? l10n.getString("option-custom-amount")
                : l10n.getString("label-amount")
            }
          />
        </ContextForm.Field>
      )}
    </>
  );
};
LoanAmountField.propTypes = {
  data: PropTypes.object,
  validateAmount: PropTypes.func,
};

export default LoanAmountField;
