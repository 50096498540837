import React from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import { Button } from "@narmi/design_system";
import BeneficiaryList from "byzantine/src/BeneficiaryList";
import { Dropdown, DropdownListItem } from "cerulean";

const BeneficiaryListItem = (props) => (
  /* For rendering each beneficiary in the list */
  <DropdownListItem
    anyItemSelected={!!props.value}
    isSelected={props.value?.value === props.beneficiary.value}
    onClick={() => {
      props.setBeneficiaryId(props.beneficiary);
      props.hideBeneficiaryForm();
    }}
    closeDropdown={props.closeDropdown}
  >
    {props.beneficiary.displayName}
  </DropdownListItem>
);
BeneficiaryListItem.propTypes = {
  beneficiary: PropTypes.object,
  setBeneficiaryId: PropTypes.func,
  closeDropdown: PropTypes.func,
  value: PropTypes.any,
  hideBeneficiaryForm: PropTypes.func,
};

const AddBeneficiaryPlainButton = (props) => {
  const { l10n } = useLocalization();
  const itemValue = { displayName: l10n.getString("beneficiary-add-new"), value: "" };
  return (
    <DropdownListItem
      anyItemSelected={!!props.value}
      isSelected={props.value?.value === itemValue.value}
      onClick={() => {
        props.setBeneficiaryId(itemValue);
        props.showBeneficiaryForm();
      }}
      closeDropdown={props.closeDropdown}
    >
      <Button kind="plain" label={l10n.getString("beneficiary-add-new")} />
    </DropdownListItem>
  );
};
AddBeneficiaryPlainButton.propTypes = {
  setBeneficiaryId: PropTypes.func,
  closeDropdown: PropTypes.func,
  value: PropTypes.any,
  showBeneficiaryForm: PropTypes.func,
};

export default function BeneficiarySelector({
  field,
  beneficiaries,
  value,
  onChange,
  setShowNewBeneficiaryForm,
  ...props
}) {
  return (
    <>
      <Dropdown
        triggerLabel={props.label}
        triggerValue={value?.displayName}
        {...props}
      >
        <BeneficiaryList
          beneficiaries={beneficiaries}
          renderBeneficiary={BeneficiaryListItem}
          setBeneficiaryId={onChange}
          hideBeneficiaryForm={() => setShowNewBeneficiaryForm(false)}
          value={value}
        />
        <AddBeneficiaryPlainButton
          setBeneficiaryId={onChange}
          showBeneficiaryForm={() => setShowNewBeneficiaryForm(true)}
          value={value}
        />
      </Dropdown>
    </>
  );
}
BeneficiarySelector.propTypes = {
  field: PropTypes.string,
  beneficiaries: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.any,
  setShowNewBeneficiaryForm: PropTypes.func,
};
