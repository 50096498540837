import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Account from "byzantine/src/Account";
import ApiHttp from "byzantine/src/ApiHttp";
import { NotificationContext } from "cerulean";
import UserFeaturesContext from "../contexts/UserFeaturesContext";
import { useCurrentUser } from "../contexts/CurrentUserContext";
import RequestsCard from "./RequestsCard";
import ServicesCard from "./ServicesCard";

const ServicesAndRequests = ({ account }) => {
  /* this components wraps the Services and Requests cards and is responsible for fetching & storing
     stop payments. it passes stopPayments to the Requests card and passes the stop payment retrieval
     function pointer to the Services card to re-fetch stop payments upon successful creation of a
     new stop payment.
  */
  const { currentUser } = useCurrentUser();
  const { sendNotification } = useContext(NotificationContext);
  const { stop_payment } = useContext(UserFeaturesContext);
  const hasStopPaymentFeature =
    account.features.includes("stop_payment") && stop_payment;

  const [stopPayments, setStopPayments] = useState([]);
  const [isLoadingStopPayments, setIsLoadingStopPayments] = useState(true);
  const primaryAddress = currentUser.getPrimaryAddress();

  const fetchStopPayments = async () => {
    try {
      /* don't bother retrieving stop payments if it isn't available as a feature */
      if (!hasStopPaymentFeature) return;

      setIsLoadingStopPayments(true);
      setStopPayments([]);
      const response = await ApiHttp.fetch(`accounts/${account.id}/stops/`, {
        method: "GET",
      });
      if (response?.stops) {
        setStopPayments(response.stops);
      }
    } catch (error) {
      sendNotification({
        type: "negative",
        text: "There was an error retrieving requests, please try again later.",
      });
    } finally {
      setIsLoadingStopPayments(false);
    }
  };

  useEffect(() => {
    fetchStopPayments();
  }, []);

  return (
    <>
      <ServicesCard
        account={account}
        address={primaryAddress}
        refreshStopPayments={fetchStopPayments}
      />
      <RequestsCard
        isLoading={isLoadingStopPayments}
        stopPayments={stopPayments}
      />
    </>
  );
};
ServicesAndRequests.propTypes = {
  account: PropTypes.instanceOf(Account).isRequired,
};
export default ServicesAndRequests;
