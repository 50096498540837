import React from "react";
import cc from "classcat";
import { useLocalization } from "@fluent/react";
import { Button } from "@narmi/design_system";
import {
  ContextForm,
  useFormData,
  EmailFormField,
  CsrfTokenMiddleware,
} from "cerulean";
import "./authentication.css";

const element = document.getElementById("password_reset_done");
const passwordResetSubmitted = element ? element.textContent === "true" : false;

const PasswordReset = () => {
  const { l10n } = useLocalization();
  const { formData, onChange } = useFormData();
  const form = React.useRef(null);

  const headerText = passwordResetSubmitted
    ? l10n.getString("heading-email-sent", null, "Email sent")
    : l10n.getString(
        "heading-forgot-credentials",
        null,
        "Forgot your username or password?"
      );

  const helpText = passwordResetSubmitted
    ? l10n.getString(
        "subheading-sent-reset-instructions",
        null,
        "We sent your username and password reset instructions."
      )
    : l10n.getString(
        "subheading-enter-email",
        null,
        "Enter your email address and we’ll send your username and password reset instructions."
      );

  return (
    <div className="authentication-container">
      <div
        className={cc([{ "mobile-web-extra-space": !passwordResetSubmitted }])}
      >
        <h1>{headerText}</h1>
        <div
          className="authentication-help-text"
          data-test-element="password-confirm-text"
        >
          {helpText}
        </div>
      </div>
      {passwordResetSubmitted ? (
        <ContextForm.ActionBar>
          <Button
            label={l10n.getString("label-back-to-login", null, "Back to login")}
            onClick={() => window.location.assign("/login")}
            data-test-element="back-to-login-button"
          />
        </ContextForm.ActionBar>
      ) : (
        <ContextForm
          ref={form}
          data={formData}
          onChange={onChange}
          autoComplete="off"
          method="post"
        >
          <EmailFormField required />
          <CsrfTokenMiddleware />
          <ContextForm.ActionBar>
            <ContextForm.Action
              onSubmit={(_, e) => {
                e.preventDefault();
                form.current.submit();
              }}
            >
              <Button
                label={l10n.getString("button-submit", null, "Submit")}
                data-test-element="submit-button"
              />
            </ContextForm.Action>
          </ContextForm.ActionBar>
        </ContextForm>
      )}
    </div>
  );
};

export default PasswordReset;
