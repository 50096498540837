import React, { useState } from "react";
import PropTypes from "prop-types";

import Institution from "byzantine/src/Institution";
import { Footer } from "cerulean";
import { useLocalization } from "@fluent/react";

const FooterContainer = (props) => {
  const { l10n } = useLocalization();
  const [institutionSettings, setInstitutionSettings] = useState({});

  React.useEffect(() => {
    const institution = new Institution();
    institution
      .getSettings()
      .then((settingsResponse) => setInstitutionSettings(settingsResponse));
  }, []);

  return (
    <Footer
      ehlLogoUrl={props.ehlLogoUrl}
      institutionSettings={institutionSettings}
      poweredByNarmiText={l10n.getString(
        "powered-by-narmi",
        null,
        "Powered by Narmi"
      )}
      equalHousingAltText={l10n.getString(
        "equal-housing-alt-text",
        null,
        "Equal Housing Lender Logo"
      )}
      privacyLabel={l10n.getString(
        "disclosure-privacy",
        null,
        "Privacy Policy"
      )}
      supportLabel={l10n.getString(
        "support-label-footer",
        null,
        "General support"
      )}
      aprLabel={l10n.getString(
        "apr-label-footer",
        null,
        "APR = Annual Percentage Rate"
      )}
      apyLabel={l10n.getString(
        "apy-label-footer",
        null,
        "APY = Annual Percentage Yield"
      )}
    />
  );
};
FooterContainer.propTypes = {
  ehlLogoUrl: PropTypes.string,
};

export default FooterContainer;
