import { isBusinessUser, businessPermissions, createDeepEqualSelector, } from "../../entities";
import * as PERMISSION from "./constants";
import { hasPermission } from "./utils";
/**
 * A user has a business permission if they specifically have the given
 * permission specified or they are either not a business user (regular user)
 */
export var hasBusinessPermission = createDeepEqualSelector([
    function (state) { return isBusinessUser(state); },
    function (state) { return businessPermissions(state); },
    function (_, permission) { return permission; },
], function (isBizUser, permissions, permission) {
    return !isBizUser || hasPermission(permissions, permission);
});
export var hasDualApprovalPermission = function (state) {
    return hasBusinessPermission(state, PERMISSION.BUSINESS.WRITE.DUAL_APPROVAL_REQUEST);
};
