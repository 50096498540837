import React from "react";
import { Tag } from "@narmi/design_system";
import { ContentCard } from "cerulean";

const DEFAULT_ALERTS = Object.freeze({
  "Reset password": "when I reset my password.",
  "Change password": "when I change my password.",
  "Change email": "when I change my email.",
  "Change address": "when I change my address.",
  "Add external account": "when I successfully add an external account.",
  "2FA backup codes are generated":
    "when staff generates backup two-factor authentication codes for me.",
  "Update 2FA devices":
    "when I add or remove a two-factor authentication device.",
  "Re-enroll":
    "when someone attempts to enroll in digital banking with my account information.",
});
const alertsArray = Object.entries(DEFAULT_ALERTS);

const SecurityAlerts = () => (
  <div className="security-card">
    <ContentCard>
      <div className="alerts-title-container">
        <h3 className="semibold">Security alerts</h3>
      </div>
      <div className="margin--y--m">
        <p>
          These are security-related email notifications and cannot be disabled.
        </p>
      </div>
      {alertsArray.map(([label, description], index) => (
        <div key={label}>
          <div>
            <Tag label={label} /> {description}
          </div>
          {alertsArray.length - 1 !== index && ( // don't show horizontal rule if it's the last element
            <hr />
          )}
        </div>
      ))}
    </ContentCard>
  </div>
);

export default SecurityAlerts;
