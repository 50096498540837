import React, { useContext } from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import {
  BackCancelButton,
  Button,
  ContextForm,
  FormHeaderWithIcon,
  formatNumber,
} from "cerulean";
import { FREQUENCIES } from "byzantine/src/Recurrence";
import AccountContext from "../../../contexts/AccountContext";
import InstitutionSettingsContext from "../../../contexts/InstitutionSettingsContext";
import { TRANSLATED_FREQUENCIES } from "../../FrequencySelector";
import LoanReviewRow from "../LoanReviewRow";

const LoanPaymentByCardActionReview = ({
  data,
  onBankSubmit,
  onCardSubmit,
  getAmountAndIsMinPayment,
  goBack,
  cancel,
  cards,
}) => {
  const { l10n } = useLocalization();
  const { accounts } = useContext(AccountContext);
  const { loan_pay_by_card_fee } = useContext(InstitutionSettingsContext);
  const loanPayByCardFee = Number(loan_pay_by_card_fee);
  const dateLabel =
    data?.frequency !== FREQUENCIES.ONCE
      ? l10n.getString("label-review-date-recurring")
      : l10n.getString("label-review-date-once");
  const { amount, isMinPayment } = getAmountAndIsMinPayment();
  const displayedAmount = isMinPayment
    ? l10n.getString("option-minimum-payment", { minimumPayment: amount })
    : amount;

  let details;
  if (data?.payment_type === "bank") {
    details = [
      {
        label: l10n.getString("label-from"),
        info: accounts
          .find((a) => a.id === data.from_account_id)
          .getShortDescription(),
      },
      { label: l10n.getString("label-amount"), info: displayedAmount },
      ...(data.frequency !== FREQUENCIES.ONCE
        ? [
            {
              label: l10n.getString("label-frequency"),
              info: l10n.getString(TRANSLATED_FREQUENCIES[data.frequency]),
            },
          ]
        : []),
      {
        label: dateLabel,
        info: DateTime.fromFormat(data.date, "M/d/yyyy").toFormat("MM/dd/yyyy"),
      },
      ...(data.memo
        ? [{ label: l10n.getString("label-memo"), info: data.memo }]
        : []),
    ];
  } else if (data?.payment_type === "card") {
    details = [
      {
        label: l10n.getString("label-from"),
        info: cards.find((card) => card.id === data.card)?.description,
      },
      { label: l10n.getString("label-amount"), info: displayedAmount },
      { label: dateLabel, info: DateTime.now().toFormat("MM/dd/yyyy") },
    ];
    if (!Number.isNaN(loanPayByCardFee) && loanPayByCardFee > 0) {
      details.push({
        label: l10n.getString("loan-paybycard-debit-card-fee"),
        info: formatNumber(loanPayByCardFee),
      });
    }
  }

  return (
    <div className="loan-payment-page">
      <FormHeaderWithIcon
        headerText={l10n.getString("loan-paybycard-review-page-heading")}
        onBack={goBack}
      />
      <div className="loan-payment-form-card">
        <h4 className="nds-sans">
          {l10n.getString("loan-paybycard-payment-title", {
            loan:
              accounts
                .find((a) => a.id === data.to_account_id)
                ?.getShortDescription() || "",
          })}
        </h4>
        <div className="margin--top--xl">
          {details?.map(({ label, info }) => (
            <LoanReviewRow key={label} label={label} content={info} />
          ))}
        </div>
      </div>
      <div className="alignChild--center--center form-buttons-with-spacing">
        <BackCancelButton
          onBack={cancel}
          backLabel={l10n.getString("loan-paybycard-cancel-button")}
        />
        <ContextForm.Action
          onSubmit={data?.payment_type === "bank" ? onBankSubmit : onCardSubmit}
        >
          <div style={{ marginLeft: "auto" }}>
            <Button
              label={l10n.getString("loan-paybycard-make-payment-button")}
            />
          </div>
        </ContextForm.Action>
      </div>
    </div>
  );
};
LoanPaymentByCardActionReview.propTypes = {
  data: PropTypes.object,
  onBankSubmit: PropTypes.func,
  onCardSubmit: PropTypes.func,
  getAmountAndIsMinPayment: PropTypes.func,
  goBack: PropTypes.func,
  cancel: PropTypes.func,
  cards: PropTypes.array,
};

export default LoanPaymentByCardActionReview;
