var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState, useRef, useContext, forwardRef, useEffect, } from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import { TruncatedAccount } from "@narmi/design_system";
import Account from "byzantine/src/Account";
import { isGroupedAccountsArray, filterAccounts, groupAccounts, DEFAULT_ACCOUNT_MINIMUM_FOR_GROUPING, } from "byzantine/src/AccountUtils";
import { featureEquals } from "byzantine/src/Feature";
import { Dropdown, DropdownListItem, DropdownLinkItem, DropdownItemGroupHeader, SearchBar, } from "cerulean";
import { useUserFeatures } from "./contexts/UserFeaturesContext";
import { useInstitutionSettings } from "./contexts/InstitutionSettingsContext";
import AddAccountModal from "./transfer/AddAccountModal";
import AccountContext from "./contexts/AccountContext";
var AccountListItem = forwardRef(function (_a, ref) {
    /* For rendering each account in the list (e.g. Primary Savings-001 ($2.31)) */
    var account = _a.account, value = _a.value, isIndented = _a.isIndented, onChange = _a.onChange, closeDropdown = _a.closeDropdown, isDestination = _a.isDestination;
    var accountDescription = account.getDescription(isDestination);
    var splittingIndex = accountDescription.indexOf("(");
    var name = accountDescription;
    var lastFour = "";
    if (splittingIndex !== -1) {
        lastFour = accountDescription.substring(splittingIndex);
        name = accountDescription.substring(0, splittingIndex);
    }
    return (_jsx(DropdownListItem, { anyItemSelected: !!value, isSelected: value === account.id, isIndented: isIndented, closeDropdown: closeDropdown, onClick: function () {
            onChange(account.id);
        }, ariaLabel: "Account: ".concat(name, " ").concat(lastFour), ref: ref, children: _jsx(TruncatedAccount, { lastFour: lastFour, name: name }) }));
});
var AddExternalAccountOptionItem = forwardRef(function (_a, ref) {
    var value = _a.value, setModalOpen = _a.setModalOpen, closeDropdown = _a.closeDropdown, accounts = _a.accounts;
    var l10n = useLocalization().l10n;
    return (_jsx(DropdownLinkItem, { anyItemSelected: !!value, setModalOpen: setModalOpen, closeDropdown: closeDropdown, ref: ref, isIndented: (accounts === null || accounts === void 0 ? void 0 : accounts.length) >= DEFAULT_ACCOUNT_MINIMUM_FOR_GROUPING, ariaLabel: l10n.getString("add-new-external-account"), children: _jsx("div", { style: { textAlign: "left" }, children: "+ ".concat(l10n.getString("add-new-external-account")) }) }));
});
export default function AccountSelector(_a) {
    var _b = _a.showAddExternalAccountLink, showAddExternalAccountLink = _b === void 0 ? true : _b, _c = _a.isDestination, isDestination = _c === void 0 ? false : _c, value = _a.value, accounts = _a.accounts, label = _a.label, onChange = _a.onChange, error = _a.error;
    var userFeatures = useUserFeatures();
    var institutionSettings = useInstitutionSettings();
    var _d = useContext(AccountContext), allAccounts = _d.accounts, setAccounts = _d.setAccounts;
    var _e = __read(useState(false), 2), isDropdownOpen = _e[0], setIsDropdownOpen = _e[1];
    var _f = __read(useState(false), 2), modalOpen = _f[0], setModalOpen = _f[1];
    var _g = __read(useState(""), 2), searchTerm = _g[0], setSearchTerm = _g[1];
    var _h = __read(useState(-1), 2), indexFocused = _h[0], setIndexFocused = _h[1];
    var listRef = useRef(null);
    var addAccountBtnRef = useRef(null);
    var searchInputRef = useRef(null);
    var shouldGroup = (accounts === null || accounts === void 0 ? void 0 : accounts.length) >= DEFAULT_ACCOUNT_MINIMUM_FOR_GROUPING;
    useEffect(function () {
        var _a;
        if (isDropdownOpen && shouldGroup) {
            (_a = searchInputRef === null || searchInputRef === void 0 ? void 0 : searchInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [isDropdownOpen, searchInputRef === null || searchInputRef === void 0 ? void 0 : searchInputRef.current]);
    var onSearchTermUpdate = function (term) {
        setIndexFocused(-1);
        setSearchTerm(term);
    };
    var filteredAccounts = filterAccounts(accounts, function (account) { return !!account; }, searchTerm);
    var groupedAccounts = shouldGroup
        ? groupAccounts(filteredAccounts)
        : filteredAccounts;
    var getListRefMap = function () {
        if (!listRef.current) {
            listRef.current = new Map();
        }
        return listRef.current;
    };
    var closeDropdown = function () { return setIsDropdownOpen(false); };
    var canAddExternalAccount = featureEquals(userFeatures, "indigo.ExternalAccount_permission", "*") &&
        showAddExternalAccountLink &&
        ((!isDestination && institutionSettings.ach_allows_pull) ||
            (isDestination && institutionSettings.ach_allows_push)) &&
        userFeatures.ach;
    var getRefNodeByIndex = function (index) {
        var _a;
        var listRefs = getListRefMap();
        if (shouldGroup && index === -1) {
            return searchInputRef.current;
        }
        if (canAddExternalAccount && index === filteredAccounts.length) {
            return canAddExternalAccount.current;
        }
        return listRefs.get((_a = filteredAccounts[index]) === null || _a === void 0 ? void 0 : _a.id);
    };
    var onKeyDown = useCallback(function (event) {
        var _a, _b;
        var list = getListRefMap();
        var lowestIndex = shouldGroup ? -1 : 0;
        var highestIndex = canAddExternalAccount
            ? filteredAccounts.length
            : filteredAccounts.length - 1;
        switch (event.key) {
            case "Enter": {
                event.preventDefault();
                if ((_a = filteredAccounts[indexFocused]) === null || _a === void 0 ? void 0 : _a.id) {
                    onChange(filteredAccounts[indexFocused].id);
                }
                return;
            }
            case "ArrowDown": {
                if (!isDropdownOpen) {
                    setIsDropdownOpen(true);
                }
                setIndexFocused(function (prevIndexFocused) {
                    event.preventDefault();
                    var nextIndex;
                    if (prevIndexFocused + 1 > highestIndex) {
                        nextIndex = lowestIndex;
                    }
                    else {
                        nextIndex = prevIndexFocused + 1;
                    }
                    var listItem = getRefNodeByIndex(nextIndex);
                    listItem === null || listItem === void 0 ? void 0 : listItem.focus();
                    return nextIndex;
                });
                return;
            }
            case "ArrowUp": {
                if (!isDropdownOpen) {
                    setIsDropdownOpen(true);
                }
                event.preventDefault();
                setIndexFocused(function (prevIndexFocused) {
                    var nextIndex;
                    if (prevIndexFocused - 1 < lowestIndex) {
                        nextIndex = highestIndex;
                    }
                    else {
                        nextIndex = prevIndexFocused - 1;
                    }
                    var listItem = getRefNodeByIndex(nextIndex);
                    listItem === null || listItem === void 0 ? void 0 : listItem.focus();
                    return nextIndex;
                });
                return;
            }
            case "tab": {
                var listItem = list.get((_b = filteredAccounts[indexFocused]) === null || _b === void 0 ? void 0 : _b.id);
                listItem === null || listItem === void 0 ? void 0 : listItem.blur();
                setIndexFocused(-1);
                break;
            }
            default:
        }
    }, [indexFocused, setIndexFocused]);
    var updateAccounts = function (changedAccounts) {
        // TODO the context should manage the update function
        var accountsUpdated = allAccounts.map(function (account) { return account; });
        changedAccounts.forEach(function (acc) {
            var account = new Account(acc);
            // current AccountContext skips pending external accounts
            if (account.isExternal() && !account.verified)
                return;
            var idx = accountsUpdated.findIndex(function (a) { return a.id === account.id; });
            if (idx !== -1) {
                accountsUpdated[idx] = account;
            }
            else {
                accountsUpdated.push(account);
            }
        });
        setAccounts(accountsUpdated);
    };
    /* Dropdown that allows search query to filter account results */
    var displayValue = "";
    if (value) {
        var selectedAccount = accounts.find(function (account) { return account.id === value; });
        if (selectedAccount) {
            displayValue = selectedAccount.getDescription(isDestination);
        }
    }
    var accountlist = null;
    if (isGroupedAccountsArray(groupedAccounts)) {
        accountlist = groupedAccounts.map(function (groupedAccount) { return (_jsx(AccountListItem, { account: groupedAccount, value: value, isIndented: false, isDestination: isDestination, closeDropdown: closeDropdown, onChange: onChange, ref: function (node) {
                var listRefMap = getListRefMap();
                if (node) {
                    listRefMap === null || listRefMap === void 0 ? void 0 : listRefMap.set(groupedAccount.id, node);
                }
                else {
                    listRefMap === null || listRefMap === void 0 ? void 0 : listRefMap.delete(groupedAccount.id);
                }
            } }, groupedAccount.id)); });
    }
    else if (groupedAccounts instanceof Map) {
        accountlist = Array.from(groupedAccounts, function (_a) {
            var _b = __read(_a, 2), groupName = _b[0], accountsForGroup = _b[1];
            return (_jsxs(_Fragment, { children: [_jsx(DropdownItemGroupHeader, { group: groupName }), accountsForGroup.map(function (account) { return (_jsx(AccountListItem, { account: account, isDestination: isDestination, closeDropdown: closeDropdown, isIndented: true, value: value, onChange: onChange, ref: function (node) {
                            var listRefMap = getListRefMap();
                            if (node) {
                                listRefMap === null || listRefMap === void 0 ? void 0 : listRefMap.set(account.id, node);
                            }
                            else {
                                listRefMap === null || listRefMap === void 0 ? void 0 : listRefMap.delete(account.id);
                            }
                        } }, account.id)); })] }));
        });
    }
    return (_jsxs("div", { onKeyDown: onKeyDown, children: [_jsxs(Dropdown, { triggerLabel: label, triggerValue: displayValue, isOpen: isDropdownOpen, setIsOpen: setIsDropdownOpen, error: error, children: [shouldGroup && (_jsx(SearchBar, { searchTerm: searchTerm, setSearchTerm: onSearchTermUpdate, ref: searchInputRef })), accountlist, canAddExternalAccount && (_jsx(AddExternalAccountOptionItem, { accounts: accounts, value: value, closeDropdown: closeDropdown, setModalOpen: setModalOpen, ref: addAccountBtnRef }))] }), _jsx(AddAccountModal, { open: modalOpen, handleClose: function () { return setModalOpen(false); }, updateAccounts: updateAccounts })] }));
}
AccountSelector.propTypes = {
    field: PropTypes.string,
    accounts: PropTypes.array,
    onChange: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.string,
    isDestination: PropTypes.bool,
    showAddExternalAccountLink: PropTypes.bool,
    error: PropTypes.string,
};
