import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { Row } from "cerulean";
var LoanReviewRow = function (_a) {
    var label = _a.label, content = _a.content;
    if ([null, undefined, ""].includes(content))
        return null;
    return (_jsx("div", { className: "loan-review-row", children: _jsxs(Row, { alignItems: "center", children: [_jsx(Row.Item, { shrink: true, children: _jsx("span", { className: "attribute-label", children: label }) }), _jsx(Row.Item, { children: _jsx("div", { className: "attribute-content", children: content }) })] }) }));
};
LoanReviewRow.propTypes = {
    label: PropTypes.string.isRequired,
    content: PropTypes.string,
};
export default LoanReviewRow;
