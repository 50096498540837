/**
 * (Web only)
 * CustomEvent names for cross-framework and cross-frame communication.
 * Any JS on the page can listen for these events on `window`.
 *
 * When dispatching one of the events below, use the CustomEvent constructor:
 * ```js
 * window.dispatchEvent(new CustomEvent(LANG_CHANGE), data);
 * ```
 */
module.exports = {
  /** User changed language */
  LANG_CHANGE: "l10n-change",
  /** User changed language */
  LANG_ERROR: "l10n-error",
  /** Dispatched at an interval until user becomes inactive */
  SESSION_HEARTBEAT: "session-heartbeat",
};
