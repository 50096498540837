import React from "react";
import PropTypes from "prop-types";
import {
  BackCancelButton,
  Button,
  ContextForm,
  FormHeaderWithIcon,
} from "cerulean";
import WireRecipient from "byzantine/src/WireRecipient";
import FormContent from "./FormContent";

const headerIconComponent = (
  <div className="narmi-transfer-icon-container ">
    <span className="narmi-icon-solid-circle" />
    <span className="narmi-icon-wires" />
  </div>
);

const WireTransferForm = ({
  cancel,
  isBusiness,
  limits,
  onSubmit,
  recipients,
  setShowNewRecipientForm,
  Header = null
}) => (
  <>
    <FormHeaderWithIcon
      headerText="Make a wire transfer"
      iconComponent={headerIconComponent}
    />
    {Header}
    <FormContent
      isBusiness={isBusiness}
      limits={limits}
      recipients={recipients}
      setShowNewRecipientForm={setShowNewRecipientForm}
    />
    <div className="alignChild--center--center form-buttons-with-spacing">
      <BackCancelButton onBack={cancel} backLabel="Cancel" />
      <ContextForm.Action onSubmit={onSubmit} dangerouslyDisableShowLoading>
        <div style={{ marginLeft: "auto" }}>
          <Button label="Review" />
        </div>
      </ContextForm.Action>
    </div>
  </>
);
WireTransferForm.propTypes = {
  cancel: PropTypes.func,
  isBusiness: PropTypes.bool,
  limits: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  recipients: PropTypes.arrayOf(PropTypes.instanceOf(WireRecipient)),
  setShowNewRecipientForm: PropTypes.func,
  Header: PropTypes.element,
};

export default WireTransferForm;
