import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Wrapper } from "@googlemaps/react-wrapper";
import { NotificationContext } from "cerulean";

/* this file also relies upon
  <script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDICAqz4l-mGgrBZI5Y758meh8udc4lM3Y&extension=.js"></script>
  because otherwise anything starting with `window.google` other than `window.google.maps.Map()` is undefined

  for more context on @googlemaps/react-wrapper, refer to https://developers.google.com/maps/documentation/javascript/react-map
*/

const Marker = ({ map, position, ...options }) => {
  /*
    Marker is essentially a wrapper; it initializes a google.maps.Marker object and passes it
    a reference to the google.maps.Map object (the `map` prop).  the google.maps.Map object will handle the actual rendering of the marker.

    therefore, Marker returns null because its `map` prop (a ref to google.maps.Map) manages the DOM manipulation.
  */
  const [marker, setMarker] = useState();
  React.useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }
    return () => {
      // remove marker from map on unmount
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions({ map, position, ...options });
    }
  }, [marker, options]);

  return null;
};

Marker.propTypes = {
  map: PropTypes.object,
  position: PropTypes.object.isRequired,
};

const Map = ({ children, className, ...options }) => {
  const ref = useRef(null);
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useEffect(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  return (
    <div className={className} ref={ref}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // this is especially necessary if the child is a marker
          return React.cloneElement(child, { map });
        }
        return null;
      })}
    </div>
  );
};

Map.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

const TransactionMap = ({ className, location }) => {
  // do not render a map if no location is supplied or the google maps api is not loaded
  const isMappable =
    window?.google?.maps &&
    ((location?.latitude && location?.longitude) ||
      (location?.city && location?.region_code));

  if (!isMappable) {
    return null;
  }

  const { sendNotification } = useContext(NotificationContext);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const mapOptions = {
    zoom: 6,
    zoomControl: true,
    disableDoubleClickZoom: true,
    mapTypeControl: false,
    scaleControl: true,
    scrollwheel: true,
    panControl: true,
    streetViewControl: true,
    draggable: true,
    overviewMapControl: true,
    overviewMapControlOptions: {
      opened: false,
    },
    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
  };

  useEffect(() => {
    if (location?.latitude && location?.longitude) {
      setCenter({ lat: location.latitude, lng: location.longitude });
    } else {
      const geocoder = new window.google.maps.Geocoder();
      const address = `${location.city}, ${location.region_code}`;
      geocoder.geocode({ address }, (results, status) => {
        if (status === "OK") {
          setCenter(results[0].geometry.location);
        } else {
          sendNotification({
            type: "negative",
            text: "There was an error retrieving the transaction location.",
          });
        }
      });
    }
  }, []);

  return (
    <Wrapper apiKey="AIzaSyDICAqz4l-mGgrBZI5Y758meh8udc4lM3Y">
      <Map className={className} center={center} {...mapOptions}>
        <Marker position={center} />
      </Map>
    </Wrapper>
  );
};

TransactionMap.propTypes = {
  className: PropTypes.string,
  location: PropTypes.object.isRequired,
};

export default TransactionMap;
