// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".recipientSelector--ZGexC div.nds-input .nds-input-box{border-top-left-radius:4px;border-top-right-radius:4px}", "",{"version":3,"sources":["webpack://./src/modules/transfers/wire/templates/TemplatesList/TemplateDrawer/TemplateForm/RecipientSelector/RecipientSelector.module.scss"],"names":[],"mappings":"AACE,uDACE,0BAAA,CACA,2BAAA","sourcesContent":[".recipientSelector {\n  div:global(.nds-input .nds-input-box) {\n    border-top-left-radius: 4px;\n    border-top-right-radius: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recipientSelector": "recipientSelector--ZGexC"
};
export default ___CSS_LOADER_EXPORT___;
