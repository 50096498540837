import React, { useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import { Button, Checkbox, TextInput } from "@narmi/design_system";
import {
  ContextForm,
  NotificationContext,
  Tooltip,
  useFormData,
  CsrfTokenMiddleware,
  jsonFromDocument,
} from "cerulean";
import Feature from "byzantine/src/Feature";
import "./authentication.css";

const MfaCodeEnter = ({
  challengeText,
  deviceSelected,
  loginLinkFlow,
  features,
}) => {
  const { l10n } = useLocalization();
  const { sendNotification } = useContext(NotificationContext);
  const loginLinkFlowBoolean = loginLinkFlow === "True";
  const form = useRef(null);
  const { formData, onChange } = useFormData({
    remember_this_device: loginLinkFlowBoolean,
  });

  const resendCode = (e) => {
    e.preventDefault();
    form.current.elements.namedItem("otp_token").value = "";
    form.current.submit();
  };

  useEffect(() => {
    const formErrors = jsonFromDocument("form_non_field_errors", "[]");
    if (formErrors.length) {
      const error = formErrors[0];
      if (error !== "Please enter your OTP token.") {
        sendNotification({ type: "negative", text: formErrors[0] });
      }
    }
  }, []);

  const isBackupCode = deviceSelected.name === "Backup codes";
  const isApp = deviceSelected.name.match(/^App added/);
  let challengeTextDisplay = challengeText;
  if (isBackupCode) {
    challengeTextDisplay = l10n.getString("prompt-enter-backup-code", null, "Enter one of your backup codes.");
  } else if (isApp) {
    challengeTextDisplay = l10n.getString("prompt-use-authenticator", null, "Use an authenticator app to get a code.");
  }

  const queryParams = new URLSearchParams(window.location.search);
  const redirectUrl = queryParams.get("next")
    ? encodeURIComponent(queryParams.get("next"))
    : "/";

  return (
    <div className="authentication-container">
      <ContextForm
        ref={form}
        data={formData}
        onChange={onChange}
        action={`/login/verify_code_enter?next=${redirectUrl}`}
        autoComplete="off"
        method="post"
      >
        <h1>{"Let's verify your account"}</h1>
        <div data-testid="mfa-challenge-text">{challengeTextDisplay}</div>
        {!isBackupCode && !isApp && (
          <div className="resend-code-container">
            <Button
              label={l10n.getString("button-resend-code", null, "Resend code")}
              type="button"
              kind="plain"
              size="s"
              onClick={resendCode}
            />
          </div>
        )}
        <div className="code-input-container">
          <ContextForm.Field required>
            <TextInput
              field="otp_token"
              name="otp_token"
              label={l10n.getString("label-enter-code", null, "Enter code")}
              type={isBackupCode ? "text" : "tel"}
              data-testid="enter-code-input"
            />
          </ContextForm.Field>
        </div>
        <Feature features={features} or="allow_olb_device_remembering">
          <div className="margin--top--s">
            <ContextForm.Field style={{ display: "inline-flex", marginTop: 0 }}>
              <Checkbox
                id="remember_this_device"
                name="remember_this_device"
                field="remember_this_device"
                label={l10n.getString("label-remember-device", null, "Remember this device")}
              />
              <Tooltip text={l10n.getString("tooltip-shared-device", null, "Do not select this box if you're using a public or shared device.")}>
                <span className="narmi-icon-info fontColor--primary fontSize--s padding--top--xxs padding--left--xxs" />
              </Tooltip>
            </ContextForm.Field>
          </div>
        </Feature>
        <input name="otp_device" hidden readOnly value={deviceSelected.id} />
        <CsrfTokenMiddleware />
        <ContextForm.ActionBar>
          <ContextForm.Action
            onSubmit={(_, e) => {
              e.preventDefault();
              form.current.submit();
            }}
          >
            <Button label={l10n.getString("button-continue", null, "Continue")} />
          </ContextForm.Action>
        </ContextForm.ActionBar>
      </ContextForm>
    </div>
  );
};
MfaCodeEnter.propTypes = {
  deviceSelected: PropTypes.any,
  challengeText: PropTypes.string,
  loginLinkFlow: PropTypes.string,
  features: PropTypes.object,
};

export default MfaCodeEnter;
