var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { ContentCard, ResponsiveFlex, Select, TextInput } from "cerulean";
import { entities, modules } from "byzantine";
import { isEmpty } from "lodash";
import styles from "../Form.module.scss";
import * as formAdapters from "../../../../../../../adapters";
import LimitsDialog from "../../../../../../../../components/transfer/LimitsDialog";
import { useDetailsSelector, useLimitsForDisplay, } from "./DetailsSelector.container";
function DetailsSelector() {
    var _a = useDetailsSelector(), wireReasons = _a.wireReasons, method = _a.method, form = _a.form;
    var limits = entities.limits.useLimits();
    var limitsForDisplay = useLimitsForDisplay(limits);
    var _b = modules.wireTemplates.useConditionalDetails(), templateSelected = _b.templateSelected, additionalDetailsRequired = _b.additionalDetailsRequired, requiresAmount = _b.requiresAmount, requiresMemo = _b.requiresMemo, requiresWireReason = _b.requiresWireReason;
    if (!method ||
        !templateSelected ||
        (!additionalDetailsRequired && isEmpty(limitsForDisplay))) {
        return null;
    }
    return (_jsxs(ContentCard, { kind: "bordered", children: [additionalDetailsRequired ? (_jsx("p", { className: styles.wireTemplatesFormSubHeader, children: "Wire details" })) : null, _jsxs(ResponsiveFlex, { gapSize: "m", children: [requiresAmount ? (_jsx(TextInput, __assign({ label: "Amount" }, formAdapters.amountInput(form, "amount")))) : null, requiresMemo ? (_jsx(TextInput, __assign({ label: "Memo" }, formAdapters.input(form, "memo")))) : null, requiresWireReason ? (_jsx(Select, __assign({ label: "Wire reason" }, formAdapters.select(form, "wireReason"), { children: wireReasons.map(function (reason) {
                            return (_jsx(Select.Item, { value: reason, children: reason }, reason));
                        }) }))) : null, _jsx(LimitsDialog, { isWire: true, limitsForDisplay: limitsForDisplay })] })] }));
}
export default React.memo(DetailsSelector);
