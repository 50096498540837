import React from "react";
import PropTypes from "prop-types";
import { ContentCard, Dialog, Row, formatNumber } from "cerulean";

const SubUserLimitsDialog = ({
  type,
  userLimits,
  isOpen,
  onClose,
  features,
}) => {
  const key = type === "ACH" ? "ach_push" : "wire";
  const orgLimits = features.limits?.[key]
    ? Object.entries(features.limits?.[key])
    : [];

  return (
    <Dialog
      isOpen={isOpen}
      title={`${type} Limits`}
      onUserDismiss={() => onClose(!isOpen)}
    >
      <div className="margin--top--s">
        {orgLimits.length > 0 && (
          <ContentCard kind="bordered">
            <h3 className="fontSize--m fontFamily--body padding--bottom--xs">
              Organizational limit
            </h3>
            <div className="margin--bottom--l">
              This is the amount your organization is allowed to send during the
              specified time period. The sum of individual user limits cannot
              exceed this amount.
            </div>
            <div className="access-manager-limits">
              {orgLimits.map(([time, value]) => (
                <div className="subuser-limits" key={`${key}_${time}`}>
                  <Row>
                    <Row.Item>
                      <div>{time.toString()} day limit</div>
                    </Row.Item>
                    <Row.Item shrink>
                      {formatNumber(value, "currency")}
                    </Row.Item>
                  </Row>
                </div>
              ))}
            </div>
          </ContentCard>
        )}
        {userLimits.length > 0 && (
          <div className="margin--top--l">
            <ContentCard kind="bordered">
              <h3 className="fontSize--m fontFamily--body padding--bottom--xs">
                Individual user limits
              </h3>
              <div className="margin--bottom--l">
                These are the amounts each user is allowed to send as long as
                the org limit allows it. Individual limits cannot exceed the
                organizational limit during the specified time period.
              </div>
              <div className="access-manager-limits">
                {userLimits.map((orgUser) => (
                  <div className="subuser-limits" key={orgUser.name}>
                    <Row>
                      <Row.Item>
                        <div>
                          {orgUser.user.first_name} {orgUser.user.last_name}
                        </div>
                      </Row.Item>
                      <Row.Item shrink>
                        {formatNumber(
                          orgUser.role.limits?.[key]?.[1],
                          "currency"
                        )}
                        /day
                      </Row.Item>
                    </Row>
                  </div>
                ))}
              </div>
            </ContentCard>
          </div>
        )}
      </div>
    </Dialog>
  );
};

SubUserLimitsDialog.propTypes = {
  type: PropTypes.string,
  userLimits: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  features: PropTypes.object,
};

export default SubUserLimitsDialog;
