var _a;
/* eslint-disable no-param-reassign */
import { createSlice, nanoid } from "@reduxjs/toolkit";
export var initialState = {
    isFetching: false,
    didFetch: false,
    byId: {},
    lastUpdated: null,
};
export var slice = createSlice({
    name: "accounts",
    initialState: initialState,
    reducers: {
        request: function (state) {
            state.isFetching = true;
        },
        receive: {
            prepare: function (data, all) {
                if (all === void 0) { all = false; }
                return ({
                    payload: {
                        id: nanoid(),
                        accounts: ("accounts" in data
                            ? data.accounts
                            : [data.account]),
                        receivedAt: Date.now(),
                        all: all,
                    },
                });
            },
            reducer: function (state, action) {
                var _a = action.payload, accounts = _a.accounts, all = _a.all, receivedAt = _a.receivedAt;
                state.isFetching = false;
                state.didFetch = true;
                state.lastUpdated = receivedAt;
                if (all) {
                    state.byId = {};
                }
                accounts.forEach(function (account) {
                    state.byId[account.id] = account;
                });
            },
        },
        fail: function (state) {
            state.isFetching = false;
        },
        update: function (state, action) {
            var account = action.payload.account;
            state.byId[account.id] = account;
        },
        unlink: function (state, action) {
            var accounts = action.payload.accounts;
            accounts.forEach(function (_a) {
                var id = _a.id;
                delete state.byId[id];
            });
        },
    },
});
var name = "accounts";
export var actions = slice.actions;
export default (_a = {},
    _a[name] = slice.reducer,
    _a);
