import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import useAccountSorting from "../hooks/useAccountSorting";
import useAccountBalances from "../hooks/useAccountBalances";

const AccountContext = React.createContext();

export const useAccountsContext = () => useContext(AccountContext);

export const AccountContextProvider = ({
  children,
  accounts: accountsProp,
}) => {
  const [accounts, setAccounts] = useState(accountsProp);
  const [isUpdatingAccounts, setIsUpdatingAccounts] = useState(false);
  const { orderedAccounts, sortGroup, getAccountSortingForGroup } =
    useAccountSorting(accounts);

  const updateAccount = (account) => {
    setAccounts((prevAccounts) => {
      prevAccounts.splice(
        prevAccounts.findIndex((a) => a.id === account.id),
        1,
        account
      );
      return [...prevAccounts];
    });
  };

  useAccountBalances({ setAccounts, setIsUpdatingAccounts });

  const internalAccounts = orderedAccounts.filter((a) => a.isInternal());
  const favoritedAccounts = internalAccounts.filter((a) => a.favorited);
  const unfavoritedAccounts = internalAccounts.filter(
    (a) => !a.favorited && !a.hidden
  );
  const hiddenAccounts = internalAccounts.filter((a) => a.hidden);
  const depositAccounts = orderedAccounts.filter(
    (account) => account.account_type === "deposit"
  );

  return (
    <AccountContext.Provider
      value={{
        accounts: orderedAccounts,
        internalAccounts,
        favoritedAccounts,
        unfavoritedAccounts,
        hiddenAccounts,
        depositAccounts,
        setAccounts,
        updateAccount,
        getAccountSortingForGroup,
        clearSorting: (groupName) => sortGroup(groupName),
        sortGroupAlphabetically: (groupName, order) =>
          sortGroup(groupName, "alphabetically", order),
        sortGroupByBalance: (groupName, order) =>
          sortGroup(groupName, "by_balance", order),
        sortGroupByAccountNumber: (groupName, order) =>
          sortGroup(groupName, "by_account_number", order),
        sortAscending: (groupName, sort_by) =>
          sortGroup(groupName, sort_by, "ascending"),
        sortDescending: (groupName, sort_by) =>
          sortGroup(groupName, sort_by, "descending"),
        isUpdatingAccounts,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
AccountContextProvider.propTypes = {
  children: PropTypes.node,
  accounts: PropTypes.array,
};

export default AccountContext;
