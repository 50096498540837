import ApiHttp from "./ApiHttp";
import Filters from "./filters";

export default class Payee {
  static TYPE = {
    BUSINESS: "business",
    PERSON: "person",
  };

  static ERROR = {
    PENDING_PAYMENTS_EXIST: "pending payments exist",
    UNMODIFIABLE_NAME: "payee name cannot be modified for this payee.",
  };

  static GEO_COUNTRIES = [{ code: "US", name: "United States (US)" }];
  constructor(props) {
    this.id = props.id || "";
    this.type = props.type;
    this.name = props.name;
    this.description = props.description || "";
    this.phone = props.phone || "";
    this.email = props.email || "";
    this.passphrase = props.passphrase || "";
    this.account_number = props.account_number || "";
    this.masked_account_number = props.masked_account_number || "";
    this.address = props.address || {};
    this.delivery_options = props.delivery_options || [];
    this.ignore_warnings = props.ignore_warnings || null;
  }

  isPersonType() {
    return [Payee.TYPE.PERSON, "personal"].includes(this.type);
  }

  getDisplayName() {
    return [
      Filters.titlecase(this.name) || "",
      this.account_number?.replace(/\*/g, "") || "",
    ]
      .filter((part) => part?.trim())
      .join("-");
  }

  newPayeePayload() {
    const payload = {
      type: this.type,
      name: this.name,
      description: this.description,
      phone: this.phone,
    };
    if (this.isPersonType()) {
      payload.email = this.email;
      payload.passphrase = this.passphrase;
    } else {
      payload.address = this.address;
      payload.account_number = this.account_number;
    }
    if (this.ignore_warnings) {
      payload.ignore_warnings = this.ignore_warnings;
    }
    return payload;
  }

  submit() {
    return ApiHttp.fetch("payees", { method: "POST" }, this.newPayeePayload());
  }

  delete() {
    return ApiHttp.fetch(`payees/${this.id}`, { method: "DELETE"});
  }

  /*
    necessary because details like email for personal payees or address for
    business payees are not included in the "fetch all" call - you need to
    specifically "inquire" for a particular payee
   */
  async getPayeeDetails() {
    const response = await ApiHttp.fetch(`payees/${this.id}`);
    if (!response?.payee) return;
    return Payee.deserialize(response.payee, true);
  }

  serialize() {
    const formData = this.newPayeePayload();
    formData.name = Filters.titlecase(this.name);

    if (!this.isPersonType()) {
      formData.street_address = this.address.street_address;
      formData.street_address_2 = this.address?.street_address_2 || undefined;
      formData.city = this.address.city;
      formData.region_code = this.address.region_code;
      formData.postal_code = this.address.postal_code;
      formData.country_code = this.address.country_code || "US";
      formData.account_number = this.account_number;

      delete formData.address;
    }

    return formData;
  }

  update() {
    return ApiHttp.fetch(`payees/${this.id}`, {method: "PUT"}, this.newPayeePayload());
  }

  /**
   * Fetches payees and deserializes them.
   * Optionally, a payee can be provided to add to the array.
   * @param {Payee} [payee]
   * @returns {Payee[]}
   */
  static async fetchPayees(payee) {
    const response = await ApiHttp.fetch("payees");
    if (!response?.payees) return;
    const deserializedPayees = response.payees.map((p) => this.deserialize(p));
    /* We don't create payees locally, so it's difficult to confirm whether the following line is necessary
        For the time being, it's a useful check to make sure that we show a newly added payee and that we only show it once */
    if (payee && !deserializedPayees.some((p) => p.id === payee.id)) {
      deserializedPayees.push(payee);
    }
    return deserializedPayees;
  }

  /**
   * Factory method returning a new instance of Payee from
   * an indigo.serialized Payee
   * */
  static deserialize(payload, showFullAccountNumber = false) {
    return new Payee({
      ...payload,
      id: payload.id,
      type: payload.type,
      name: payload.name,
      description: payload.description,
      phone: payload.phone,
      email: payload.email,
      passphrase: payload.passphrase,
      account_number: showFullAccountNumber ? payload.account_number : payload.masked_account_number,
      address: payload.address,
      delivery_options: payload.delivery_options,
    });
  }
}
