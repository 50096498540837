var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable camelcase */
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountContext from "../contexts/AccountContext";
import { useInstitutionSettings } from "../contexts/InstitutionSettingsContext";
import { useUserFeatures } from "../contexts/UserFeaturesContext";
import SimpleTransfer from "./SimpleTransfer";
var SimpleTransferRouter = function (_a) {
    var limits = _a.limits;
    var accounts = useContext(AccountContext).accounts;
    var _b = useInstitutionSettings(), _c = _b.ach_allows_pull, ach_allows_pull = _c === void 0 ? false : _c, _d = _b.ach_allows_push, ach_allows_push = _d === void 0 ? false : _d;
    var _e = useUserFeatures(), loan_payment_by_card = _e.loan_payment_by_card, loan_principal_payment = _e.loan_principal_payment;
    var navigate = useNavigate();
    var _f = __read(useState(), 2), toAccountId = _f[0], setToAccountId = _f[1];
    var _g = __read(useState(), 2), fromAccountId = _g[0], setFromAccountId = _g[1];
    useEffect(function () {
        var params = new URLSearchParams(window.location.search);
        var from = params.get("from_account_id");
        if (from &&
            accounts
                .filter(function (account) {
                return account.isValidInternalOrExternalTransferSource(ach_allows_pull);
            })
                .map(function (account) { return account.id; })
                .includes(from)) {
            setFromAccountId(from);
        }
        params.delete("from_account_id");
        var to = params.get("to_account_id");
        if (to &&
            accounts
                .filter(function (account) {
                return account.isValidInternalOrExternalTransferDestination(ach_allows_push);
            })
                .map(function (account) { return account.id; })
                .includes(to)) {
            setToAccountId(to);
            // check if eligible for the loan payment by card or optionality flow
            var toAccount = accounts.find(function (account) { return account.id === to; });
            var isEligibleForLoanPaymentByCard = (toAccount === null || toAccount === void 0 ? void 0 : toAccount.isPayableByCard()) && loan_payment_by_card;
            var isEligibleForLoanPrincipalPayment = (toAccount === null || toAccount === void 0 ? void 0 : toAccount.isInternalLoan()) && loan_principal_payment;
            if (isEligibleForLoanPaymentByCard || isEligibleForLoanPrincipalPayment) {
                navigate("/loans?".concat(params.toString()), {
                    replace: true,
                });
                return;
            }
        }
        params.delete("to_account_id");
        params.delete("previous");
        window.history.replaceState({}, "", "".concat(document.location.href.split("?")[0], "?").concat(params.toString()));
    }, [accounts]);
    return (_jsx(SimpleTransfer, { limits: limits, toAccountId: toAccountId, fromAccountId: fromAccountId }));
};
export default SimpleTransferRouter;
