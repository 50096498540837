import { useFetchTemplates } from "../entities/fedwireTemplates/requests";
import { useFetchInstitution } from "../entities/helpers/institution.helpers";
import { useFetchUser } from "../entities/helpers/user.helpers";
import { useFetchWireRecipients } from "../entities/wires";
export var requestHooks = {
    institution: useFetchInstitution,
    user: useFetchUser,
    fedwireTemplates: useFetchTemplates,
    wireRecipients: useFetchWireRecipients,
};
