import React from "react";
import PropTypes from "prop-types";
import cc from "classcat";
import { LoadingSkeleton } from "@narmi/design_system";
import { Button } from "cerulean";
import styles from "./SectionCard.module.css";

const Title = ({
  text,
  button,
  className,
  isExpanded,
  setIsExpanded,
  testId,
  titleIcon,
}) => (
  <div className={className || styles.cardTitle}>
    <div
      className="alignChild--left--center"
      style={{ flexWrap: "wrap", gap: "var(--space-xxs)" }}
    >
      <h4
        className="nds-sans"
        style={{ marginRight: "auto" }}
        data-testid={testId}
      >
        {text}
        {titleIcon && isExpanded && (
          <span className="margin--left--xxs">{titleIcon}</span>
        )}
      </h4>
      {button}
      {setIsExpanded && (
        <Button
          kind="plain"
          type="button"
          onClick={() => setIsExpanded((prev) => !prev)}
          label={isExpanded ? "Hide" : "View"}
        />
      )}
    </div>
  </div>
);
Title.propTypes = {
  text: PropTypes.string,
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func,
  button: PropTypes.node,
  className: PropTypes.string,
  isVisible: PropTypes.bool,
  setIsCardVisible: PropTypes.func,
  testId: PropTypes.string,
  titleIcon: PropTypes.node,
};

const SectionCard = ({
  children,
  isLoading = false,
  title,
  isExpanded = true,
  setIsExpanded,
  loadingContent = "paragraph",
  showLoadingTitle = false,
  loadingLines = 3,
  loadingSize = "medium",
  paddingSize = "l",
  classNames = "",
  kind,
  testId = "",
  titleIcon,
}) => {
  /* if we are done loading there is no content, then return null */
  if (!isLoading && !children) {
    return null;
  }

  return (
    <div
      className={cc([
        { [`padding--all--${paddingSize}`]: paddingSize },
        `${styles.sectionCard} section-card ${classNames}`,
        { [styles.transactionsCard]: kind === "transactions" },
      ])}
    >
      <LoadingSkeleton
        isLoading={isLoading}
        content={loadingContent}
        showTitle={showLoadingTitle}
        lines={loadingLines}
        size={loadingSize}
      >
        {title && (
          <Title
            text={title}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            testId={testId}
            titleIcon={titleIcon}
          />
        )}
        {isExpanded && children}
      </LoadingSkeleton>
    </div>
  );
};
SectionCard.propTypes = {
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func,
  children: PropTypes.node,
  loadingContent: PropTypes.oneOf(["paragraph", "headerText"]),
  loadingLines: PropTypes.number,
  showLoadingTitle: PropTypes.bool,
  loadingSize: PropTypes.oneOf(["small", "medium", "large"]),
  paddingSize: PropTypes.oneOf(["xs", "s", "m", "l", "xl", null]),
  classNames: PropTypes.string,
  kind: PropTypes.oneOf(["transactions"]),
  testId: PropTypes.string,
  titleIcon: PropTypes.node,
};

SectionCard.Title = Title;

export default SectionCard;
