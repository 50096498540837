import PropTypes from "prop-types";
import React from "react";

import OrganizationDeactivateUser from "./OrganizationDeactivateUser";

const UserInfoHeader = ({
  organizationUser,
  orgId,
  showDeactivateButton = false,
}) => (
    <div
      className="section-border"
      style={{ display: "flex", alignItems: "center" }}
    >
      <span className="section-title">User info</span>
      {showDeactivateButton && (
        <div style={{ marginLeft: "auto" }}>
          <OrganizationDeactivateUser
            orgId={orgId}
            organizationUser={organizationUser}
          />
        </div>
      )}
    </div>
  );

UserInfoHeader.propTypes = {
  organizationUser: PropTypes.object,
  orgId: PropTypes.string,
  showDeactivateButton: PropTypes.bool,
};

export default UserInfoHeader;
