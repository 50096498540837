import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Tag } from "@narmi/design_system";
import {
  ContentCard,
  useFormData,
  useLoadingContext,
  useNotificationContext,
} from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";
import MfaDevice from "byzantine/src/MfaDevice";
import Institution from "byzantine/src/Institution";
import AddAuthenticationDeviceSelector from "./AddAuthenticationDeviceSelector";
import DeviceItem from "./DeviceItem";
import AddNewPhoneDevice from "./AddNewPhoneDevice";

const MultiFactorAuthentication = ({ initialDevices }) => {
  const { setIsLoading } = useLoadingContext();
  const { sendNotification } = useNotificationContext();
  const [queuedForRemovalDeviceId, setQueuedForRemovalDeviceId] = useState("");
  const [addDeviceType, setAddDeviceType] = useState("");
  const [permittedDeviceTypes, setPermittedDeviceTypes] = useState([]);
  // we can't currently fetch all devices from the API (only phone devices currently), so we'll just track removed devices through state for now
  // once we redesign settings and move away from Django forms, we can update the mfa_devices endpoint to handle app, email, & recovery code devices
  const [devices, setDevices] = useState(initialDevices);
  const { formData, onChange, setFormData } = useFormData();
  const mfaEnabled = devices.length > 0;

  useEffect(() => {
    new Institution().getSettings().then((response) => {
      setPermittedDeviceTypes(response?.permitted_mfa_methods);
    });
  }, []);

  const isPermittedDevice = (device) => {
    if (
      device.id?.includes("EmailDevice") &&
      permittedDeviceTypes.includes("email")
    ) {
      return true;
    }
    if (
      device.id?.includes("TwilioSMSDevice") &&
      permittedDeviceTypes.includes("phone")
    ) {
      return true;
    }
    if (
      device.id?.includes("TOTPDevice") &&
      permittedDeviceTypes.includes("app")
    ) {
      return true;
    }
    return false;
  };

  const onRemove = async (device) => {
    const deviceId = device.id;
    if (!queuedForRemovalDeviceId || queuedForRemovalDeviceId !== deviceId) {
      setQueuedForRemovalDeviceId(deviceId);
      return;
    }
    setIsLoading(true);
    const idChunks = deviceId.split(".");
    const deviceUrlId = idChunks[idChunks.length - 1]
      .toLowerCase()
      .replace(/\//g, ":");
    ApiHttp.fetch(`/settings/security/devices/${deviceUrlId}`, {
      method: "DELETE",
      endpointIsUrl: true,
    })
      .then(() => {
        sendNotification({
          type: "success",
          text: `${device.name} deleted.`,
        });
        const updatedDevices = devices.filter((d) => d.id !== deviceId);
        setDevices(updatedDevices);
      })
      .catch(() => {
        sendNotification({
          type: "negative",
          text: "There was an error deleting this device. Please try again or contact Support.",
        });
      })
      .finally(() => {
        setQueuedForRemovalDeviceId("");
        setIsLoading(false);
      });
  };

  const onDoneOrCancelAddPhone = () => {
    setAddDeviceType("");
    setFormData({});
  };

  const isRemovableDevice = (device) => {
    // if there is only one device, it is not removable
    if (devices?.length < 2) {
      return false;
    }
    const permittedDevices = devices.filter((d) => isPermittedDevice(d));
    // if there are multiple permitted devices (excl. backup codes), all are removable
    if (permittedDevices?.length > 1) {
      return true;
    }
    // if there are multiple devices and only one is permitted device (excl. backup codes), everything besides the permitted device is removable
    if (permittedDevices?.length === 1) {
      return device.id !== permittedDevices[0].id;
    }
    // if there are multiple unpermitted devices and no permitted devices, they are all removable (until there is one left)
    return true;
  };

  return (
    <div className="security-card">
      <ContentCard>
        <div className="alerts-title-container">
          <h3 className="semibold">Two-factor authentication</h3>
        </div>
        <div className="mfa-status">
          <h4 style={{ marginTop: "-2px", marginRight: "var(--space-xs)" }}>
            Status:
          </h4>
          <Tag
            label={mfaEnabled ? "Enabled" : "Not Enabled"}
            kind={mfaEnabled ? "success" : "error"}
          />
        </div>
        {mfaEnabled && devices && (
          <div className="devices-container">
            <h4 className="enabled-devices-header">Enabled devices</h4>
            {devices.map((device) => (
              <DeviceItem
                key={device.id}
                device={device}
                showHorizontalRule={
                  devices[devices.length - 1].id !== device.id &&
                  devices?.length !== 1
                } // show if neither the last nor only device
                isQueuedForRemoval={queuedForRemovalDeviceId === device.id}
                isRemovable={isRemovableDevice(device)}
                onRemove={onRemove}
              />
            ))}
          </div>
        )}
        <div className="add-authentication-device-selector-container">
          {addDeviceType !== MfaDevice.TYPES.PHONE ? (
            <AddAuthenticationDeviceSelector
              devices={devices}
              permittedDeviceTypes={permittedDeviceTypes}
              setAddDeviceType={setAddDeviceType}
              value={addDeviceType}
            />
          ) : (
            <AddNewPhoneDevice
              data={formData}
              devices={devices}
              onCancel={onDoneOrCancelAddPhone}
              onChange={onChange}
              onDone={onDoneOrCancelAddPhone}
              setDevices={setDevices}
            />
          )}
        </div>
      </ContentCard>
    </div>
  );
};
MultiFactorAuthentication.propTypes = {
  initialDevices: PropTypes.array,
};

export default MultiFactorAuthentication;
