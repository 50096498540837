import User from "./User";
import Role from "./Role";
import ApiHttp from "./ApiHttp";

export default class OrganizationUser {
  static STATUS = {
    ACTIVE: "Active",
    PENDING: "Pending",
    INACTIVE: "Inactive",
  };

  static ROLE = {
    ACCOUNT_HOLDER: "Account Holder",
    ADMIN: "Admin",
    COLLABORATOR: "Collaborator",
    VIEWER: "Viewer",
  };

  static ROLES_WITH_EDIT_PERMISSION = [
    OrganizationUser.ROLE.ACCOUNT_HOLDER,
    OrganizationUser.ROLE.ADMIN,
  ];

  static isOmniUser(roleName) {
    return OrganizationUser.ROLES_WITH_EDIT_PERMISSION.includes(roleName);
  }

  static statusForDisplay(status) {
    return status === OrganizationUser.STATUS.INACTIVE ? "Deactivated" : status;
  }

  constructor(props) {
    this.uuid = props.uuid;
    this.user = props.user || {};
    this.role = props.role;
    this.status = props.status;
    this.organization_uuid = props.organization_uuid;
    this.demo_invite_url = props.demo_invite_url;
  }

  /*
   * Get the current business user if HTML content is not sufficient / not available
   *  - Accessible by any user who is a member of an Org
   */
  static currentOrgUser() {
    return ApiHttp.fetch("organization_users/current").then((res) => {
      return OrganizationUser.deserialize(res.organization_user);
    });
  }

  /*
   * Hack to get the current org user from indigo until we can get it at login time
   */
  static currentOrgUserFromHTML() {
    const currentOrgUserEl = document.getElementById("current_business_user");
    if (!currentOrgUserEl || currentOrgUserEl.textContent == "{}") {
      return new OrganizationUser({});
    }
    const businessUserData = JSON.parse(currentOrgUserEl.textContent) || {};
    return OrganizationUser.deserialize(businessUserData);
  }

  static forOrganization(orgId, fetchApiWithAuth = null) {
    const url = `organizations/${orgId}/users`;

    if (fetchApiWithAuth) {
      return fetchApiWithAuth(url)
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          return json.organization_users.map((u) =>
            OrganizationUser.deserialize(u)
          );
        });
    }
    return ApiHttp.fetch(url);
  }

  sendInvite(fetchApiWithAuth = null) {
    const url = `organizations/${this.organization_uuid}/users`;
    const method = "POST";
    const payload = this.serialize();

    if (fetchApiWithAuth) {
      return fetchApiWithAuth(url, { method, payload });
    }
    return ApiHttp.fetch(url, { method }, payload);
  }

  updateAttrs(fetchApiWithAuth = null) {
    const url = `organizations/${this.organization_uuid}/user/${this.uuid}`;
    const method = "PUT";
    const payload = this.serialize();

    if (fetchApiWithAuth) {
      return fetchApiWithAuth(url, { method, payload });
    }
    return ApiHttp.fetch(url, { method }, payload);
  }

  reasonIfNotEditable(editorRoleName, editorUuid) {
    if (!editorRoleName || !editorUuid) {
      return "Editor must be specified";
    } else if (!OrganizationUser.isOmniUser(editorRoleName)) {
      return "Your role does not have edit permission";
    } else if (this.role.name === OrganizationUser.ROLE.ACCOUNT_HOLDER) {
      return "Account owners can't be edited";
    } else if (editorUuid === this.uuid) {
      return "Users can't edit their own account";
    }

    return "";
  }

  deactivate(fetchApiWithAuth = null) {
    const url = `organizations/${this.organization_uuid}/user/${this.uuid}`;
    const method = "DELETE";

    if (fetchApiWithAuth) {
      return fetchApiWithAuth(url, { method }).then(() => {});
    }
    return ApiHttp.fetch(url, {
      method,
    });
  }

  activityLine() {
    return `${OrganizationUser.statusForDisplay(
      this.status
    )} | Last login: ${this.user.getLastLogin()}`;
  }

  /** *
  Factory method returning a new instance of OrganizationMember from
  an ApiHttp serialized OrganizationMember
   ** */
  static deserialize(payload) {
    if (!payload) return null;
    // api previously gave name of role instead of the Role
    const role =
      typeof payload.role === "string"
        ? new Role({ name: payload.role })
        : new Role(payload.role);
    return new OrganizationUser({
      uuid: payload.uuid,
      user: User.deserialize(payload),
      role,
      status: payload.status,
      organization_uuid: payload.organization_uuid,
      demo_invite_url: payload.demo_invite_url,
    });
  }

  serialize() {
    return {
      uuid: this.uuid,
      first_name: this.user.first_name,
      last_name: this.user.last_name,
      email: this.user.email,
      phone: this.user.phone,
      role_uuid: this.role.uuid,
      role: this.role.serialize(),
      status: this.status,
    };
  }
}
