import React, { useContext } from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import { Button } from "@narmi/design_system";
import { ContextForm } from "cerulean";
import AccountContext from "../contexts/AccountContext";
import ActionReview from "./ActionReview";

const BillPayActionReview = ({ data, onSubmit, goBack, cancel, payees }) => {
  const { l10n } = useLocalization();
  const { accounts } = useContext(AccountContext);
  const details = [
    {
      label: "To",
      info: payees.find((p) => p.id === data.payee_id).getDisplayName(),
    },
    { label: "Amount", info: `${data.amount}` },
    { label: "Date", info: data.payment_date },
  ];

  // this is feature-flagged at the moment so from_account_id may not exist
  if (data?.from_account_id) {
    details.unshift({
      label: "From",
      info: accounts
        .find((a) => a.id === data.from_account_id)
        .getDescription(),
    });
  }

  return (
    <ActionReview
      details={details}
      icon="dollar-bill-line"
      title="Review your payment"
      goBack={goBack}
    >
      <ContextForm.ActionBar vertical>
        <ContextForm.Action
          onSubmit={onSubmit}
          label={l10n.getString("label-submit-payment")}
        >
          <Button label={l10n.getString("label-submit-payment")} />
        </ContextForm.Action>
        <div className="transfer-cancel-button">
          <Button
            onClick={() => cancel()}
            kind="negative"
            type="button"
            label="Cancel"
          />
        </div>
      </ContextForm.ActionBar>
    </ActionReview>
  );
};
BillPayActionReview.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  goBack: PropTypes.func,
  cancel: PropTypes.func,
  payees: PropTypes.array,
};

export default BillPayActionReview;
