import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Check, X } from "react-feather";
import ReactMarkdown from "react-markdown";
import { Button } from "@narmi/design_system";
import useTimeout from "../useTimeout";
var SuccessMessage = function (_a) {
    var text = _a.text, dismissMessage = _a.dismissMessage, creationTime = _a.creationTime, _b = _a.notificationType, notificationType = _b === void 0 ? "success" : _b;
    if (notificationType === "success") {
        useTimeout(dismissMessage, 5000 - (Date.now() - creationTime));
    }
    return (_jsxs("div", { className: "message-content success", children: [_jsx(Check, { color: "var(--color-successDark)" }), _jsx("span", { className: notificationType === "success" ? "" : "margin--right--l", "data-testid": "success-message", children: _jsx(ReactMarkdown, { children: text }) }), notificationType === "dismissable_success" && (_jsx(Button, { kind: "plain", label: _jsx(X, { className: "toast-x" }), onClick: dismissMessage }))] }));
};
export default SuccessMessage;
