import { useCallback } from "react";
import { modules, useObjectMemo } from "byzantine";
import { useNavigate } from "react-router-dom";
export var useTemplateSelector = function () {
    var _a = modules.wireTemplates.useTemplateSelector(), sortedFedwireTemplateIds = _a.sortedFedwireTemplateIds, showManageTemplatesUI = _a.showManageTemplatesUI, selectedTemplateId = _a.selectedTemplateId, onSelectTemplate = _a.onSelectTemplate, method = _a.method, error = _a.error, resetForm = _a.resetForm;
    var navigate = useNavigate();
    var manageTemplates = useCallback(function () {
        navigate("templates");
        resetForm();
    }, [navigate, resetForm]);
    var createNewTemplate = useCallback(function () {
        navigate("templates/new");
        resetForm();
    }, [navigate, resetForm]);
    return useObjectMemo({
        showManageTemplatesUI: showManageTemplatesUI,
        sortedFedwireTemplateIds: sortedFedwireTemplateIds,
        selectedTemplateId: selectedTemplateId,
        onSelectTemplate: onSelectTemplate,
        createNewTemplate: createNewTemplate,
        manageTemplates: manageTemplates,
        method: method,
        error: error,
    });
};
