var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Button, TextInput } from "@narmi/design_system";
var PasswordTextInput = function (_a) {
    var _b = _a.label, label = _b === void 0 ? "Password" : _b, _c = _a.labelShow, labelShow = _c === void 0 ? "Show" : _c, _d = _a.labelHide, labelHide = _d === void 0 ? "Hide" : _d, otherProps = __rest(_a, ["label", "labelShow", "labelHide"]);
    var _e = __read(useState(false), 2), showPassword = _e[0], setShowPassword = _e[1];
    var handleShowPassword = function () {
        setShowPassword(function (prevState) { return !prevState; });
    };
    return (_jsxs("div", { className: "password-input", children: [_jsx(TextInput, __assign({ label: label, name: "password" }, otherProps, { type: showPassword ? "text" : "password", error: otherProps.fieldError || otherProps.error })), _jsx("div", { className: "show-hide-button", children: _jsx(Button, { type: "button", kind: "negative", size: "xs", onClick: handleShowPassword, label: showPassword ? labelHide : labelShow }) })] }));
};
export default PasswordTextInput;
