import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import { Search } from "react-feather";
import ApiHttp from "byzantine/src/ApiHttp";
import ThreadList from "./ThreadList";

const ThreadSearch = ({
  closeSearch,
  selectedThreadId,
  setSelectedThreadId,
}) => {
  const [subjectFilter, setSubjectFilter] = useState("");
  const [matchingThreads, setMatchingThreads] = useState(null);

  const loadThreads = useMemo(
    () =>
      debounce((filter) => {
        const params = { order_by: "updated_at", filter };
        ApiHttp.fetch("threads", { params }).then((response) => {
          setMatchingThreads(
            response.threads.map((thread) => ({
              threadId: thread.id,
              subject: thread.subject,
              updatedAt: thread.updated_at,
            }))
          );
        });
      }, 500),
    []
  );

  useEffect(() => {
    loadThreads(subjectFilter);
  }, [subjectFilter, loadThreads]);

  return (
    <div>
      <div className="search-bar">
        <div className="ui icon input">
          <Search className="message-search-icon" color="#8c8c8c" />
          <input
            type="text"
            className="field"
            value={subjectFilter}
            onChange={(e) => setSubjectFilter(e.target.value)}
            placeholder="Search messages"
          />
        </div>
        <button
          className="ui button message-search-button"
          onClick={closeSearch}
        >
          Cancel
        </button>
      </div>
      {matchingThreads ? (
        <ThreadList
          threads={matchingThreads}
          selectedThreadId={selectedThreadId}
          onThreadSelected={(newSelectedThreadId) =>
            setSelectedThreadId(newSelectedThreadId)
          }
          emptyListSubject="No results found"
          emptyListSubtext="Please try adjusting your search"
        />
      ) : null}
    </div>
  );
};
ThreadSearch.propTypes = {
  closeSearch: PropTypes.func.isRequired,
  selectedThreadId: PropTypes.string,
  setSelectedThreadId: PropTypes.func.isRequired,
};

export default ThreadSearch;
