var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRequestWithFeedback, combineRunProps, } from "../../../composites";
import { useObjectMemo } from "../../../hooks";
import { API } from "./api";
import { actions } from "./slice";
import { selectWireRecipients } from "./selectors";
export var useFetchRecentWires = function () {
    var dispatch = useDispatch();
    var _a = useRequestWithFeedback(), send = _a.send, loading = _a.loading;
    var fetchWires = useCallback(function (accountId, overrideRunProps) {
        if (overrideRunProps === void 0) { overrideRunProps = {}; }
        send(__assign({ action: API.fetchRecent(accountId), onData: function (data) {
                dispatch(actions.receive(accountId, data));
            } }, overrideRunProps));
    }, [dispatch, send]);
    return useObjectMemo({ send: fetchWires, loading: loading });
};
export var useFetchWireRecipients = function () {
    var dispatch = useDispatch();
    var _a = useRequestWithFeedback(), send = _a.send, loading = _a.loading, status = _a.status;
    var fetchWireRecipients = useCallback(function (overrideRunProps) {
        if (overrideRunProps === void 0) { overrideRunProps = {}; }
        send(__assign({ action: API.listWireRecipients(), onData: function (data) {
                dispatch(actions.receiveWireRecipients(data));
            } }, overrideRunProps));
    }, [dispatch, send]);
    var hasData = useSelector(selectWireRecipients).length > 0;
    return useObjectMemo({
        send: fetchWireRecipients,
        loading: loading,
        error: status === "error",
        hasData: hasData,
    });
};
export var useDeleteWireRecipient = function () {
    var dispatch = useDispatch();
    var send = useRequestWithFeedback().send;
    var deleteWireRecipient = useCallback(function (id, overrideRunProps) {
        if (overrideRunProps === void 0) { overrideRunProps = {}; }
        send(__assign({ action: API.deleteWireRecipient(id), onData: function () {
                dispatch(actions.deleteWireRecipient(id));
            }, messaging: {
                toast: {
                    success: "Recipient successfully removed.",
                    error: "Recipient could not be removed. Please try again.",
                },
            } }, overrideRunProps));
    }, [dispatch, send]);
    return useObjectMemo({ send: deleteWireRecipient });
};
export var useAddWireRecipientRequest = function () {
    var dispatch = useDispatch();
    var send = useRequestWithFeedback().send;
    var addWireRecipient = useCallback(function (wireRecipient, overrideRunProps) {
        if (overrideRunProps === void 0) { overrideRunProps = {}; }
        send(__assign({ action: API.saveWireRecipient(wireRecipient) }, combineRunProps({
            onData: function (data) {
                dispatch(actions.createWireRecipient(data));
            },
        }, overrideRunProps)));
    }, [send, dispatch]);
    return useObjectMemo({ send: addWireRecipient });
};
