import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ZELLE_LOGO from "../../assets/images/zelle-logo.svg";
var W = 1568.63;
var H = 678.02;
export default function ZelleLogo(_a) {
    var _b = _a.size, size = _b === void 0 ? 22 : _b;
    var height = size;
    var width = W * (height / H);
    return (_jsxs("div", { className: "alignChild--left--top", children: [_jsx("img", { width: width, height: height, src: ZELLE_LOGO, alt: "Zelle", "aria-label": "Zelle" }), _jsx("sup", { style: {
                    fontSize: "var(--font-size-xs)",
                    marginLeft: 2,
                    color: "var(--color-black))",
                }, children: "\u00AE" })] }));
}
