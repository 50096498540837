var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, createContext, useEffect, useContext, } from "react";
import Institution from "byzantine/src/Institution";
var defaultInstitutionSettings = { long_name: "" };
var InstitutionSettingsContext = createContext(defaultInstitutionSettings);
export var InstitutionSettingsContextProvider = function (_a) {
    var children = _a.children;
    var _b = __read(useState(defaultInstitutionSettings), 2), settings = _b[0], setSettings = _b[1];
    useEffect(function () {
        var institution = new Institution();
        institution
            .getSettings()
            .then(function (settingsResponse) { return setSettings(settingsResponse); });
    }, []);
    return (_jsx(InstitutionSettingsContext.Provider, { value: settings, children: children }));
};
export var useInstitutionSettings = function () {
    return useContext(InstitutionSettingsContext);
};
export default InstitutionSettingsContext;
