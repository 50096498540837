import React from "react";
import PropTypes from "prop-types";
import { DropdownListItem } from "cerulean";
import WireRecipient from "byzantine/src/WireRecipient";

const RecipientListItem = ({ closeDropdown, recipient, onChange, value }) => (
    <DropdownListItem
      anyItemSelected={!!value?.id}
      isIndented={false}
      isSelected={value.id === recipient.id}
      onClick={() => {
        closeDropdown();
        onChange(recipient);
      }}
    >
      {recipient.displayValue}
    </DropdownListItem>
  );
RecipientListItem.propTypes = {
  closeDropdown: PropTypes.func,
  hideRecipientsForm: PropTypes.func,
  recipient: PropTypes.instanceOf(WireRecipient),
  onChange: PropTypes.func,
  value: PropTypes.object,
};

export default RecipientListItem;
