import React from "react";
import PropTypes from "prop-types";
import { NotificationContext } from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";
import Balances from "byzantine/src/Balances";

const useAccountBalances = ({ setAccounts, setIsUpdatingAccounts }) => {
  const { sendNotification } = React.useContext(NotificationContext);
  React.useEffect(() => {
    const fetchAccountBalances = async () => {
      setIsUpdatingAccounts(true);
      try {
        const { account_balances } = await ApiHttp.fetch("account_balances/");
        setAccounts((prev) => {
          prev.forEach((account) => {
            const balance_for_account = account_balances.find(
              (balance) => balance.account_id === account.id
            );
            if (balance_for_account) {
              account.balances = Balances.deserialize(balance_for_account);
            }
          });
          return [...prev];
        });
      } catch {
        sendNotification({
          type: "negative",
          text: "Your account balances may be out of date",
        });
      } finally {
        setIsUpdatingAccounts(false);
      }
    };
    fetchAccountBalances();
  }, []);
};
useAccountBalances.propTypes = {
  setAccounts: PropTypes.func,
};

export default useAccountBalances;
