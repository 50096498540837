import User from "./User";
import Filters from "./filters";
import ApiHttp from "./ApiHttp";

class DualApprovalRequestAction {
  constructor(dualApprovalRequest) {
    if (dualApprovalRequest.wire) {
      this.type = "Wire transfer";
      this.from = dualApprovalRequest.wire.from_account_display;
      this.to = dualApprovalRequest.wire.beneficiary_name;
      this.amount = Filters.penniesToDollars(dualApprovalRequest.wire.amount);
      this.status = dualApprovalRequest.wire.state;
    }
  }
}

export default class DualApprovalRequest {
  constructor(props) {
    this.uuid = props.uuid;
    this.created_at = props.created_at;
    this.requester = props.requester;
    this.responder = props.responder;
    this.action = props.action;
  }

  approve() {
    const payload = { action: "approve" };
    return ApiHttp.fetch(
      `approval_requests/${this.uuid}`,
      { method: "POST" },
      payload
    ).then((response) => {
      this.action.status = response.approval_request.wire.state;
    });
  }

  reject() {
    const payload = { action: "reject" };
    return ApiHttp.fetch(
      `approval_requests/${this.uuid}`,
      { method: "POST" },
      payload
    ).then((response) => {
      this.action.status = response.approval_request.wire.state;
    });
  }

  static deserialize(payload) {
    return new DualApprovalRequest({
      uuid: payload.uuid,
      created_at: new Date(payload.created_at),
      requester: User.deserialize(payload.requester),
      responder: User.deserialize(payload.responder),
      action: new DualApprovalRequestAction(payload),
    });
  }
}
