import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from "react";
import { Button, IconButton, Row } from "cerulean";
import { modules } from "byzantine";
import { v4 as uuidv4 } from "uuid";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import HeaderWithSteps from "../../../../../../../components/HeaderWithSteps";
import styles from "./Review.module.scss";
function Review(_a) {
    var onSubmit = _a.onSubmit, loading = _a.loading, goBack = _a.goBack;
    var itempotencyKey = useMemo(function () { return uuidv4(); }, []);
    var details = modules.wireTemplates.useReviewDetails();
    var navigate = useNavigate();
    var cancel = useCallback(function () {
        navigate("/");
    }, [navigate]);
    if (!details) {
        return null;
    }
    var sections = details.sections, buttonLabel = details.buttonLabel, disclosure = details.disclosure;
    return (_jsxs("div", { className: styles.wireViaTemplateReviewContainer, children: [_jsxs("div", { className: styles.backButtonRow, children: [_jsx("span", { "aria-hidden": "true", className: styles.iconContainer, children: _jsx(IconButton, { kind: "plain", label: "Go Back", name: "chevron-left", onClick: goBack, textSize: "l" }) }), _jsx("div", { className: styles.headerContainer, children: _jsx(HeaderWithSteps, { headerText: "Review your wire", step: 2, totalSteps: 2 }) })] }), sections.map(function (_a, i) {
                var header = _a.header, rows = _a.rows;
                return (_jsxs("div", { children: [_jsx("p", { className: styles.reviewSubHeader, children: header }), _jsx("div", { className: styles.card, children: rows.map(function (_a, j) {
                                var rowHeader = _a.header, value = _a.value;
                                return (_jsxs("div", { children: [_jsx("p", { className: styles.key, children: rowHeader }), _jsx("p", { className: styles.value, children: value })] }, "row-".concat(rowHeader, "-").concat(j)));
                            }) })] }, "section-".concat(header, "-").concat(i)));
            }), disclosure ? (_jsx("div", { className: "fontColor--secondary", children: _jsx(ReactMarkdown, { linkTarget: "_blank", children: disclosure }) })) : null, _jsx("div", { className: styles.buttonRowContainer, children: _jsxs(Row, { alignItems: "center", children: [_jsx(Row.Item, { children: _jsx(Button, { label: "Cancel", kind: "negative", onClick: cancel }) }), _jsx(Row.Item, { shrink: true, children: _jsx(Button, { label: buttonLabel, onClick: function () { return onSubmit(itempotencyKey); }, isLoading: loading, disabled: loading }) })] }) })] }));
}
export default React.memo(Review);
