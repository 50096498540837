import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from "prop-types";
import styles from "./ButtonBar.module.scss";
var ButtonBar = function (_a) {
    var className = _a.className, children = _a.children;
    return (_jsx("div", { className: "".concat(styles.buttonbar, " ").concat(className), children: children }));
};
ButtonBar.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
export default ButtonBar;
