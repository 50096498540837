import React from "react";

export default function Loading() {
  return (
    <div className="ui one column grid">
      <div
        className="ui wireframe placeholder"
        style={{ height: "10em" }}
      ></div>
      <div className="ui inverted dimmer active">
        <div className="ui text loader active">Loading</div>
      </div>
    </div>
  );
}
