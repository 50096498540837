import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from "react";
import { LibraryProvider } from "byzantine";
import webPlatform from "./platform.implementation";
import webNetwork from "./network.implementation";
import useWebToasts from "./toasts.implmentation";
var DBBLLibraryProvider = function (_a) {
    var preloadedState = _a.preloadedState, children = _a.children;
    var toasts = useWebToasts();
    return (_jsx(LibraryProvider, { platform: webPlatform, network: webNetwork, toasts: toasts, preloadedState: preloadedState, children: children }));
};
export default memo(DBBLLibraryProvider);
