export var METHOD_OPTIONS = [
    {
        id: "freeform",
        label: "Send without a template",
    },
    {
        id: "template",
        label: "Send via template",
    },
];
export var TEMPLATE_UPDATED_FLASH_DURATION = 5 * 1000;
