import React from "react";
import PropTypes from "prop-types";

const SudoAction = ({
  reviewableAction,
  renderInput,
  renderSudo,
  renderReview,
}) => {
  const {
    ACTION_STATES,
    screenState,
    goToInput,
    goToSudo,
    goToReview,
  } = reviewableAction;

  let content;
  if (screenState === ACTION_STATES.INPUT) content = renderInput;
  else if (screenState === ACTION_STATES.SUDO) content = renderSudo;
  else content = renderReview;

  return React.cloneElement(content, {
    goToInput,
    goToSudo,
    goToReview,
  });
};
SudoAction.propTypes = {
  reviewableAction: PropTypes.object,
  renderInput: PropTypes.node,
  renderSudo: PropTypes.node,
  renderReview: PropTypes.node,
};

export default SudoAction;
