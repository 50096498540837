import React from "react";
import PropTypes from "prop-types";

const ActionHeader = ({ title, icon }) => (
    <div style={{ textAlign: "center", paddingBottom: "40px" }}>
      <div className="narmi-transfer-icon-container">
        <span className="narmi-icon-blob" />
        <span className={`narmi-icon-${icon}`} />
      </div>
      <div className="narmi-transfer-header-container">
        <span
          style={{
            color: "rgb(var(--nds-black))",
            fontWeight: "700",
            fontSize: "20px",
            lineHeight: "25px",
          }}
        >
          {title}
        </span>
      </div>
    </div>
  );

ActionHeader.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
};

export default ActionHeader;
