import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isFeatureEnabled, isFeatureDisabled, doesFeatureEqual, } from "./isEnabled";
import { selectFeatures } from "./selectors";
export var useFeature = function () {
    var features = useSelector(selectFeatures);
    return useMemo(function () { return ({
        featureEnabled: function (query) {
            return isFeatureEnabled(features, typeof query !== "string" ? query : { or: query });
        },
        featureDisabled: function (feature) {
            return isFeatureDisabled(features, feature);
        },
        featureEquals: function (flag, setting) {
            return doesFeatureEqual(features, flag, setting);
        },
        features: features,
    }); }, [features]);
};
