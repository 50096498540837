var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocalization } from "@fluent/react";
import { pickBy } from "lodash";
import User from "byzantine/src/User";
import { Button, ContextForm, Dialog, Row, TextInput, useFormData, useNotificationContext, } from "cerulean";
import { useCurrentUser } from "../../../../contexts/CurrentUserContext";
var NewUsernameInput = function (_a) {
    var onChange = _a.onChange, value = _a.value, field = _a.field, textInputProps = __rest(_a, ["onChange", "value", "field"]);
    var handleInput = function (event) {
        var parsedValue = event.target.value.toLowerCase();
        if (onChange) {
            onChange(parsedValue);
        }
    };
    return (_jsx(TextInput, __assign({ label: "Username", onChange: handleInput, value: value, "aria-label": field }, textInputProps)));
};
var UsernameEditDialog = function (_a) {
    var isOpen = _a.isOpen, closeDialog = _a.closeDialog;
    var _b = useCurrentUser(), currentUser = _b.currentUser, setCurrentUser = _b.setCurrentUser;
    var sendNotification = useNotificationContext().sendNotification;
    var _c = useFormData({
        username: currentUser === null || currentUser === void 0 ? void 0 : currentUser.username,
    }), formData = _c.formData, onChange = _c.onChange;
    var l10n = useLocalization().l10n;
    var validateField = function (value) {
        if (!value) {
            return l10n.getString("error-required", null, "Must be filled in.");
        }
        if (value.toLowerCase() === (currentUser === null || currentUser === void 0 ? void 0 : currentUser.username)) {
            return l10n.getString("error-same-username", null, "New username same as current.");
        }
        return null;
    };
    var onSubmit = function (callback) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedUser, cleanedUpdatedUser, error_1, e;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (currentUser === null || currentUser === void 0 ? void 0 : currentUser.updateUsername(formData.username))];
                case 1:
                    updatedUser = _a.sent();
                    if (updatedUser) {
                        cleanedUpdatedUser = pickBy(updatedUser, function (v) { return ![undefined, '""', "[]", "{}"].includes(JSON.stringify(v)); });
                        setCurrentUser(new User(__assign(__assign({}, currentUser), cleanedUpdatedUser)));
                    }
                    callback();
                    sendNotification({ type: "success", text: "Username updated." });
                    closeDialog();
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    e = error_1;
                    callback(e.username);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Dialog, { isOpen: isOpen, onUserDismiss: closeDialog, title: "Edit username", children: [_jsx("div", { className: "margin--top--s" }), _jsxs(ContextForm, { data: formData, onChange: onChange, children: [_jsx(ContextForm.Field, { validate: validateField, children: _jsx(NewUsernameInput, { field: "username" }) }), _jsx("div", { className: "margin--bottom--xl" }), _jsxs(Row, { alignItems: "center", justifyContent: "end", children: [_jsx(Row.Item, { shrink: true, children: _jsx(Button, { type: "button", onClick: closeDialog, kind: "negative", label: "Cancel" }) }), _jsx(Row.Item, { shrink: true, children: _jsx(ContextForm.Action, { onSubmit: onSubmit, children: _jsx(Button, { kind: "primary", label: "Save changes", disabled: (formData === null || formData === void 0 ? void 0 : formData.username) === (currentUser === null || currentUser === void 0 ? void 0 : currentUser.username) }) }) })] })] })] }));
};
export default UsernameEditDialog;
