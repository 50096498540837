import React from "react";
import PropTypes from "prop-types";
import utils from "./utils";

const RenderContent = (props) => {
  if (!props.payees.length) {
    return <props.renderEmpty {...props} />;
  }
  return (
    <React.Fragment>
      {props.filteredPayees.map((payee) => (
        <props.renderPayee payee={payee} key={payee.id} {...props} />
      ))}
    </React.Fragment>
  );
};
RenderContent.propTypes = {
  payees: PropTypes.array,
  filteredPayees: PropTypes.array,
};

export const filterPayees = (payees, filter, searchTerm) => {
  return payees.filter(filter).filter((payee) => {
    if (!searchTerm || searchTerm === "") return true;
    const re = new RegExp(utils.escapeRegExp(searchTerm), "i");
    return re.test(payee.getDisplayName());
  });
};

export default function PayeeList(props) {
  const [searchTerm, setSearchTerm] = React.useState("");
  const filteredPayees =
    props.payees?.length >= 10
      ? filterPayees(props.payees, props.filter, searchTerm)
      : props.payees;
  return (
    <React.Fragment>
      {props.payees?.length >= 10 && (
        <props.renderSearch
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      )}
      <RenderContent filteredPayees={filteredPayees} {...props} />
    </React.Fragment>
  );
}

PayeeList.defaultProps = {
  payees: [],
  renderSearch: () => null,
  renderEmpty: () => null,
  filter: (p) => !!p,
};

PayeeList.propTypes = {
  payees: PropTypes.array,
  renderSearch: PropTypes.func,
  renderPayee: PropTypes.func.isRequired,
  renderEmpty: PropTypes.func,
  filter: PropTypes.func,
};
