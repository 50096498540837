import { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { NotificationContext } from "cerulean";
import Account from "byzantine/src/Account";
import Transaction from "byzantine/src/Transaction";
import ApiHttp from "byzantine/src/ApiHttp";

const usePendingTransactions = ({
  accounts,
  setPendingTransactions,
  excludeHiddenAccounts = false,
}) => {
  const { sendNotification } = useContext(NotificationContext);

  useEffect(() => {
    const fetchPendingTransactions = async () => {
      try {
        let fetchUrl = "";
        if (accounts?.length === 1) fetchUrl += `accounts/${accounts[0].id}/`;
        fetchUrl += "transactions?";
        if (excludeHiddenAccounts) fetchUrl += "filter=hidden&";
        fetchUrl += "q=is:unsettled";

        const response = await ApiHttp.fetch(fetchUrl, { method: "GET" });
        if (response?.transactions) {
          const pendingTransactions = response.transactions.map((t) =>
            Transaction.deserialize(
              t,
              accounts.find((a) => a.id === t.account_id)
            )
          );
          setPendingTransactions(pendingTransactions);
        }
      } catch {
        sendNotification({
          type: "negative",
          text: "There was an error fetching pending transactions.",
        });
      }
    };

    fetchPendingTransactions();
  }, [JSON.stringify(accounts)]);
};

usePendingTransactions.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.instanceOf(Account)).isRequired,
  setPendingTransactions: PropTypes.func.isRequired,
  excludeHiddenAccounts: PropTypes.bool,
};

export default usePendingTransactions;
