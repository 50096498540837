import ApiHttp from "./ApiHttp";
import utils from "./utils";
var LoanPayment = /** @class */ (function () {
    function LoanPayment(props) {
        this.from_account_id = props.from_account_id;
        this.to_account_id = props.to_account_id;
        this.amount = props.amount;
        this.payment_type =
            props.payment_type === "principal" ? props.payment_type : "default";
        this.itempotencyKey = props.itempotencyKey;
    }
    LoanPayment.prototype.submit = function () {
        return ApiHttp.fetch("internal_loan_payments/", { method: "POST", headers: { "Idempotency-Key": this.itempotencyKey } }, {
            amount: utils.dollarsToPennies(this.amount.replace(/[^\d.]/g, "")),
            from_account: this.from_account_id,
            to_account: this.to_account_id,
            payment_type: this.payment_type,
        });
    };
    return LoanPayment;
}());
export default LoanPayment;
