class MfaDevice {
  // eventually we want to expand this to include app/email devices
  static TYPES = Object.freeze({
    PHONE: "phone",
    APP: "app",
    RECOVERY_CODES: "recovery_codes",
    EMAIL: "email",
  });
}

export default MfaDevice;
