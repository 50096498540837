// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".memberships-card-title--hgPPL{display:flex;justify-content:space-between;padding-bottom:var(--space-s)}", "",{"version":3,"sources":["webpack://./components/settings/profile_settings/linked_account_memberships/LinkedAccountMembershipsCard.module.scss"],"names":[],"mappings":"AAAA,+BACE,YAAA,CACA,6BAAA,CACA,6BAAA","sourcesContent":[".memberships-card-title {\n  display: flex;\n  justify-content: space-between;\n  padding-bottom: var(--space-s);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"memberships-card-title": "memberships-card-title--hgPPL"
};
export default ___CSS_LOADER_EXPORT___;
