import React, { useContext, useEffect, useState } from "react";
import { useLocalization } from "@fluent/react";
import User from "byzantine/src/User";
import {
  LoadingSkeleton,
  PreferenceContentCard,
  useLoadingContext,
} from "cerulean";

import InstitutionSettingsContext from "../contexts/InstitutionSettingsContext";
import PaperlessStatementFooter from "./PaperlessStatementFooter";
import PaperlessStatementContent from "./PaperlessStatementContent";
import UnenrollButton from "./UnenrollButton";
import { useCurrentUser } from "../contexts/CurrentUserContext";

const EstatementsContainer = () => {
  const { l10n } = useLocalization();
  const [confirmedEstatementAccess, setConfirmedEstatementAccess] =
    useState(false);
  const { currentUser, setCurrentUser } = useCurrentUser();
  const [estatementsEnabled, setEstatementsEnabled] = useState(false);
  const [estatementsWereEnabledInitially, setEstatementsWereEnabledInitially] =
    useState(false);

  const { setIsLoading } = useLoadingContext();

  useEffect(() => {
    setIsLoading(true);
    // Two states updated here, both of which initially set to whether the user has estatements:
    // 1) estatementsEnabled tracks whether the user has enabled estatements
    // 2) estatementsWereEnabledInitially tracks whether the user had enabled estatements as of page load, which determines which copy we show
    User.getUser()
      .then((u) => {
        setCurrentUser(u);
        const hasEstatements = !!u?.enabled_estatements_at;
        setEstatementsEnabled(hasEstatements);
        setEstatementsWereEnabledInitially(hasEstatements);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const { core_update_estatement } = useContext(InstitutionSettingsContext);
  if ([core_update_estatement, currentUser?.features].includes(undefined)) {
    return <LoadingSkeleton isLoading={true} lines={3} />;
  }

  // don't render if core doesn't support estatements
  if (!core_update_estatement) {
    return null;
  }

  // don't render if neither paperless enrollment nor unenrollment are supported
  if (
    !currentUser?.features?.paperless_statement_enrollment &&
    !currentUser?.features?.paperless_statement_unenrollment
  ) {
    return null;
  }

  // don't render if the user is not enrolled and the FI doesn't support enrollment
  if (
    !estatementsEnabled &&
    !currentUser?.features.paperless_statement_enrollment
  ) {
    return null;
  }

  return (
    <div className="confirm-estatements-access">
      <PreferenceContentCard
        field="estatements"
        headerButton={
          <UnenrollButton
            // update state because the user will need to re-view the sample PDF if they unenroll
            setConfirmedEstatementAccess={setConfirmedEstatementAccess}
            setEstatementsEnabled={setEstatementsEnabled}
            setEstatementsWereEnabledInitially={
              setEstatementsWereEnabledInitially
            }
            // unenroll button only renders if user has unenrollment feature and has enabled estatements
            show={
              currentUser?.features?.paperless_statement_unenrollment &&
              estatementsEnabled
            }
          />
        }
        showToggle={false}
        subtext={
          <PaperlessStatementFooter
            // update state when user has viewed the sample statement via the sample PDF link in the footer
            setConfirmedEstatementAccess={setConfirmedEstatementAccess}
          />
        }
        subtitle={
          <PaperlessStatementContent
            confirmedEstatementAccess={confirmedEstatementAccess}
            estatementsEnabled={estatementsEnabled}
            estatementsWereEnabledInitially={estatementsWereEnabledInitially}
            // update state when user has viewed the sample statement via the sample PDF link in the main content
            setConfirmedEstatementAccess={setConfirmedEstatementAccess}
            setEstatementsEnabled={setEstatementsEnabled}
          />
        }
        title={l10n.getString(
          "label-paperless-statements",
          null,
          "Paperless statements"
        )}
      />
    </div>
  );
};

export default EstatementsContainer;
