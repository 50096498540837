import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef } from "react";
import { Button, useLoadingContext, useNotificationContext } from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";
import utils from "../../utils";
var BillPaySso = function (_a) {
    var _b = _a.label, label = _b === void 0 ? "Manage bill pay" : _b, _c = _a.size, size = _c === void 0 ? "m" : _c;
    var setIsLoading = useLoadingContext().setIsLoading;
    var sendNotification = useNotificationContext().sendNotification;
    var formRef = useRef(null);
    var p1InputRef = useRef(null);
    var handleSsoSuccess = function (_a) {
        var url = _a.url, session = _a.session;
        if (!formRef || !formRef.current || !p1InputRef || !p1InputRef.current) {
            return;
        }
        formRef.current.action = url;
        p1InputRef.current.value = session;
        formRef.current.submit();
    };
    var handleSsoError = function (error) {
        var defaultErrorMessage = "There was an error, please try again shortly.";
        var extractedError = utils.extractErrorMessage(error, defaultErrorMessage);
        sendNotification({ type: "negative", text: extractedError });
    };
    var getSso = function () {
        setIsLoading(true);
        ApiHttp.fetch("signed_urls/bill_pay/")
            .then(handleSsoSuccess)
            .catch(handleSsoError)
            .finally(function () {
            setIsLoading(false);
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { as: "a", onClick: getSso, kind: "plain", label: label, size: size, endIcon: "external-link", ariaLabel: "".concat(label, " - opens in new window") }), _jsxs("form", { ref: formRef, id: "ipay-sso-form", hidden: true, method: "POST", target: "_blank", children: [_jsx("input", { ref: p1InputRef, id: "ipay-p1-input", type: "hidden", name: "p1" }), _jsx("input", { type: "hidden", name: "op", value: "Login" }), _jsx("input", { type: "hidden", name: "method", value: "Session" })] })] }));
};
export default BillPaySso;
