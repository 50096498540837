import React from "react";
import PropTypes from "prop-types";
import { Button, ContextForm } from "cerulean";

const OrgUserActions = ({
  onSubmit,
  dangerouslyDisableShowLoading = false,
  submitButtonLabel = "Send invite",
}) => (
  <ContextForm.ActionBar
    style={{
      display: "flex",
      alignItems: "center",
      marginTop: "40px",
    }}
  >
    <ContextForm.Action
      noValidation
      onSubmit={() => {
        window.location.assign("/manage_users");
      }}
      dangerouslyDisableShowLoading={dangerouslyDisableShowLoading}
      // new user has dangerouslyDisableShowLoading true
    >
      <Button
        kind="negative"
        style={{ marginLeft: "auto" }}
        data-testid="Cancel"
        label="Cancel"
      />
    </ContextForm.Action>
    <ContextForm.Action onSubmit={onSubmit}>
      <div className="margin--left">
        <Button label={submitButtonLabel} />
      </div>
    </ContextForm.Action>
  </ContextForm.ActionBar>
);
OrgUserActions.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  dangerouslyDisableShowLoading: PropTypes.bool,
  submitButtonLabel: PropTypes.string.isRequired,
};

export default OrgUserActions;
