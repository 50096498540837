import React, { useContext } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import Account from "byzantine/src/Account";
import { Button } from "@narmi/design_system";
import { ContextForm } from "cerulean";
import AccountContext from "../contexts/AccountContext";
import ActionReview from "./ActionReview";

const SimpleTransferActionReview = ({ data, onSubmit, goBack, cancel }) => {
  const { accounts } = useContext(AccountContext);
  const details = [
    {
      label: "From",
      info: accounts
        .find((a) => a.id === data.from_account_id)
        .getDescription(),
    },
    {
      label: "To",
      info: accounts
        .find((a) => a.id === data.to_account_id)
        .getDescription(true),
    },
    { label: "Amount", info: `${data.amount}` },
    { label: "Frequency", info: data.frequency },
    {
      label: "Date",
      info: DateTime.fromFormat(data.date, "M/d/yyyy").toFormat("MM/dd/yyyy"),
    },
    ...(data.memo ? [{ label: "Memo", info: data.memo }] : []),
  ];

  const transactionType = Account.getTransactionType(
    data.to_account_id,
    data.from_account_id,
    accounts
  );

  return (
    <ActionReview
      details={details}
      icon="transfer-arrows"
      title={`Review your ${transactionType.toLowerCase()}`}
      goBack={goBack}
    >
      <ContextForm.ActionBar vertical>
        <ContextForm.Action onSubmit={onSubmit}>
          <Button label={`Make ${transactionType.toLowerCase()}`} />
        </ContextForm.Action>
        <div className="transfer-cancel-button">
          <Button
            onClick={() => cancel()}
            kind="negative"
            type="button"
            label="Cancel"
          />
        </div>
      </ContextForm.ActionBar>
    </ActionReview>
  );
};
SimpleTransferActionReview.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  goBack: PropTypes.func,
  cancel: PropTypes.func,
};

export default SimpleTransferActionReview;
