var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { isGroupedAccountsArray, filterAndGroupAccounts, DEFAULT_ACCOUNT_MINIMUM_FOR_GROUPING, } from "byzantine/src/AccountUtils";
var RenderContent = function (_a) {
    var groupedAccounts = _a.groupedAccounts, isDestination = _a.isDestination, isIndented = _a.isIndented, RenderAccount = _a.RenderAccount, RenderEmpty = _a.RenderEmpty, RenderGroup = _a.RenderGroup, RenderHeader = _a.RenderHeader, closeDropdown = _a.closeDropdown, value = _a.value;
    var numGroups = isGroupedAccountsArray(groupedAccounts)
        ? groupedAccounts.length
        : Array.from(groupedAccounts).length;
    if (isGroupedAccountsArray(groupedAccounts)) {
        return groupedAccounts.map(function (groupedAccount) { return (_jsx(RenderAccount, { account: groupedAccount, value: value, isIndented: isIndented, closeDropdown: closeDropdown, isDestination: isDestination }, groupedAccount.id)); });
    }
    if (!(groupedAccounts instanceof Map)) {
        return _jsx(RenderEmpty, {});
    }
    return Array.from(groupedAccounts, function (_a) {
        var _b = __read(_a, 2), groupName = _b[0], accountsForGroup = _b[1];
        return (_jsxs(RenderGroup, { numGroups: numGroups, children: [_jsx(RenderHeader, { group: groupName }), accountsForGroup.map(function (account) { return (_jsx(RenderAccount, { account: account, isDestination: isDestination, isIndented: true, value: value, closeDropdown: closeDropdown }, account.id)); })] }, groupName));
    });
};
var AccountList = function (_a) {
    var _b = _a.alwaysGroup, alwaysGroup = _b === void 0 ? false : _b, _c = _a.accounts, accounts = _c === void 0 ? [] : _c, _d = _a.filter, filter = _d === void 0 ? function (account) { return !!account; } : _d, _e = _a.isDestination, isDestination = _e === void 0 ? true : _e, _f = _a.isIndented, isIndented = _f === void 0 ? false : _f, _g = _a.RenderSearch, RenderSearch = _g === void 0 ? function () { return null; } : _g, _h = _a.RenderAccount, RenderAccount = _h === void 0 ? function () { return null; } : _h, _j = _a.RenderEmpty, RenderEmpty = _j === void 0 ? function () { return null; } : _j, _k = _a.RenderGroup, RenderGroup = _k === void 0 ? function (_a) {
        var children = _a.children;
        return _jsx(_Fragment, { children: children });
    } : _k, _l = _a.RenderHeader, RenderHeader = _l === void 0 ? function () { return null; } : _l, _m = _a.closeDropdown, closeDropdown = _m === void 0 ? function () { return null; } : _m, value = _a.value;
    var _o = __read(useState(""), 2), searchTerm = _o[0], setSearchTerm = _o[1];
    var shouldGroup = alwaysGroup || (accounts === null || accounts === void 0 ? void 0 : accounts.length) >= DEFAULT_ACCOUNT_MINIMUM_FOR_GROUPING;
    var groupedAccounts = shouldGroup
        ? filterAndGroupAccounts(accounts, filter, searchTerm)
        : accounts;
    return (_jsxs(_Fragment, { children: [shouldGroup && (_jsx(RenderSearch, { searchTerm: searchTerm, setSearchTerm: setSearchTerm })), _jsx(RenderContent, { groupedAccounts: groupedAccounts, isDestination: isDestination, isIndented: isIndented, RenderAccount: RenderAccount, RenderEmpty: RenderEmpty, RenderGroup: RenderGroup, RenderHeader: RenderHeader, closeDropdown: closeDropdown, value: value })] }));
};
export default AccountList;
