var _a;
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
export var initialState = {
    sorting: [],
    isFetching: false,
};
var name = "accountSorting";
export var accountSortingSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        receive: function (state, action) {
            var sorting = action.payload.account_sorting;
            state.sorting = sorting || [];
            state.isFetching = false;
        },
    },
});
export var actions = accountSortingSlice.actions;
export default (_a = {},
    _a[name] = accountSortingSlice.reducer,
    _a);
