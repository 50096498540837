var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ApiHttp from "byzantine/src/ApiHttp";
import Membership from "byzantine/src/Membership";
import { Button, ContentCard, useNotificationContext } from "cerulean";
import cloneDeep from "lodash/cloneDeep";
import { useState } from "react";
import { useCurrentUser } from "../../../contexts/CurrentUserContext";
import LinkInternalAccountModal from "./LinkInternalAccountModal";
import styles from "./LinkedAccountMembershipsCard.module.scss";
import MembershipInfoRow from "./MembershipInfoRow";
var LinkedAccountMembershipsCard = function (_a) {
    var initialMemberships = _a.initialMemberships;
    var _b = __read(useState(initialMemberships), 2), memberships = _b[0], setMemberships = _b[1];
    var _c = __read(useState(false), 2), linkInternalAccountModalOpen = _c[0], setLinkInternalAccountModalOpen = _c[1];
    var sendNotification = useNotificationContext().sendNotification;
    var currentUser = useCurrentUser().currentUser;
    var userPrimaryMembershipId = (currentUser === null || currentUser === void 0 ? void 0 : currentUser.institution_user_identifier)
        ? currentUser.institution_user_identifier.toString()
        : "";
    var removeMembership = function (id) {
        var membershipsUpdated = cloneDeep(memberships);
        var idx = membershipsUpdated.findIndex(function (m) { return m.id === id; });
        if (idx < 0)
            return;
        membershipsUpdated.splice(idx, 1);
        setMemberships(membershipsUpdated);
    };
    var fetchMemberships = function () {
        ApiHttp.fetch("accounts/memberships", { method: "GET" })
            .then(function (res) {
            var membershipsArray = res.memberships.map(function (membershipId) { return Membership.deserialize(membershipId); });
            setMemberships(membershipsArray);
        })
            .catch(function () {
            sendNotification({
                type: "negative",
                text: "There was an error fetching linked internal accounts.",
            });
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs(ContentCard, { children: [_jsxs("div", { className: styles["memberships-card-title"], children: [_jsx("h4", { className: "nds-sans fontSize--heading5", children: "Membership" }), _jsx(Button, { kind: "plain", label: "Link another account", size: "m", onClick: setLinkInternalAccountModalOpen })] }), _jsx(MembershipInfoRow, { name: "Primary account number", membership: new Membership({ id: userPrimaryMembershipId }) }), memberships.map(function (membership) { return (_jsx(MembershipInfoRow, { name: "Account number", membership: membership, removeMembership: removeMembership }, membership.id)); })] }), _jsx(LinkInternalAccountModal, { linkInternalAccountModalOpen: linkInternalAccountModalOpen, setLinkInternalAccountModalOpen: setLinkInternalAccountModalOpen, fetchMemberships: fetchMemberships })] }));
};
export default LinkedAccountMembershipsCard;
