import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

const ActionContext = createContext({});

export const ActionContextProvider = ({ children, ...otherProps }) => (
  <ActionContext.Provider value={{ ...otherProps }}>
    {children}
  </ActionContext.Provider>
);
ActionContextProvider.propTypes = {
  cancelAction: PropTypes.func,
  children: PropTypes.node,
};

export const useActionContext = () => useContext(ActionContext);

export default ActionContext;
