import React from "react";
import PropTypes from "prop-types";
import Feature from "byzantine/src/Feature";
import SecurityAlerts from "./alerts/SecurityAlerts";
import TransactionAndBalanceAlerts from "./alerts/TransactionAndBalanceAlerts";
import MultiFactorAuthentication from "./mfa/MultiFactorAuthentication";
import DualApprovalOptInContainer from "../../dual_approval/DualApprovalOptInReact";

const SecuritySettingsContainer = ({ accounts, devices, features }) => {
  // MultiFactorAuthentication is positioned at the top because we want to make it as easy as possible
  // for users to find where to add MFA devices
  const parsedDevices = JSON.parse(devices);

  return (
    <div className="nds-grid margin--top--l">
      <div className="nds-span-6">
        <Feature features={features} flag="alerts">
          <TransactionAndBalanceAlerts accounts={accounts} />
        </Feature>
        <SecurityAlerts />
      </div>
      <div className="nds-span-6">
        <MultiFactorAuthentication initialDevices={parsedDevices} />
        <DualApprovalOptInContainer />
      </div>
    </div>
  );
};
SecuritySettingsContainer.propTypes = {
  accounts: PropTypes.array,
  devices: PropTypes.string,
  features: PropTypes.object,
};

export default SecuritySettingsContainer;
