import hoistStatics from "hoist-non-react-statics";
import PropTypes from "prop-types";
import React from "react";
import OrganizationUser from "./OrganizationUser";

export const SORT = {
  NAME_ASC: (a, b) => {
    return a.user.last_name.toLowerCase() > b.user.last_name.toLowerCase()
      ? 1
      : -1;
  },
  NAME_DESC: (a, b) =>
    a.user.last_name.toLowerCase() < b.user.last_name.toLowerCase() ? 1 : -1,
  LASTLOGIN_ASC: (a, b) => {
    if (!a.user.last_login) return 1;
    if (a.user.last_login > b.user.last_login) {
      return 1;
    }
    return -1;
  },
  LASTLOGIN_DESC: (a, b) => {
    if (!a.user.last_login) return 1;
    if (a.user.last_login < b.user.last_login) {
      return 1;
    }
    return -1;
  },
};
SORT.DEFAULT = SORT.NAME_ASC;

const statusForDisplay = OrganizationUser.statusForDisplay;
const ROLE = OrganizationUser.ROLE;
const STATUS = OrganizationUser.STATUS;

export const FILTER = {
  ROLE_ADMIN: (a) => a.role.getAccessLevel() === ROLE.ADMIN,
  ROLE_COLLABORATOR: (a) => a.role.getAccessLevel() === ROLE.COLLABORATOR,
  ROLE_VIEWER: (a) => a.role.getAccessLevel() === ROLE.VIEWER,
  STATUS_ACTIVE: (a) => a.status === STATUS.ACTIVE,
  STATUS_PENDING: (a) => a.status === STATUS.PENDING,
  STATUS_INACTIVE: (a) => a.status === STATUS.INACTIVE,
};
const ROLE_FILTERS = [
  FILTER.ROLE_ADMIN,
  FILTER.ROLE_COLLABORATOR,
  FILTER.ROLE_VIEWER,
];
const STATUS_FILTERS = [
  FILTER.STATUS_ACTIVE,
  FILTER.STATUS_PENDING,
  FILTER.STATUS_INACTIVE,
];

export const DISPLAY_FILTERS = {
  STATUS: [
    { text: statusForDisplay(STATUS.ACTIVE), filter: FILTER.STATUS_ACTIVE },
    { text: statusForDisplay(STATUS.PENDING), filter: FILTER.STATUS_PENDING },
    { text: statusForDisplay(STATUS.INACTIVE), filter: FILTER.STATUS_INACTIVE },
  ],
  ROLE: [
    { text: ROLE.ADMIN, filter: FILTER.ROLE_ADMIN },
    { text: ROLE.COLLABORATOR, filter: FILTER.ROLE_COLLABORATOR },
    { text: ROLE.VIEWER, filter: FILTER.ROLE_VIEWER },
  ],
};

export const FILTERS_TO_NAMES = Object.entries(FILTER).reduce((acc, f) => {
  const [name, filter] = f;
  acc[filter] = name;
  return acc;
}, {});

function getOrganizationUsers(organizationUsers, search, filters, sortOrder) {
  if (!organizationUsers) return [];
  let displayOrganizationUsers = organizationUsers.slice();
  if (search) {
    const searchQuery = new RegExp(search, "i");
    displayOrganizationUsers = displayOrganizationUsers.filter((orgUser) => {
      if (searchQuery.test(orgUser.user.first_name)) return true;
      if (searchQuery.test(orgUser.user.last_name)) return true;
      if (searchQuery.test(orgUser.user.email)) return true;
      if (searchQuery.test(orgUser.user.getDescription())) return true;
      return false;
    });
  }
  if (filters.length) {
    displayOrganizationUsers = displayOrganizationUsers.filter((a) => {
      const activeFilterCategories = [
        filters.filter((filter) => ROLE_FILTERS.includes(filter)),
        filters.filter((filter) => STATUS_FILTERS.includes(filter)),
      ].filter((f) => f.length);
      return activeFilterCategories.every((category) =>
        category.some((filter) => filter(a))
      );
    });
  }
  return displayOrganizationUsers.sort(sortOrder);
}

export default function OrganizationUserList(props) {
  const [orgUsers, setOrgUsers] = React.useState(props.organizationUsers || []);
  const [search, setSearch] = React.useState("");
  const [filters, setFilters] = React.useState(() => []);
  const [sortOrder, setSortOrder] = React.useState(() => SORT.DEFAULT);

  // allow bootstrapping orgUsers state via a prop
  React.useEffect(() => {
    setOrgUsers(props.organizationUsers);
  }, [props.organizationUsers]);

  const displayOrganizationUsers = getOrganizationUsers(
    orgUsers,
    search,
    filters,
    sortOrder
  );
  const toggleFilter = (filter) => {
    const index = filters.indexOf(filter);
    if (filter === undefined) {
      setFilters(() => []);
    } else if (index === -1) {
      setFilters((prevFilters) => [...prevFilters, filter]);
    } else {
      setFilters((prevFilters) => {
        const newFilters = prevFilters.slice();
        newFilters.splice(index, 1);
        return newFilters;
      });
    }
  };
  if (!displayOrganizationUsers.length && props.renderEmpty) {
    return (
      <props.renderEmpty
        {...props}
        setSearch={setSearch}
        toggleFilter={toggleFilter}
        setSortOrder={setSortOrder}
      />
    );
  }
  const clearFilters = () => {
    setFilters(() => []);
  };

  return (
    <props.renderContainer
      {...props}
      displayOrganizationUsers={displayOrganizationUsers}
      setOrganizationUsers={setOrgUsers}
      search={search}
      setSearch={setSearch}
      filters={filters}
      toggleFilter={toggleFilter}
      clearFilters={clearFilters}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
    />
  );
}

OrganizationUserList.defaultProps = {
  organizationUsers: [],
};

OrganizationUserList.propTypes = {
  organizationUsers: PropTypes.array, // can be passed in or bootstrapped by wrapped component
  renderContainer: PropTypes.func.isRequired,
  renderEmpty: PropTypes.func,
};

/*
 *  HOC that wraps your desired component in an OrganizationUserList if you so prefer
 */
function getDisplayName(WrappedComponent) {
  return (
    WrappedComponent.displayName || WrappedComponent.name || "WrappedComponent"
  );
}

export function withOrgUserList(
  WrappedComponent,
  EmptyStateComponent = undefined
) {
  class WithOrgUserList extends React.Component {
    render() {
      return (
        <OrganizationUserList
          renderContainer={WrappedComponent}
          renderEmpty={EmptyStateComponent}
          {...this.props}
        />
      );
    }
  }
  WithOrgUserList.displayName = `WithOrgUserList(${getDisplayName(
    WrappedComponent
  )})`; // so sez Gaearon: https://reactjs.org/docs/higher-order-components.html#convention-wrap-the-display-name-for-easy-debugging
  hoistStatics(WithOrgUserList, WrappedComponent);
  return WithOrgUserList;
}
