var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useObjectMemo } from "../../../../hooks";
import { useFeature } from "../../features";
import { PERMISSION, useBusinessPermissions } from "../../permissions";
var WIRE_PERMISSIONS = [PERMISSION.BUSINESS.WRITE.WIRE_TRANSACTION];
export var useWirePermissions = function () {
    var _a = useFeature(), featureEnabled = _a.featureEnabled, featureEquals = _a.featureEquals;
    var showWireTransferOptionInMenu = featureEnabled("wires");
    var fedwireTemplatesEnabled = featureEquals("wire_templates", "fedwire");
    var _b = __read(useBusinessPermissions(WIRE_PERMISSIONS), 1), hasSuperWiresPermission = _b[0];
    var showSendViaTemplateUI = fedwireTemplatesEnabled && hasSuperWiresPermission;
    var showManageTemplatesUI = fedwireTemplatesEnabled && hasSuperWiresPermission;
    return useObjectMemo({
        showWireTransferOptionInMenu: showWireTransferOptionInMenu,
        fedwireTemplatesEnabled: fedwireTemplatesEnabled,
        showSendViaTemplateUI: showSendViaTemplateUI,
        showManageTemplatesUI: showManageTemplatesUI,
    });
};
