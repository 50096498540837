import React from "react";
import PropTypes from "prop-types";
import { ContentCard, Error } from "cerulean";
import OrganizationUser from "byzantine/src/OrganizationUser";

const ACCESS_LEVELS = {
  [OrganizationUser.ROLE.ADMIN]: {
    title: "Admin",
    description:
      "Full access including adding new users and managing existing ones",
    icon: "admin-user",
  },
  [OrganizationUser.ROLE.COLLABORATOR]: {
    title: "Collaborator",
    description: "Customize your teammate's access to specific features",
    icon: "users",
  },
  [OrganizationUser.ROLE.VIEWER]: {
    title: "Viewer",
    description: "View information, excluding statements, on selected accounts",
    icon: "eye",
  },
};

const AccessLevelCard = ({ option, value, onChange }) => {
  if (!ACCESS_LEVELS[option.title]) {
    return null;
  }
  const { title, description, icon } = ACCESS_LEVELS[option.title];
  const isSelected = value === option.value;
  return (
    <div
      data-testid={isSelected ? "selected" : "unselected"}
      className="nds-span-4 nds-span-tablet-6"
    >
      <ContentCard
        kind="interactive"
        onClick={(event) => {
          onChange({ ...event, target: { value: option.value } });
        }}
        isSelected={isSelected}
      >
        <h4 className="access-manager-radio-select-card--heading padding--bottom--s">
          {title}
          <span className={`narmi-icon-${icon}`}></span>
        </h4>
        <p>{description}</p>
      </ContentCard>
    </div>
  );
};

AccessLevelCard.propTypes = {
  option: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

const RadioSelect = ({ onChange, value, error, options }) => (
  <>
    <div className="nds-grid">
      {options.map((option) => (
        <AccessLevelCard
          key={option.value}
          option={option}
          onChange={onChange}
          value={value}
        />
      ))}
    </div>
    <Error error={error} />
  </>
);

RadioSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  renderOption: PropTypes.func,
  error: PropTypes.string,
};

export default RadioSelect;
