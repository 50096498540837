var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { PreferenceContentCard } from "cerulean";
import ProfileSettingRow from "../ProfileSettingRow";
import PasswordEditDialog from "./PasswordEditDialog/PasswordEditDialog";
var PasswordInfoCard = function () {
    var _a = __read(useState(false), 2), isPasswordEditDialogOpen = _a[0], setIsPasswordEditDialogOpen = _a[1];
    return (_jsxs(_Fragment, { children: [_jsx(PreferenceContentCard, { title: "Password", showSubtext: false, showToggle: false, content: _jsx(ProfileSettingRow, { label: "Password", value: "********************", editOnClick: function () {
                        setIsPasswordEditDialogOpen(true);
                    }, testId: "password-setting" }) }), _jsx(PasswordEditDialog, { isOpen: isPasswordEditDialogOpen, closeDialog: function () {
                    setIsPasswordEditDialogOpen(false);
                } })] }));
};
export default PasswordInfoCard;
