import React from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import Filters from "byzantine/src/filters";

function EmptyThreadItem({ subject, subtext }) {
  return (
    <div className="thread-item">
      <div className="thread-item-title">{subject}</div>
      <div>{subtext}</div>
    </div>
  );
}
EmptyThreadItem.propTypes = {
  subject: PropTypes.node.isRequired,
  subtext: PropTypes.node,
};

function ThreadItem({
  threadId,
  selected,
  subject,
  updatedAt,
  onSelected,
  alternativeMessage,
  isRead,
}) {
  const threadItemClasses = selected ? "thread-item selected" : "thread-item";
  return (
    <div
      role="button"
      tabIndex="0"
      className={threadItemClasses}
      onKeyUp={({ key }) => {
        if (onSelected && key === "Enter") {
          onSelected(threadId);
        }
      }}
      onClick={() => {
        if (onSelected) {
          onSelected(threadId);
        }
      }}
    >
      {!isRead ? (
        <div className="thread-read-indicator">
          <span className="narmi-icon-solid-circle thread-read-indicator-icon" />
        </div>
      ) : null}

      <div className="thread-item-title">{subject}</div>
      {updatedAt ? (
        <div>{Filters.longMonthDayYear(updatedAt)}</div>
      ) : (
        <div>{alternativeMessage}</div>
      )}
    </div>
  );
}
ThreadItem.propTypes = {
  selected: PropTypes.bool,
  subject: PropTypes.string.isRequired,
  threadId: PropTypes.string,
  updatedAt: PropTypes.string,
  onSelected: PropTypes.func,
  alternativeMessage: PropTypes.string,
  isRead: PropTypes.bool,
};

export default function ThreadList({
  threads,
  selectedThreadId,
  onThreadSelected,
  emptyListSubject,
  emptyListSubtext,
}) {
  const { l10n } = useLocalization();
  if (threads === null) {
    return <div className="thread-list"></div>;
  } else if (threads.length === 0) {
    return (
      <div className="thread-list">
        <EmptyThreadItem
          subject={emptyListSubject || l10n.getString("thread-list-empty-state")}
          subtext={emptyListSubtext}
        />
      </div>
    );
  }
  return (
    <div className="thread-list">
      {threads.map(({ threadId, subject, updatedAt, isRead }) => (
        <ThreadItem
          key={threadId}
          threadId={threadId}
          subject={subject}
          updatedAt={updatedAt}
          selected={threadId === selectedThreadId}
          onSelected={(newSelectedThreadId) =>
            onThreadSelected(newSelectedThreadId)
          }
          isRead={isRead}
        />
      ))}
    </div>
  );
}
ThreadList.propTypes = {
  threads: PropTypes.array,
  selectedThreadId: PropTypes.string,
  onThreadSelected: PropTypes.func.isRequired,
  emptyListSubject: PropTypes.string,
  emptyListSubtext: PropTypes.string,
};
