import React, { useEffect, useState } from "react";
import { useLocalization } from "@fluent/react";
import Institution from "byzantine/src/Institution";
import User from "byzantine/src/User";
import { LoadingShim } from "cerulean";
import SideBarPage from "./SideBarPage";
import Verify from "./Verify";
import CreateYourLogin from "./CreateYourLogin";
import ReviewDisclosures from "./ReviewDisclosures";
import SetUpYourAccountPreferences from "./SetUpYourAccountPreferences";

const EnrollmentPageContainer = () => {
  let encodedPayload;
  if (document.getElementById("encoded_payload")) {
    encodedPayload = JSON.parse(
      document.getElementById("encoded_payload").textContent
    );
  }
  const { l10n } = useLocalization();
  const [institutionSettings, setInstitutionSettings] = useState({});
  const [currentUser, setCurrentUser] = useState(null);
  const [initialUser, setInitialUser] = useState(null);
  const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);

  const ENROLLMENT_SCREENS = [
    {
      description: l10n.getString("enroll-step-verify", null, "Verify"),
      path: "verify",
      isActive: (user) => !user || user.requires_enrollment_code_verification,
      component: Verify,
    },
    {
      description: l10n.getString("enroll-step-register", null, "Register"),
      path: "register",
      isActive: (user) => !user?.has_username || !user?.has_password,
      component: CreateYourLogin,
    },
    {
      description: l10n.getString(
        "enroll-step-disclosures",
        null,
        "Disclosures"
      ),
      path: "disclosures",
      isActive: (user) => !user?.has_accepted_latest_terms,
      component: ReviewDisclosures,
    },
    {
      description: l10n.getString(
        "enroll-step-preferences",
        null,
        "Preferences"
      ),
      /*
      if a user is brought back to the enroll flow just to accept updated terms of service,
      they should not be shown this page and it should not appear in the sidebar
      */
      path: "preferences",
      isActive: (user) => !user || user?.is_converting,
      component: SetUpYourAccountPreferences,
    },
  ];

  useEffect(() => {
    const loadInitialState = async () => {
      // Fire off the initial institution fetch
      // We need the institution because we cannot rely on `user`
      // to fetch relevant institution features when there is no user yet
      const institutionPromise = new Institution().getSettings();

      // Handle the synchronous stuff
      // If the user is brought to /enroll from an email link (e.g. the user is a business subuser),
      // they should skip the Tell Us More About You page
      if (document.getElementById("enrolling_user")) {
        const user = new User(
          JSON.parse(document.getElementById("enrolling_user").textContent)
        );
        if (user.id) {
          setCurrentUser(user);
          setInitialUser(user);
        }
      } else {
        User.getUser().then((u) => {
          setCurrentUser(u);
          setInitialUser(u);
        });
      }

      // wait for the institution settings promise to resolve
      // if we get back a valid response, we know that we're done loading
      // TODO Add retry logic here and an error state if we can't fetch institution settings
      const settingsResponse = await institutionPromise;
      if (settingsResponse) {
        setInstitutionSettings(settingsResponse);
        setIsInitialLoadDone(true);
      }
    };
    loadInitialState();
  }, []);

  const sidebarSteps = ENROLLMENT_SCREENS.filter((screen) =>
    screen.isActive(initialUser, institutionSettings)
  ).map((screen) => ({
    label: screen.description,
    path: screen.path,
  }));

  const currentScreen = ENROLLMENT_SCREENS.find((screen) =>
    screen.isActive(currentUser)
  );

  if (!currentScreen) {
    window.location.assign("/dashboard");
    return null;
  }

  return (
    <div className="nds-typography">
      <div id="loading-container" />
      <div id="notifications-container" />
      <div className="page-container">
        {isInitialLoadDone ? (
          <SideBarPage
            activeStep={{
              label: currentScreen.description,
              path: currentScreen.path,
            }}
            steps={sidebarSteps}
            institution={institutionSettings}
          >
            <currentScreen.component
              user={currentUser}
              updateUser={setCurrentUser}
              encodedPayload={encodedPayload}
              institution={institutionSettings}
            />
          </SideBarPage>
        ) : (
          <LoadingShim isLoading>
            <div style={{ height: "100vh" }} />
          </LoadingShim>
        )}
      </div>
    </div>
  );
};

export default EnrollmentPageContainer;
