import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "cerulean";
import WireRecipient from "byzantine/src/WireRecipient";
import AddRecipientPlainButton from "./AddRecipientPlainButton";
import RecipientListItem from "./RecipientListItem";

export default function RecipientSelector({
  field,
  label,
  onChange,
  recipients,
  setShowNewRecipientForm,
  value,
  ...props
}) {
  let displayValue = "";
  if (value instanceof WireRecipient) {
    displayValue = value.displayValue;
  }

  return (
    <div className="margin--bottom--l">
      <Dropdown triggerLabel={label} triggerValue={displayValue} {...props}>
        {recipients.map((recipient) => (
            <RecipientListItem
              key={recipient.id}
              recipient={recipient}
              onChange={onChange}
              value={value}
            />
          ))}
        <AddRecipientPlainButton
          showNewRecipientForm={() => setShowNewRecipientForm(true)}
        />
      </Dropdown>
    </div>
  );
}
RecipientSelector.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  recipients: PropTypes.arrayOf(PropTypes.instanceOf(WireRecipient)),
  setShowNewRecipientForm: PropTypes.func,
  value: PropTypes.object,
};
