import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from 'uuid';
import Wire from "byzantine/src/Wire";
import Institution from "byzantine/src/Institution";
import Address from "byzantine/src/Address";
import ApiHttp from "byzantine/src/ApiHttp";
import WireRecipient from "byzantine/src/WireRecipient";
import { ContextForm, useFormData, useNotificationContext } from "cerulean";
import useReviewableAction from "../../useReviewableAction";
import WireTransferForm from "./form/WireTransferForm";
import WireTransferActionReview from "./review/WireTransferActionReview";
import SudoAction from "../../SudoAction";
import TfaVerify from "../../verify/TfaVerify";
import useActions from "../../hooks/useActions";
import AddRecipientDialog from "./recipients/AddRecipientDialog";
import { useCurrentUser } from "../../contexts/CurrentUserContext";

const errorIsElevatedAuthError = (error) =>
  error === "Elevated authentication required to confirm this action";

const WireTransfer = ({ limits, Header }) => {
  const { formData, onChange } = useFormData({ recipient: {} });
  const reviewableAction = useReviewableAction();
  const { goToPrevious } = useActions();
  const { sendNotification } = useNotificationContext();
  const [showNewRecipientForm, setShowNewRecipientForm] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [itempotencyKey, setItempotencyKey] = useState(uuidv4());
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    WireRecipient.fetchAll().then((wireRecipients) => {
      setRecipients(wireRecipients);
    });
  }, []);

  const onSubmit = (callback) => {
    const { recipient } = formData;
    // constructs a wire with the from_account, amount, and memo fields
    // and adds the recipient details
    const { memo, ...rest } = formData;
    const memoFields = Wire.splitMemo(memo);
    const wire = new Wire({ ...memoFields, ...rest}).to(recipient);
    wire.itempotencyKey = itempotencyKey;
    wire
      .submit()
      .then((response) => {
        let success_message = "success=Wire+sent.";
        if (response.wires[0].state === "awaiting_approval") {
          success_message = "success=Wire+submitted+for+approval.";
        }
        goToPrevious(success_message);
      })
      .catch((error) => {
        if (errorIsElevatedAuthError(error)) {
          callback();
          reviewableAction.goToSudo();
          return;
        }
        if (typeof error === "string") {
          goToPrevious(`negative=${encodeURIComponent(error)}`);
        } else {
          callback(error);
          reviewableAction.goToInput();
        }
      })
      .finally(() => {
        setItempotencyKey(uuidv4());
      });
  };

  const checkSudo = async () => {
    // Cerulean's AddressField does not use ContextForm so we need to validate that all
    // required fields are present before moving onto review.
    const address = new Address(formData.recipient?.address);
    try {
      address.validate();
    } catch (error) {
      sendNotification({
        type: "negative",
        text: "Please enter a complete address.",
      });
      return;
    }
    const institution = new Institution();
    const settings = await institution.getSettings();
    if (!settings.sudo_mode_required_for_wires) {
      reviewableAction.goToReview();
      return;
    }
    ApiHttp.fetch("sudo")
      .then(() => reviewableAction.goToReview())
      .catch(() => reviewableAction.goToSudo());
  };

  return (
    <>
      <ContextForm nativeForm={false} data={formData} onChange={onChange}>
        <SudoAction
          reviewableAction={reviewableAction}
          renderInput={
            <div className="wire-transfer-container">
              <div className="mobile-web-extra-space">
                <WireTransferForm
                  onSubmit={checkSudo}
                  cancel={goToPrevious}
                  limits={limits}
                  onChange={onChange}
                  isBusiness={currentUser?.isBusiness()}
                  recipients={recipients}
                  setShowNewRecipientForm={setShowNewRecipientForm}
                  Header={Header}
                />
              </div>
            </div>
          }
          renderSudo={
            <TfaVerify
              goToReview={reviewableAction.goToReview}
              cancelAction={() => goToPrevious()}
            />
          }
          renderReview={
            <div className="wire-transfer-container">
              <div className="mobile-web-extra-space">
                <WireTransferActionReview
                  data={formData}
                  onSubmit={onSubmit}
                  goBack={reviewableAction.goToInput}
                  cancel={goToPrevious}
                />
              </div>
            </div>
          }
        />
      </ContextForm>
      <AddRecipientDialog
        open={showNewRecipientForm}
        handleClose={() => setShowNewRecipientForm(false)}
        recipients={recipients}
        updateTransferFormWithRecipient={onChange}
        setRecipients={setRecipients}
      />
    </>
  );
};

WireTransfer.propTypes = {
  limits: PropTypes.object,
  Header: PropTypes.element
};

export default WireTransfer;
