import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import {
  BackCancelButton,
  Button,
  CollapsibleSection,
  FormHeaderWithIcon,
  ContextForm,
  RadioButtons,
} from "cerulean";
import AccountContext from "../../../contexts/AccountContext";
import InstitutionSettingsContext from "../../../contexts/InstitutionSettingsContext";
import CardLogos from "./add_a_card/CardLogos";
import SimpleTransferFields, {
  TRANSFER_FORM_TYPES,
} from "../../SimpleTransferFields";
import PayByCardForm from "./add_a_card/PayByCardForm";
import { useCurrentUser } from "../../../contexts/CurrentUserContext";
import LoanSelector from "../LoanSelector";

const LoanPaymentByCardForm = ({
  data,
  onSubmit,
  cancel,
  limits,
  cards,
  setCards,
}) => {
  const { l10n } = useLocalization();
  const { currentUser } = useCurrentUser();
  const { accounts } = useContext(AccountContext);
  const { dao_fund_card_network, loan_pay_by_card_fee } = useContext(
    InstitutionSettingsContext
  );
  const [currentStep, setCurrentStep] = useState(1);
  const loanAccounts = useMemo(
    () => accounts.filter((a) => a.isPayableByCard()),
    [accounts]
  );

  let form = null;
  if (data?.payment_type === "bank") {
    form = (
      <SimpleTransferFields
        data={data}
        limits={limits}
        formType={TRANSFER_FORM_TYPES.LOAN_PAY_BY_CARD__BANK}
      />
    );
  } else if (data?.payment_type === "card") {
    form = (
      <PayByCardForm
        currentUser={currentUser}
        data={data}
        cards={cards}
        setCards={setCards}
        loanPayByCardFee={Number(loan_pay_by_card_fee)}
      />
    );
  }

  useEffect(() => {
    if (data?.to_account_id && currentStep !== 2) {
      setCurrentStep(2);
    }
  }, [data?.to_account_id]);

  return (
    <div className="loan-payment-page">
      <FormHeaderWithIcon
        headerText={l10n.getString("loan-paybycard-form-page-heading")}
      />
      <CollapsibleSection
        title={l10n.getString("loan-paybycard-step-1-title")}
        currentStep={currentStep}
        sectionStep={1}
        totalSteps={2}
        tagName={l10n.getString("loan-paybycard-step-x-of-y-tag", {
          sectionStep: 1,
          totalSteps: 2,
        })}
      >
        <ContextForm.Field required style={{ marginBottom: "var(--space-s)" }}>
          <LoanSelector
            loans={loanAccounts}
            field="to_account_id"
            label={l10n.getString("loan-paydown-loan-selector-label")}
          />
        </ContextForm.Field>
      </CollapsibleSection>
      <CollapsibleSection
        title={l10n.getString("loan-paybycard-step-2-title")}
        currentStep={currentStep}
        sectionStep={2}
        totalSteps={2}
        tagName={l10n.getString("loan-paybycard-step-x-of-y-tag", {
          sectionStep: 2,
          totalSteps: 2,
        })}
      >
        <div className="alignChild--left--bottom">
          <ContextForm.Field style={{ marginBottom: 0 }}>
            <RadioButtons
              name="payment_type"
              field="payment_type"
              options={{
                [l10n.getString("loan-paybycard-bank-account")]: "bank",
                [l10n.getString("loan-paybycard-debit-card")]: "card",
              }}
            />
          </ContextForm.Field>
          <div style={{ marginLeft: "auto" }}>
            <CardLogos fundCardNetwork={dao_fund_card_network} />
          </div>
        </div>
        {!!form && (
          <div className="margin--top--l">
            <div
              className="margin--bottom--l border--top"
              style={{
                width: "calc(var(--space-l) + 100% + var(--space-l))",
                marginLeft: "calc(-1*var(--space-l))",
              }}
            />
            {form}
          </div>
        )}
      </CollapsibleSection>
      <div className="alignChild--center--center form-buttons-with-spacing">
        <BackCancelButton
          onBack={cancel}
          backLabel={l10n.getString("loan-paybycard-cancel-button")}
        />
        <ContextForm.Action onSubmit={onSubmit} dangerouslyDisableShowLoading>
          <div style={{ marginLeft: "auto" }}>
            <Button label={l10n.getString("loan-paybycard-review-button")} />
          </div>
        </ContextForm.Action>
      </div>
    </div>
  );
};
LoanPaymentByCardForm.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  cancel: PropTypes.func,
  limits: PropTypes.object,
  cards: PropTypes.array,
  setCards: PropTypes.func,
};

export default LoanPaymentByCardForm;
