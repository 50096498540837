import React, { useState } from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import { Select, useNotificationContext } from "cerulean";
import AddCardDialog from "./AddCardDialog";

const CardSelector = ({ onChange, value, error, cards, setCards }) => {
  const { l10n } = useLocalization();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { sendNotification } = useNotificationContext();

  const updateCards = (newCard) => {
    setCards((prev) => [...prev, newCard]);
    if (onChange) onChange(newCard.id);
  };

  return (
    <>
      <Select
        label={l10n.getString("loan-paybycard-debit-card")}
        onChange={onChange}
        value={value}
        errorText={error}
      >
        {cards?.map((card) => (
          <Select.Item key={card.id} value={card.id}>
            {card.description}
          </Select.Item>
        ))}
        <Select.Action onSelect={() => setIsDialogOpen(true)}>
          <span className="fontColor--theme--secondary fontWeight--semibold">
            <span className="narmi-icon-plus" />{" "}
            {l10n.getString("loan-paybycard-add-card-dialog-launch-button")}
          </span>
        </Select.Action>
      </Select>
      <AddCardDialog
        isDialogOpen={isDialogOpen}
        closeDialog={() => setIsDialogOpen(false)}
        sendNotification={sendNotification}
        updateCardList={updateCards}
      />
    </>
  );
};
CardSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  cards: PropTypes.array,
  setCards: PropTypes.func,
};

export default CardSelector;
