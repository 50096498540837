var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
var name = "fedwireTemplates";
var initialState = {
    byId: {},
};
var slice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        upsertAll: function (state, action) {
            state.byId = action.payload.reduceRight(function (prev, cur) {
                var _a;
                return (__assign(__assign({}, prev), (_a = {}, _a[cur.id] = cur, _a)));
            }, {});
        },
        updateOne: function (state, action) {
            var template = action.payload;
            state.byId[template.id] = template;
        },
        deleteOne: function (state, action) {
            var templateId = action.payload;
            delete state.byId[templateId];
        },
    },
});
export var actions = slice.actions;
export default (_a = {},
    _a[name] = slice.reducer,
    _a);
