var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useLocalization } from "@fluent/react";
import Address from "byzantine/src/Address";
import User from "byzantine/src/User";
import { Button, Dialog, Row, useFormData, useNotificationContext, Checkbox, ContextForm, useLoadingContext, } from "cerulean";
import BankingUSAddressField from "../../../../address/BankingUSAddressField";
import { useCurrentUser } from "../../../../contexts/CurrentUserContext";
import { useInstitutionSettings } from "../../../../contexts/InstitutionSettingsContext";
import utils from "../../../../../utils";
var AddressEditDialogBody = function (_a) {
    var doAddressesMatch = _a.doAddressesMatch, closeDialog = _a.closeDialog;
    var l10n = useLocalization().l10n;
    var _b = useCurrentUser(), currentUser = _b.currentUser, setCurrentUser = _b.setCurrentUser;
    var sendNotificationToParent = useNotificationContext().sendNotificationToParent;
    var setIsLoading = useLoadingContext().setIsLoading;
    var institutionSettings = useInstitutionSettings();
    var _c = useFormData({
        primary_address: currentUser === null || currentUser === void 0 ? void 0 : currentUser.getPrimaryAddress(),
        mailing_address: currentUser === null || currentUser === void 0 ? void 0 : currentUser.getMailingAddress(),
    }), formData = _c.formData, setFormData = _c.setFormData, onChange = _c.onChange;
    var _d = __read(useState(doAddressesMatch), 2), addressesMatch = _d[0], setAddressesMatch = _d[1];
    var _e = __read(useState({}), 2), primaryAddressErrors = _e[0], setPrimaryAddressErrors = _e[1];
    var _f = __read(useState({}), 2), mailingAddressErrors = _f[0], setMailingAddressErrors = _f[1];
    var isBusinessUser = !!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.isBusiness());
    var streetAddressLabel = l10n.getString(isBusinessUser
        ? "label-business-street-address"
        : "label-street-address");
    var onPrimaryAddressChange = function (update) {
        setFormData(function (prev) { return (__assign(__assign({}, prev), { primary_address: __assign(__assign({}, prev.primary_address), update) })); });
    };
    var onMailingAddressChange = function (update) {
        setFormData(function (prev) { return (__assign(__assign({}, prev), { mailing_address: __assign(__assign({}, prev.mailing_address), update) })); });
    };
    var validateAddresses = function () {
        var primaryErrors = utils.validateAddress(formData.primary_address);
        var mailingErrors = utils.validateAddress(formData.mailing_address);
        setPrimaryAddressErrors(primaryErrors);
        setMailingAddressErrors(mailingErrors);
        if (Object.keys(primaryErrors).length !== 0 ||
            Object.keys(mailingErrors).length !== 0) {
            return "Please correct the below errors";
        }
        return null;
    };
    useEffect(function () {
        if (addressesMatch) {
            setFormData(__assign(__assign({}, formData), { mailing_address: __assign(__assign({}, formData.primary_address), { type: "mailing" }) }));
        }
    }, [addressesMatch]);
    var onSubmit = function (callback) { return __awaiter(void 0, void 0, void 0, function () {
        var primaryAddress, mailingAddress, updatedUser, addresses, updatedAddressesNotificationText, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (validateAddresses() !== null) {
                        setIsLoading(false);
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, 5, 6]);
                    primaryAddress = new Address(formData.primary_address);
                    mailingAddress = new Address(formData.mailing_address);
                    if (addressesMatch) {
                        mailingAddress = new Address(__assign(__assign({}, formData.primary_address), { type: "mailing" }));
                    }
                    return [4 /*yield*/, (currentUser === null || currentUser === void 0 ? void 0 : currentUser.updateAddress(primaryAddress))];
                case 2:
                    updatedUser = _a.sent();
                    return [4 /*yield*/, (currentUser === null || currentUser === void 0 ? void 0 : currentUser.updateAddress(mailingAddress))];
                case 3:
                    updatedUser = _a.sent();
                    if (updatedUser) {
                        addresses = updatedUser.addresses;
                        setCurrentUser(new User(__assign(__assign({}, currentUser), { addresses: addresses })));
                        updatedAddressesNotificationText = institutionSettings.has_mailing_address
                            ? "Addresses updated."
                            : "Address updated.";
                        sendNotificationToParent({
                            type: "success",
                            text: updatedAddressesNotificationText,
                        });
                        callback();
                    }
                    closeDialog();
                    return [3 /*break*/, 6];
                case 4:
                    error_1 = _a.sent();
                    if (error_1 instanceof Error) {
                        callback(error_1.message);
                    }
                    else {
                        callback(error_1);
                    }
                    return [3 /*break*/, 6];
                case 5:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "margin--top--s" }), _jsx("div", { className: "fontWeight--bold  margin--bottom--s", style: { color: "RGB(var(--nds-black))" }, children: l10n.getString(isBusinessUser
                    ? "label-business-primary-address"
                    : "label-personal-primary-address") }), _jsxs(ContextForm, { data: formData, onChange: onChange, children: [_jsx(ContextForm.Field, { field: "primary_address", children: _jsx(BankingUSAddressField, { addressType: "primary", onUpdate: onPrimaryAddressChange, streetAddressLabel: streetAddressLabel, data: formData.primary_address || {}, showTitle: false, errors: primaryAddressErrors, marginTop: "none" }) }), institutionSettings.has_mailing_address && (_jsxs(_Fragment, { children: [_jsx("div", { className: "fontWeight--bold margin--bottom--s", style: { color: "RGB(var(--nds-black))" }, children: l10n.getString(isBusinessUser
                                    ? "label-business-mailing-address"
                                    : "label-personal-mailing-address") }), !addressesMatch && (_jsx(ContextForm.Field, { field: "mailing_address", children: _jsx(BankingUSAddressField, { addressType: "mailing", onUpdate: onMailingAddressChange, streetAddressLabel: streetAddressLabel, data: formData.mailing_address || {}, showTitle: false, errors: mailingAddressErrors, marginTop: "none", marginBottom: "margin--bottom--s" }) })), _jsx(Checkbox, { onChange: function () { return setAddressesMatch(!addressesMatch); }, label: l10n.getString(isBusinessUser
                                    ? "label-same-business-address"
                                    : "label-same-residential-address"), checked: addressesMatch })] })), _jsx("div", { className: "margin--bottom--xl" }), _jsxs(Row, { alignItems: "center", justifyContent: "end", children: [_jsx(Row.Item, { shrink: true, children: _jsx(Button, { type: "button", onClick: closeDialog, kind: "negative", label: "Cancel" }) }), _jsx(Row.Item, { shrink: true, children: _jsx(ContextForm.Action, { onSubmit: onSubmit, children: _jsx(Button, { kind: "primary", label: "Save changes" }) }) })] })] })] }));
};
var AddressEditDialog = function (_a) {
    var isOpen = _a.isOpen, closeDialog = _a.closeDialog, doAddressesMatch = _a.doAddressesMatch;
    return (_jsx(Dialog, { isOpen: isOpen, onUserDismiss: closeDialog, title: "Edit address", children: _jsx(AddressEditDialogBody, { doAddressesMatch: doAddressesMatch, closeDialog: closeDialog }) }));
};
export default AddressEditDialog;
