import React from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import { translateSupportLinks } from "byzantine/src/SettingLocalizers";

export default function QuickLinkList({ links }) {
  const { l10n } = useLocalization();
  const translatedLinks = translateSupportLinks(links, l10n);
  return (
    <div className="quick-links-list">
      <div className="support-section-title">{l10n.getString("heading-support-links")}</div>
      <div className="card">
        {translatedLinks.map((link) => (
          <div key={`${link.text}${link.url}`} className="quick-link-item">
            <a href={link.url} target="_blank" rel="noreferrer">
              {link.text}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}
QuickLinkList.propTypes = {
  links: PropTypes.array.isRequired,
};
