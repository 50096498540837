import React from "react";
import PropTypes from "prop-types";

import { ScreenStateContextProvider } from "./contexts/ScreenStateContextProvider";
import { SelectedDeviceContextProvider } from "./contexts/SelectedDeviceContextProvider";
import { ActionContextProvider } from "./contexts/ActionContextProvider";
import TfaVerifyContainer from "./TfaVerifyContainer";

const TfaVerify = ({ totalSteps, step, cancelAction, goToReview }) => (
  <ScreenStateContextProvider>
    <SelectedDeviceContextProvider>
      <ActionContextProvider
        cancelAction={cancelAction}
        goToReview={goToReview}
      >
        <div className="sudo-mode-container">
          <div className="mobile-web-extra-space">
            <TfaVerifyContainer totalSteps={totalSteps} step={step} />
          </div>
        </div>
      </ActionContextProvider>
    </SelectedDeviceContextProvider>
  </ScreenStateContextProvider>
);
TfaVerify.propTypes = {
  cancelAction: PropTypes.func,
  goToReview: PropTypes.func,
  totalSteps: PropTypes.number,
  step: PropTypes.number,
};

export default TfaVerify;
