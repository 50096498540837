var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var catcat = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return args
        .map(function (arg) {
        if (typeof arg === "object" && !Array.isArray(arg) && arg !== null) {
            return Object.entries(arg)
                .map(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                return value && key;
            })
                .filter(function (x) { return typeof x === "string" && x !== "" && typeof x !== "boolean"; });
        }
        return arg;
    })
        .flat()
        .filter(function (x) { return typeof x === "string"; })
        .join(" ")
        .trim();
};
export default catcat;
