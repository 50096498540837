var _a;
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
var name = "featureStatus";
var initialState = {
    ga: {},
};
var slice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {},
});
export var actions = slice.actions;
export default (_a = {},
    _a[name] = slice.reducer,
    _a);
