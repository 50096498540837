export var API = {
    createWireViaTemplate: function (_a) {
        var idempotencyKey = _a.idempotencyKey, _b = _a.fedwireTemplate, fedwire_template = _b.id, version_id = _b.version_id, wire_reason = _a.wireReason, amount = _a.amount, memo = _a.memo;
        return {
            url: "wires/fedwire_templates/",
            options: {
                method: "POST",
                payload: {
                    fedwire_template: fedwire_template,
                    version_id: version_id,
                    wire_reason: wire_reason || undefined,
                    amount: amount || undefined,
                    memo: memo || undefined,
                },
                idempotencyKey: idempotencyKey,
            },
        };
    },
    createOrEditTemplate: function (_a, existingFedwireTemplateId) {
        var source_account = _a.sourceAccountId, recipient = _a.wireRecipientId, name = _a.name, wireReason = _a.wireReason, amount = _a.amount, memo = _a.memo;
        return {
            url: "fedwire_templates/".concat(existingFedwireTemplateId ? "".concat(existingFedwireTemplateId, "/") : ""),
            options: {
                method: existingFedwireTemplateId ? "PUT" : "POST",
                payload: {
                    name: name,
                    recipient: recipient,
                    source_account: source_account,
                    wire_reason: wireReason || null,
                    amount: amount || null,
                    memo: memo || null,
                },
            },
        };
    },
};
