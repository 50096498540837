import { jsx as _jsx } from "react/jsx-runtime";
import React, { memo, useMemo } from "react";
import { createContext } from "react";
import _ from "lodash";
export var DEFAULT_CONFIG = {
    network: {
        cache: {
            ttlMilliseconds: 5 * 1000,
        },
    },
};
export var ConfigContext = createContext(undefined);
var ContextProvider = function (_a) {
    var _b = _a.config, config = _b === void 0 ? {} : _b, children = _a.children;
    var value = useMemo(function () { return _.merge(DEFAULT_CONFIG, config); }, [config]);
    return (_jsx(ConfigContext.Provider, { value: value, children: children }));
};
export default memo(ContextProvider);
