import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from "@narmi/design_system";
export var STATES = [
    { code: "AL", name: "Alabama (AL)" },
    { code: "AK", name: "Alaska (AK)" },
    { code: "AS", name: "American Samoa (AS)" },
    { code: "AZ", name: "Arizona (AZ)" },
    { code: "AR", name: "Arkansas (AR)" },
    { code: "CA", name: "California (CA)" },
    { code: "CO", name: "Colorado (CO)" },
    { code: "CT", name: "Connecticut (CT)" },
    { code: "DE", name: "Delaware (DE)" },
    { code: "DC", name: "District of Columbia (DC)" },
    { code: "FL", name: "Florida (FL)" },
    { code: "GA", name: "Georgia (GA)" },
    { code: "GU", name: "Guam (GU)" },
    { code: "HI", name: "Hawaii (HI)" },
    { code: "ID", name: "Idaho (ID)" },
    { code: "IL", name: "Illinois (IL)" },
    { code: "IN", name: "Indiana (IN)" },
    { code: "IA", name: "Iowa (IA)" },
    { code: "KS", name: "Kansas (KS)" },
    { code: "KY", name: "Kentucky (KY)" },
    { code: "LA", name: "Louisiana (LA)" },
    { code: "ME", name: "Maine (ME)" },
    { code: "MD", name: "Maryland (MD)" },
    { code: "MA", name: "Massachusetts (MA)" },
    { code: "MI", name: "Michigan (MI)" },
    { code: "MN", name: "Minnesota (MN)" },
    { code: "MS", name: "Mississippi (MS)" },
    { code: "MO", name: "Missouri (MO)" },
    { code: "MT", name: "Montana (MT)" },
    { code: "NE", name: "Nebraska (NE)" },
    { code: "NV", name: "Nevada (NV)" },
    { code: "NH", name: "New Hampshire (NH)" },
    { code: "NJ", name: "New Jersey (NJ)" },
    { code: "NM", name: "New Mexico (NM)" },
    { code: "NY", name: "New York (NY)" },
    { code: "NC", name: "North Carolina (NC)" },
    { code: "ND", name: "North Dakota (ND)" },
    { code: "MP", name: "Northern Mariana Islands (MP)" },
    { code: "OH", name: "Ohio (OH)" },
    { code: "OK", name: "Oklahoma (OK)" },
    { code: "OR", name: "Oregon (OR)" },
    { code: "PA", name: "Pennsylvania (PA)" },
    { code: "PR", name: "Puerto Rico (PR)" },
    { code: "RI", name: "Rhode Island (RI)" },
    { code: "SC", name: "South Carolina (SC)" },
    { code: "SD", name: "South Dakota (SD)" },
    { code: "TN", name: "Tennessee (TN)" },
    { code: "TX", name: "Texas (TX)" },
    { code: "AA", name: "U.S. Armed Forces, Americas (AA)" },
    { code: "AE", name: "U.S. Armed Forces, Europe (AE)" },
    { code: "AP", name: "U.S. Armed Forces, Pacific (AP)" },
    { code: "UM", name: "United States Minor Outlying Islands (UM)" },
    { code: "UT", name: "Utah (UT)" },
    { code: "VT", name: "Vermont (VT)" },
    { code: "VI", name: "Virgin Islands (VI)" },
    { code: "VA", name: "Virginia (VA)" },
    { code: "WA", name: "Washington (WA)" },
    { code: "WV", name: "West Virginia (WV)" },
    { code: "WI", name: "Wisconsin (WI)" },
    { code: "WY", name: "Wyoming (WY)" },
];
var StateSelector = function (_a) {
    var label = _a.label, onChange = _a.onChange, value = _a.value, error = _a.error, _b = _a.states, states = _b === void 0 ? STATES : _b;
    return (_jsx(Select, { value: value, onChange: onChange, errorText: error, label: label, children: states.map(function (state) { return (_jsx(Select.Item, { value: state.code, children: state.name }, state.code)); }) }));
};
export default StateSelector;
