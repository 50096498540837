import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import BaseBanner from "../BaseBanner";
import InstitutionSettingsContext from "../contexts/InstitutionSettingsContext";
import UserFeaturesContext from "../contexts/UserFeaturesContext";
import EditAccountNameModal from "./EditAccountNameModal";

const EditIcon = ({ onClick }) => (
  <div className="margin--left--m margin--top--xxs fontSize--l">
    <div
      className="narmi-icon-edit clickable"
      onClick={onClick}
      onKeyUp={({ key }) => {
        if (key === "Enter") onClick();
      }}
      aria-label="Edit name"
      role="button"
      tabIndex="0"
    />
  </div>
);
EditIcon.propTypes = {
  onClick: PropTypes.func,
};

const AccountBanner = ({ account }) => {
  const { ach_allows_pull, ach_allows_push } = useContext(
    InstitutionSettingsContext
  );
  const [dialogOpen, setDialogOpen] = useState(false);

  const userFeatures = React.useContext(UserFeaturesContext);

  const buttonName = account.isDeposit()
    ? account.isValidInternalOrExternalTransferSource(ach_allows_pull) &&
      "Transfer funds"
    : account.isValidInternalOrExternalTransferDestination(ach_allows_push) &&
      "Make a payment";

  return (
    <div>
      <BaseBanner
        bannerName={account.nickname || account.name}
        buttonFunction={() =>
          window.location.assign(
            `/transfer/funds/?${
              account.isCredit() ? "to_account_id" : "from_account_id"
            }=${account.id}&previous=/accounts/${account.id}`
          )
        }
        buttonName={buttonName}
        orFeatureFlagChecks={["internal_transfers", "ach"]}
        features={userFeatures}
        icon={
          <EditIcon
            onClick={() => {
              setDialogOpen(true);
            }}
          />
        }
      />
      <EditAccountNameModal
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        accountUuid={account.id}
      />
    </div>
  );
};
AccountBanner.propTypes = {
  account: PropTypes.object,
};

export default AccountBanner;
