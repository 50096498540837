import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useRequestWithFeedback } from "../../../composites/request/request.hooks";
import { useObjectMemo } from "../../../hooks";
import { actions as accountsActions } from "../slices/accounts.slice";
import { actions as accountSortingActions } from "../slices/accountSorting.slice";
export var useFetchAccounts = function () {
    var dispatch = useDispatch();
    var _a = useRequestWithFeedback(), send = _a.send, loading = _a.loading, status = _a.status;
    var fetchAccounts = useCallback(function () {
        send({
            action: {
                url: "accounts/",
                options: {
                    method: "GET",
                    timeoutMilliseconds: 90 * 1000,
                },
            },
            onData: function (data) {
                dispatch(accountsActions.receive(data, true));
            },
        });
    }, [dispatch, send]);
    return useObjectMemo({
        send: fetchAccounts,
        loading: loading,
        error: status === "error",
    });
};
export var useFetchAccountSorting = function () {
    var dispatch = useDispatch();
    var _a = useRequestWithFeedback(), send = _a.send, loading = _a.loading, status = _a.status;
    var fetchSorting = useCallback(function () {
        var action = {
            url: "accounts/sorting",
            options: {
                method: "GET",
            },
        };
        var onData = function (data) {
            dispatch(accountSortingActions.receive(data));
        };
        send({
            action: action,
            onData: onData,
        });
    }, [dispatch, send]);
    return useObjectMemo({
        send: fetchSorting,
        loading: loading,
        error: status === "error",
    });
};
export var ACCOUNT_PRODUCT_GROUP = {
    CHECKING: "Checking",
    SAVINGS: "Savings",
    CREDIT_CARD: "Credit cards",
    LOAN: "Loans",
    CD: "CDs",
    EXTERNAL: "External accounts",
    UNKNOWN: "Others",
};
export var isLoan = function (account) {
    return ["mortgage", "lease", "loan", "line_of_credit"].includes(account.product_type);
};
