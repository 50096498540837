import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Button, DateInput } from "@narmi/design_system";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import Feature from "byzantine/src/Feature";
import { ContextForm, useNotificationContext } from "cerulean";
import AccountContext from "../contexts/AccountContext";
import UserFeaturesContext from "../contexts/UserFeaturesContext";
import PayeeSelector from "./PayeeSelector";
import AmountTextInput from "../form/AmountTextInput";
import AccountSelector from "../AccountSelector";
import ActionHeader from "./ActionHeader";
import BillPaySso from "./BillPaySso";

const BillPayForm = ({ onChange, onSubmit, cancel, payees, fetchPayees }) => {
  const features = React.useContext(UserFeaturesContext);
  const [minDate, setMinDate] = useState(DateTime.now().toFormat("MM/dd/yyyy"));
  const [disabledDates, setDisabledDates] = useState([]);
  const { sendNotification } = useNotificationContext();
  const { accounts } = useContext(AccountContext);
  const { t } = useTranslation();

  const updatePayees = (payee) => {
    fetchPayees(payee);
    onChange({ payee_id: payee.id });
    sendNotification({ type: "success", text: "Payee added." });
  };

  const updateDatepickerValue = (date) => {
    onChange({
      payment_date: DateTime.fromFormat(date, "M/d/yyyy").toFormat(
        "MM/dd/yyyy"
      ),
    });
  };

  return (
    <div className="form-card-layout">
      <div className="card">
        <ActionHeader title={t("Make a payment")} icon="dollar-bill-line" />
        <Feature features={features} or="allow_billpay_from_account">
          <ContextForm.Field required>
            <AccountSelector
              field="from_account_id"
              label="From"
              showAddExternalAccountLink={false}
              accounts={accounts.filter((a) => a.isValidBillpaySource())}
            />
          </ContextForm.Field>
        </Feature>
        <ContextForm.Field required>
          <PayeeSelector
            field="payee_id"
            label="To"
            payees={payees}
            updatePayees={updatePayees}
            setMinDate={setMinDate}
            setDisabledDates={setDisabledDates}
            updateDatepickerValue={updateDatepickerValue}
          />
        </ContextForm.Field>
        <ContextForm.Field
          validate={(a) => (a === "$" ? "Please enter an amount" : "")}
          required
        >
          <AmountTextInput field="amount" label="Amount" />
        </ContextForm.Field>
        <ContextForm.Field required style={{ marginBottom: "12px" }}>
          <DateInput
            minDate={minDate}
            disableDates={disabledDates}
            dateFormat={"m/d/Y"}
            altInput={true}
            altFormat={"m/d/Y"}
            field="payment_date"
            label="Date (mm/dd/yyyy)"
            useIsoOnChange={false}
          />
        </ContextForm.Field>
        <BillPaySso label="View advanced bill pay options" size="s" />
        <ContextForm.ActionBar vertical>
          <ContextForm.Action onSubmit={onSubmit} dangerouslyDisableShowLoading>
            <Button label="Continue" />
          </ContextForm.Action>
          <div className="transfer-cancel-button">
            <Button
              onClick={() => cancel()}
              kind="negative"
              type="button"
              label="Cancel"
            />
          </div>
        </ContextForm.ActionBar>
      </div>
    </div>
  );
};

BillPayForm.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  cancel: PropTypes.func,
  payees: PropTypes.array,
  fetchPayees: PropTypes.func,
};

export default BillPayForm;
