import React from "react";
import PropTypes from "prop-types";
import { Button, useNotificationContext } from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";

const NoAlerts = ({ fetchAlerts, setIsLoading }) => {
  const { sendNotification } = useNotificationContext();
  const submitDefaultAlert = () => {
    setIsLoading(true);
    const payload = {
      filters: null,
      query: " balance<25 ",
      alert_type: "email",
    };
    ApiHttp.fetch("saved_searches", { method: "POST" }, payload)
      .then(() => {
        fetchAlerts();
        sendNotification({
          type: "success",
          text: "Alert created.",
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      You have no active alerts. Try setting one up:
      <Button kind="plain" onClick={submitDefaultAlert}>
        Email me when any account balance drops below $25.
      </Button>
    </>
  );
};
NoAlerts.propTypes = {
  fetchAlerts: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default NoAlerts;
