import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import cc from "classcat";
import { Button } from "@narmi/design_system";
var BACK_LABEL = "Back";
var BackCancelButton = function (_a) {
    var onBack = _a.onBack, _b = _a.backLabel, backLabel = _b === void 0 ? BACK_LABEL : _b, disabled = _a.disabled;
    return (_jsxs("span", { className: "back-cancel-button", children: [!disabled && (_jsxs("button", { style: {
                    color: "rgba(var(--nds-grey))",
                    paddingLeft: 0,
                    border: "none",
                    backgroundColor: "transparent",
                    fontWeight: "normal",
                }, type: "button", className: cc([
                    "nds-plain-button",
                    {
                        "negative-margin": backLabel === BACK_LABEL,
                    },
                ]), onClick: onBack, children: [backLabel === BACK_LABEL && (_jsxs(_Fragment, { children: [" ", _jsx("span", { className: "narmi-icon-chevron-left", style: { verticalAlign: "middle" } }), " "] })), _jsxs("span", { className: cc([
                            "back-button-text hoverable",
                            {
                                "negative-margin": backLabel === BACK_LABEL,
                            },
                        ]), children: [backLabel, " "] }), " "] })), disabled && (_jsx(Button, { kind: "negative", startIcon: backLabel === BACK_LABEL ? "chevron-left" : undefined, label: backLabel, testId: "back", type: "button", disabled: true }))] }));
};
export default BackCancelButton;
