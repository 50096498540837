import React from "react";
import PropTypes from "prop-types";
import { XCircle } from "react-feather";
import FormBase from "byzantine/src/FormBase";
import { NotificationContext, withNotifications } from "cerulean";

const RenderLoadingSpinner = (props) => {
  if (props.submitting !== props.label) return null;
  return (
    <div className="ui active">
      <div className="ui loader" />
    </div>
  );
};
RenderLoadingSpinner.propTypes = {
  label: PropTypes.string,
  submitting: PropTypes.string,
};

const Action = (props) => {
  const className = props.action.className
    ? props.action.className
    : `ui button primary`;
  return (
    <button
      className={className}
      style={props.action.style}
      onClick={props.onSubmit.bind(this, props.action)}
    >
      {props.action.fancyLabel ? props.action.fancyLabel : props.action.label}
      <RenderLoadingSpinner
        data-testid="RenderLoadingSpinner"
        submitting={props.submitting}
        label={props.action.label}
      />
    </button>
  );
};
Action.propTypes = {
  action: PropTypes.object.isRequired,
  submitting: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

const ErrorMessage = (props) => {
  if (!props.errorMessage) return null;
  return (
    <div className="error-message" style={props.errorMessageStyle}>
      <div className="text">{props.errorMessage}</div>
      <div className="carat" />
    </div>
  );
};
ErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
  errorMessageStyle: PropTypes.object,
};

const renderActionBar = (props) => {
  const actions = [];
  if (props.actions) {
    props.actions.forEach((action) => {
      actions.push(<Action key={action.label} {...props} action={action} />);
    });
  }

  const actionBarStyle = props.actionBarStyle || {
    flexDirection: "row",
    marginTop: "40px",
  };
  return (
    <div className="actionbar" style={actionBarStyle}>
      {actions}
      {!props.disableActionBarErrorMessage && (
        <ErrorMessage
          errorMessage={props.errorMessage}
          errorMessageStyle={props.errorMessageStyle}
        />
      )}
    </div>
  );
};

const renderContainer = (props) => (
  <div
    onSubmit={props.onSubmit}
    className={props.className || "form"}
    data-testid="form"
    style={props.style}
  >
    {props.content}
    {props.actionBar}
  </div>
);

export default class Form extends React.Component {
  static contextType = NotificationContext;

  constructor(props) {
    super(props);
    this.validateForm = null;

    this.renderContainerWrapper = (_props) => {
      if (_props.validate) {
        this.validateForm = _props.validate.bind(this, _props.data); // can't use a ref on functional component yet
      }
      return renderContainer(_props);
    };
  }

  validate() {
    if (this.validateForm) return this.validateForm();
    return null;
  }

  render() {
    // wrap actions with notification message handling
    const actions = this.props.actions
      ? this.props.actions.map((rawAction) =>
          Object.assign({}, rawAction, {
            onSubmit: (callback) => {
              const wrappedSubmitCallback = (err) => {
                callback(err);
                if (err && rawAction.failNotification) {
                  this.context.sendNotification(rawAction.failNotification);
                } else if (rawAction.successNotification) {
                  this.context.sendNotification(rawAction.successNotification);
                }
              };
              rawAction.onSubmit(wrappedSubmitCallback);
            },
          })
        )
      : this.props.actions;
    return (
      <FormBase
        React={React} // we pass in React to avoid version differences of react between our projects
        actions={actions}
        className={this.props.className}
        data={this.props.data}
        errors={this.props.errors}
        onChange={this.props.onChange}
        renderActionBar={renderActionBar}
        renderContainer={this.renderContainerWrapper}
        style={this.props.style}
        actionBarStyle={this.props.actionBarStyle}
        errorMessageStyle={this.props.errorMessageStyle}
        disableActionBarErrorMessage={this.props.disableActionBarErrorMessage}
      >
        {this.props.children}
      </FormBase>
    );
  }
}
Form.propTypes = {
  actions: PropTypes.array,
  data: PropTypes.object,
  errors: PropTypes.object,
  children: PropTypes.any,
  className: PropTypes.string,
  onChange: PropTypes.func,
  notificationBanner: PropTypes.bool,
  style: PropTypes.object,
  actionBarStyle: PropTypes.object,
  errorMessageStyle: PropTypes.object,
  disableActionBarErrorMessage: PropTypes.bool,
};

class FieldError extends React.PureComponent {
  render() {
    if (!this.props.error) return null;
    return (
      <div data-testid="field-error" className="nds-input-error">
        <XCircle size="11px" style={{ marginRight: "5px" }} />
        {this.props.error}
      </div>
    );
  }
}
FieldError.propTypes = {
  error: PropTypes.string,
};
Form.FieldError = FieldError;

class Input extends React.PureComponent {
  render() {
    const className = `FormInput ${this.props.className || ""} ${
      this.props.error ? "error" : ""
    }`;
    const value = this.props.format
      ? this.props.format(this.props.value)
      : this.props.value || "";
    // don't pass function-props to native <input> element, to avoid warnings.
    const { format, validate, ...nativeInputElementProps } = this.props;
    return (
      <React.Fragment>
        <input
          {...nativeInputElementProps}
          value={value}
          className={className}
        />
        <FieldError error={this.props.error} />
      </React.Fragment>
    );
  }
}
Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  format: PropTypes.func,
  validate: PropTypes.func,
};
Form.Input = Input;

export const NotificationForm = withNotifications(Form, true);
