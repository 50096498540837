var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from "react";
import { ContentCard, Button, RadioButtons, ResponsiveFlex, Row, } from "cerulean";
import { entities } from "byzantine";
import HeaderWithSteps from "../../../../../../../components/HeaderWithSteps";
import WireTransferRedesign from "../../../../../../../components/transfer/wires/WireTransfer";
import * as formAdapters from "../../../../../../adapters";
import { OPTIONS as METHOD_OPTIONS, useFormContainer } from "./Form.container";
import styles from "./Form.module.scss";
import TemplateSelector from "./TemplateSelector";
import DetailsSelector from "./DetailsSelector";
function Form(_a) {
    var onSubmit = _a.onSubmit;
    var _b = useFormContainer(), method = _b.method, onCancel = _b.onCancel, onSubmitMethod = _b.onSubmitMethod, form = _b.form;
    var limits = entities.limits.useLimits();
    var MethodSelector = (_jsxs(ContentCard, { kind: "bordered", children: [_jsx("p", { className: styles.wireTemplatesFormSubHeader, children: "Method" }), _jsx(RadioButtons, __assign({ name: "options", options: METHOD_OPTIONS }, formAdapters.radio(form, "method")))] }));
    if (method === "freeform") {
        return (_jsx("div", { className: styles.removePadding, children: _jsx(WireTransferRedesign, { Header: _jsx("div", { className: styles.wireTemplatesMethodSelector, children: MethodSelector }), limits: limits }) }));
    }
    return (_jsx("div", { className: styles.sendViaWireTemplateForm, children: _jsxs(ResponsiveFlex, { gapSize: "xl", children: [_jsx(HeaderWithSteps, { headerText: "Make a wire transfer", step: 1, totalSteps: 2 }), MethodSelector, _jsx(TemplateSelector, {}), _jsx(DetailsSelector, {}), _jsxs(Row, { alignItems: "center", children: [_jsx(Row.Item, { children: _jsx(Button, { label: "Cancel", kind: "negative", onClick: onCancel }) }), _jsx(Row.Item, { shrink: true, children: _jsx(Button, { label: "Next", onClick: !method ? onSubmitMethod : onSubmit }) })] })] }) }));
}
export default memo(Form);
