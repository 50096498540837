import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useCallback, useContext, } from "react";
import { useObjectMemo } from "../../hooks";
import { requestHooks } from "./bootstrap.hooks";
export function createBootstrap() {
    var keys = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        keys[_i] = arguments[_i];
    }
    var hooks = keys.map(function (name) { return requestHooks[name]; });
    var BootstrapContext = createContext([]);
    var useBootstrap = function () {
        var results = useContext(BootstrapContext);
        var sends = results.map(function (r) { return r.send; });
        var load = useCallback(function () {
            sends.forEach(function (s) { return s(); });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, sends);
        var alreadyHasData = results.every(function (r) { return r.hasData; });
        var error = results.some(function (r) { return r.error; });
        var loading = results.some(function (r) { return r.loading; });
        var refreshing = alreadyHasData && loading;
        return useObjectMemo({
            loading: !refreshing && loading,
            load: load,
            refreshing: refreshing,
            refresh: load,
            error: error,
        });
    };
    var Provider = function (_a) {
        var children = _a.children;
        var results = hooks.map(function (hook) { return hook(); });
        return (_jsx(BootstrapContext.Provider, { value: results, children: children }));
    };
    return {
        Provider: Provider,
        useBootstrap: useBootstrap,
    };
}
