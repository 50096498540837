var _a;
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
var name = "user";
var initialState = {
    user: null,
};
var slice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        upsert: function (state, action) {
            state.user = action.payload;
        },
    },
});
export var actions = slice.actions;
export default (_a = {},
    _a[name] = slice.reducer,
    _a);
