var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useRequestWithFeedback, combineRunProps, parseErrorResponse, } from "../../../composites";
import { useObjectMemo } from "../../../hooks";
import { useLibrary } from "../../../providers";
import * as entities from "../../entities";
import { sendViaTemplateForm, upsertTemplateForm } from "./forms";
import { API } from "./api";
export var useCreateWireViaTemplate = function () {
    var _a = useRequestWithFeedback(), send = _a.send, loading = _a.loading;
    var _b = sendViaTemplateForm.useForm().values, fedwireTemplate = _b.fedwireTemplate, wireReason = _b.wireReason, amount = _b.amount, memo = _b.memo;
    var requiresDualApproval = useSelector(entities.dualApprovalRequired);
    var throwToast = useLibrary("toasts").throwToast;
    var dispatch = entities.utils.useAppDispatch();
    var fetchTemplates = entities.fedwireTemplates.useFetchTemplates().send;
    var fetchWireRecipients = entities.wires.useFetchWireRecipients().send;
    var fetchAllLimits = entities.limits.useFetchMultipleLimitsRequest().sendAll;
    var createWireViaTemplate = useCallback(function (idempotencyKey, overrideRunProps) {
        if (overrideRunProps === void 0) { overrideRunProps = {}; }
        if (!fedwireTemplate) {
            return;
        }
        send(__assign({ action: API.createWireViaTemplate({
                idempotencyKey: idempotencyKey,
                fedwireTemplate: fedwireTemplate,
                wireReason: wireReason,
                amount: amount,
                memo: memo,
            }), messaging: {
                toast: {
                    success: requiresDualApproval
                        ? "Wire submitted for approval."
                        : "Wire sent.",
                },
            } }, combineRunProps({
            onError: function (error) { return __awaiter(void 0, void 0, void 0, function () {
                var errors;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, parseErrorResponse(error)];
                        case 1:
                            errors = (_a.sent()).errors;
                            if (errors.length) {
                                throwToast({
                                    kind: "error",
                                    message: "".concat(errors[0].description),
                                });
                                fetchTemplates();
                                fetchWireRecipients();
                                fetchAllLimits();
                            }
                            return [2 /*return*/];
                    }
                });
            }); },
            onData: function (wire) {
                dispatch(entities.wires.actions.upsertOneWire(wire));
            },
        }, overrideRunProps)));
    }, [
        fedwireTemplate,
        send,
        wireReason,
        amount,
        memo,
        requiresDualApproval,
        throwToast,
        fetchTemplates,
        fetchWireRecipients,
        fetchAllLimits,
        dispatch,
    ]);
    return useObjectMemo({
        createWireViaTemplate: createWireViaTemplate,
        loading: loading,
    });
};
export var useCreateOrEditTemplate = function () {
    var _a = useRequestWithFeedback(), send = _a.send, loading = _a.loading;
    var values = upsertTemplateForm.useForm().values;
    var dispatch = entities.utils.useAppDispatch();
    var throwToast = useLibrary("toasts").throwToast;
    var createWireTemplate = useCallback(function (existingFedwireTemplateId, overrideRunProps) {
        if (overrideRunProps === void 0) { overrideRunProps = {}; }
        send(__assign({ action: API.createOrEditTemplate(values, existingFedwireTemplateId), messaging: {
                toast: {
                    success: existingFedwireTemplateId
                        ? "Template updated."
                        : "Template created.",
                },
            }, onData: function (template) {
                dispatch(entities.fedwireTemplates.actions.updateOne(template));
            }, onError: function (error) { return __awaiter(void 0, void 0, void 0, function () {
                var errors;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, parseErrorResponse(error)];
                        case 1:
                            errors = (_a.sent()).errors;
                            if (errors.length) {
                                throwToast({
                                    kind: "error",
                                    message: "".concat(errors[0].description),
                                });
                            }
                            return [2 /*return*/];
                    }
                });
            }); } }, overrideRunProps));
    }, [dispatch, send, throwToast, values]);
    return useObjectMemo({
        createWireTemplate: createWireTemplate,
        loading: loading,
    });
};
