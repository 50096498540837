import React from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import ContactUsList from "./ContactUsList";
import FAQList from "./FAQList";
import QuickLinkList from "./QuickLinkList";

import "./SupportPageLayout.css";

export default function SupportPageLayout({
  supportContacts,
  supportFAQs,
  supportLinks,
}) {
  const { l10n } = useLocalization();
  return <>
    <div className="section-header-background">
      <div className="ui center aligned grid container">
        <div className="left aligned sixteen wide column">
          <h2 className="section-header">{l10n.getString("header-support")}</h2>
        </div>
      </div>
    </div>
    <div className="ui center aligned grid container">
      <div className="left aligned sixteen wide column">
        <div className="support-page-layout">
          <ContactUsList contacts={supportContacts} />
          <FAQList categories={supportFAQs} />
          <QuickLinkList links={supportLinks} />
        </div>
      </div>
    </div>
  </>;
}
SupportPageLayout.propTypes = {
  supportContacts: PropTypes.array.isRequired,
  supportFAQs: PropTypes.array.isRequired,
  supportLinks: PropTypes.array.isRequired,
};
