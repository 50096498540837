var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useCallback } from "react";
import { Button, ContextForm, Dialog, Row, PasswordTextInput, useFormData, useNotificationContext, LoadingSkeleton, useDebounce, Error as FieldError, } from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";
import { useCurrentUser } from "../../../../contexts/CurrentUserContext";
var isSuccessfulUpdateResponse = function (response) { return !!(response === null || response === void 0 ? void 0 : response.message); };
var hasPasswordValidateErrors = function (errors) { return !!(errors === null || errors === void 0 ? void 0 : errors.new_password); };
var hasChangePasswordValidationErrors = function (errors) { return !!((errors === null || errors === void 0 ? void 0 : errors.old_password) ||
    (errors === null || errors === void 0 ? void 0 : errors.new_password1) ||
    (errors === null || errors === void 0 ? void 0 : errors.new_password2)); };
var hasApiError = function (errors) { return errors === null || errors === void 0 ? void 0 : errors.password; };
var PasswordEditBody = function (_a) {
    var closeDialog = _a.closeDialog;
    var _b = useNotificationContext(), sendNotificationToParent = _b.sendNotificationToParent, sendNotification = _b.sendNotification;
    var _c = __read(useState([]), 2), passwordValidators = _c[0], setPasswordValidators = _c[1];
    var _d = __read(useState([]), 2), newPasswordErrors = _d[0], setNewPasswordErrors = _d[1];
    var _e = __read(useState([]), 2), oldPasswordErrors = _e[0], setOldPasswordErrors = _e[1];
    var _f = __read(useState(null), 2), confirmPasswordError = _f[0], setConfirmPasswordError = _f[1];
    var _g = __read(useState(true), 2), arePasswordValidatorsLoading = _g[0], setArePasswordValidatorsLoading = _g[1];
    var _h = useFormData({
        oldPassword: "",
        newPassword1: "",
        newPassword2: "",
    }), formData = _h.formData, onChange = _h.onChange;
    var currentUser = useCurrentUser().currentUser;
    var fetchPasswordValidators = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setArePasswordValidatorsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, ApiHttp.fetch("password_validators", {
                            method: "GET",
                        })];
                case 2:
                    response = _a.sent();
                    setPasswordValidators(response.results);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    setPasswordValidators([]);
                    return [3 /*break*/, 4];
                case 4:
                    setArePasswordValidatorsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchPasswordValidators();
    }, []);
    var validatePasswordsMatch = useCallback(function (password1, password2) {
        var error = password1 !== password2
            ? "The two password fields didn't match."
            : null;
        setConfirmPasswordError(error);
    }, []);
    var onPasswordChange = useCallback(function (password1, password2) { return __awaiter(void 0, void 0, void 0, function () {
        var error_2, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, ApiHttp.fetch("password_validate", {
                            method: "POST",
                        }, { new_password: password1 })];
                case 1:
                    _a.sent();
                    // Successful
                    setNewPasswordErrors([]);
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    if (hasPasswordValidateErrors(error_2)) {
                        setNewPasswordErrors((error_2 === null || error_2 === void 0 ? void 0 : error_2.new_password) || []);
                    }
                    errorMessage = error_2 instanceof Error ? error_2.message : error_2;
                    sendNotification(errorMessage);
                    return [3 /*break*/, 3];
                case 3:
                    if (password2 !== "") {
                        validatePasswordsMatch(password1, password2);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [formData]);
    var debouncedOnPasswordChange = useDebounce(onPasswordChange, 1000);
    var debouncedOnConfirmPasswordChange = useDebounce(validatePasswordsMatch, 1000);
    var onSubmit = function (callback) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!currentUser) {
                        return [2 /*return*/];
                    }
                    setOldPasswordErrors([]);
                    setNewPasswordErrors([]);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, currentUser.updatePassword(formData.newPassword1, formData.newPassword2, formData.oldPassword)];
                case 2:
                    response = _a.sent();
                    if (isSuccessfulUpdateResponse(response)) {
                        sendNotificationToParent({ type: "success", text: response.message });
                    }
                    closeDialog();
                    callback();
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _a.sent();
                    if (hasChangePasswordValidationErrors(error_3)) {
                        if (error_3.old_password) {
                            setOldPasswordErrors(error_3.old_password);
                        }
                        if (error_3.new_password2) {
                            setNewPasswordErrors(error_3.new_password2);
                        }
                        callback();
                    }
                    else if (hasApiError(error_3)) {
                        callback(error_3 === null || error_3 === void 0 ? void 0 : error_3.password);
                    }
                    else if (error_3 instanceof Error) {
                        callback(error_3 === null || error_3 === void 0 ? void 0 : error_3.message);
                    }
                    else if (typeof error_3 === "string") {
                        callback(error_3);
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    if (arePasswordValidatorsLoading) {
        return (_jsxs(_Fragment, { children: [_jsx(LoadingSkeleton, { isLoading: true, lines: 3 }), _jsx(LoadingSkeleton, { isLoading: true, lines: 6, showTitle: true })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "margin--top--s" }), _jsxs(ContextForm, { data: formData, onChange: onChange, children: [_jsxs(ContextForm.Field, { required: true, children: [_jsx(PasswordTextInput, { required: true, field: "oldPassword", label: "Current password", value: formData === null || formData === void 0 ? void 0 : formData.oldPassword, "aria-label": "Current password", 
                                /*
                                because of the unique way we handle password field errors (we validate on input change rather than submit),
                                and because we can get multiple field errors for a given field here,
                                we instead pass the first field error in here, which gets forwarded along to TextInput
                                and results in the correct styling being applied to the input
                                */
                                fieldError: (oldPasswordErrors === null || oldPasswordErrors === void 0 ? void 0 : oldPasswordErrors.length) ? oldPasswordErrors[0] : "" }), (oldPasswordErrors === null || oldPasswordErrors === void 0 ? void 0 : oldPasswordErrors.length) > 1 && // we then map all remaining field errors
                                oldPasswordErrors
                                    .slice(1, oldPasswordErrors.length)
                                    .map(function (error) { return _jsx(FieldError, { error: error }, error); })] }), _jsxs(ContextForm.Field, { required: true, children: [_jsx(PasswordTextInput, { field: "newPassword1", label: "New password", value: formData === null || formData === void 0 ? void 0 : formData.newPassword1, onChange: function (event) {
                                    debouncedOnPasswordChange(event.target.value, formData.newPassword2);
                                }, "aria-label": "New password", fieldError: (newPasswordErrors === null || newPasswordErrors === void 0 ? void 0 : newPasswordErrors.length) ? newPasswordErrors[0] : "" }), (newPasswordErrors === null || newPasswordErrors === void 0 ? void 0 : newPasswordErrors.length) > 1 &&
                                newPasswordErrors
                                    .slice(1, newPasswordErrors.length)
                                    .map(function (error) { return _jsx(FieldError, { error: error }, error); })] }), _jsx(ContextForm.Field, { required: true, children: _jsx(PasswordTextInput, { field: "newPassword2", label: "Confirm new password", value: formData === null || formData === void 0 ? void 0 : formData.newPassword2, onChange: function (event) {
                                debouncedOnConfirmPasswordChange(event.target.value, formData.newPassword1);
                            }, "aria-label": "Confirm new password", fieldError: confirmPasswordError }) }), _jsxs("div", { className: "password-requirements-container", children: [_jsx("div", { className: "password-requirements-title fontWeight--semibold", children: "Password requirements" }), _jsx("ul", { className: "password-requirements", children: passwordValidators.map(function (validator) { return (_jsx("li", { children: validator.help_text }, validator.name)); }) })] }), _jsxs(Row, { alignItems: "center", justifyContent: "end", children: [_jsx(Row.Item, { shrink: true, children: _jsx(Button, { type: "button", onClick: closeDialog, kind: "negative", label: "Cancel" }) }), _jsx(Row.Item, { shrink: true, children: _jsx(ContextForm.Action, { onSubmit: onSubmit, children: _jsx(Button, { kind: "primary", label: "Save changes" }) }) })] })] })] }));
};
var PasswordEditDialog = function (_a) {
    var isOpen = _a.isOpen, closeDialog = _a.closeDialog;
    return (_jsx(Dialog, { isOpen: isOpen, onUserDismiss: closeDialog, title: "Edit password", children: _jsx(PasswordEditBody, { closeDialog: closeDialog }) }));
};
export default PasswordEditDialog;
