import { createDeepEqualSelector } from "../utils";
export var sortWires = function (a, b) {
    if (a.created_at < b.created_at) {
        return 1;
    }
    if (a.created_at > b.created_at) {
        return -1;
    }
    return 0;
};
export var selectRecentWires = createDeepEqualSelector([
    function (state) { return Object.values(state.wires.byId); },
    function (_, accountId) { return accountId; },
], function (wireValues, accountId) {
    var filtered = wireValues.filter(function (wire) { return wire.from_account === accountId; });
    filtered.sort(sortWires);
    return filtered;
});
export var selectWire = function (state, wireId) {
    var wireValues = Object.values(state.wires.byId);
    var wire = wireValues.find(function (w) {
        return w.id === wireId;
    });
    return wire;
};
export var selectWireRecipient = function (state, wireRecipientId) {
    var wireRecipientValues = Object.values(state.wires.recipients.byId);
    return wireRecipientValues.find(function (w) { return w.id === wireRecipientId; });
};
export var selectWireRecipients = createDeepEqualSelector(function (state) { return state.wires.recipients.byId; }, function (wireRecipients) { return Object.values(wireRecipients); });
export var selectOneWireRecipient = createDeepEqualSelector([
    function (state) { return state.wires.recipients.byId; },
    function (_, recipientId) { return recipientId; },
], function (recipientsById, recipientId) {
    if (!recipientId) {
        return undefined;
    }
    return recipientsById[recipientId];
});
