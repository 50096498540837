import React from "react";
import PropTypes from "prop-types";
import Wire from "byzantine/src/Wire";
import { Dialog } from "cerulean";
import { useCurrentUser } from "../contexts/CurrentUserContext";

const WireStatusesDialog = ({ isDialogOpen, closeDialog }) => {
  const { currentUser } = useCurrentUser();
  const wireStatuses = currentUser.isBusiness()
    ? Wire.getBusinessPendingStatuses()
    : Wire.getConsumerPendingStatuses();

  return (
    <Dialog
      isOpen={isDialogOpen}
      onUserDismiss={closeDialog}
      title="Wire statuses"
    >
      <div className="margin--top--s">
        {wireStatuses.map(([status, meaning]) => (
          <div key={status} className="margin--bottom--l">
            <div className="attribute-label">{status}</div>
            <div>{meaning}</div>
          </div>
        ))}
      </div>
    </Dialog>
  );
};

WireStatusesDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default WireStatusesDialog;
