var ALL = "*";
var WRITE = "write";
var READ = "read";
var canPerformOperation = function (requiredOperationPermission, userOperationPermission) {
    switch (userOperationPermission) {
        case ALL: {
            return true;
        }
        case WRITE: {
            return (requiredOperationPermission === WRITE ||
                requiredOperationPermission === READ);
        }
        case READ: {
            return requiredOperationPermission === READ;
        }
        default:
            return false;
    }
};
export var hasPermission = function (permissions, _a) {
    var modelStr = _a.model_str, operation = _a.operation, uuid = _a.uuid;
    var found = permissions.find(function (permission) {
        return [modelStr, ALL].includes(permission.model_str) &&
            [uuid, ALL].includes(permission.uuid);
    });
    if (found) {
        return canPerformOperation(operation, found.operation);
    }
    return false;
};
