var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable camelcase */
import { useCallback, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { FREQUENCIES } from "byzantine/src/Recurrence";
import LoanPayment from "byzantine/src/LoanPayment";
import TransferSchedule from "byzantine/src/TransferSchedule";
import { ContextForm, useFormData } from "cerulean";
import useReviewableAction from "../../../useReviewableAction";
import { useUserFeatures } from "../../../contexts/UserFeaturesContext";
import { useCurrentUser } from "../../../contexts/CurrentUserContext";
import LoanPaydownForm from "./LoanPaydownForm";
import LoanPaydownActionReview from "./LoanPaydownActionReview";
var LoanPaydown = function (_a) {
    var limits = _a.limits, loanId = _a.loanId, goToPrevious = _a.goToPrevious;
    var currentUser = useCurrentUser().currentUser;
    var _b = useFormData({
        frequency: FREQUENCIES.ONCE,
        date: DateTime.now().toFormat("MM/dd/yyyy"),
        to_account_id: loanId,
    }), formData = _b.formData, onChange = _b.onChange;
    var _c = useReviewableAction(), isInput = _c.isInput, goToInput = _c.goToInput, goToReview = _c.goToReview;
    var loan_principal_payment = useUserFeatures().loan_principal_payment;
    var _d = __read(useState(uuidv4()), 2), itempotencyKey = _d[0], setItempotencyKey = _d[1];
    if (!loan_principal_payment || !currentUser) {
        return null;
    }
    useEffect(function () {
        if (loanId) {
            onChange({ to_account_id: loanId });
        }
    }, [loanId]);
    useEffect(function () {
        // if scheduling a transfer, cannot start today
        if (formData.frequency !== FREQUENCIES.ONCE) {
            var tomorrow = DateTime.now().plus({ days: 1 });
            if (DateTime.fromFormat(formData.date, "M/d/yyyy") < tomorrow) {
                onChange({ date: tomorrow.toFormat("MM/dd/yyyy") });
            }
        }
    }, [formData.frequency]);
    var onSubmit = useCallback(function (callback) {
        var start_date = DateTime.fromFormat(formData.date, "M/d/yyyy").toFormat("yyyy-MM-dd");
        var isIOT = TransferSchedule.isImmediateOneTimeTransfer(formData.frequency, start_date);
        var transferVerb = isIOT ? "sent" : "scheduled";
        var defaultPayload = {
            amount: formData.amount,
            from_account_id: formData.from_account_id,
            to_account_id: formData.to_account_id,
            itempotencyKey: itempotencyKey,
        };
        var schedule;
        if (isIOT) {
            schedule = new LoanPayment(__assign(__assign({}, defaultPayload), { payment_type: formData.payment_type }));
        }
        else {
            schedule = new TransferSchedule(__assign(__assign({}, defaultPayload), { frequency: formData.frequency, start_date: start_date }));
        }
        schedule
            .submit()
            .then(function () {
            goToPrevious("success=Payment+".concat(transferVerb, "."));
        })
            .catch(function (error) {
            if (typeof error === "string") {
                goToPrevious("negative=".concat(encodeURIComponent(error)));
            }
            else {
                callback(error);
                goToInput();
            }
        })
            .finally(function () {
            setItempotencyKey(uuidv4());
        });
    }, [formData]);
    return (_jsx(ContextForm, { data: formData, onChange: onChange, children: isInput ? (_jsx(LoanPaydownForm, { data: formData, onChange: onChange, onSubmit: goToReview, cancel: function () { return goToPrevious(); }, limits: limits })) : (_jsx(LoanPaydownActionReview, { data: formData, goBack: goToInput, cancel: function () { return goToPrevious(); }, onSubmit: onSubmit })) }));
};
export default LoanPaydown;
