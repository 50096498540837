import React from "react";
import PropTypes from "prop-types";
import { DropdownLinkItem } from "cerulean";

const AddRecipientPlainButton = ({ closeDropdown, showNewRecipientForm }) => (
    <DropdownLinkItem
      closeDropdown={closeDropdown}
      setModalOpen={showNewRecipientForm}
    >
      <div>{"+ Add a new wire recipient"}</div>
    </DropdownLinkItem>
  );
AddRecipientPlainButton.propTypes = {
  closeDropdown: PropTypes.func,
  showNewRecipientForm: PropTypes.func,
};

export default AddRecipientPlainButton;
