/* eslint-disable react/jsx-key */

import React, { useContext, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import { Button, TruncatedAccount } from "@narmi/design_system";
import Account from "byzantine/src/Account";
import { featureEnabled } from "byzantine/src/Feature";
import utils from "byzantine/src/utils";
import { useNotificationContext } from "cerulean";

import AccountList from "./AccountList";
import AppAuthorize from "./AppAuthorize";
import NavBar from "./NavBar";
import AccountContext from "./contexts/AccountContext";
import { InstitutionSettingsContextProvider } from "./contexts/InstitutionSettingsContext";
import Details from "./Details";
import useAccountSorting from "./hooks/useAccountSorting";
import { useCurrentUser } from "./contexts/CurrentUserContext";

function RenderGroup({ children, numGroups }) {
  return (
    <div className={`account-group navbar-accountGroupColumn--${numGroups}`}>
      {children}
    </div>
  );
}

RenderGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  numGroups: PropTypes.number,
};

function RenderHeader({ group }) {
  return <div className="group-header">{group}</div>;
}

RenderHeader.propTypes = {
  group: PropTypes.string,
};

const RenderAccount = forwardRef(({ account }, ref) => (
  <div>
    <a
      className="fontWeight--default nds-button nds-button--menu"
      href={`/accounts/${account.id}`}
      aria-label={` Account Group - ${account.getGroupName()} - ${
        account.name
      } ${account.getMaskedNumber()}`}
      ref={ref}
    >
      <TruncatedAccount
        name={account.name}
        lastFour={account.getMaskedNumber()}
      />
    </a>
  </div>
));

RenderAccount.displayName = "RenderAccount";
RenderAccount.propTypes = {
  account: PropTypes.instanceOf(Account).isRequired,
};

function TopMenu(props) {
  const accountContext = useContext(AccountContext);
  const { currentUser } = useCurrentUser();
  const accounts = accountContext?.accounts || props.accounts;
  const { orderedAccounts = [] } = currentUser
    ? useAccountSorting(accounts)
    : {};

  const renderItemMenu = ([name, itemProps]) => {
    // enable each menu item to be conditional by feature flag
    if (itemProps.feature_flag) {
      if (!featureEnabled(props.features, { or: itemProps.feature_flag }))
        return null;
    }

    let label = name;
    if (itemProps.superscript) {
      label = (
        <>
          {name}
          <sup>{itemProps.superscript}</sup>
        </>
      );
    }

    // handle each potential menu option
    if (itemProps.url) {
      if (itemProps.openNewTab) {
        return (
          <Button
            as="a"
            label={label}
            kind="menu"
            onClick={() => window.open(itemProps.url, "_blank")}
            key={name}
          />
        );
      }
      return (
        <Button
          as="a"
          label={label}
          kind="menu"
          href={itemProps.url}
          key={name}
        />
      );
    }
    if (itemProps.menu) {
      const renderedItems = Object.entries(itemProps.menu)
        .map(renderItemMenu)
        .filter((item) => item);
      const unpackedItems = renderedItems.flatMap((item) => {
        if (item.key !== "naf") return item;
        return item.props.children;
      });
      const chunkSize = unpackedItems.length % 5 !== 1 ? 5 : 6;
      const chunkedItems = utils.shardArrayByChunkSize(
        unpackedItems,
        chunkSize
      );
      return (
        <Details
          summary={<Button as="a" kind="menu" label={name} />}
          key={name}
          type="wide details"
        >
          {chunkedItems.map((menuChunk) => (
            <div key={menuChunk[0].key} className="tools">
              {menuChunk}
            </div>
          ))}
        </Details>
      );
    }
    if (itemProps.accounts) {
      return (
        <Details
          summary={<Button as="a" kind="menu" label="Accounts" tabIndex={-1} />}
          key={"Accounts"}
          type="wide details"
          ariaLabel="Accounts menu"
        >
          <AccountList
            accounts={orderedAccounts.filter((a) => a.isInternal())}
            alwaysGroup={true}
            RenderGroup={RenderGroup}
            RenderHeader={RenderHeader}
            RenderAccount={RenderAccount}
          />
        </Details>
      );
    }
    if (itemProps.app) {
      return (
        <AppAuthorize app={itemProps.app} key={itemProps.app.name}>
          {itemProps.app.name}
        </AppAuthorize>
      );
    }
    if (itemProps.naf) {
      const listOfNafItems = {};
      props.institution.apps.forEach((app) => {
        listOfNafItems[app.name] = { app };
      });
      props.legacyNafUrls.forEach((link) => {
        listOfNafItems[link.title] = { url: link.url, openNewTab: true };
      });
      return (
        <React.Fragment key="naf">
          {Object.entries(listOfNafItems).map(renderItemMenu)}
        </React.Fragment>
      );
    }
    return null;
  };

  const menuItems = currentUser ? props.features.top_menu || {} : {};

  const { sendNotification } = useNotificationContext();
  const { l10n } = useLocalization();

  /*
  for FI's that require MFA, a typical user cannot finish enrollment/conversion w/o having at least one mfa device
  however, the user can still complete the process if backup codes are added or if an email device (although unpermitted) was added, which is common with conversions
  so, if the user makes it to the dashboard, but hasn't added a permitted device, show them an info pop-up until they add one
  */
  useEffect(() => {
    if (currentUser?.has_permitted_device === false && sendNotification) {
      sendNotification({
        type: "info",
        text: l10n.getString(
          "info-add-permitted-mfa-device",
          null,
          "We've updated our account security. Please add a new two-factor authentication device, which is required to access Digital Banking. [Add one now](/settings/security)."
        ),
      });
    }
  }, [currentUser?.has_permitted_device]);

  return (
    <NavBar
      features={props.features}
      deviceIsRemembered={props.deviceIsRemembered}
      institution={props.institution}
      React={React}
    >
      {Object.entries(menuItems).map(renderItemMenu)}
    </NavBar>
  );
}

TopMenu.propTypes = {
  features: PropTypes.object,
  accounts: PropTypes.array,
  legacyNafUrls: PropTypes.array,
  institution: PropTypes.object,
  deviceIsRemembered: PropTypes.bool,
};

const TopMenuContainer = (props) => (
    <InstitutionSettingsContextProvider>
      <TopMenu {...props} />
    </InstitutionSettingsContextProvider>
  );

export default TopMenuContainer;
