import React from "react";
import PropTypes from "prop-types";
import { formatNumber } from "@narmi/design_system";
import SectionCard from "../SectionCard";

const StopPaymentRequest = ({ stop }) => (
    <div className="requests-card-subsection-item">
      {`Check-${stop.min_check_number}`}
      {stop.max_check_number && ` to Check-${stop.max_check_number}`}
      {!!stop?.amount && ` for ${formatNumber(stop.amount / 100)}`}
    </div>
  );
StopPaymentRequest.propTypes = {
  stop: PropTypes.object.isRequired,
};

const RequestsCard = ({ isLoading, stopPayments }) => (
    <SectionCard isLoading={isLoading}>
      {stopPayments.length > 0 && (
        <>
          <SectionCard.Title text="Requests" />
          <div className="requests-card-subsection-title">Stop payment</div>
          {stopPayments.map((stop) => (
            <StopPaymentRequest key={JSON.stringify(stop)} stop={stop} />
          ))}
        </>
      )}
    </SectionCard>
  );
RequestsCard.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  stopPayments: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default RequestsCard;
