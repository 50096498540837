import React from "react";
import PropTypes from "prop-types";

import { TextInput } from "@narmi/design_system";

export const computeAccountNumberDisplayValue = (rawValue) => {
  if (!rawValue) return "";
  if (rawValue.length < 6) return rawValue;
  return `${rawValue.substring(0, 5)} ${rawValue.substring(5)}`;
};

const AccountNumberTextInput = ({
  onChange,
  value,
  field,
  ...textInputProps
}) => {
  const handleInput = (event) => {
    onChange(event.target.value.replace(/[^\d]/g, ""));
  };

  return (
    <TextInput
      onChange={handleInput}
      value={computeAccountNumberDisplayValue(value)}
      field={field}
      {...textInputProps}
    />
  );
};
AccountNumberTextInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  field: PropTypes.string,
};

export default AccountNumberTextInput;
