import { useMemo } from "react";
var useWelcomeOffer = function () {
    var _a;
    var welcomeOfferRaw = (_a = document.getElementById("welcome_offer")) === null || _a === void 0 ? void 0 : _a.textContent;
    return useMemo(function () {
        var welcomeOffer = null;
        if (welcomeOfferRaw) {
            welcomeOffer = JSON.parse(welcomeOfferRaw);
        }
        return welcomeOffer;
    }, [welcomeOfferRaw]);
};
export default useWelcomeOffer;
