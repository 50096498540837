/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";

import { InstitutionSettingsContextProvider } from "../contexts/InstitutionSettingsContext";
import EstatementsContainer from "./EstatementsContainer";

// this is just a wrapper component so that the EstatementsContainer can have various contexts
const EstatementsManager = () => (
    <InstitutionSettingsContextProvider>
      <EstatementsContainer />
    </InstitutionSettingsContextProvider>
  );

export default EstatementsManager;
