export default class Pagination {
  constructor(props) {
    this.page = parseInt(props.page, 10) || 1;
    this.pageSize = parseInt(props.pageSize, 10);
    this.total = parseInt(props.total, 10);
    this.navigatePage = props.navigatePage;
  }

  getNumPages() {
    if (!this.pageSize) return 0;
    return Math.ceil(this.total / this.pageSize);
  }
}
