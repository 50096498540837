import React from "react";
import PropTypes from "prop-types";

const RenderContent = (props) => {
  if (!props.beneficiaries.length) {
    return <props.renderEmpty {...props} />;
  }
  return (
    <>
      {props.beneficiaries.map((beneficiary) => (
        <props.renderBeneficiary
          beneficiary={beneficiary}
          key={beneficiary.value}
          {...props}
        />
      ))}
    </>
  );
};
RenderContent.propTypes = {
  beneficiaries: PropTypes.array,
};

export default function BeneficiaryList(props) {
  return (
    <>
      <RenderContent {...props} />
    </>
  );
}

BeneficiaryList.defaultProps = {
  beneficiaries: [],
  renderEmpty: () => null,
};

BeneficiaryList.propTypes = {
  beneficiaries: PropTypes.array,
  renderBeneficiary: PropTypes.func.isRequired,
  renderEmpty: PropTypes.func,
};
