import Account from "./Account";
import ApiHttp from "./ApiHttp";

export const ALL = "*";

export const MODEL_STR = {
  ACCOUNT: "indigo.Account",
  ACH_TRANSFER: "indigo.AchTransfer",
  ALL_MODELS: ALL,
  CARD: "integrations.base.Card",
  EXTERNAL_ACCOUNT: "indigo.ExternalAccount",
  INTRA_INSTITUTION_TRANSFER: "indigo.IntraInstitutionTransfer",
  MEMBER_FRIEND: "indigo.MemberFriend",
  PAYEE: "integrations.base.Payee",
  PAYMENT: "integrations.base.Payment",
  TRANSACTION: "indigo.Transaction",
  WIRE_TRANSACTION: "indigo.WireTransaction",
  DOCUMENTS: "integrations.documents",
  RDC: "integrations.rdc",
  DUAL_APPROVAL_REQUEST: "indigo.DualApprovalRequest",
  ORGANIZATION: "indigo.Organization",
  ORGANIZATION_USER: "indigo.OrganizationUser",
  ROLE: "indigo.Role",
};

export const OPERATION_FOR_ACCESS_LEVEL = {
  Collaborator: "write",
  Viewer: "read",
};

export default class Role {
  constructor(props) {
    this.uuid = props.uuid;
    this.organization_uuid = props.organization_uuid;
    this.name = props.name;
    this.description = props.description;
    this.permissions = props.permissions || [];
    this.limits = props.limits || {};
  }

  static forOrganization(orgUuid, method = "GET") {
    return ApiHttp.fetch(`organizations/${orgUuid}/roles`, {
      method,
    }).then((res) => {
      return res.roles.map((r) => Role.deserialize(r));
    });
  }

  /**
  If Account Holder, return "Account owner"
  Otherwise returns "Admin", "Collaborator", or "Viewer"
  Necessary only for the Organization Users table
  * */
  getAccessLevelUnlessIsOwner() {
    if (this.name === "Account Holder") {
      return "Account owner";
    }
    return this.getAccessLevel();
  }

  /**
  Returns "Admin", "Collaborator", or "Viewer"
  * */
  getAccessLevel() {
    const ACCESS_LEVEL_FROM_ROLE = {
      Admin: "Admin",
      "Account Holder": "Admin",
    };
    if (ACCESS_LEVEL_FROM_ROLE[this.name]) {
      return ACCESS_LEVEL_FROM_ROLE[this.name];
    }

    if (
      this.permissions.filter(
        (p) =>
          p.model_str === MODEL_STR.ACCOUNT &&
          [ALL, "write"].includes(p.operation)
      ).length
    ) {
      return "Collaborator";
    }
    return "Viewer";
  }

  /**
  Returns array of accounts with added prop "selected" that is truthy if there
  is a corresponding permission
  * */
  markSelectedAccounts(accounts) {
    return accounts.map((account) => {
      const markedAccount = new Account(account);
      // if we have a permission for this account, mark as selected
      const relevantPermission = markedAccount.isInternal()
        ? MODEL_STR.ACCOUNT
        : MODEL_STR.EXTERNAL_ACCOUNT;
      markedAccount.selected = this.permissions.find(
        (p) =>
          relevantPermission === p.model_str &&
          [ALL, String(account.id)].includes(String(p.uuid))
      );
      return markedAccount;
    });
  }

  /**
  Factory method generating a canonical Role from AccessLevel format
  * */
  static generateFromAccessLevel(
    accessLevel,
    accounts,
    externalAccounts,
    permissionOverrides,
    roles,
    limits
  ) {
    // there should always be a corresponding Role
    let role = roles.find((r) => r.name === accessLevel);
    // special handling for specific AccessLevels
    const mutateAccountOperation = OPERATION_FOR_ACCESS_LEVEL[accessLevel];
    // Collaborator and Viewer have special handling, otherwise we have our role
    if (!mutateAccountOperation) {
      return role;
    }

    // create a new role that has the same permissions as the corresponding system default role's
    // except account permissions (which will be handled in the forEach loop)
    role = new Role({
      permissions: role.permissions.filter(
        (p) => ![MODEL_STR.ACCOUNT].includes(p.model_str)
      ),
    });

    // apply permissionOverrides
    if (permissionOverrides) {
      Object.keys(permissionOverrides).forEach((key) => {
        if (permissionOverrides[key]) {
          // add the new permission if we need to
          if (role.permissions.find((p) => p.model_str === key)) return;
          role.permissions.push({
            model_str: key,
            operation: mutateAccountOperation,
            uuid: ALL,
          });
        } else {
          // entering this block means that the permission override has been disabled,
          // so we want to make sure to remove any related permissions
          role.permissions = role.permissions.filter(
            (p) => p.model_str !== key
          );
        }
      });
    }
    // update the account permissions for our new custom role
    accounts.forEach((account) => {
      if (!account.selected) return;
      role.permissions.push({
        model_str: MODEL_STR.ACCOUNT,
        operation: mutateAccountOperation,
        uuid: account.id,
      });
    });
    // the permissions for individual external accounts are different
    // from the permission to add, edit, or remove any external account
    // if the "Add external account" permission is removed, we still want to keep any individual external account permissions
    externalAccounts.forEach((externalAccount) => {
      if (!externalAccount.selected) return;
      if (!role.permissions.some((p) => p.model_str === MODEL_STR.ACH_TRANSFER))
        return;
      role.permissions.push({
        model_str: MODEL_STR.EXTERNAL_ACCOUNT,
        operation: mutateAccountOperation,
        uuid: externalAccount.id,
      });
    });

    // apply daily limits
    if (limits) {
      let ach_limits = limits.ach_limits;
      // if there are no external accounts on the organization and the user does not have permissions to add an external
      // account then ACH push limits should be 0
      if (
        limits.ach_limits &&
        !externalAccounts.length &&
        !role.permissions.some(
          (p) => p.model_str === MODEL_STR.EXTERNAL_ACCOUNT
        )
      )
        ach_limits = 0;
      role.limits = {
        ach_push: { 1: ach_limits },
        wire: { 1: limits.wire_limits },
      };
    }
    return role;
  }

  /** *
  Factory method returning a new instance of Role from
  an indigo.serialize'd Role
  ** */
  static deserialize(payload) {
    return new Role(payload);
  }

  serialize() {
    return {
      uuid: this.uuid,
      organization_uuid: this.organization_uuid,
      name: this.name,
      description: this.description,
      permissions: this.permissions,
      limits: this.limits,
    };
  }
}
