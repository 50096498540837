import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import catcat from "../utils/catcat";
var CollapsibleSection = function (_a) {
    var title = _a.title, sectionStep = _a.sectionStep, totalSteps = _a.totalSteps, currentStep = _a.currentStep, children = _a.children, _b = _a.tagName, tagName = _b === void 0 ? "" : _b;
    /*
    `sectionStep` is the step number for this section.
    `totalSteps` is the total number of steps in the whole page.
    `currentStep` is the number of the step that the user is currently on in the page.
  
    If the user has not reached this step, the whole section is collapsed.
    Once the user reaches the step, the whole section is displayed.
    `Step X of Y` tag is only displayed if this section is the active step.
     */
    if (currentStep < sectionStep) {
        return (_jsx("div", { className: "collapsible-section-card", children: _jsx("h4", { className: "nds-sans fontColor--hint", children: title }) }));
    }
    var isActiveStep = currentStep === sectionStep;
    return (_jsxs("div", { className: catcat("collapsible-section-card", {
            "active-step": isActiveStep,
        }), children: [_jsxs("div", { className: "alignChild--left--center margin--bottom--l", style: { flexWrap: "wrap", gap: "var(--space-s)" }, children: [_jsx("h4", { className: "nds-sans", style: { marginRight: "auto" }, children: title }), isActiveStep && (_jsx("div", { className: "step-x-of-y-tag", children: tagName || "Step ".concat(sectionStep, " of ").concat(totalSteps) }))] }), children] }));
};
export default CollapsibleSection;
