import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Paperclip } from "react-feather";

import ApiHttp from "byzantine/src/ApiHttp";
import Filters from "byzantine/src/filters";
import ThreadReply from "./ThreadReply";

function DateBoundary({ date }) {
  if (date === null) {
    return null;
  }
  return (
    <div className="date-boundary">
      <div className="date">{Filters.longMonthDayYear(date)}</div>
    </div>
  );
}
DateBoundary.propTypes = {
  date: PropTypes.string.isRequired,
};

function linebreaks(str) {
  const strTrimmed = str.trim();
  const paragraphEndIndex = strTrimmed.indexOf("\n\n");
  const parts =
    paragraphEndIndex !== -1
      ? strTrimmed.substring(0, paragraphEndIndex).split("\n")
      : strTrimmed.split("\n");
  return (
    <>
      <p>
        {parts.map((part, i) => (
          <React.Fragment key={i}>
            {part}
            <br />
          </React.Fragment>
        ))}
      </p>
      {paragraphEndIndex !== -1
        ? linebreaks(strTrimmed.substring(paragraphEndIndex))
        : null}
    </>
  );
}

function parseFileUrlLink(str) {
  const match = str.match(/^\[(.*?)\]\((.*\/(.*?)\?.*)\)$/);
  if (match) {
    return {
      text: match[3],
      link: match[2],
    };
  }
  return null;
}

function MessageItem({ isSelf, messageBody, messageDateTime }) {
  const messageItemClasses = isSelf ? "message-item self" : "message-item";
  const fileMeta = parseFileUrlLink(messageBody);
  const formattedMessageBody =
    fileMeta !== null ? (
      <div className="file-message">
        <Paperclip size={16} />
        <a href={fileMeta.link}>{fileMeta.text}</a>
      </div>
    ) : (
      linebreaks(messageBody)
    );
  return (
    <div className={messageItemClasses}>
      <div className="message-author">{isSelf ? "Self" : "Support"}</div>
      <div className="message-body">{formattedMessageBody}</div>
      <div className="message-datetime">{Filters.time(messageDateTime)}</div>
    </div>
  );
}
MessageItem.propTypes = {
  isSelf: PropTypes.bool.isRequired,
  messageBody: PropTypes.string.isRequired,
  messageDateTime: PropTypes.string.isRequired,
};

export default function ThreadMessageList({
  threadId,
  currentUserUUID,
  replyDisclaimer,
  replyTime,
  userMessageUploads,
}) {
  const messageList = useRef();
  const [thread, setThread] = useState(null);
  const [messages, setMessages] = useState(null);

  const reloadThread = useCallback(() => {
    if (!threadId) {
      return;
    }
    ApiHttp.fetch(`threads/${threadId}`).then((result) => {
      setThread(result.thread);
      if (result.thread.is_read) {
        return;
      }
      ApiHttp.fetch(`threads/${threadId}/mark_read`, { method: "PUT" });
    });
    ApiHttp.fetch(`threads/${threadId}/messages`).then((result) => {
      setMessages(result.messages);
      if (messageList.current) {
        messageList.current.scrollTop = 0;
      }
    });
  }, [threadId]);

  useEffect(() => {
    reloadThread();
  }, [reloadThread]);

  if (!thread || !messages) {
    return <div className="thread-message-list"></div>;
  }

  let currentDate = null;
  return (
    <div className="thread-message-list">
      <div className="thread-subject">{thread.subject}</div>
      <div className="message-list-scroller" ref={messageList}>
        <div className="message-list">
          {messages.map((message, index) => {
            const nextDate = message.created_at;
            const isLastMessage = messages.length - 1 === index;
            const didDateChange =
              Filters.americanDate(currentDate) !==
                Filters.americanDate(nextDate) && currentDate !== null;
            const fragment = (
              <React.Fragment key={message.id}>
                {didDateChange ? <DateBoundary date={currentDate} /> : null}
                <MessageItem
                  isSelf={message.author.id === currentUserUUID}
                  messageBody={message.body}
                  messageDateTime={message.created_at}
                />
                {isLastMessage ? <DateBoundary date={nextDate} /> : null}
              </React.Fragment>
            );

            currentDate = nextDate;
            return fragment;
          })}
        </div>
      </div>
      <ThreadReply
        threadId={threadId}
        reloadThread={reloadThread}
        replyTime={replyTime}
        replyDisclaimer={replyDisclaimer}
        userMessageUploads={userMessageUploads}
      />
    </div>
  );
}
ThreadMessageList.propTypes = {
  threadId: PropTypes.string,
  currentUserUUID: PropTypes.string,
  replyDisclaimer: PropTypes.string,
  replyTime: PropTypes.string,
  userMessageUploads: PropTypes.bool,
};
