import { useEffect, useState } from "react";
import Account from "byzantine/src/Account";
import {
  groupAccounts,
  sortAccountGroups,
  DEFAULT_SORT,
} from "byzantine/src/AccountUtils";
import ApiHttp from "byzantine/src/ApiHttp";

const useAccountSorting = (accounts) => {
  const [accountSorting, setAccountSorting] = useState([]);
  const { order: DEFAULT_ORDER, sort_by: DEFAULT_SORT_BY } = DEFAULT_SORT;

  /* the server requires the group name to be formatted in a certain way */
  const convertToServerGroupName = (groupName) => {
    if (groupName === "Credit cards") return "credit_cards";
    return groupName.toLowerCase();
  };

  const getAccountSortingForGroup = (groupName) =>
    accountSorting.find(
      (acctSort) => acctSort.group_name === convertToServerGroupName(groupName)
    ) || {};

  const fetchAccountSorting = () => {
    ApiHttp.fetch("accounts/sorting", { method: "GET" })
      .then((response) => {
        const { account_sorting } = response;
        const groupsWithSort = account_sorting.map((sort) => sort.group_name);

        Object.keys(Account.PRODUCT_GROUPS).forEach((groupName) => {
          const serverGroupName = convertToServerGroupName(groupName);
          if (!groupsWithSort.includes(serverGroupName)) {
            account_sorting.push({
              group_name: serverGroupName,
              sort_by: DEFAULT_SORT_BY,
              order: DEFAULT_ORDER,
            });
          }
        });

        setAccountSorting(account_sorting);
      })
      .catch(() => {});
  };
  useEffect(fetchAccountSorting, []);

  const sortGroup = (groupName, sort_by, order) => ApiHttp.fetch(
      "accounts/sorting",
      { method: "POST" },
      {
        group_name: convertToServerGroupName(groupName),
        sort_by: sort_by || DEFAULT_SORT_BY,
        order: order || DEFAULT_ORDER,
      }
    ).then(({ account_sorting }) => {
      setAccountSorting((prevAccountSorting) => {
        const updatedAccountSorting = [...prevAccountSorting];
        const prevIndex = updatedAccountSorting.findIndex(
          (accSort) => accSort.group_name === account_sorting.group_name
        );
        if (prevIndex > -1) updatedAccountSorting[prevIndex] = account_sorting;
        else updatedAccountSorting.push(account_sorting);
        return updatedAccountSorting;
      });
    });

  const groupedAccounts = groupAccounts(accounts);
  sortAccountGroups(groupedAccounts, accountSorting);
  const orderedAccounts = Array.from(groupedAccounts.values()).flat();

  return {
    orderedAccounts,
    sortGroup,
    getAccountSortingForGroup,
  };
};

export default useAccountSorting;
