var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../selectors/user.selectors";
import { useRequestWithFeedback } from "../../../composites/request/request.hooks";
import { useAppDispatch } from "../utils/useAppDispatch.hook";
import { actions } from "../slices/user.slice";
import { useObjectMemo } from "../../../hooks";
export var useUser = function () {
    return useSelector(selectUser);
};
export var useFetchUser = function () {
    var _a = useRequestWithFeedback(), send = _a.send, loading = _a.loading, status = _a.status;
    var dispatch = useAppDispatch();
    var fetch = useCallback(function () {
        send({
            action: {
                url: "me/",
                options: {
                    method: "GET",
                },
            },
            onData: function (_a) {
                var _b = __read(_a.users, 1), user = _b[0];
                dispatch(actions.upsert(user));
            },
        });
    }, [dispatch, send]);
    var user = useUser();
    return useObjectMemo({
        send: fetch,
        loading: loading,
        error: status === "error",
        hasData: user !== null,
    });
};
