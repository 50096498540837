import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  AddPhoneMfaDevice,
  ContextForm,
  phoneFormatter,
  useNotificationContext,
} from "cerulean";
import MfaDevice from "byzantine/src/MfaDevice";
import User from "byzantine/src/User";

const AddNewPhoneDevice = ({
  data,
  devices,
  onCancel,
  onChange,
  onDone,
  setDevices,
}) => {
  const { sendNotification } = useNotificationContext();
  const [user, setUser] = useState(null);

  useEffect(() => {
    User.getUser().then((u) => {
      setUser(u);
    });
  }, []);

  const onRequestCode = () => {
    onChange({
      twoFactorAuthentication: {
        phone_number: data.twoFactorAuthentication.phone_number,
      },
    });
    return user.addMfaDevice({
      phone_number: data.twoFactorAuthentication.phone_number,
      device_type: MfaDevice.TYPES.PHONE,
    });
  };

  const onVerifyCode = async (seed) => {
    const newDevice = await user.addMfaDevice({
      phone_number: data.twoFactorAuthentication.phone_number,
      device_type: MfaDevice.TYPES.PHONE,
      token: data.twoFactorAuthentication.token,
      seed,
    });
    let action;
    if (devices.some((device) => device.id === newDevice?.device?.id)) {
      action = "reverified";
    } else {
      setDevices([...devices, newDevice.device]);
      action = "added";
    }
    onDone();
    sendNotification({
      type: "success",
      text: `${phoneFormatter(
        data.twoFactorAuthentication.phone_number
      )} was successfully ${action} as a two-factor authentication device.`,
    });
  };

  return (
    <div style={{ fontSize: "16px" }}>
      <div className="margin--top--m fontWeight--bold margin--bottom--s">
        Add a phone device
      </div>
      <ContextForm data={data} onChange={onChange}>
        <ContextForm.Field field="twoFactorAuthentication">
          <AddPhoneMfaDevice
            field="twoFactorAuthentication"
            onCancel={onCancel}
            onRequestCode={onRequestCode}
            onUseADifferentPhoneNumber={() =>
              onChange({ twoFactorAuthentication: {} })
            }
            onVerifyCode={onVerifyCode}
          />
        </ContextForm.Field>
      </ContextForm>
    </div>
  );
};
AddNewPhoneDevice.propTypes = {
  data: PropTypes.object.isRequired,
  devices: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  setDevices: PropTypes.func.isRequired,
};

export default AddNewPhoneDevice;
