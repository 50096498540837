import { DateTime } from "luxon";
import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { formatNumber } from "@narmi/design_system";
import ApiHttp from "byzantine/src/ApiHttp";
import { NotificationContext } from "cerulean";
import SectionCard from "../SectionCard";

const HoldsCard = ({ accountUuid }) => {
  const [holds, setHolds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const { sendNotification } = useContext(NotificationContext);

  const fetchHolds = async () => {
    try {
      setIsLoading(true);
      const response = await ApiHttp.fetch(`accounts/${accountUuid}/holds`, {
        method: "GET",
      });
      if (response?.holds) {
        setHolds(response.holds);
      }
    } catch {
      sendNotification({
        type: "negative",
        text: "There was an error retrieving holds, please try again later.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHolds();
  }, []);

  return (
    <SectionCard
      isLoading={isLoading}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      kind={isExpanded ? "transactions" : undefined}
      title="Holds"
    >
      {holds.length !== 0 && (
        <>
          <div className="card-table three">
            <div className="row-wrapper">
              <div className="row-item-header">DESCRIPTION</div>
              <div className="row-item-header align-right">AMOUNT</div>
              <div className="row-item-header">EXPIRATION</div>
            </div>
            {holds.map((hold) => (
              <div key={hold.id} className="row-wrapper">
                <div className="row-items-column">
                  <div className="row-item">{hold.description}</div>
                </div>
                <div className="row-items-column">
                  <div className="row-item align-right number">
                    {formatNumber(hold.amount / 100)}
                  </div>
                  <div className="row-item align-right responsive-text-styling">
                    {hold.expires_at
                      ? DateTime.fromISO(hold.expires_at)
                          .toUTC()
                          .toFormat("MM/dd/yyyy")
                      : "N/A"}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </SectionCard>
  );
};
HoldsCard.propTypes = {
  accountUuid: PropTypes.string,
};

export default HoldsCard;
