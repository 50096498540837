import React from "react";
import PropTypes from "prop-types";
import { Button, ContextForm, Dialog, TextInput } from "cerulean";

import AccountSelector from "./AccountSelector";
import MinMaxAmountField from "./MinMaxAccountField";
import NotificationTypeSelector from "./NotificationTypeSelector";

const NewAlertDialog = ({
  accounts,
  data,
  dialogIsOpen,
  onChange,
  onSaveAlert,
  setDialogIsOpen,
  setFormData,
}) => (
  <Dialog
    isOpen={dialogIsOpen}
    onUserDismiss={() => {
      setDialogIsOpen(false);
      setFormData({ alert_type: "email" });
    }}
    title="Add a new alert"
  >
    <div style={{ fontSize: "16px" }}>
      <ContextForm data={data} onChange={onChange}>
        <ContextForm.Field required>
          <NotificationTypeSelector field="alert_type" />
        </ContextForm.Field>
        <ContextForm.Field>
          <AccountSelector
            field="account_et"
            accounts={accounts.filter((a) => a.isInternal())}
          />
        </ContextForm.Field>
        <ContextForm.Field>
          <TextInput field="keywords" label="Transaction keywords" />
        </ContextForm.Field>
        <MinMaxAmountField header="Amount" type="amount" />
        <MinMaxAmountField header="Resulting balance" type="balance" />
        <div className="margin--top--xl alignChild--right--center">
          <ContextForm.Action onSubmit={onSaveAlert}>
            <Button kind="primary" label="Save" />
          </ContextForm.Action>
        </div>
      </ContextForm>
    </div>
  </Dialog>
);
NewAlertDialog.propTypes = {
  accounts: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  dialogIsOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSaveAlert: PropTypes.func.isRequired,
  setDialogIsOpen: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
};

export default NewAlertDialog;
