import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import ApiHttp from "byzantine/src/ApiHttp";
import Account from "byzantine/src/Account";
import { Row } from "@narmi/design_system";
import { NotificationContext } from "cerulean";
import AccountBeneficiaries from "./AccountBeneficiaries";
import SectionCard from "../SectionCard";

const AccountRow = ({
  account,
  allBeneficiaries,
  fetchAllBeneficiaries,
  fetchBeneficiariesForAccount,
  deleteBeneficiary,
  addBeneficiary,
  supportPhoneNumber,
}) => {
  const [viewBeneficiariesDetail, setViewBeneficiariesDetail] = useState(false);
  return (
    <div className="fontWeight--bold padding--y--s brand-hover account-row card-layout">
      <Row>
        <Row.Item>
          <span
            onClick={() => {
              setViewBeneficiariesDetail(!viewBeneficiariesDetail);
            }}
            role="button"
            tabIndex="0"
            onKeyUp={({ key }) => {
              if (key === "Enter") {
                setViewBeneficiariesDetail(!viewBeneficiariesDetail);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            {account.name}
            <span
              className={`chevron narmi-icon-chevron-${
                viewBeneficiariesDetail ? "up" : "down"
              }`}
            />
          </span>
        </Row.Item>
      </Row>
      {viewBeneficiariesDetail && (
        <AccountBeneficiaries
          account={account}
          setViewBeneficiariesDetail={setViewBeneficiariesDetail}
          allBeneficiaries={allBeneficiaries}
          fetchAllBeneficiaries={fetchAllBeneficiaries}
          fetchBeneficiariesForAccount={fetchBeneficiariesForAccount}
          deleteBeneficiary={deleteBeneficiary}
          addBeneficiary={addBeneficiary}
          supportPhoneNumber={supportPhoneNumber}
        />
      )}
    </div>
  );
};

AccountRow.propTypes = {
  account: PropTypes.object,
  allBeneficiaries: PropTypes.array,
  fetchAllBeneficiaries: PropTypes.func,
  fetchBeneficiariesForAccount: PropTypes.func,
  deleteBeneficiary: PropTypes.func,
  addBeneficiary: PropTypes.func,
  supportPhoneNumber: PropTypes.string,
};

const AccountsTable = ({ token, secret, supportPhoneNumber }) => {
  const { l10n } = useLocalization();
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allAccountBeneficiaries, setAllAccountBeneficiaries] = useState([]);
  const { sendNotification } = useContext(NotificationContext);

  const simplifyBene = (beneficiary) => ({
    displayName: `${beneficiary.first_name} ${beneficiary.last_name}`,
    value: beneficiary.core_user_id,
  });

  const fetchAllBeneficiaries = async () => {
    const newBeneficiaries = [];
    try {
      const response = await ApiHttp.fetch("beneficiaries", {
        method: "GET",
        token,
        secret,
      });
      if (response.beneficiaries) {
        response.beneficiaries.forEach((beneficiary) => {
          newBeneficiaries.push(simplifyBene(beneficiary));
        });
        setAllAccountBeneficiaries(newBeneficiaries);
      }
    } catch (error) {
      sendNotification({
        type: "negative",
        text: `${error}`,
      });
    }
  };

  const fetchAccounts = async () => {
    try {
      setIsLoading(true);
      const response = await ApiHttp.fetch("accounts", {
        method: "GET",
        token,
        secret,
      });
      if (response.accounts) {
        setAccounts(
          response.accounts
            .map(Account.deserialize)
            .filter((a) => a.isDeposit() && a.isInternal())
        );
      }
    } catch {
      sendNotification({
        type: "negative",
        text: l10n.getString("error-account-fetch"),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBeneficiariesForAccount = async (
    account,
    setAccountBeneficiaries
  ) => {
    /* fetches beneficiaries for one particular account */
    try {
      const response = await ApiHttp.fetch(
        "beneficiaries",
        { method: "GET", token, secret },
        { account_uuid: account.id }
      );
      setAccountBeneficiaries(response.beneficiaries || []);
    } catch {
      setAccountBeneficiaries([]);
      sendNotification({
        type: "negative",
        text: l10n.getString("error-beneficiaries-fetch"),
      });
    }
  };

  const deleteBeneficiary = async (
    account,
    beneficiary,
    setAccountBeneficiaries
  ) => {
    await ApiHttp.fetch(
      "beneficiaries",
      { method: "DELETE", token, secret },
      { account_uuid: account.id, core_user_id: beneficiary.core_user_id }
    );
    fetchBeneficiariesForAccount(account, setAccountBeneficiaries);
    fetchAllBeneficiaries();
    sendNotification({
      type: "success",
      text: l10n.getString("notification-beneficiary-removed"),
    });
  };

  const addBeneficiary = (account, payload, setAccountBeneficiaries) =>
    ApiHttp.fetch(
      "beneficiaries",
      { method: "POST", token, secret },
      payload
    ).then(() => {
      fetchBeneficiariesForAccount(account, setAccountBeneficiaries);
      fetchAllBeneficiaries();
      sendNotification({
        type: "success",
        text: l10n.getString("notification-beneficiary-added"),
      });
    });

  useEffect(() => {
    fetchAccounts();
    fetchAllBeneficiaries();
  }, []);

  return (
    <SectionCard isLoading={isLoading} paddingSize="s">
      {accounts.length > 0 ? (
        accounts.map((account) => (
          <AccountRow
            key={account.id}
            account={account}
            allBeneficiaries={allAccountBeneficiaries}
            fetchAllBeneficiaries={fetchAllBeneficiaries}
            fetchBeneficiariesForAccount={fetchBeneficiariesForAccount}
            deleteBeneficiary={deleteBeneficiary}
            addBeneficiary={addBeneficiary}
            supportPhoneNumber={supportPhoneNumber}
          />
        ))
      ) : (
        <div className="ui info message margin--y--xl">
          <div className="ui center aligned container">
            <span className="title">
              {l10n.getString("beneficiary-account-ineligible")}
            </span>
          </div>
        </div>
      )}
    </SectionCard>
  );
};
AccountsTable.propTypes = {
  token: PropTypes.string,
  secret: PropTypes.string,
  supportPhoneNumber: PropTypes.string,
};

const BeneficiariesContainer = ({ token, secret, supportPhoneNumber }) => {
  const { l10n } = useLocalization();
  return (
    <>
      <h1>{l10n.getString("heading-beneficiaries")}</h1>
      <p>{l10n.getString("beneficiaries-allocation")}</p>
      <div className="beneficiaries-page-layout">
        <AccountsTable
          token={token}
          secret={secret}
          supportPhoneNumber={supportPhoneNumber}
        />
      </div>
    </>
  );
};

BeneficiariesContainer.propTypes = {
  token: PropTypes.string,
  secret: PropTypes.string,
  supportPhoneNumber: PropTypes.string,
};

export default BeneficiariesContainer;
