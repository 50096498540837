import React from "react";
import PropTypes from "prop-types";
import { useFormData } from "cerulean";
import User from "byzantine/src/User";
import { InstitutionSettingsContextProvider } from "../../../../contexts/InstitutionSettingsContext";
import { UserFeaturesContextProvider } from "../../../../contexts/UserFeaturesContext";
import { CurrentUserContextProvider } from "../../../../contexts/CurrentUserContext";
import AddCardForm from "./AddCardForm";

const AddCardNafApp = ({ userUuid, orgUuid, token, secret }) => {
  const { formData, setFormData, onChange } = useFormData({});
  const currentUser = new User({ uuid: userUuid, org_uuid: orgUuid });

  return (
    <UserFeaturesContextProvider>
      <InstitutionSettingsContextProvider>
        <CurrentUserContextProvider currentUser={currentUser}>
          <div className="margin--left--xs margin--right--xs margin--bottom--xl">
            <AddCardForm
              token={token}
              secret={secret}
              shouldHideAllToasts={true}
              formData={formData}
              setFormData={setFormData}
              onChange={onChange}
              onSuccess={(newCard) => {
                const { id } = newCard;
                window.location.assign(
                  `com.add-card.naf://success?cardUuid=${id}`
                );
              }}
              onError={(errorMessage) =>
                window.location.assign(
                  `com.add-card.naf://error?message=${errorMessage}`
                )
              }
            />
          </div>
        </CurrentUserContextProvider>
      </InstitutionSettingsContextProvider>
    </UserFeaturesContextProvider>
  );
};
AddCardNafApp.propTypes = {
  userUuid: PropTypes.string,
  orgUuid: PropTypes.string,
  token: PropTypes.string,
  secret: PropTypes.string,
};

export default AddCardNafApp;
