import React from "react";
import PropTypes from "prop-types";
import { Pagination } from "@narmi/design_system";

export default function PageNavigation({ pagination, marginClasses }) {
  /* need to set pagination.page as the defaultSelectedPage
  otherwise the displayed selected page in the page navigator
  will be 1 instead of the actual current page */
  if (pagination.getNumPages() <= 1) return null;

  return (
    <div className={marginClasses}>
      <Pagination
        onPageChange={pagination.navigatePage}
        defaultSelectedPage={pagination.page}
        totalPages={pagination.getNumPages()}
      />
    </div>
  );
}

PageNavigation.propTypes = {
  pagination: PropTypes.object.isRequired,
  marginClasses: PropTypes.string,
};
