import React from "react";
import PropTypes from "prop-types";
import App from "byzantine/src/App";
import SectionCard from "../SectionCard";
import AppAuthorize from "../AppAuthorize";

const ServiceItem = ({ service }) => (
  <div className="dashboard-item-border-box">
    <div className="brand-hover">
      <AppAuthorize app={service} dataClassnames="services-button">
        <div className="dashboard-item">
          <div className="dashboard-item-subsection dashboard-item-icon-section">
            <span className={service.getNdsIconClassname()}></span>
          </div>
          <div className="dashboard-item-subsection dashboard-item-text-section">
            <div className="dashboard-item-name">{service.name}</div>
            <div className="dashboard-item-description">
              {service.description}
            </div>
          </div>
        </div>
      </AppAuthorize>
    </div>
  </div>
);
ServiceItem.propTypes = {
  service: PropTypes.object,
};

const ServicesCard = ({ services }) => {
  if (services.length < 1) return null;
  return (
    <SectionCard isLoading={false} classNames="dashboard-section-card">
      <SectionCard.Title text="Services" className="services-title" />
      <div className="dashboard-section-container">
        {services.map((service) => (
          <ServiceItem key={service.id} service={service} />
        ))}
      </div>
    </SectionCard>
  );
};
ServicesCard.propTypes = {
  services: PropTypes.arrayOf(PropTypes.instanceOf(App)),
};

export default ServicesCard;
