var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContentCard, MenuButton, Button, Dialog, Row } from "cerulean";
import { entities, modules } from "byzantine";
import styles from "./Template.module.scss";
var Template = function (_a) {
    var template = _a.template, recipient = _a.recipient;
    var navigate = useNavigate();
    var _b = entities.fedwireTemplates.useDeleteTemplateRequest(), deleteTemplate = _b.deleteTemplate, loading = _b.loading;
    var _c = __read(useState(false), 2), isDeleteDialogOpen = _c[0], setIsDeleteDialogOpen = _c[1];
    var goToEdit = useCallback(function () {
        navigate("/wires/templates/edit/".concat(template.id));
    }, [navigate, template.id]);
    var destinationAccountPreview = modules.wireTemplates.getDestinationAccountPreview(recipient);
    return (_jsxs("div", { className: styles.template, children: [_jsx(ContentCard, { kind: "bordered", children: _jsxs("div", { className: styles.templateContent, children: [_jsxs("div", { className: styles.templateTopContainer, children: [_jsxs("div", { className: styles.templateNames, children: [_jsx("div", { className: styles.templateName, children: template.name }), _jsx("div", { children: recipient.name })] }), _jsx("div", { className: styles.templateMenu, children: _jsxs(MenuButton, { label: "template action menu", children: [_jsx(MenuButton.Item, { label: "Edit", startIcon: "edit-2", onSelect: goToEdit }), _jsx(MenuButton.Item, { label: "Delete", startIcon: "trash-2", onSelect: function () { return setIsDeleteDialogOpen(true); } })] }) })] }), _jsxs("div", { className: styles.templateInstitution, children: [_jsx("div", { className: styles.institutionLogo, children: _jsx("span", { className: "narmi-icon-bank" }) }), destinationAccountPreview] })] }) }, template.id), _jsx(Dialog, { isOpen: isDeleteDialogOpen, onUserDismiss: function () { return setIsDeleteDialogOpen(false); }, title: "Delete ".concat(template.name, "?"), width: "385px", footer: _jsxs(Row, { alignItems: "center", justifyContent: "end", children: [_jsx(Row.Item, { shrink: true, children: _jsx(Button, { onClick: function () { return setIsDeleteDialogOpen(false); }, kind: "negative", label: "Cancel", disabled: loading }) }), _jsx(Row.Item, { shrink: true, children: _jsx(Button, { onClick: function () { return deleteTemplate(template.id); }, kind: "primary", label: "Yes, delete", isLoading: loading, disabled: loading }) })] }), children: _jsxs("div", { style: { overflowWrap: "anywhere" }, children: ["Are you sure that you want to delete ", template.name, "?"] }) })] }));
};
export default Template;
