import ApiHttp from "./ApiHttp";
var Membership = /** @class */ (function () {
    function Membership(_a) {
        var id = _a.id;
        this.id = id;
    }
    Membership.prototype.getDescription = function () {
        return "*".concat(this.id.slice(-4));
    };
    Membership.prototype.delete = function () {
        return ApiHttp.fetch("accounts/membership/".concat(this.id), {
            method: "DELETE",
        });
    };
    /**
     * Factory method returning a new instance of Account from
     * an indigo.serialized Account
     * */
    Membership.deserialize = function (membershipId) {
        var membership = new Membership({
            id: membershipId,
        });
        return membership;
    };
    return Membership;
}());
export default Membership;
