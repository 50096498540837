import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import M2M from "byzantine/src/M2M";
import MemberFriend from "byzantine/src/MemberFriend";
import { ContextForm, useFormData, useNotificationContext } from "cerulean";
import M2MTransferForm from "./M2MTransferForm";
import useReviewableAction from "../useReviewableAction";
import M2MTransferActionReview from "./M2MTransferActionReview";
import useActions from "../hooks/useActions";

const M2MTransfer = () => {
  const [memberFriends, setMemberFriends] = useState([]);
  const [itempotencyKey, setItempotencyKey] = useState();
  const { sendNotification } = useNotificationContext();
  const { formData, onChange } = useFormData({});
  const { isInput, goToInput, goToReview } = useReviewableAction();
  const { goToPrevious } = useActions();

  const fetchMemberFriends = async () => {
    MemberFriend.getMemberFriends()
      .then((mfs) => {
        setMemberFriends(mfs);
      })
      .catch(() => {
        sendNotification({
          type: "negative",
          text: "Unable to retrieve your member friends, please try again.",
        });
      });
  };

  useEffect(() => {
    fetchMemberFriends();
  }, []);

  const onSubmit = (callback) => {
    const schedule = new M2M({
      amount: formData.amount,
      from_account_id: formData.from_account_id,
      to_member_id: formData.to_member_id,
      memo: formData.memo,
      itempotencyKey,
    });

    schedule
      .submit()
      .then(() => {
        goToPrevious("success=Transfer+sent.");
      })
      .catch((error) => {
        callback(error);
        goToInput();
      })
      .finally(() => {
        setItempotencyKey(uuidv4());
      });
  };

  return (
    <ContextForm data={formData} onChange={onChange}>
      {isInput ? (
        <M2MTransferForm
          memberFriends={memberFriends}
          setMemberFriends={setMemberFriends}
          data={formData}
          onChange={onChange}
          onSubmit={goToReview}
          cancel={goToPrevious}
        />
      ) : (
        <M2MTransferActionReview
          memberFriends={memberFriends}
          data={formData}
          onSubmit={onSubmit}
          goBack={goToInput}
          cancel={goToPrevious}
        />
      )}
    </ContextForm>
  );
};

export default M2MTransfer;
