var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as Yup from "yup";
import { cents } from "../../../../utils";
import { createForm } from "../../../forms";
var validationSchema = Yup.object().shape({
    fedwireTemplate: Yup.object().nullable().required("Required"),
    fedwireTemplateId: Yup.string().required("Required"),
    amount: Yup.number().when("fedwireTemplate", function (_a, schema) {
        var _b = __read(_a, 1), fedwireTemplate = _b[0];
        if (!(fedwireTemplate === null || fedwireTemplate === void 0 ? void 0 : fedwireTemplate.amount)) {
            return Yup.number()
                .integer("Please enter a number.")
                .positive("Please enter a positive amount.")
                .required("Required");
        }
        return schema;
    }),
    memo: Yup.string().when("fedwireTemplate", function (_a, schema) {
        var _b = __read(_a, 1), fedwireTemplate = _b[0];
        if (!(fedwireTemplate === null || fedwireTemplate === void 0 ? void 0 : fedwireTemplate.memo)) {
            return Yup.string()
                .min(1)
                .max(140, "Memo cannot exceed 140 characters")
                .required("Required");
        }
        return schema;
    }),
    wireReason: Yup.string().when("fedwireTemplate", function (_a, schema) {
        var _b = __read(_a, 1), fedwireTemplate = _b[0];
        if (!(fedwireTemplate === null || fedwireTemplate === void 0 ? void 0 : fedwireTemplate.wire_reason)) {
            return Yup.string().required("Required");
        }
        return schema;
    }),
});
var initialValues = {
    fedwireTemplateId: "",
    fedwireTemplate: undefined,
    amount: cents(0),
    wireReason: "",
    memo: "",
};
export var sendViaTemplateForm = createForm({
    initialValues: initialValues,
    validationSchema: validationSchema,
});
