var _a;
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    isReady: false,
    featureOverrides: {},
};
var name = "endToEndTest";
var slice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        setReady: function (state, action) {
            state.isReady = action.payload;
        },
        setFeatures: function (state, action) {
            state.featureOverrides = action.payload;
        },
    },
});
export var actions = slice.actions;
export default (_a = {},
    _a[name] = slice.reducer,
    _a);
