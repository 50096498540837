import React from "react";
import PropTypes from "prop-types";
import { useNotificationContext, useLoadingContext } from "cerulean";
import { Tabs } from "@narmi/design_system";
import Filters from "byzantine/src/filters";

import SignedUrlIframe from "../SignedUrlIframe";

const ServicePage = ({
  contentParams,
  tabsParsed,
  tabs,
  selectedTab,
  templateTag,
}) => {
  const { setIsLoading } = useLoadingContext();
  const { sendNotification } = useNotificationContext();

  if (contentParams.fullWidth) {
    return (
      <div className="full">
        <SignedUrlIframe
          endpoint={`signed_urls/${contentParams.service}/${contentParams.resource}/${contentParams.id}`}
          height="600px"
          setLoading={setIsLoading}
          sendNotification={sendNotification}
        />
      </div>
    );
  }

  return tabs.length > 1 ? (
    <Tabs
      selectedIndex={tabs.indexOf(selectedTab)}
      onTabChange={(key) => {
        window.location.href = `/${templateTag.split(":")[1]}/${tabs[key]}`;
      }}
    >
      <Tabs.List>
        {Object.keys(tabsParsed).map((key, index) => (
          <Tabs.Tab key={index} label={Filters.humanize(key)} tabId={key} />
        ))}
      </Tabs.List>
      <Tabs.Panel tabId={selectedTab}>
        <SignedUrlIframe
          endpoint={`signed_urls/${contentParams.service}/${contentParams.resource}/${contentParams.id}`}
          height="600px"
          setLoading={setIsLoading}
          sendNotification={sendNotification}
        />
        -{" "}
      </Tabs.Panel>
    </Tabs>
  ) : (
    <SignedUrlIframe
      endpoint={`signed_urls/${contentParams.service}/${contentParams.resource}/${contentParams.id}`}
      height="600px"
      setLoading={setIsLoading}
      sendNotification={sendNotification}
    />
  );
};

ServicePage.propTypes = {
  contentParams: PropTypes.object,
  tabs: PropTypes.array,
  tabsParsed: PropTypes.string,
  templateTag: PropTypes.string,
  selectedTab: PropTypes.string,
};

const ServicePageContainer = ({
  contentParamsJson,
  tabsJson,
  templateTag,
  selectedTabJson,
}) => {
  const contentParams = JSON.parse(contentParamsJson);
  const tabsParsed = JSON.parse(tabsJson);
  const tabs = Object.keys(tabsParsed).map((key) => key);
  const selectedTab = JSON.parse(selectedTabJson);

  return (
    <div className="service-page-container">
      <ServicePage
        contentParams={contentParams}
        tabs={tabs}
        tabsParsed={tabsParsed}
        selectedTab={selectedTab}
        templateTag={templateTag}
      />
    </div>
  );
};

ServicePageContainer.propTypes = {
  contentParamsJson: PropTypes.string,
  tabsJson: PropTypes.string,
  selectedTabJson: PropTypes.string,
  templateTag: PropTypes.string,
};

export default ServicePageContainer;
