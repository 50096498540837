import { createContext } from "react";
import { PlatformContext } from "../interfaces/platform.interface";
import { NetworkContext } from "../interfaces/network.interface";
import { ToastsContext } from "../interfaces/toasts.interface";
import { ConfigContext } from "../interfaces/config.interface";
export var contextMap = {
    platform: PlatformContext,
    network: NetworkContext,
    toasts: ToastsContext,
    config: ConfigContext,
    store: createContext(undefined),
};
