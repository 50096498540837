import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFluentLocalization, LangSelect, LoadingShim } from "cerulean";
import { LocalizationProvider } from "@fluent/react";
var AppLocalizationProvider = function (_a) {
    var internalName = _a.internalName, s3ImagesBucket = _a.s3ImagesBucket, children = _a.children, hasLangSelect = _a.hasLangSelect;
    var _b = useFluentLocalization({
        internalName: internalName,
        s3ImagesBucket: s3ImagesBucket,
        productCode: "olb",
        apiUrl: "/v1/",
        isMfe: true, // azul is a "microfrontend" due to its multiple React roots
    }), l10n = _b.l10n, isLoading = _b.isLoading, isMultilingual = _b.isMultilingual, localeCodes = _b.localeCodes, localeNames = _b.localeNames, selectedLocale = _b.selectedLocale, setSelectedLocale = _b.setSelectedLocale;
    return isLoading ? (_jsx(LoadingShim, { isLoading: true, children: _jsx("div", { style: { height: "100vh" } }) })) : (_jsxs(_Fragment, { children: [_jsx(LocalizationProvider, { l10n: l10n, children: children }), hasLangSelect && isMultilingual && (_jsx("div", { className: "padding--y--l padding--x--xl bgColor--smokeGrey", children: _jsx(LangSelect, { localeCodes: localeCodes, localeNames: localeNames, selectedLocale: selectedLocale, onChange: function (lang) {
                        setSelectedLocale(lang);
                    } }) }))] }));
};
export default AppLocalizationProvider;
