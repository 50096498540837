import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import { useTranslation } from "react-i18next";
import { Button } from "@narmi/design_system";
import Account from "byzantine/src/Account";
import utils from "../../utils";
import InformationRow from "../InformationRow";
import SectionCard from "../SectionCard";

const ExpandButton = ({ isExpanded, setIsExpanded, canBeExpanded = true }) =>
  canBeExpanded ? (
    <Button
      kind="plain"
      onClick={() => setIsExpanded(!isExpanded)}
      label={isExpanded ? "Hide details" : "More details"}
    />
  ) : null;
ExpandButton.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
  canBeExpanded: PropTypes.bool.isRequired,
};

export const translateAccountDetailsLabel = (label, l10n, t) => {
  // apply django-style translations first (lets them override fluent translations)
  const djangoTranslated = t(label);
  // if the label is empty, return a hard coded empty string
  if (label.length < 1 || djangoTranslated.length < 1) {
    return "";
  }
  // account details fluent ids are kebabcase prepended by account-detail
  const fluentId = `account-detail-${djangoTranslated
    .replace(/\s+/g, "-")
    .toLowerCase()}`;
  return l10n.getString(fluentId, null, djangoTranslated);
};

const AccountMetadata = ({ metadata }) =>
  Array.isArray(metadata) ? (
    metadata
      .filter(({ label }) => !!label)
      .map(({ label, value }) => (
        <InformationRow key={label} label={label} value={value} />
      ))
  ) : (
    <InformationRow label={metadata?.label} value={metadata?.value} />
  );
AccountMetadata.propTypes = {
  metadata: PropTypes.array,
};

const AccountDetailsCard = ({ account }) => {
  const { t } = useTranslation();
  const { l10n } = useLocalization();
  const [isExpanded, setIsExpanded] = useState(false);

  let title = null;
  if (
    ["checking", "savings", "money_market", "certificate_of_deposit"].includes(
      account.product_type
    )
  ) {
    title = "Account details";
  } else if (account.product_type === "loan") {
    title = "Loan details";
  } else if (account.product_type === "line_of_credit") {
    title = "Line of credit details";
  } else if (account.product_type === "credit_card") {
    title = "Account details";
  } else {
    title = "Details";
  }

  const { defaultDetails, expandedDetails } = account.accountDetails(
    utils.getNDSFormatters()
  );

  return (
    <SectionCard>
      <SectionCard.Title
        text={title}
        button={
          <ExpandButton
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            canBeExpanded={expandedDetails.length > 0}
          />
        }
      />
      <div>
        <AccountMetadata
          metadata={defaultDetails.map((detail) => ({
            label: translateAccountDetailsLabel(detail?.label, l10n, t),
            value: detail?.value,
          }))}
        />
        {isExpanded && (
          <AccountMetadata
            metadata={expandedDetails.map((detail) => ({
              label: translateAccountDetailsLabel(detail?.label, l10n, t),
              value: detail?.value,
            }))}
          />
        )}
      </div>
    </SectionCard>
  );
};
AccountDetailsCard.propTypes = {
  account: PropTypes.instanceOf(Account).isRequired,
};

export default AccountDetailsCard;
