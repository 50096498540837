export var API = {
    fetchTemplates: function () {
        return {
            url: "fedwire_templates/",
            options: {
                method: "GET",
            },
        };
    },
    deleteTemplate: function (fedwireTemplateId) {
        return {
            url: "fedwire_templates/".concat(fedwireTemplateId, "/"),
            options: {
                method: "DELETE",
                isJsonResponse: false,
            },
        };
    },
};
