import { matchRoutes, useLocation } from "react-router-dom";
var DRAWER_ROUTES = [
    { path: "/wires/templates/new" },
    { path: "/wires/templates/edit/:templateId" },
    { path: "/wires/templates/recipients/new" },
    { path: "/wires/templates/recipients/edit/:recipientId" },
];
var useIsTemplateDrawerRoute = function () {
    var location = useLocation();
    return (matchRoutes(DRAWER_ROUTES, location === null || location === void 0 ? void 0 : location.pathname) || []).length > 0;
};
export default useIsTemplateDrawerRoute;
