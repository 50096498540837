import { useCallback } from "react";
import { useSelector } from "react-redux";
import { selectInstitution } from "../selectors/institution.selectors";
import { useRequestWithFeedback } from "../../../composites/request/request.hooks";
import { useAppDispatch } from "../utils/useAppDispatch.hook";
import { useObjectMemo } from "../../../hooks";
import { actions } from "../slices/institution.slice";
export var useInstitution = function () {
    return useSelector(selectInstitution);
};
export var useFetchInstitution = function () {
    var _a = useRequestWithFeedback(), send = _a.send, loading = _a.loading, status = _a.status;
    var dispatch = useAppDispatch();
    var fetch = useCallback(function (type) {
        if (type === void 0) { type = "private"; }
        return new Promise(function (resolve) {
            send({
                action: {
                    url: "institution/",
                    options: {
                        method: "GET",
                        disableAuth: type === "public",
                    },
                },
                onData: function (_a) {
                    var institution = _a.institution;
                    dispatch(actions.upsert(institution));
                    resolve(institution);
                },
                onError: function () {
                    resolve(undefined);
                },
            });
        });
    }, [dispatch, send]);
    var institution = useInstitution();
    return useObjectMemo({
        send: fetch,
        loading: loading,
        error: status === "error",
        hasData: institution !== null,
    });
};
export var useInstitutionHelpers = function () {
    var dispatch = useAppDispatch();
    var updateAuthenticatingInstitutionApp = useCallback(function (app) {
        dispatch(actions.updateAuthenticatingInstitutionApp(app));
    }, [dispatch]);
    return useObjectMemo({
        updateAuthenticatingInstitutionApp: updateAuthenticatingInstitutionApp,
    });
};
