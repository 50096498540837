import React from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownListItem } from "cerulean";
import Filters from "byzantine/src/filters";

const displayValue = (value) =>
  Filters.capitalizeFirstLetter(value === "sms" ? "text" : value);

const NotificationTypeSelector = ({ onChange, value, ...props }) => (
  <Dropdown
    triggerLabel="Alert type"
    triggerValue={displayValue(value)}
    {...props}
  >
    {["email", "sms"].map((notificationType) => (
      <DropdownListItem
        key={notificationType}
        anyItemSelected={!!value}
        isSelected={value === notificationType}
        onClick={() => onChange(notificationType)}
      >
        {displayValue(notificationType)}
      </DropdownListItem>
    ))}
  </Dropdown>
);
NotificationTypeSelector.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default NotificationTypeSelector;
