import { useContext } from "react";
import { contextMap } from "./library.context";
var ambiguouslyTypedContextMap = contextMap;
export function useLibrary(libraryName) {
    var context = ambiguouslyTypedContextMap[libraryName];
    var library = useContext(context);
    if (!library) {
        throw new Error("Context error: ".concat(libraryName, " is undefined. You likely forgot to pass the interface implementation to <LibraryProvider/>."));
    }
    return library;
}
