import ApiHttp from "./ApiHttp";
var Institution = /** @class */ (function () {
    function Institution() {
    }
    Institution.prototype.getSettings = function () {
        if (Institution.settingsPromise === undefined) {
            Institution.settingsPromise = ApiHttp.fetch("institution", {
                method: "GET",
            }).then(function (response) {
                return response.institution;
            });
        }
        return Institution.settingsPromise;
    };
    return Institution;
}());
export default Institution;
