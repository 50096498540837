import * as Yup from "yup";
import { createForm } from "../../../forms";
var validationSchema = Yup.object().shape({
    wireRecipientId: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    sourceAccountId: Yup.string().required("Required"),
    wireReason: Yup.string(),
    amount: Yup.number().integer("Please enter a number."),
    memo: Yup.string().min(1).max(140, "Memo cannot exceed 140 characters"),
});
var initialValues = {
    wireRecipientId: "",
    name: "",
    sourceAccountId: "",
    wireReason: undefined,
    amount: undefined,
    memo: undefined,
};
export var transformApiTemplateToForm = function (_a) {
    var wireRecipientId = _a.recipient, sourceAccountId = _a.source_account, name = _a.name, amount = _a.amount, wireReason = _a.wire_reason, memo = _a.memo;
    return {
        wireRecipientId: wireRecipientId,
        sourceAccountId: sourceAccountId,
        name: name,
        amount: amount || undefined,
        wireReason: wireReason || undefined,
        memo: memo || undefined,
    };
};
export var upsertTemplateForm = createForm({
    initialValues: initialValues,
    validationSchema: validationSchema,
});
