import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Button } from "cerulean";
import { modules } from "byzantine";
import Template from "./Template";
import BackButton from "./BackButton";
import TemplateDrawer from "./TemplateDrawer";
import useIsTemplateDrawerRoute from "./TemplateDrawer/useIsTemplateDrawerRoute";
import styles from "./TemplatesList.module.scss";
var TemplatesList = function () {
    var templatesWithRecipients = modules.wireTemplates.useData().templatesWithRecipients;
    var navigate = useNavigate();
    var isDrawerRoute = useIsTemplateDrawerRoute();
    var showManageTemplatesUI = modules.wireTemplates.useWirePermissions().showManageTemplatesUI;
    var goBack = useCallback(function () {
        navigate("/wires");
    }, [navigate]);
    if (!showManageTemplatesUI) {
        return _jsx(Navigate, { to: "/", replace: true });
    }
    return (_jsx("div", { className: "content-container", children: _jsx("div", { className: "narmi-grid-container", children: _jsxs("div", { className: "narmi-grid-content", children: [_jsx("div", { className: styles.backButtonContainer, children: _jsx(BackButton, { text: "Back to wires", onClick: goBack }) }), _jsx("h1", { className: styles.header, children: "Templates" }), _jsxs("div", { className: styles.templateList, children: [templatesWithRecipients.length === 0 && (_jsx("div", { children: "You don\u2018t have any templates yet." })), templatesWithRecipients.map(function (_a) {
                                var template = _a.template, recipient = _a.recipient;
                                return (_jsx(Template, { template: template, recipient: recipient }, template.id));
                            })] }), _jsx(Button, { kind: "primary", onClick: function () {
                            navigate("new");
                        }, children: "+ New template" }), _jsx(TemplateDrawer, { isOpen: isDrawerRoute })] }) }) }));
};
export default TemplatesList;
