var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Account from "./Account";
import Address from "./Address";
import ApiHttp from "./ApiHttp";
var WireRecipient = /** @class */ (function () {
    function WireRecipient(_a) {
        var id = _a.id, name = _a.name, address = _a.address, routing_number = _a.routing_number, account_number = _a.account_number, created_at = _a.created_at, destination_institution_name = _a.destination_institution_name;
        this.id = id;
        this.name = name;
        this.address = new Address(address);
        this.routing_number = routing_number;
        this.account_number = account_number;
        this.created_at = created_at;
        this.destination_institution_name = destination_institution_name;
    }
    Object.defineProperty(WireRecipient.prototype, "maskedAccountNumber", {
        get: function () {
            return [
                "**",
                new Account({ number: this.account_number }).getMaskedNumber(),
            ].join("");
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WireRecipient.prototype, "bankDetails", {
        get: function () {
            return "".concat(this.destination_institution_name, "-").concat(this.maskedAccountNumber);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WireRecipient.prototype, "displayValue", {
        get: function () {
            return "".concat(this.name, " - ").concat(new Account({
                number: this.account_number,
            }).getMaskedNumber());
        },
        enumerable: false,
        configurable: true
    });
    WireRecipient.fetchAll = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ApiHttp.fetch("wire_recipients/")];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.results.map(function (r) {
                                return WireRecipient.deserialize(r);
                            })];
                }
            });
        });
    };
    WireRecipient.create = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ApiHttp.fetch("wire_recipients/", { method: "POST" }, payload)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, [WireRecipient.deserialize(response), response]];
                }
            });
        });
    };
    WireRecipient.prototype.update = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ApiHttp.fetch("wire_recipients/".concat(this.id, "/"), { method: "PUT" }, this)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    WireRecipient.deserialize = function (payload) {
        var wireRecipient = new WireRecipient(payload);
        return wireRecipient;
    };
    WireRecipient.prototype.delete = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ApiHttp.fetch("wire_recipients/".concat(this.id), {
                            method: "DELETE",
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    WireRecipient.prototype.serialize = function () {
        return {
            id: this.id,
            name: this.name,
            address: {
                street_address: this.address.street_address,
                street_address_2: this.address.street_address_2,
                city: this.address.city,
                region_code: this.address.region_code,
                postal_code: this.address.postal_code,
                country_code: this.address.country_code,
            },
            routing_number: this.routing_number,
            account_number: this.account_number,
            created_at: this.created_at,
            destination_institution_name: this.destination_institution_name,
        };
    };
    return WireRecipient;
}());
export default WireRecipient;
