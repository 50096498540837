import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Drawer } from "cerulean";
import { modules } from "byzantine";
import RecipientForm from "./RecipientForm";
import TemplateForm from "./TemplateForm";
var TemplateDrawer = function (_a) {
    var isOpen = _a.isOpen;
    var navigate = useNavigate();
    var location = useLocation();
    return (_jsx(Drawer, { isOpen: isOpen, paddingSize: "none", onUserDismiss: function () {
            var _a, _b;
            if ((_a = location.state) === null || _a === void 0 ? void 0 : _a.from.includes("/wires/templates")) {
                navigate((_b = location.state) === null || _b === void 0 ? void 0 : _b.from);
            }
            else {
                navigate("/wires/templates");
            }
        }, showControls: false, depth: "633px", children: _jsx(modules.wireTemplates.upsertTemplateForm.Provider, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/new", element: _jsx(TemplateForm, {}) }), _jsx(Route, { path: "/edit/:templateId", element: _jsx(TemplateForm, {}) }), _jsx(Route, { path: "/recipients/new", element: _jsx(RecipientForm, {}) })] }) }) }));
};
export default TemplateDrawer;
