import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const SelectedDeviceContext = createContext({});

export const SelectedDeviceContextProvider = ({ children }) => {
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [helpMessage, setHelpMessage] = useState("");
  return (
    <SelectedDeviceContext.Provider
      value={{
        selectedDeviceId,
        setSelectedDeviceId,
        helpMessage,
        setHelpMessage,
      }}
    >
      {children}
    </SelectedDeviceContext.Provider>
  );
};
SelectedDeviceContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useSelectedDeviceContext = () => useContext(SelectedDeviceContext);

export default SelectedDeviceContext;
