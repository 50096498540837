import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useMemo } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { PlatformContext } from "../interfaces/platform.interface";
import { NetworkContext } from "../interfaces/network.interface";
import { ToastsContext } from "../interfaces/toasts.interface";
import ConfigProvider from "../interfaces/config.interface";
import { setupStore } from "../businessLogic/entities";
import BackgroundActions from "./backgroundActions";
function Provider(_a) {
    var platform = _a.platform, network = _a.network, toasts = _a.toasts, passedStore = _a.store, preloadedState = _a.preloadedState, config = _a.config, children = _a.children;
    var store = useMemo(function () {
        return passedStore || setupStore(preloadedState);
    }, [passedStore, preloadedState]);
    return (_jsx(ConfigProvider, { config: config, children: _jsx(PlatformContext.Provider, { value: platform, children: _jsx(NetworkContext.Provider, { value: network, children: _jsx(ToastsContext.Provider, { value: toasts, children: _jsxs(ReduxProvider, { store: store, children: [_jsx(BackgroundActions, {}), children] }) }) }) }) }));
}
export default memo(Provider);
