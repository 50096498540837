import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cc from "classcat";
import { useLocalization } from "@fluent/react";
import { useCurrentUser } from "../contexts/CurrentUserContext";
var AddressTitle = function (_a) {
    var noMailing = _a.noMailing, address = _a.address, _b = _a.aligned, aligned = _b === void 0 ? "right" : _b;
    var currentUser = useCurrentUser().currentUser;
    var l10n = useLocalization().l10n;
    var addressClasses = cc([
        "profile-field-value",
        "pulled",
        { right: aligned === "right", left: aligned === "left" },
    ]);
    if (noMailing) {
        return (_jsx("span", { className: addressClasses, children: (currentUser === null || currentUser === void 0 ? void 0 : currentUser.isBusiness())
                ? l10n.getString("label-same-business-address", null, "Same as business address")
                : l10n.getString("label-same-residential-address", null, "Same as residential address") }));
    }
    if (!address) {
        return null;
    }
    return address.street_address_2 ? (_jsxs("span", { className: addressClasses, children: [_jsxs("div", { children: [address.street_address, ", ", address.street_address_2] }), _jsxs("div", { children: [address.city, ", ", address.region_code, " ", address.postal_code] })] })) : (_jsxs("span", { className: addressClasses, children: [_jsx("div", { children: address.street_address }), _jsxs("div", { children: [address.city, ", ", address.region_code, " ", address.postal_code] })] }));
};
export default AddressTitle;
