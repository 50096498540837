import * as Yup from "yup";
import { createForm } from "../../../forms";
var validationSchema = Yup.object().shape({
    method: Yup.string().required("Required"),
});
var initialValues = {
    method: "",
};
export var methodSelectorForm = createForm({
    initialValues: initialValues,
    validationSchema: validationSchema,
});
