import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { DateTime } from "luxon";
import { useLocalization } from "@fluent/react";
import { BackCancelButton, Button, ContextForm, FormHeaderWithIcon, } from "cerulean";
import { FREQUENCIES } from "byzantine/src/Recurrence";
import AccountContext from "../../../contexts/AccountContext";
import { TRANSLATED_FREQUENCIES } from "../../FrequencySelector";
import LoanReviewRow from "../LoanReviewRow";
import { PaymentType } from "./LoanPaydownForm";
var LoanPaydownActionReview = function (_a) {
    var data = _a.data, goBack = _a.goBack, cancel = _a.cancel, onSubmit = _a.onSubmit;
    var l10n = useLocalization().l10n;
    var accounts = useContext(AccountContext).accounts;
    var fromAccount = accounts.find(function (a) { return a.id === data.from_account_id; });
    var loanAccount = accounts.find(function (a) { return a.id === data.to_account_id; });
    var displayedPaymentType = l10n.getString("loan-paydown-payment-title", {
        loan: (loanAccount === null || loanAccount === void 0 ? void 0 : loanAccount.getShortDescription()) || "",
    });
    var displayedDate = DateTime.fromFormat(data.date, "M/d/yyyy").toFormat("MM/dd/yyyy");
    var displayedFrequency = data.frequency;
    if (data.payment_type === PaymentType.Principal) {
        displayedPaymentType = l10n.getString("loan-paydown-principal-payment-title", { loan: (loanAccount === null || loanAccount === void 0 ? void 0 : loanAccount.getShortDescription()) || "" });
        displayedDate = DateTime.now().toFormat("MM/dd/yyyy");
        displayedFrequency = FREQUENCIES.ONCE;
    }
    var dateLabel = l10n.getString("label-review-date-once");
    if (data.payment_type !== PaymentType.Principal &&
        data.frequency !== FREQUENCIES.ONCE) {
        dateLabel = l10n.getString("label-review-date-recurring");
    }
    return (_jsxs("div", { className: "loan-payment-page", children: [_jsx(FormHeaderWithIcon, { headerText: l10n.getString("loan-paydown-review-page-heading"), onBack: goBack }), _jsxs("div", { className: "loan-payment-form-card", children: [_jsx("h4", { className: "nds-sans", children: displayedPaymentType }), _jsxs("div", { className: "margin--top--xl", children: [_jsx(LoanReviewRow, { label: l10n.getString("label-from"), content: fromAccount === null || fromAccount === void 0 ? void 0 : fromAccount.getShortDescription() }), _jsx(LoanReviewRow, { label: l10n.getString("label-amount"), content: data.amount }), displayedFrequency !== FREQUENCIES.ONCE && (_jsx(LoanReviewRow, { label: l10n.getString("label-frequency"), content: l10n.getString(TRANSLATED_FREQUENCIES[displayedFrequency]) })), _jsx(LoanReviewRow, { label: dateLabel, content: displayedDate })] })] }), _jsxs("div", { className: "alignChild--center--center form-buttons-with-spacing", children: [_jsx(BackCancelButton, { onBack: cancel, backLabel: l10n.getString("loan-paydown-cancel-button") }), _jsx(ContextForm.Action, { onSubmit: onSubmit, children: _jsx("div", { style: { marginLeft: "auto" }, children: _jsx(Button, { label: l10n.getString("loan-paydown-make-payment-button") }) }) })] })] }));
};
export default LoanPaydownActionReview;
