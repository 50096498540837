import { useMemo } from "react";

const DESTINATIONS = [
  "/",
  /^\/accounts\/[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89AB][0-9a-f]{3}-[0-9a-f]{12}$/i,
];
const isValidDestination = (destination) =>
  DESTINATIONS.some((validDestination) =>
    validDestination instanceof RegExp
      ? validDestination.test(destination)
      : validDestination === destination
  );

const useActions = () =>
  useMemo(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const previous = urlSearchParams.get("previous");
    return {
      goToPrevious: (queryParams = "") => {
        let destination = "/";
        if (previous && isValidDestination(previous)) destination = previous;
        window.location.assign(`${destination}?${queryParams}`);
      },
    };
  }, []);

export default useActions;
