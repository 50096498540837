/* global Accept */
const dispatchData = async (payload) =>
  new Promise((resolve, reject) => {
    // docs: https://developer.authorize.net/api/reference/features/acceptjs.html
    Accept.dispatchData(payload, (response) => {
      if (response.messages.resultCode === "Error") {
        reject(
          Error(
            response.messages.message.map((message) => message.text).join(" ")
          )
        );
      }
      resolve(response);
    });
  });

const getNonce = async (payload) => {
  const response = await dispatchData(payload);
  if (!response.opaqueData) {
    throw Error("Authorize.net API did not return a valid response");
  }
  return response.opaqueData.dataValue;
};

const ApiAuthorizenet = {
  getNonce,
};
export default ApiAuthorizenet;
