// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".membership-info-row--kYD2U{display:flex;justify-content:space-between;padding:var(--space-s) 0;border-bottom:1px solid RGB(var(--border-color))}.membership-info-row--kYD2U:last-of-type{border-bottom:none;padding-bottom:0px}.membership-info-row--kYD2U .left--QqsdY{padding-top:2px;font-weight:var(--font-weight-semibold)}.membership-info-row--kYD2U .right--QVyEu{display:flex;align-items:center}.membership-info-row--kYD2U .right--QVyEu .membership-id--mfaHV{padding-top:2px;padding-right:2px}", "",{"version":3,"sources":["webpack://./components/settings/profile_settings/linked_account_memberships/MembershipInfoRow/MembershipInfoRow.module.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CACA,6BAAA,CACA,wBAAA,CACA,gDAAA,CAEA,yCACE,kBAAA,CACA,kBAAA,CAGF,yCACE,eAAA,CACA,uCAAA,CAGF,0CACE,YAAA,CACA,kBAAA,CAEA,gEACE,eAAA,CACA,iBAAA","sourcesContent":[".membership-info-row {\n  display: flex;\n  justify-content: space-between;\n  padding: var(--space-s) 0;\n  border-bottom: 1px solid RGB(var(--border-color));\n\n  &:last-of-type {\n    border-bottom: none;\n    padding-bottom: 0px;\n  }\n\n  .left {\n    padding-top: 2px;\n    font-weight: var(--font-weight-semibold);\n  }\n\n  .right {\n    display: flex;\n    align-items: center;\n\n    .membership-id {\n      padding-top: 2px;\n      padding-right: 2px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"membership-info-row": "membership-info-row--kYD2U",
	"left": "left--QqsdY",
	"right": "right--QVyEu",
	"membership-id": "membership-id--mfaHV"
};
export default ___CSS_LOADER_EXPORT___;
