var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Button, ContentCard, LoadingSkeleton, Row, useLoadingContext, useNotificationContext, } from "cerulean";
import { MenuButton } from "@narmi/design_system";
import Payee from "byzantine/src/Payee";
import Filters from "byzantine/src/filters";
import AddPayeeModal from "../../transfer/AddPayeeModal";
import DeleteDialog from "./DeleteDialog";
var PayeeOptions = function (_a) {
    var openDeleteDialog = _a.openDeleteDialog;
    return (_jsx(MenuButton, { triggerIcon: "more-horizontal", children: _jsx(MenuButton.Item, { label: "Delete", startIcon: "trash-2", onSelect: openDeleteDialog }) }));
};
var PayeeCard = function (_a) {
    var payee = _a.payee, fetchPayees = _a.fetchPayees;
    var _b = __read(useState(false), 2), isDeletePayeeDialogOpen = _b[0], setIsDeletePayeeDialogOpen = _b[1];
    var sendNotification = useNotificationContext().sendNotification;
    var setIsLoading = useLoadingContext().setIsLoading;
    var deletePayee = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1, e, toastMessage;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsDeletePayeeDialogOpen(false);
                    setIsLoading(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, payee.delete()];
                case 2:
                    _b.sent();
                    setIsLoading(false);
                    sendNotification({
                        type: "success",
                        text: "Payee deleted.",
                    });
                    return [4 /*yield*/, fetchPayees()];
                case 3:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _b.sent();
                    e = error_1;
                    toastMessage = "Unable to delete payee, please try again.";
                    if (((_a = e === null || e === void 0 ? void 0 : e.reason) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === Payee.ERROR.PENDING_PAYMENTS_EXIST) {
                        toastMessage =
                            "You cannot delete this payee due to pending payment(s).";
                    }
                    sendNotification({
                        type: "negative",
                        text: toastMessage,
                    });
                    setIsLoading(false);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx(ContentCard, { children: _jsxs("div", { style: { display: "flex", flexDirection: "column", height: "100%" }, children: [_jsxs(Row, { children: [_jsx(Row.Item, { children: _jsx("div", { className: "fontColor--heading fontWeight--semibold whiteSpace--truncate--multiLine--2", children: Filters.titlecase(payee.name) }) }), _jsx(Row.Item, { shrink: true, children: _jsx("div", { style: { marginTop: "-4px" }, children: _jsx(PayeeOptions, { openDeleteDialog: function () { return setIsDeletePayeeDialogOpen(true); } }) }) })] }), _jsx("div", { className: "margin--top--xxs margin--bottom--s", style: { height: "20px" }, children: payee.isPersonType() ? payee === null || payee === void 0 ? void 0 : payee.email : payee === null || payee === void 0 ? void 0 : payee.masked_account_number }), (payee === null || payee === void 0 ? void 0 : payee.description) && (_jsx("div", { className: "fontSize--s fontColor--secondary", style: { marginTop: "auto" }, children: payee.description }))] }) }), _jsx(DeleteDialog, { recipientName: Filters.titlecase(payee.name), recipientType: "payee", onDelete: deletePayee, isOpen: isDeletePayeeDialogOpen, closeDialog: function () { return setIsDeletePayeeDialogOpen(false); } })] }));
};
var PayeeSection = function () {
    var _a = __read(useState([]), 2), payees = _a[0], setPayees = _a[1];
    var _b = __read(useState(false), 2), isAddPayeeDialogOpen = _b[0], setIsAddPayeeDialogOpen = _b[1];
    var _c = __read(useState(false), 2), isFetchingPayees = _c[0], setIsFetchingPayees = _c[1];
    var sendNotification = useNotificationContext().sendNotification;
    var fetchPayees = function (payee) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setIsFetchingPayees(true);
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, 4, 5]);
                    _a = setPayees;
                    return [4 /*yield*/, Payee.fetchPayees(payee)];
                case 2:
                    _a.apply(void 0, [_c.sent()]);
                    return [3 /*break*/, 5];
                case 3:
                    _b = _c.sent();
                    sendNotification({
                        type: "negative",
                        text: "Error fetching your payees. Please contact support.",
                    });
                    return [3 /*break*/, 5];
                case 4:
                    setIsFetchingPayees(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchPayees();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "margin--top--xl margin--bottom--l", children: _jsxs(Row, { children: [_jsx(Row.Item, { children: _jsx("div", { className: "fontSize--l fontColor--heading fontWeight--bold", children: "Payees" }) }), _jsx(Row.Item, { shrink: true, children: _jsx(Button, { kind: "plain", label: "Add a new payee", onClick: function () { return setIsAddPayeeDialogOpen(true); } }) })] }) }), _jsx(LoadingSkeleton, { content: "paragraph", isLoading: isFetchingPayees, children: _jsxs("div", { className: "recipients-container", children: [payees === null || payees === void 0 ? void 0 : payees.map(function (payee) { return (_jsx(PayeeCard, { payee: payee, fetchPayees: fetchPayees }, payee.id)); }), !(payees === null || payees === void 0 ? void 0 : payees.length) && _jsx("div", { children: "You don't have any linked payees." })] }) }), _jsx(AddPayeeModal, { open: isAddPayeeDialogOpen, handleClose: function () { return setIsAddPayeeDialogOpen(false); }, updatePayees: function (newPayee) {
                    sendNotification({ type: "success", text: "Payee added." });
                    fetchPayees(newPayee);
                } })] }));
};
export default PayeeSection;
