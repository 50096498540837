import React from "react";
import PropTypes from "prop-types";
import { DateInput } from "@narmi/design_system";
import { ContextForm } from "cerulean";

const DateRangeFormField = ({ hidden = true, required = false }) => {
  if (hidden) return null;

  const datesAfterToday = (date) => Date.now() < date;
  return (
    <div style={{ display: "flex", marginBottom: "20px" }}>
      <ContextForm.Field style={{ marginBottom: "0px" }} required={required}>
        <DateInput
          disableDates={[datesAfterToday]}
          altInput={true}
          altFormat={"m/d/Y"}
          dateFormat={"m/d/Y"}
          field="min_date"
          useIsoOnChange={false}
          placeholder="mm/dd/yyyy"
        />
      </ContextForm.Field>
      <div className="margin--x--xs fontSize--s" style={{ display: "flex" }}>
        <div style={{ margin: "auto" }}>to</div>
      </div>
      <ContextForm.Field style={{ marginBottom: "0px" }} required={required}>
        <DateInput
          disableDates={[datesAfterToday]}
          altInput={true}
          altFormat={"m/d/Y"}
          dateFormat={"m/d/Y"}
          field="max_date"
          useIsoOnChange={false}
          placeholder="mm/dd/yyyy"
        />
      </ContextForm.Field>
    </div>
  );
};
DateRangeFormField.propTypes = {
  hidden: PropTypes.bool.isRequired,
  required: PropTypes.bool,
};

export default DateRangeFormField;
