import { jsxs as _jsxs } from "react/jsx-runtime";
/**
 * Component to render the logo of a financial instituition
 *
 * An `institution` prop containing the `logo` for the url and `name` for the alt text is required
 *
 * Clicking on the logo will navigate to the root page of the domain
 */
var InstitutionLogo = function (_a) {
    var _b = _a.className, className = _b === void 0 ? "" : _b, institution = _a.institution;
    var logo = institution.logo, name = institution.name;
    return logo && name ? (_jsxs("a", { className: "institution-logo ".concat(className), style: { backgroundImage: "url(".concat(logo, ")") }, href: "/", children: [name, " Logo"] })) : null;
};
export default InstitutionLogo;
