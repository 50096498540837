import React from "react";
import PropTypes from "prop-types";
import { Button, Dialog, Row } from "cerulean";
import Account from "byzantine/src/Account";
import AccountSelector from "../AccountSelector";

const EditRuleDialog = ({
  isEditDialogOpen,
  closeDialog,
  accounts,
  isNewRule,
  sourceUuid,
  onSourceChange,
  destinationUuid,
  onDestinationChange,
  onSave,
}) => (
  <Dialog
    width="525px"
    testId="edit-rule-dialog"
    isOpen={isEditDialogOpen}
    onUserDismiss={closeDialog}
    title={isNewRule ? "Set up automatic savings" : "Edit your 3% rule"}
    footer={
      <Row alignItems="center" justifyContent="end">
        <Row.Item shrink>
          <Button onClick={closeDialog} kind="negative" label="Cancel" />
        </Row.Item>
        <Row.Item shrink>
          <Button
            onClick={onSave}
            kind="primary"
            label="Save"
            data-testid="save-rule"
          />
        </Row.Item>
      </Row>
    }
  >
    <div className="margin--top--s margin--bottom--s">
      {isNewRule
        ? `When you receive a deposit or external transfer in your checking account,
        we transfer 3% to your savings account.`
        : "Choose the source and destination accounts."}
    </div>
    <div className="margin--bottom--l">
      <AccountSelector
        label="From"
        accounts={accounts.automatic_savings_sources.map((a) =>
          Account.deserialize(a)
        )}
        value={sourceUuid}
        onChange={onSourceChange}
        showAddExternalAccountLink={false}
      />
    </div>
    <div className="margin--bottom--l">
      <AccountSelector
        label="To"
        accounts={accounts.automatic_savings_destinations.map((a) =>
          Account.deserialize(a)
        )}
        value={destinationUuid}
        onChange={onDestinationChange}
        showAddExternalAccountLink={false}
      />
    </div>
  </Dialog>
);
EditRuleDialog.propTypes = {
  isEditDialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  accounts: PropTypes.object.isRequired,
  isNewRule: PropTypes.bool.isRequired,
  sourceUuid: PropTypes.string.isRequired,
  onSourceChange: PropTypes.func.isRequired,
  destinationUuid: PropTypes.string.isRequired,
  onDestinationChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EditRuleDialog;
