import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useCallback, useMemo } from "react";
import { modules } from "byzantine";
import cc from "classcat";
import { IconButton } from "cerulean";
import styles from "./TemplatePreview.module.scss";
var FlashUpdated = function (_a) {
    var versionId = _a.versionId;
    var updated = modules.wireTemplates.useTemplateUpdated(versionId);
    return updated ? _jsx("p", { className: styles.updated, children: "Just updated" }) : null;
};
var FedwireTemplatePreview = function (_a) {
    var templateId = _a.templateId, selected = _a.selected, onSelect = _a.onSelect;
    var _b = modules.wireTemplates.useFedwireTemplatePreview(), collapsed = _b.collapsed, onToggle = _b.onToggle;
    var cardStyle = useMemo(function () { return cc([styles.roundCard, selected && styles.selectedCard]); }, [selected]);
    var onClick = useCallback(function () { return onSelect(templateId); }, [onSelect, templateId]);
    var onTogglePreview = useCallback(function (event) {
        event.stopPropagation();
        onToggle();
    }, [onToggle]);
    var details = modules.wireTemplates.useTemplateDetails(templateId);
    if (!details) {
        return null;
    }
    var templateDetails = details.templateDetails, recipientDetails = details.recipientDetails, bankDetails = details.bankDetails, title = details.title, subheader = details.subheader, versionId = details.versionId;
    return (_jsxs("div", { onClick: onClick, className: cardStyle, children: [_jsxs("div", { className: styles.container, children: [_jsxs("div", { className: styles.shrink, children: [_jsx("p", { className: styles.header, children: title }), _jsx("p", { className: styles.subHeader, children: subheader }), _jsx(FlashUpdated, { versionId: versionId })] }), _jsx("div", { className: styles.toggle, children: _jsx("div", { className: styles.dropdownArrow, children: _jsx(IconButton, { kind: "plain", label: "Edit", name: collapsed ? "chevron-down" : "chevron-up", onClick: onTogglePreview, textSize: "l" }) }) })] }), !collapsed ? (_jsxs("div", { children: [_jsx("div", { className: styles.divider }), _jsxs("div", { className: styles.detailsContainer, children: [_jsxs("div", { children: [_jsx("p", { className: styles.detailsHeader, children: templateDetails.header }), templateDetails.rows.map(function (row, i) { return (_jsx("p", { className: styles.detailsValue, children: row }, "templateDetails-".concat(row, "-").concat(i))); })] }), _jsxs("div", { children: [_jsx("p", { className: styles.detailsHeader, children: recipientDetails.header }), recipientDetails.rows.map(function (row, i) { return (_jsx("p", { className: styles.detailsValue, children: row }, "recipientDetails-".concat(row, "-").concat(i))); })] }), _jsxs("div", { children: [_jsx("p", { className: styles.detailsHeader, children: bankDetails.header }), _jsxs("div", { className: styles.bankDetails, children: [_jsx("div", { className: styles.templateInstitution, children: _jsx("div", { className: styles.institutionLogo, children: _jsx("span", { className: "narmi-icon-bank" }) }) }), bankDetails.rows.map(function (row, i) { return (_jsx("p", { className: styles.detailsValue, children: row }, "bankDetails-".concat(row, "-").concat(i))); })] })] })] })] })) : null] }));
};
export default memo(FedwireTemplatePreview);
