import React, { useContext } from "react";
import PropTypes from "prop-types";
import { FormContext } from "./";

const Action = ({
  children,
  // Setting this property to true will disable the multiple click prevention that the loading state provides.
  dangerouslyDisableShowLoading,
  ...actionProps
}) => {
  const { onSubmit } = useContext(FormContext);

  actionProps.dangerouslyDisableShowLoading = dangerouslyDisableShowLoading;

  const onClick = (e) => {
    onSubmit(actionProps, e);
  };
  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          onClick,
        });
      })}
    </>
  );
};

Action.defaultProps = {
  dangerouslyDisableShowLoading: false,
};

Action.propTypes = {
  children: PropTypes.node,
  dangerouslyDisableShowLoading: PropTypes.bool,
};

const VERTICAL_STYLES = {
  display: "flex",
  flexDirection: "column",
  marginTop: "40px",
  alignItems: "center",
};
export const ActionBar = ({ children, style: styleProps, vertical }) => {
  const style = {
    ...styleProps,
    ...(vertical ? VERTICAL_STYLES : undefined),
  };
  return (
    <div className="action-bar" style={style}>
      {children}
    </div>
  );
};
ActionBar.defaultProps = {
  actions: [],
  style: {
    display: "flex",
    flexDirection: "row",
    marginTop: "40px",
  },
  vertical: false,
};
ActionBar.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  vertical: PropTypes.bool,
};

export default Action;
