import { jsx as _jsx } from "react/jsx-runtime";
import { Select, TruncatedAccount, useBreakpoints } from "cerulean";
var LoanSelector = function (_a) {
    var loans = _a.loans, onChange = _a.onChange, value = _a.value, error = _a.error, _b = _a.label, label = _b === void 0 ? "Loan" : _b;
    var m = useBreakpoints().m;
    return (_jsx(Select, { label: label, onChange: onChange, value: value, errorText: error, children: loans.map(function (loan) {
            var _a;
            var name = loan.getName();
            var maskedNumber = loan.getMaskedNumber();
            var ledgerBalance = (_a = loan.balances) === null || _a === void 0 ? void 0 : _a.ledger;
            var numberAndBalance = maskedNumber;
            if (m && ledgerBalance) {
                numberAndBalance = "".concat(maskedNumber, " (").concat(ledgerBalance, ")");
            }
            return (_jsx(Select.Item, { value: loan.id, children: _jsx(TruncatedAccount, { name: name, lastFour: numberAndBalance }) }, loan.id));
        }) }));
};
export default LoanSelector;
