// A basic implementation of the Observer pattern.
export default class SimpleSubject {
  constructor() {
    this.callbacks = {};
  }

  subscribe(channel, callback) {
    if (!(channel in this.callbacks)) {
      this.callbacks[channel] = new Set();
    }
    this.callbacks[channel].add(callback);
  }

  unsubscribe(channel, callback) {
    if (channel in this.callbacks) {
      this.callbacks[channel].delete(callback);
    }
  }

  publish(channel) {
    if (channel in this.callbacks) {
      this.callbacks[channel].forEach((callback) => callback());
    }
  }
}
