var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { selectEndToEndTest, selectGAFeatures, selectInstitution, selectUser, } from "../../entities";
import { createDeepEqualSelector } from "../../entities/utils/createDeepEqualSelector";
import { featureEquals } from "../../../../Feature";
import { isFeatureEnabled } from "./isEnabled";
export var selectFeatures = createDeepEqualSelector([selectGAFeatures, selectInstitution, selectUser, selectEndToEndTest], function (gaFeatures, institution, user, _a) {
    var featureOverrides = _a.featureOverrides;
    // If we have user features (which we get right after the user authenticates,
    // Use them instead of the public institution features received before auth
    return __assign(__assign(__assign({}, gaFeatures), ((user === null || user === void 0 ? void 0 : user.features) || (institution === null || institution === void 0 ? void 0 : institution.features))), featureOverrides);
});
export function selectFeatureEnabled(state, or, and, not) {
    var flags = selectFeatures(state);
    return isFeatureEnabled(flags, { or: or, and: and, not: not });
}
export function selectFeatureEquals(state, flag, setting) {
    var flags = selectFeatures(state);
    return featureEquals(flags, flag, setting);
}
