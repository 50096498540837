import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Localized } from "@fluent/react";
export var REGEX_FLUENT_ID = /^\$\$.+/;
var LocalizedSetting = function (_a) {
    var value = _a.value;
    var isFluentId = REGEX_FLUENT_ID.test(value);
    var toId = function (value) { return value.substring(2); }; // remove `$$` prefix
    return isFluentId ? (_jsx(Localized, { id: toId(value), children: value })) : (_jsx(_Fragment, { children: value }));
};
export default LocalizedSetting;
