var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { cents, currencyToCents } from "byzantine";
import filters from "byzantine/src/filters";
function createCommonProps(form, key) {
    var error = (form.touched[key] && form.errors[key]);
    return {
        id: key,
        onBlur: form.handleBlur,
        onChange: form.handleChange,
        value: form.values[key],
        error: error,
    };
}
export function input(form, key) {
    var commonProps = createCommonProps(form, key);
    return commonProps;
}
export function amountInput(form, key) {
    var _a = createCommonProps(form, key), error = _a.error, value = _a.value, rest = __rest(_a, ["error", "value"]);
    var displayValue = filters.currency(cents(value));
    return __assign(__assign({}, rest), { value: displayValue, onChange: function (event) {
            form.setFieldValue(key, currencyToCents(event.target.value), true);
        }, error: error });
}
export function radio(form, key) {
    var commonProps = createCommonProps(form, key);
    return __assign(__assign({}, commonProps), { onChange: function (event) {
            form.setFieldValue(key, event.target.value, true);
        } });
}
export function select(form, key) {
    var _a = createCommonProps(form, key), error = _a.error, commonProps = __rest(_a, ["error"]);
    return __assign(__assign({}, commonProps), { onChange: function (value) { return form.setFieldValue(key, value, true); }, errorText: error, error: error });
}
