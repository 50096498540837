import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useObjectMemo } from "../../../hooks";
import { useManyRequestsWithFeedback } from "../../../composites/request/request.hooks";
import { notNull } from "../../../composites/request/request.helpers";
import { API } from "./api";
import { actions as limitsActions } from "./slice";
export function useFetchMultipleLimitsRequest(limitTypes) {
    if (limitTypes === void 0) { limitTypes = []; }
    var _a = useManyRequestsWithFeedback(limitTypes.length), sendAll = _a.sendAll, loading = _a.loading;
    var dispatch = useDispatch();
    var fetchMultiple = useCallback(function () {
        var actions = limitTypes.map(function (type) { return API.fetch(type); });
        var onData = function (data) {
            data.filter(notNull).forEach(function (d) {
                dispatch(limitsActions.receive(d));
            });
        };
        sendAll({
            actions: actions,
            onData: onData,
        });
    }, [dispatch, limitTypes, sendAll]);
    return useObjectMemo({ loading: loading, sendAll: fetchMultiple });
}
