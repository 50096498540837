import React from "react";
import PropTypes from "prop-types";
import {
  ContextForm,
  EmailFormField,
  PhoneTextInput,
  TextInput,
  Tooltip,
} from "cerulean";

const DisabledInputTooltip = ({ warningText, isPending, children }) => {
  if (isPending) return children;
  return (
    <Tooltip text={warningText} wrapperDisplay="block">
      {children}
    </Tooltip>
  );
};
DisabledInputTooltip.propTypes = {
  warningText: PropTypes.string,
  isPending: PropTypes.bool,
  children: PropTypes.node,
};

const OrgUserEmailField = ({ isEditing, isPending }) => {
  if (isEditing)
    return (
      <div className="nds-span-6 nds-span-mobile-12 nds-input-style-override has-tooltip-child">
        <DisabledInputTooltip
          warningText={"Email address can't be changed"}
          isPending={isPending}
        >
          <EmailFormField
            required
            disabled={!isPending}
            fieldStyle={{ marginBottom: "0px" }}
          />
        </DisabledInputTooltip>
      </div>
    );
  return (
    <div className="nds-span-6 nds-span-mobile-12 nds-input-style-override">
      <EmailFormField required fieldStyle={{ marginBottom: "0px" }} />
    </div>
  );
};
OrgUserEmailField.propTypes = {
  isEditing: PropTypes.bool,
  isPending: PropTypes.bool,
};

const PhoneInputTooltip = () => (
  <div className="tooltip-info-icon">
    <Tooltip text="This phone number will be used for two-factor authentication.">
      <span className="narmi-icon-info"></span>
    </Tooltip>
  </div>
);

const OrgUserPhoneField = ({ isEditing, isPending }) => {
  if (isEditing)
    return (
      <div className="nds-span-6 nds-span-mobile-12 nds-input-style-override has-tooltip-child">
        <DisabledInputTooltip
          warningText={"Phone number can't be changed"}
          isPending={isPending}
        >
          <ContextForm.Field
            required={isPending ? true : undefined}
            style={{ marginBottom: "0px" }}
          >
            <PhoneTextInput disabled={!isPending} field="phone" />
            {isPending && <PhoneInputTooltip />}
          </ContextForm.Field>
        </DisabledInputTooltip>
      </div>
    );
  return (
    <div className="nds-span-6 nds-span-mobile-12 nds-input-style-override has-tooltip-child">
      <ContextForm.Field required style={{ marginBottom: "0px" }}>
        <PhoneTextInput field="phone" />
        <PhoneInputTooltip />
      </ContextForm.Field>
    </div>
  );
};
OrgUserPhoneField.propTypes = {
  isEditing: PropTypes.bool,
  isPending: PropTypes.bool,
};

const UserInfoInputs = ({ isPending = false, isEditing = false }) => {
  let authenticationUsername;
  if (document.getElementById("authentication_username")) {
    authenticationUsername = JSON.parse(
      document.getElementById("authentication_username").textContent
    );
  }
  return (
    <div className="nds-grid nds-span-12">
      <div className="nds-span-6 nds-span-mobile-12 nds-input-style-override">
        <ContextForm.Field required style={{ marginBottom: "0px" }}>
          <TextInput id="first-name" field="first_name" label="First name" />
        </ContextForm.Field>
      </div>
      <div className="nds-span-6 nds-span-mobile-12 nds-input-style-override">
        <ContextForm.Field required style={{ marginBottom: "0px" }}>
          <TextInput id="last-name" field="last_name" label="Last name" />
        </ContextForm.Field>
      </div>
      <OrgUserEmailField isEditing={isEditing} isPending={isPending} />
      <OrgUserPhoneField isEditing={isEditing} isPending={isPending} />
      {isEditing && authenticationUsername && (
        <div className="nds-span-6 nds-span-mobile-12 nds-input-style-override has-tooltip-child">
          <DisabledInputTooltip
            warningText={
              "Users can edit their username when they log in for the first time."
            }
          >
            <ContextForm.Field style={{ marginBottom: "0px" }}>
              <TextInput
                field="username"
                id="username"
                label="Username"
                disabled={true}
              />
            </ContextForm.Field>
          </DisabledInputTooltip>
        </div>
      )}
    </div>
  );
};
UserInfoInputs.propTypes = {
  isPending: PropTypes.bool,
  isEditing: PropTypes.bool,
};

export default UserInfoInputs;
