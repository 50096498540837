import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ApiHttp from "byzantine/src/ApiHttp";
import Transaction from "byzantine/src/Transaction";
import { NotificationContext } from "cerulean";
import AccountContext from "../contexts/AccountContext";
import SectionCard from "../SectionCard";
import TransactionTable, { EmptyTransactionState } from "../TransactionTable";
import usePendingTransactions from "../hooks/usePendingTransactions";

const RecentTransactionsCard = ({ replyTime }) => {
  const { sendNotification } = useContext(NotificationContext);
  const { accounts } = useContext(AccountContext);
  const [isLoading, setIsLoading] = useState(false);
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [settledTransactions, setSettledTransactions] = useState([]);
  const [pendingTransactions, setPendingTransactions] = useState([]);
  usePendingTransactions({
    accounts,
    setPendingTransactions,
    excludeHiddenAccounts: true,
  });

  const fetchRecentTransactions = async () => {
    try {
      setIsLoading(true);
      const response = await ApiHttp.fetch(
        `transactions?filter=hidden&q=is:settled`,
        { method: "GET" },
        { page: 1, per_page: 5 }
      );
      if (response?.transactions) {
        const transactions = response.transactions.map((t) =>
          Transaction.deserialize(
            t,
            accounts.find((a) => a.id === t.account_id)
          )
        );
        setSettledTransactions(transactions);
      }
    } catch {
      sendNotification({
        type: "negative",
        text: "There was an error retrieving your recent activity. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRecentTransactions();
  }, [JSON.stringify(accounts)]);

  useEffect(() => {
    // pending transactions get returned in addition to the 5 posted transactions,
    // so we need to reduce it down to 5 transactions
    const transactions = Transaction.sortByPostedDate([
      ...pendingTransactions,
      ...settledTransactions,
    ]).slice(0, 5);
    setRecentTransactions(transactions);
  }, [
    JSON.stringify(pendingTransactions),
    JSON.stringify(settledTransactions),
  ]);

  return (
    <SectionCard isLoading={isLoading} kind="transactions">
      <SectionCard.Title text="Recent activity" />
      {recentTransactions.length > 0 ? (
        <TransactionTable
          isSourceMultipleAccounts={true}
          transactions={recentTransactions}
          replyTime={replyTime}
        />
      ) : (
        <EmptyTransactionState isSourceMultipleAccounts={true} />
      )}
    </SectionCard>
  );
};
RecentTransactionsCard.propTypes = {
  replyTime: PropTypes.string,
};

export default RecentTransactionsCard;
