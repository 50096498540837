import { sortBy } from "lodash";
import { createDeepEqualSelector } from "../utils/createDeepEqualSelector";
var selectFromState = function (state) { return state.fedwireTemplates.byId; };
export var selectFedwireTemplates = createDeepEqualSelector(selectFromState, function (byId) { return Object.values(byId); });
export var selectOneFedwireTemplate = createDeepEqualSelector([
    selectFromState,
    function (_, fedWireTemplateId) { return fedWireTemplateId; },
], function (byId, fedWireTemplateId) {
    return fedWireTemplateId ? byId[fedWireTemplateId] : undefined;
});
export var selectSortedFedwireTemplateIds = createDeepEqualSelector([selectFedwireTemplates], function (templates) {
    return sortBy(templates, function (t) { var _a; return (_a = t.last_used) !== null && _a !== void 0 ? _a : -1; })
        .reverse()
        .map(function (t) { return t.id; });
});
