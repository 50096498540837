import Account from "./Account";
import utils from "./utils";
export var DEFAULT_ACCOUNT_MINIMUM_FOR_GROUPING = 10;
export var SORT_BY;
(function (SORT_BY) {
    SORT_BY["ALPHABETICALLY"] = "alphabetically";
    SORT_BY["BALANCE"] = "by_balance";
    SORT_BY["ACCOUNT_NUMBER"] = "by_account_number";
})(SORT_BY || (SORT_BY = {}));
export var SORT_ORDER;
(function (SORT_ORDER) {
    SORT_ORDER["ASC"] = "ascending";
    SORT_ORDER["DESC"] = "descending";
})(SORT_ORDER || (SORT_ORDER = {}));
export var DEFAULT_SORT = {
    sort_by: SORT_BY.ALPHABETICALLY,
    order: SORT_ORDER.ASC,
    group_name: "",
};
export function isGroupedAccountsArray(groupedAccounts) {
    return Array.isArray(groupedAccounts);
}
export function isGroupedAccountsMap(groupedAccounts) {
    return groupedAccounts instanceof Map;
}
export function getValidTransferDestinations(accounts, selectedSourceAccountId, achAllowsPush, supportsInternalTransfers) {
    if (supportsInternalTransfers === void 0) { supportsInternalTransfers = true; }
    var selectedSourceAccount = accounts.find(function (a) { return a.id === selectedSourceAccountId; });
    return accounts.filter(function (a) {
        return a.isValidTransferDestinationForSource(selectedSourceAccount, achAllowsPush, supportsInternalTransfers);
    });
}
export function getValidTransferSources(accounts, selectedDestinationAccountId, supportsInternalTransfers) {
    if (supportsInternalTransfers === void 0) { supportsInternalTransfers = true; }
    var selectedDestinationAccount = accounts.find(function (a) { return a.id === selectedDestinationAccountId; });
    return accounts.filter(function (a) {
        return a.isValidTransferSourceForDestination(selectedDestinationAccount, supportsInternalTransfers);
    });
}
export function groupAccounts(accounts) {
    var groupedAccounts = {};
    Object.keys(Account.PRODUCT_GROUPS).forEach(function (groupName) {
        accounts.forEach(function (account) {
            var accountGroupName = account.getGroupName();
            if (accountGroupName !== groupName)
                return;
            if (!groupedAccounts[accountGroupName]) {
                groupedAccounts[accountGroupName] = [];
            }
            groupedAccounts[accountGroupName].push(account);
        });
    });
    return new Map(Object.entries(groupedAccounts));
}
export var filterAccounts = function (accounts, filter, searchTerm) {
    return accounts.filter(filter).filter(function (account) {
        if (!searchTerm || searchTerm === "")
            return true;
        var re = new RegExp(utils.escapeRegExp(searchTerm), "i");
        return re.test(account.getDescription());
    });
};
export var filterAndGroupAccounts = function (accounts, filter, searchTerm) {
    var filteredAccounts = filterAccounts(accounts, filter, searchTerm);
    return groupAccounts(filteredAccounts);
};
export var sortAccountGroups = function (groupedAccounts, sorting) {
    var _a;
    var sortByBalance = function (a, b) {
        var firstDisplayBalance = a.isCredit()
            ? a.ledgerBalanceAsFloat()
            : a.availableBalanceAsFloat();
        var secondDisplayBalance = b.isCredit()
            ? b.ledgerBalanceAsFloat()
            : b.availableBalanceAsFloat();
        if (firstDisplayBalance > secondDisplayBalance)
            return 1;
        if (secondDisplayBalance > firstDisplayBalance)
            return -1;
        return 0;
    };
    var sortByAccountNumber = function (a, b) {
        if (a.number > b.number)
            return 1;
        if (b.number > a.number)
            return -1;
        return 0;
    };
    var sortAlphabetically = function (a, b) {
        var nameA = a.getShortDescription().toLowerCase();
        var nameB = b.getShortDescription().toLowerCase();
        if (nameA > nameB)
            return 1;
        if (nameB > nameA)
            return -1;
        return 0;
    };
    var sortMap = (_a = {},
        _a[SORT_BY.ALPHABETICALLY] = sortAlphabetically,
        _a[SORT_BY.BALANCE] = sortByBalance,
        _a[SORT_BY.ACCOUNT_NUMBER] = sortByAccountNumber,
        _a);
    groupedAccounts.forEach(function (accountsForGroup, groupName) {
        var accountSort = sorting.find(function (obj) { return obj.group_name === groupName.toLowerCase(); }) ||
            DEFAULT_SORT;
        accountsForGroup.sort(sortMap[accountSort.sort_by]);
        if (accountSort.order === SORT_ORDER.DESC)
            accountsForGroup.reverse();
    });
};
