import React, { useContext, useEffect, useState } from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";

import {
  AddPhoneMfaDevice,
  ContextForm,
  PreferenceContentCard,
  NotificationContext,
  phoneFormatter,
} from "cerulean";
import MfaDevice from "byzantine/src/MfaDevice";

/*
  HIDE_INPUT is the state used when mfa is not required and the toggle is switched off

  SHOW_PHONE_INPUT is the state used when:
    1) on initial page load or refresh, we fetch the user's devices and find a phone device has already been added
    2) mfa is not required, the toggle is switched on, and the user has not verified a device
    3) mfa is required and the user has not just added an additional device

  COMPLETE is used when the user has just successfully verified a device
*/
const DEVICE_ADDING_STATES = Object.freeze({
  HIDE_INPUT: "hide_input",
  SHOW_PHONE_INPUT: "show_phone_input",
  COMPLETE: "complete",
});

const FIELD_NAME = "twoFactorAuthentication";

const AddMfaDevice = ({
  mfa_required,
  user,
  data,
  onChange,
  setHasAddedOrReverifiedDevice,
}) => {
  const { l10n } = useLocalization();
  const { sendNotification } = useContext(NotificationContext);
  const [newestMfaDeviceNumber, setNewestMfaDeviceNumber] = useState("");
  const [mfaVerificationState, setMfaVerificationState] = useState(
    DEVICE_ADDING_STATES.HIDE_INPUT
  );

  useEffect(() => {
    user.getPhoneDevices().then((devicesResponse) => {
      /* If, on page load, the user already has a phone device:
        1) prepopulate the form field with the number of the most recently added device &
          show the phone input so the user knows which device has been added
        2) set the state of that number so we can determine whether to show the toggle on page load
          (we don't want to show the toggle if the user already has a phone device)
      */
      if (devicesResponse?.[0]) {
        onChange({
          twoFactorAuthentication: {
            phone_number: devicesResponse?.[0].number,
          },
        });
        setMfaVerificationState(DEVICE_ADDING_STATES.SHOW_PHONE_INPUT);
        setNewestMfaDeviceNumber(devicesResponse?.[0].number);
      } else {
        /* If user doesn't have a phone device, only show phone input if mfa is required
         The user can toggle this input on if mfa isn't required and they still want to add a device.
        */
        setMfaVerificationState(
          mfa_required
            ? DEVICE_ADDING_STATES.SHOW_PHONE_INPUT
            : DEVICE_ADDING_STATES.HIDE_INPUT
        );
      }
    });
  }, []);

  const onRequestCode = () => {
    onChange({
      twoFactorAuthentication: {
        phone_number: data.twoFactorAuthentication.phone_number,
      },
    });
    return user.addMfaDevice({
      phone_number: data.twoFactorAuthentication.phone_number,
      device_type: MfaDevice.TYPES.PHONE,
    });
  };

  const onVerifyCode = async (seed) => {
    await user.addMfaDevice({
      phone_number: data.twoFactorAuthentication.phone_number,
      device_type: MfaDevice.TYPES.PHONE,
      token: data.twoFactorAuthentication.token,
      seed,
    });
    setNewestMfaDeviceNumber(data.twoFactorAuthentication.phone_number);
    setMfaVerificationState(DEVICE_ADDING_STATES.COMPLETE);
    sendNotification({
      type: "success",
      text: `${phoneFormatter(
        data.twoFactorAuthentication.phone_number
      )} was added successfully as a two-factor authentication device.`,
    });
    setHasAddedOrReverifiedDevice(true);
  };

  const onMfaToggleChange = (val) => {
    const state = val
      ? DEVICE_ADDING_STATES.SHOW_PHONE_INPUT
      : DEVICE_ADDING_STATES.HIDE_INPUT;
    setMfaVerificationState(state);
  };

  const onUseADifferentPhoneNumber = () => {
    setMfaVerificationState(DEVICE_ADDING_STATES.SHOW_PHONE_INPUT);
    onChange({ twoFactorAuthentication: {} });
  };

  return (
    <ContextForm.Field field={FIELD_NAME} testId="twoFactorAuthentication">
      <PreferenceContentCard
        title="Two-factor authentication"
        subtitle={
          <>
            {mfaVerificationState === DEVICE_ADDING_STATES.COMPLETE
              ? `Two-factor authentication set up for ${phoneFormatter(
                  data.twoFactorAuthentication.phone_number
                )}.`
              : l10n.getString(
                  "two-factor-authentication-recommendation",
                  null,
                  "For your security, we require setting up two-factor authentication with your mobile phone number."
                )}
          </>
        }
        subtext={
          <AddPhoneMfaDevice
            onRequestCode={onRequestCode}
            onVerifyCode={onVerifyCode}
            onUseADifferentPhoneNumber={onUseADifferentPhoneNumber}
            field={FIELD_NAME}
            label={l10n.getString("label-mobile-phone")}
            labelCancel={l10n.getString("labelCancel")}
            labelVerify={l10n.getString("labelVerify")}
            labelResendCode={l10n.getString("labelResendCode")}
            labelDifferentPhone={l10n.getString("labelDifferentPhone")}
          />
        }
        onChange={onMfaToggleChange}
        showSubtext={
          mfaVerificationState === DEVICE_ADDING_STATES.SHOW_PHONE_INPUT
        }
        showToggle={!mfa_required && !newestMfaDeviceNumber}
      />
    </ContextForm.Field>
  );
};

AddMfaDevice.propTypes = {
  mfa_required: PropTypes.bool,
  user: PropTypes.object,
  data: PropTypes.object,
  onChange: PropTypes.func,
  setHasAddedOrReverifiedDevice: PropTypes.func,
};

export default AddMfaDevice;
