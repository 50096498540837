// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cardList--O5GmA{display:flex;flex-direction:row;flex-wrap:wrap;gap:var(--space-l);padding-top:var(--space-m)}.cardList--O5GmA .cardContainer--kU2aC{display:flex;flex-direction:row;flex:1;max-width:568px;flex-basis:100%}@media only screen and (min-width: 1280px){.cardList--O5GmA .cardContainer--kU2aC{flex-basis:calc(50% - 10px)}}", "",{"version":3,"sources":["webpack://./components/settings/cards/BankCardList/BankCardList.module.scss"],"names":[],"mappings":"AAEA,iBACE,YAAA,CACA,kBAAA,CACA,cAAA,CACA,kBAAA,CAEA,0BAAA,CAEA,uCACE,YAAA,CACA,kBAAA,CACA,MAAA,CACA,eAAA,CACA,eAAA,CAAA,2CALF,uCAQI,2BAAA,CAAA","sourcesContent":["@import \"../../../../../scss/mixins\";\n\n.cardList {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  gap: var(--space-l);\n\n  padding-top: var(--space-m);\n\n  .cardContainer {\n    display: flex;\n    flex-direction: row;\n    flex: 1;\n    max-width: 568px;\n    flex-basis: 100%;\n\n    @include minViewport(\"l\") {\n      flex-basis: calc(50% - 10px);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardList": "cardList--O5GmA",
	"cardContainer": "cardContainer--kU2aC"
};
export default ___CSS_LOADER_EXPORT___;
