import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { LoadingShim } from "cerulean";
import { ComposeProviders, modules } from "byzantine";
import TemplatesList from "./TemplatesList";
import SendViaTemplate from "./SendViaTemplate";
var Providers = [
    modules.wireTemplates.bootstrap.Provider,
    modules.wireTemplates.methodSelectorForm.Provider,
    modules.wireTemplates.sendViaTemplateForm.Provider,
];
var WireTemplatesScreen = memo(function WireTemplatesScreen() {
    var _a = modules.wireTemplates.bootstrap.useBootstrap(), loading = _a.loading, load = _a.load;
    useEffect(function () {
        load();
    }, [load]);
    if (loading) {
        return (_jsx(LoadingShim, { isLoading: true, children: _jsx("div", { style: { height: "100vh" } }) }));
    }
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/templates/*", element: _jsx(TemplatesList, {}) }), _jsx(Route, { path: "/", element: _jsx(SendViaTemplate, {}) })] }));
});
function Bootstrapped() {
    return (_jsx(ComposeProviders, { components: Providers, children: _jsx(WireTemplatesScreen, {}) }));
}
export default memo(Bootstrapped);
