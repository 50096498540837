// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sectionCard--jFkPX {\n  box-shadow: var(--elevation-low);\n  border-radius: 4px;\n  background-color: var(--color-white);\n}\n\n.sectionCard--jFkPX:first-child {\n  margin-top: 0;\n}\n\n@media (min-width: 1280px) {\n  .sectionCard--jFkPX:first-child {\n    margin-top: initial;\n  }\n}\n\n.cardTitle--sR1Bl + * {\n  padding-top: var(--space-s);\n}\n", "",{"version":3,"sources":["webpack://./components/SectionCard.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,kBAAkB;EAClB,oCAAoC;AACtC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".sectionCard {\n  box-shadow: var(--elevation-low);\n  border-radius: 4px;\n  background-color: var(--color-white);\n}\n\n.sectionCard:first-child {\n  margin-top: 0;\n}\n\n@media (min-width: 1280px) {\n  .sectionCard:first-child {\n    margin-top: initial;\n  }\n}\n\n.cardTitle + * {\n  padding-top: var(--space-s);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionCard": "sectionCard--jFkPX",
	"cardTitle": "cardTitle--sR1Bl"
};
export default ___CSS_LOADER_EXPORT___;
