import React from "react";
import PropTypes from "prop-types";

const FilterButton = ({ filterName, toggleActiveFilters, isActive }) => (
  <div
    className="clickable filter-button"
    style={{
      backgroundColor: isActive
        ? "var(--color-lightGrey)"
        : "var(--bgColor-cloudGrey)",
    }}
    role="button"
    tabIndex="0"
    onKeyUp={({ key }) => {
      if (key === "Enter") {
        toggleActiveFilters();
      }
    }}
    onClick={toggleActiveFilters}
  >
    <div className="alignChild--center--center">
      {isActive && (
        <div className="narmi-icon-check margin--right--xxs fontSize--s" />
      )}
      {filterName}
    </div>
  </div>
);
FilterButton.propTypes = {
  filterName: PropTypes.string.isRequired,
  toggleActiveFilters: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default FilterButton;
