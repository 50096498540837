var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useLocalization } from "@fluent/react";
import isEqual from "lodash.isequal";
import omit from "lodash.omit";
import { useCurrentUser } from "../../../../contexts/CurrentUserContext";
import { useInstitutionSettings } from "../../../../contexts/InstitutionSettingsContext";
import { useUserFeatures } from "../../../../contexts/UserFeaturesContext";
import ProfileSettingRow from "../../ProfileSettingRow";
import Address from "./Address";
import AddressEditDialog from "./AddressEditDialog";
var AddressSettings = function () {
    var institutionSettings = useInstitutionSettings();
    var userFeatures = useUserFeatures();
    var l10n = useLocalization().l10n;
    var currentUser = useCurrentUser().currentUser;
    var primaryAddress = currentUser === null || currentUser === void 0 ? void 0 : currentUser.getPrimaryAddress();
    var mailingAddress = currentUser === null || currentUser === void 0 ? void 0 : currentUser.getMailingAddress();
    var _a = __read(useState(false), 2), isAddressDialogOpen = _a[0], setIsAddressDialogOpen = _a[1];
    var openAddressDialog = function () { return setIsAddressDialogOpen(true); };
    var doAddressesMatch = isEqual(omit(primaryAddress, ["type"]), omit(mailingAddress, ["type"])) ||
        !mailingAddress;
    var hasCoreAddress = institutionSettings.core_update_address;
    var userHasAddressChangeFeature = userFeatures.address_change;
    var isReadOnly = !(userHasAddressChangeFeature && (currentUser === null || currentUser === void 0 ? void 0 : currentUser.isPersonalUserOrBusinessAdmin()));
    var primaryAddressLabel = (currentUser === null || currentUser === void 0 ? void 0 : currentUser.isBusiness())
        ? l10n.getString("label-business-primary-address")
        : l10n.getString("label-personal-primary-address");
    var mailingAddressLabel = (currentUser === null || currentUser === void 0 ? void 0 : currentUser.isBusiness())
        ? l10n.getString("label-business-mailing-address")
        : l10n.getString("label-personal-mailing-address");
    var samePrimaryAndMailingLabel = (currentUser === null || currentUser === void 0 ? void 0 : currentUser.isBusiness())
        ? l10n.getString("label-same-business-address")
        : l10n.getString("label-same-residential-address");
    var primaryAddressTooltipText = null;
    var mailingAddressTooltipText = null;
    // a personal user can edit the address if they have the address_change feature
    // a business user can edit the address if they have the address_change feature AND have ownership over the organization
    var userIsAddressOwnerButCantUpdate = !userHasAddressChangeFeature &&
        (currentUser === null || currentUser === void 0 ? void 0 : currentUser.isPersonalUserOrBusinessAdmin());
    if (userIsAddressOwnerButCantUpdate) {
        primaryAddressTooltipText = "Please contact support to edit your ".concat(primaryAddressLabel.toLocaleLowerCase(), ".");
        mailingAddressTooltipText = "Please contact support to edit your ".concat(mailingAddressLabel.toLocaleLowerCase(), ".");
    }
    return (_jsxs(_Fragment, { children: [hasCoreAddress && (_jsx(ProfileSettingRow, { label: primaryAddressLabel, tooltipText: primaryAddressTooltipText, value: primaryAddress && _jsx(Address, { address: primaryAddress }), editOnClick: openAddressDialog, isReadOnly: isReadOnly, testId: "primary-address-setting" })), hasCoreAddress && institutionSettings.has_mailing_address && (_jsx(ProfileSettingRow, { label: mailingAddressLabel, tooltipText: mailingAddressTooltipText, value: doAddressesMatch
                    ? samePrimaryAndMailingLabel
                    : mailingAddress && _jsx(Address, { address: mailingAddress }), isReadOnly: isReadOnly, editOnClick: openAddressDialog })), _jsx(AddressEditDialog, { isOpen: isAddressDialogOpen, closeDialog: function () { return setIsAddressDialogOpen(false); }, doAddressesMatch: doAddressesMatch })] }));
};
export default AddressSettings;
