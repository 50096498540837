import React from "react";
import PropTypes from "prop-types";
import { SideBar } from "cerulean";

const SideBarPage = ({ children, activeStep, steps, institution }) => (
    <div className="sidebar-page-container">
      <SideBar
        title={institution.long_name}
        activeStep={activeStep}
        steps={steps}
        logo_url={institution.logo_url}
      />
      <div className="sidebar-content-container">
        <div className="sidebar-content">{children}</div>
      </div>
    </div>
  );

SideBarPage.propTypes = {
  children: PropTypes.node.isRequired,
  activeStep: PropTypes.object,
  steps: PropTypes.array,
  institution: PropTypes.object.isRequired,
};

export default SideBarPage;
