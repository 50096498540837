var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { createDeepEqualSelector } from "../utils/createDeepEqualSelector";
export var selectAllLimits = function (state) { return state.limits.data; };
export var selectWireLimit = function (state) { return state.limits.data.wire; };
export var selectLimitType = createDeepEqualSelector([selectAllLimits, function (_, type) { return type; }], function (limits, type) { return (limits[type] || null); });
var isInterval = function (s) {
    return !Number.isNaN(parseFloat(s));
};
export var selectLimitsForDisplay = createDeepEqualSelector([selectAllLimits, function (_, types) { return types; }], function (allLimits, types) {
    var allDisplayLimits = {};
    types.forEach(function (type) {
        var limit = (allLimits[type] || null);
        if (!limit || !Object.values(limit).some(function (l) { return !!l; })) {
            return;
        }
        var displayLimits = [];
        Object.entries(limit).forEach(function (_a) {
            var _b = __read(_a, 2), period = _b[0], limitInfo = _b[1];
            if (isInterval(period) && limitInfo && typeof limitInfo !== "number") {
                displayLimits.push({
                    period: period,
                    limitInfo: limitInfo,
                });
            }
        });
        allDisplayLimits[type] = displayLimits;
    });
    return allDisplayLimits;
});
