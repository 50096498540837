import React from "react";
import _ from "lodash";
export function useDeepEqualMemo(factory, dependencies) {
    var value = dependencies === undefined ? factory : dependencies;
    var ref = React.useRef(value);
    var signalRef = React.useRef(0);
    if (!_.isEqual(value, ref.current)) {
        ref.current = value;
        signalRef.current += 1;
    }
    var callback = typeof factory === "function" ? factory : function () { return ref.current; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useMemo(callback, [signalRef.current]);
}
export function useDeepEqualEffect(effect, dependencies) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useEffect(effect, useDeepEqualMemo(dependencies));
}
