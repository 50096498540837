import React from "react";
import PropTypes from "prop-types";
import ApiHttp from "byzantine/src/ApiHttp";
import { useLocalization, Localized } from "@fluent/react";
import {
  TextInput,
  ContextForm,
  PasswordTextInput,
  ProgressButtons,
  useFormData,
} from "cerulean";

const CreateYourLogin = ({ user, updateUser, institution }) => {
  const { l10n } = useLocalization();
  const { formData, onChange } = useFormData({});

  const nextHandler = async (callback) => {
    // we don't have a single endpoint that attempts to make the username & password updates at once,
    // so for now we can only display a username error first and then a password error
    if (!user.has_username) {
      try {
        const updatedUsernameUser = await user.updateUsername(
          formData.username
        );
        updateUser(updatedUsernameUser);
      } catch (err) {
        callback(err);
        return; // return here so that we don't try to make the password update when the username update fails
      }
    }
    if (user.has_password) {
      callback(); // if we've made it to this point and the user already has a password, we're done
      return;
    }
    try {
      const response = await user.updatePassword(
        formData.password,
        formData.password
      );
      updateUser(response.user);
      callback();
    } catch (err) {
      callback(err);
    }
  };

  const getSubheaderString = () => {
    const passwordMsg = l10n.getString(
      "label-type-password",
      null,
      "Choose a password for logging in."
    );
    const usernameMsg = l10n.getString(
      "label-type-password",
      null,
      "Choose a password for logging in."
    );
    const credentialsMsg = l10n.getString(
      "label-type-credentials",
      null,
      "Choose your credentials for logging in."
    );
    let result = credentialsMsg;
    if (user.has_username && !user.has_password) {
      result = passwordMsg;
    }
    if (!user.has_username && user.has_password) {
      result = usernameMsg;
    }
    return result;
  };

  return (
    <div className="small">
      <h1>
        <Localized id="heading-create-login">Create your login</Localized>
      </h1>
      <div
        className="margin--top--xs margin--bottom--xl"
        data-testid="enrollment-page-subtitle"
      >
        {getSubheaderString()}
      </div>
      <ContextForm data={formData} onChange={onChange}>
        {!user.has_username && (
          <ContextForm.Field required>
            <TextInput
              field="username"
              label={l10n.getString("label-username", null, "Username")}
              testId="username-input"
            />
          </ContextForm.Field>
        )}
        {!user.has_password && (
          <ContextForm.Field required>
            <PasswordTextInput field="password" testId="password-input" />
          </ContextForm.Field>
        )}
        <div className="progress-buttons-container">
          <ProgressButtons
            onNext={nextHandler}
            onBack={() => {
              if (!institution?.logout_redirect) {
                ApiHttp.fetch("logout", { endpointIsUrl: true }).then(() => {
                  window.location.assign("/enroll");
                });
              } else {
                window.open(`/logout`, "_self");
              }
            }}
            backLabel={l10n.getString("button-cancel", null, "Cancel")}
          />
        </div>
      </ContextForm>
    </div>
  );
};

CreateYourLogin.propTypes = {
  updateUser: PropTypes.func,
  user: PropTypes.object,
  institution: PropTypes.object,
};

export default CreateYourLogin;
