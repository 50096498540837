import React from "react";
import PropTypes from "prop-types";
import { Button } from "@narmi/design_system";
import { ContextForm } from "cerulean";
import ActionHeader from "./ActionHeader";
import SimpleTransferFields, {
  TRANSFER_FORM_TYPES,
} from "./SimpleTransferFields";

const SimpleTransferForm = ({ data, onSubmit, cancel, limits = {}, title }) => (
  <div className="form-card-layout">
    <div className="card">
      <ActionHeader icon="transfer-arrows" title={title} />
      <SimpleTransferFields
        data={data}
        limits={limits}
        formType={TRANSFER_FORM_TYPES.SIMPLE_TRANSFER__NEW}
      />
      <ContextForm.ActionBar vertical>
        <ContextForm.Action onSubmit={onSubmit} dangerouslyDisableShowLoading>
          <Button label="Continue" />
        </ContextForm.Action>
        <div className="transfer-cancel-button">
          <Button
            onClick={() => cancel()}
            kind="negative"
            type="button"
            label="Cancel"
          />
        </div>
      </ContextForm.ActionBar>
    </div>
  </div>
);
SimpleTransferForm.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  cancel: PropTypes.func,
  limits: PropTypes.object,
  title: PropTypes.string,
};

export default SimpleTransferForm;
