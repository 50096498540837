import React from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownListItem } from "cerulean";

const AccountSelector = ({ accounts, onChange, value }) => {
  let displayValue = "";
  if (value) {
    const selectedAccount = accounts.filter((account) => account.id === value);
    if (selectedAccount.length > 0) {
      displayValue = selectedAccount[0].getDescription();
    }
  }
  return (
    <Dropdown triggerLabel="Account" triggerValue={displayValue}>
      {accounts.map((account) => (
        <DropdownListItem
          key={account.id}
          anyItemSelected={!!value}
          isSelected={value === account.id}
          onClick={() => onChange(account.id)}
        >
          {account.getDescription()}
        </DropdownListItem>
      ))}
    </Dropdown>
  );
};
AccountSelector.propTypes = {
  accounts: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default AccountSelector;
