import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import BillPaySchedule from "byzantine/src/BillPaySchedule";
import PayeeList from "byzantine/src/PayeeList";
import {
  Dropdown,
  DropdownListItem,
  DropdownLinkItem,
  SearchBar,
} from "cerulean";
import AddPayeeModal from "./AddPayeeModal";
import utils from "../../utils";

const PayeeListItem = (props) => (
  /* For rendering each payee in the list e.g. Jane Dough (*****6789) */
  <DropdownListItem
    anyItemSelected={!!props.value}
    isSelected={props.value === props.payee.id}
    onClick={() => props.setPayeeId(props.payee.id)}
    closeDropdown={props.closeDropdown}
  >
    {props.payee.getDisplayName()}
  </DropdownListItem>
);
PayeeListItem.propTypes = {
  payee: PropTypes.object,
  setPayeeId: PropTypes.func,
  closeDropdown: PropTypes.func,
  value: PropTypes.string,
};

export default function PayeeSelector({
  field,
  payees,
  value,
  onChange,
  updatePayees,
  setMinDate,
  setDisabledDates,
  updateDatepickerValue,
  ...props
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPayee, setSelectedPayee] = useState(null);
  let minDate = DateTime.now().toFormat("MM/dd/yyyy");

  useEffect(() => {
    if (value) {
      const matchingPayees = payees.filter((payee) => payee.id === value);
      if (matchingPayees.length > 0) {
        setSelectedPayee(matchingPayees[0]);
      }
    }
  }, [value, payees]);

  useEffect(() => {
    BillPaySchedule.getHolidays().then((holidays) => {
      if (holidays && holidays.map) {
        const disabled = holidays.map((holiday) =>
          DateTime.fromISO(holiday.start_time).toJSDate()
        );
        // also disable weekends
        disabled.push((date) => [0, 6].includes(date.getDay()));
        setDisabledDates(disabled);
      }
      /* set the minDate based on the selected Payee */
      if (
        selectedPayee &&
        selectedPayee.delivery_options &&
        selectedPayee.delivery_options.length > 0
      ) {
        const minDateForPayee = DateTime.fromISO(
          selectedPayee.delivery_options[0].date
        ).toJSDate();
        /* minDate can't be earlier than today */
        if (minDateForPayee > utils.localIsoDate()) {
          minDate = DateTime.fromJSDate(minDateForPayee).toFormat("MM/dd/yyyy");
          setMinDate(minDate);
        }
        updateDatepickerValue(minDate);
      }
    });
  }, [selectedPayee]);

  let displayValue = "";
  if (selectedPayee) {
    displayValue = selectedPayee.getDisplayName();
  }

  return (
    <>
      <Dropdown
        triggerLabel={props.label}
        triggerValue={displayValue}
        {...props}
      >
        <PayeeList
          payees={payees}
          renderPayee={PayeeListItem}
          renderSearch={SearchBar}
          setPayeeId={onChange}
          value={value}
        />
        <DropdownLinkItem anyItemSelected={!!value} setModalOpen={setModalOpen}>
          Link a new payee
        </DropdownLinkItem>
      </Dropdown>
      <AddPayeeModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        updatePayees={updatePayees}
      />
    </>
  );
}
PayeeSelector.propTypes = {
  field: PropTypes.string,
  payees: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  updatePayees: PropTypes.func,
  setMinDate: PropTypes.func,
  setDisabledDates: PropTypes.func,
  updateDatepickerValue: PropTypes.func,
};
