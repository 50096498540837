var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, IconButton } from "@narmi/design_system";
import { Dialog, useLoadingContext, useNotificationContext } from "cerulean";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./MembershipInfoRow.module.scss";
var MembershipInfoRow = function (_a) {
    var name = _a.name, membership = _a.membership, removeMembership = _a.removeMembership;
    var _b = __read(useState(false), 2), deleteModalOpen = _b[0], setDeleteModalOpen = _b[1];
    var setIsLoading = useLoadingContext().setIsLoading;
    var sendNotification = useNotificationContext().sendNotification;
    var t = useTranslation().t;
    var handleDelete = function () {
        setIsLoading(true);
        membership
            .delete()
            .then(function () {
            setDeleteModalOpen(false);
            setIsLoading(false);
            sendNotification({
                type: "success",
                text: t("Account successfully unlinked"),
            });
            removeMembership === null || removeMembership === void 0 ? void 0 : removeMembership(membership.id);
        })
            .catch(function (err) {
            setIsLoading(false);
            sendNotification({
                type: "negative",
                text: t(err),
            });
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles["membership-info-row"], children: [_jsx("span", { className: styles.left, children: name }), _jsxs("span", { className: styles.right, children: [_jsx("div", { className: styles["membership-id"], children: membership.id }), removeMembership && (_jsx(IconButton, { kind: "plain", label: "Edit", name: "trash-2", onClick: setDeleteModalOpen }))] })] }), _jsx(Dialog, { isOpen: deleteModalOpen, footer: _jsxs("div", { style: { textAlign: "right" }, children: [_jsx(Button, { kind: "negative", onClick: function () { return setDeleteModalOpen(false); }, children: t("Cancel") }), _jsx("span", { className: "margin--left--s", children: _jsx(Button, { onClick: handleDelete, children: t("Yes, unlink") }) })] }), onUserDismiss: function () { return setDeleteModalOpen(false); }, title: t("Unlink account?"), children: _jsx("div", { children: "Are you sure that you want to unlink this account?" }) })] }));
};
export default MembershipInfoRow;
