import ApiHttp from "byzantine/src/ApiHttp";

const fetchCode = (selectedDeviceId, setHelpMessage) => (
  // we will get a 403 forbidden here, as we're fetching an MFA code
   ApiHttp.fetch("sudo", { method: "POST" }, { device_id: selectedDeviceId }).catch((response) => {
    setHelpMessage(response.message);
  }
));

export default fetchCode;

