import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Select } from "@narmi/design_system";
var noop = function () { };
/**
 * **Fully controlled** Language selection component.
 *
 * These props should be taken from the result of the `useFluentLocalization` hook.
 * Size constraints should be managed by a parent wrapper.
 */
var LangSelect = function (_a) {
    var localeCodes = _a.localeCodes, localeNames = _a.localeNames, _b = _a.onChange, onChange = _b === void 0 ? noop : _b, _c = _a.selectedLocale, selectedLocale = _c === void 0 ? "en" : _c;
    return (_jsx(Select, { label: "Language", onChange: onChange, value: selectedLocale, children: localeCodes.map(function (localeCode, i) { return (_jsx(Select.Item, { value: localeCode, children: localeNames[i] }, localeCode)); }) }));
};
export default LangSelect;
