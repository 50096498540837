import React from "react";
import PropTypes from "prop-types";
import EnterYourVerificationCode from "./EnterYourVerificationCode";
import TellUsMoreAboutYou from "./TellUsMoreAboutYou";

const Verify = ({ user, updateUser, encodedPayload, institution }) => {
  if (user?.requires_enrollment_code_verification) {
    return (
      <EnterYourVerificationCode
        user={user}
        updateUser={updateUser}
        institution={institution}
      />
    );
  }
  return (
    <TellUsMoreAboutYou
      updateUser={updateUser}
      encodedPayload={encodedPayload}
      institution={institution}
    />
  );
};

Verify.propTypes = {
  user: PropTypes.object,
  updateUser: PropTypes.func,
  encodedPayload: PropTypes.string,
  institution: PropTypes.object,
};

export default Verify;
