import { formatDate, formatNumber } from "@narmi/design_system";

function parseError(messageOrReason) {
  if (Array.isArray(messageOrReason)) {
    return messageOrReason.join(" ");
  }
  if (typeof messageOrReason === "object") {
    return Object.keys(messageOrReason)
      .map((k) => messageOrReason[k].join(" "))
      .join(" ");
  }
  return messageOrReason;
}

export default {
  localIsoDate() {
    const date = new Date();
    const localDate = date - date.getTimezoneOffset() * 60 * 1000;
    return new Date(localDate);
  },
  // decodes only the most common characters
  unescape(htmlSafe) {
    const entities = [
      ["amp", "&"],
      ["apos", "'"],
      ["#x27", "'"],
      ["#x2F", "/"],
      ["#39", "'"],
      ["#47", "/"],
      ["lt", "<"],
      ["gt", ">"],
      ["nbsp", " "],
      ["quot", '"'],
    ];

    let unescaped = htmlSafe;
    for (let i = 0; i < entities.length; i += 1) {
      unescaped = unescaped.replace(
        new RegExp(`&${entities[i][0]};`, "g"),
        entities[i][1]
      );
    }

    return unescaped;
  },
  zeroDecimalAmount(amount) {
    return Math.round(Number(amount) * 100);
  },
  formattedRoutingNumber: {
    numericOnly: true,
    delimiter: " ",
    delimiterLazyShow: true,
    blocks: [4, 4, 1],
  },
  extractErrorMessage(
    error,
    defaultErrorMessage = "An error occurred, please try again later"
  ) {
    if (!error) {
      return defaultErrorMessage;
    }
    if (typeof error === "string") {
      return error;
    }
    const data = error.response && error.response.data;
    if (
      data &&
      (error.response.status === 422 || error.response.status === 503)
    ) {
      if (data.reason) return parseError(data.reason);
      if (data.message) return parseError(data.message);
    }
    return defaultErrorMessage;
  },
  extractIgnoredWarnings(error) {
    if (!error || !error.response || !error.response.data) return null;
    // api can return arrays of errors and warnings
    if (
      Array.isArray(error.response.data.errors) &&
      Array.isArray(error.response.data.warnings)
    ) {
      // if we only have warnings, allow overriding
      if (
        error.response.data.warnings.length &&
        !error.response.data.errors.length
      ) {
        return error.response.data.warnings;
      }
    }
    return null;
  },
  send_ga(windowObject, args) {
    if (windowObject.ga) {
      windowObject.ga("send", args);
    }
  },
  // amount values at different times can be: '$', '1', '1.00' '$1.00, or '$1,000.00'
  parseValueAsFloat(value) {
    // cover falsy values just in case
    if (!value) return 0;
    if (typeof value === "number") return value;
    return parseFloat(value.replace(/[^\d.]/g, "")) || 0;
  },
  getNDSFormatters() {
    return {
      currency: formatNumber,
      percentage: (num) => formatNumber(num, "percent"),
      date: formatDate,
    };
  },
  allAreSelected(accounts) {
    return !accounts.filter((a) => !a.selected).length;
  },
  validateAddress(address) {
    const updatedAddressErrors = {};
    ["street_address", "city", "region_code", "postal_code"].forEach(
      (field) => {
        if (!address[field]) {
          updatedAddressErrors[field] = "Required";
        }
      }
    );
    if (address.postal_code) {
      const isLongForm = address.postal_code.includes("-");
      if (address.postal_code.length < 5) {
        updatedAddressErrors.postal_code =
          "Zip code must be at least 5 digits.";
      }
      if (isLongForm) {
        // Hyphen must be in correct place and cannot be last char
        if (
          address.postal_code.charAt(5) !== "-" ||
          address.postal_code.slice(-1) === "-"
        )
          updatedAddressErrors.postal_code = "Zip code must be valid.";
        const formattedAddress = address.postal_code.replace("-", "");
        if (formattedAddress.length > 5 && formattedAddress.length < 9) {
          updatedAddressErrors.postal_code = "Zip code must be valid.";
        }
      }
    }
    return updatedAddressErrors;
  },
};
