import { useMemo } from "react";
import { useObjectMemo, modules } from "byzantine";
export var useDetailsSelector = function () {
    var wireReasons = modules.wireTemplates.useData().wireReasons;
    var form = modules.wireTemplates.sendViaTemplateForm.useForm();
    var method = modules.wireTemplates.methodSelectorForm.useForm().values.method;
    return useObjectMemo({
        wireReasons: wireReasons,
        method: method,
        form: form,
    });
};
export var useLimitsForDisplay = function (limits) {
    return useMemo(function () {
        var result = {}; // amount and available amount per time period for each transaction type
        var keys = Object.keys(limits);
        keys.forEach(function (transaction_type) {
            if (!["wire", "organization_user_wire"].includes(transaction_type)) {
                return; // only want wire lmits here
            }
            result[transaction_type] = {};
            var limitForTransaction = limits[transaction_type];
            if (!limitForTransaction) {
                return;
            }
            var limitsForTransactionKeys = Object.keys(limitForTransaction);
            limitsForTransactionKeys.forEach(function (key) {
                // limits[transaction] has numbers (which represent days) and "amount_available" as keys
                if (Number(key) && limitForTransaction[key] !== undefined) {
                    // only want number keys for display
                    result[transaction_type][key] = limitForTransaction[key];
                }
            });
        });
        return result;
    }, [limits]);
};
