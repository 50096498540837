import { jsx as _jsx } from "react/jsx-runtime";
import PromotionCard from "../PromotionCard";
import MarketingCard from "../MarketingCard/MarketingCard";
import { useUserFeatures } from "../../contexts/UserFeaturesContext";
import useWelcomeOffer from "./useWelcomeOffer";
var MarketingCardContainer = function () {
    var welcomeOffer = useWelcomeOffer();
    var features = useUserFeatures();
    if (welcomeOffer && !features.olb_enable_marketing_banners) {
        return _jsx(PromotionCard, { promotion: welcomeOffer });
    }
    else if (features.olb_enable_marketing_banners) {
        return _jsx(MarketingCard, { address: "31b65103-a70b-4a35-8245-124a91fe1fe0" });
    }
    return null;
};
export default MarketingCardContainer;
