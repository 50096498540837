import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from "react";
import { ContentCard, Button, Error } from "cerulean";
import styles from "../Form.module.scss";
import TemplatePreview from "../../TemplatePreview";
import { useTemplateSelector } from "./TemplateSelector.container";
function TemplateSelector() {
    var _a = useTemplateSelector(), showManageTemplatesUI = _a.showManageTemplatesUI, sortedFedwireTemplateIds = _a.sortedFedwireTemplateIds, selectedTemplateId = _a.selectedTemplateId, onSelectTemplate = _a.onSelectTemplate, manageTemplates = _a.manageTemplates, createNewTemplate = _a.createNewTemplate, method = _a.method, error = _a.error;
    if (method !== "template") {
        return null;
    }
    return (_jsxs(ContentCard, { kind: "bordered", paddingSize: "none", children: [_jsxs("div", { className: styles.manageTemplatesRow, children: [_jsx("p", { className: styles.wireTemplatesFormSubHeader, children: "Templates" }), sortedFedwireTemplateIds.length && showManageTemplatesUI ? (_jsx(Button, { label: "Manage templates", kind: "plain", onClick: manageTemplates, size: "s" })) : null] }), _jsx("div", { className: styles.templateList, children: sortedFedwireTemplateIds.map(function (templateId) { return (_jsx(TemplatePreview, { templateId: templateId, onSelect: onSelectTemplate, selected: selectedTemplateId === templateId }, templateId)); }) }), !sortedFedwireTemplateIds.length ? (_jsxs("div", { className: styles.emptyContainer, children: [_jsx("p", { className: styles.emptyText, children: "You don\u2019t have any templates set up yet." }), _jsx(Button, { label: "Set up a template", kind: "primary", onClick: createNewTemplate, size: "s" })] })) : null, _jsx("div", { className: styles.templateListError, children: _jsx(Error, { error: error, marginTop: "s" }) })] }));
}
export default memo(TemplateSelector);
