var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import institution from "../slices/institution.slice";
import user from "../slices/user.slice";
import accounts from "../slices/accounts.slice";
import accountSorting from "../slices/accountSorting.slice";
import endToEndTest from "../slices/endToEndTest.slice";
import featureStatus from "../slices/featureStatus.slice";
import fedwireTemplates from "../fedwireTemplates/slice";
import wires from "../wires/slice";
import limits from "../limits/slice";
export var reducers = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, institution), user), accounts), accountSorting), endToEndTest), featureStatus), fedwireTemplates), wires), limits);
var rootReducer = combineReducers(reducers);
var defaultStore = configureStore({
    reducer: rootReducer,
});
export var DEFAULT_STATE = defaultStore.getState();
export var setupStore = function (preloadedState) {
    return configureStore({
        reducer: rootReducer,
        preloadedState: preloadedState,
    });
};
