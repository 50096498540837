/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import ApiHttp from "byzantine/src/ApiHttp";
import User from "byzantine/src/User";
import { Localized, useLocalization } from "@fluent/react";
import { Button, useLoadingContext, useNotificationContext } from "cerulean";
import InstitutionSettingsContext from "../contexts/InstitutionSettingsContext";
import { useCurrentUser } from "..//contexts/CurrentUserContext";

import SamplePDFLink from "./SamplePDFLink";

const EstatementsNotEnabled = ({
  confirmedEstatementAccess,
  setConfirmedEstatementAccess,
  setEstatementsEnabled,
}) => {
  const { clearNotifications, sendNotification } = useNotificationContext();
  const { setIsLoading } = useLoadingContext();
  const { l10n } = useLocalization();
  const { require_viewing_sample_pdf_for_paperless_enrollment } = useContext(
    InstitutionSettingsContext
  );
  const { currentUser, setCurrentUser } = useCurrentUser();

  return (
    <>
      <p>
        <Localized id="subtitle-estatement-benefits"></Localized>
      </p>
      <Localized
        id="subtitle-estatement-benefits-2"
        elems={{
          samplePdf: (
            <SamplePDFLink
              onConfirmedEstatementAccess={setConfirmedEstatementAccess}
            />
          ),
        }}
      >
        <>
          {`To enroll in paperless statements, view the <samplePdf>sample PDF</samplePdf> and then click enroll.`}
        </>
      </Localized>
      <div className="margin--top--l">
        <Button
          as="a"
          onClick={() => {
            clearNotifications();
            if (
              !confirmedEstatementAccess &&
              require_viewing_sample_pdf_for_paperless_enrollment
            ) {
              sendNotification({
                type: "negative",
                text: "You must view the sample PDF to enroll.",
              });
              return;
            }
            setIsLoading(true);
            ApiHttp.fetch(`users/${currentUser.id}/estatements`, {
              method: "POST",
            })
              .then(() => {
                setEstatementsEnabled(true);
                User.getUser().then((u) => {
                  setCurrentUser(u);
                });
              })
              .catch((err) => {
                sendNotification({
                  type: "negative",
                  text: err,
                });
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
          label={l10n.getString("estatements-enroll-button", null, "Enroll")}
        />
      </div>
    </>
  );
};
EstatementsNotEnabled.propTypes = {
  confirmedEstatementAccess: PropTypes.bool,
  setConfirmedEstatementAccess: PropTypes.func,
  setEstatementsEnabled: PropTypes.func,
};

export default EstatementsNotEnabled;
