import React from "react";
import PropTypes from "prop-types";
import { Dialog, Row } from "cerulean";

const BalanceDetailsModal = ({ account, dialogOpen, setDialogOpen }) => (
    <Dialog
      title="Your balance"
      isOpen={dialogOpen}
      onUserDismiss={() => {
        setDialogOpen(false);
      }}
    >
      <div className="margin--bottom margin--top--s">
        <Row>
          <Row.Item>
            <span className="attribute-label">Available balance</span>
          </Row.Item>
          <Row.Item shrink>
            <span className="attribute-label">
              {account.balances.available}
            </span>
          </Row.Item>
        </Row>
        <p className="fontColor--secondary">
          Your immediately available funds to use, including any pending
          transactions or temporary holds on your account.
        </p>
      </div>
      <div>
        <Row>
          <Row.Item>
            <span className="attribute-label">Current balance</span>
          </Row.Item>
          <Row.Item shrink>
            <span className="attribute-label">{account.balances.ledger}</span>
          </Row.Item>
        </Row>
        <p className="fontColor--secondary">
          The total amount of funds in your account not including temporary
          holds or pending transactions.
        </p>
      </div>
    </Dialog>
  );

BalanceDetailsModal.propTypes = {
  setDialogOpen: PropTypes.func,
  dialogOpen: PropTypes.bool,
  account: PropTypes.object,
};

export default BalanceDetailsModal;
