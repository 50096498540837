import React from "react";
import PropTypes from "prop-types";
import { useLocalization, Localized } from "@fluent/react";
import ApiHttp from "byzantine/src/ApiHttp";
import User from "byzantine/src/User";
import {
  Button,
  TextInput,
  ContextForm,
  ProgressButtons,
  useFormData,
  useLoadingContext,
  useNotificationContext,
} from "cerulean";

const EnterYourVerificationCode = ({ user, updateUser, institution }) => {
  const { l10n } = useLocalization();
  const { formData, onChange } = useFormData({});
  const { sendNotification } = useNotificationContext();
  const { setIsLoading } = useLoadingContext();

  const nextHandler = async (callback) => {
    let enrolledUser;
    try {
      enrolledUser = await User.verifyEnrollmentCode(formData);
      callback();
      updateUser(enrolledUser);
    } catch (err) {
      callback(err);
    }
  };

  const resendCode = async () => {
    try {
      setIsLoading(true);
      await user.resendEnrollmentCode();
      sendNotification({
        type: "success",
        text: l10n.getString("notif-code-resent", null, "Code resent."),
      });
    } catch {
      sendNotification({
        type: "negative",
        text: l10n.getString(
          "error-contact-support",
          null,
          "Something went wrong. Please try again and contact support if the issue persists."
        ),
      });
    } finally {
      setIsLoading(false);
    }
  };
  const userEmail = user.getEnrollmentCodeEmail();
  // show the dummy text if the env isn't production or is demo
  const optionalDemoText =
    !institution.is_production || institution.is_demo
      ? " For test environments, please enter 'enrollcode'."
      : "";
  const enterCodeText = l10n
    .getString(
      "label-enter-verification-code",
      { userEmail },
      `Enter the code sent to ${userEmail}.`
    )
    .concat(optionalDemoText);

  return (
    <div className="small">
      <h1>
        <Localized id="heading-verification-code">
          Enter your verification code
        </Localized>
      </h1>
      <div
        className="margin--top--xs margin--bottom--xl"
        data-testid="enrollment-page-subtitle"
      >
        {enterCodeText}
      </div>
      <ContextForm data={formData} onChange={onChange}>
        <ContextForm.Field style={{ marginBottom: "var(--space-s)" }} required>
          <TextInput
            field="enrollment_code"
            label={l10n.getString(
              "label-verification-code",
              null,
              "Verification code"
            )}
          />
        </ContextForm.Field>
        <Button
          kind="plain"
          label={l10n.getString("label-resend-code", null, "Resend code")}
          type="button"
          size="s"
          onClick={resendCode}
        />
        <div className="progress-buttons-container">
          <ProgressButtons
            onNext={nextHandler}
            onBack={() => {
              if (!institution?.logout_redirect) {
                ApiHttp.fetch("logout", { endpointIsUrl: true }).then(() => {
                  window.location.assign("/enroll");
                });
              } else {
                window.open(`/logout`, "_self");
              }
            }}
            backLabel={l10n.getString("button-cancel", null, "Cancel")}
          />
        </div>
      </ContextForm>
    </div>
  );
};

EnterYourVerificationCode.propTypes = {
  user: PropTypes.instanceOf(User).isRequired,
  updateUser: PropTypes.func,
  institution: PropTypes.object,
};

export default EnterYourVerificationCode;
