var getLastFour = function (num) {
    var accountAndShareId = num.split("=") || [""];
    var accountNumber = accountAndShareId[0];
    if (accountNumber.length <= 4)
        return num || "";
    // show suffix if it has one, else last four of number
    return accountAndShareId.length > 1
        ? "".concat(accountNumber.substring(accountNumber.length - 2), "=").concat(accountAndShareId[1])
        : accountNumber.substring(accountNumber.length - 4);
};
export var getDestinationAccountPreview = function (_a) {
    var account_number = _a.account_number, destination_institution_name = _a.destination_institution_name;
    var lastFour = getLastFour(account_number);
    return "".concat(destination_institution_name, "-**").concat(lastFour);
};
