import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "cerulean";

const Country = ({ country, selectCountry, closeDropdown }) => {
  const onClick = () => {
    selectCountry(country.value);
    closeDropdown();
  };
  return (
    <div
      role="button"
      tabIndex="0"
      className="dropdown-list-item hoverable"
      onClick={onClick}
      onKeyUp={({ key }) => {
        if (key === "Enter") {
          onClick();
        }
      }}
    >
      {country.text}
    </div>
  );
};
Country.propTypes = {
  country: PropTypes.object,
  selectCountry: PropTypes.func,
  closeDropdown: PropTypes.func,
};

const CountrySelector = ({ label, onChange, value, error, countries }) => {
  const sortAndMapCountries = () => {
    countries.sort((a, b) => {
      if (a.code === "US") return -1;
      if (b.code === "US") return 1;
      return a.name.localeCompare(b.name);
    });
    return countries.map((c) => ({
      key: c.code,
      text: c.name,
      value: c.code,
    }));
  };
  return (
    <Dropdown error={error} triggerLabel={label} triggerValue={value}>
      {Object.values(sortAndMapCountries()).map((c) => (
        <Country key={c.key} selectCountry={onChange} country={c} />
      ))}
    </Dropdown>
  );
};
CountrySelector.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.string,
  countries: PropTypes.array,
};

export default CountrySelector;
