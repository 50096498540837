import React from "react";
import PropTypes from "prop-types";
import ApiHttp from "byzantine/src/ApiHttp";
import { Button, ContentCard, Row, useNotificationContext } from "cerulean";
import cc from "classcat";

export function SsoRedirectAccountList({
  accountNamesAndNumbersJson,
  supportsSsoTaxDocumentJson,
  setLoading,
}) {
  const accounts = JSON.parse(accountNamesAndNumbersJson);
  const supportsSsoTaxDocument = !!JSON.parse(supportsSsoTaxDocumentJson);
  const [url, setUrl] = React.useState("");
  const [token, setToken] = React.useState("");
  const { sendNotification } = useNotificationContext();
  const tokenLoginForm = React.useRef();

  const onSubmit = async (apiUrl) => {
    if (setLoading) setLoading(true);
    try {
      const response = await ApiHttp.fetch(apiUrl);
      setUrl(response.url);
      setToken(response.post_body);
    } catch (err) {
      sendNotification({
        type: "negative",
        text: "Sorry, this external service is not available at this time.",
      });
    }
  };

  React.useEffect(() => {
    // submit the tokenLoginForm once we have a token
    if (!token) return;
    tokenLoginForm.current?.submit();
  }, [token]);

  const buttonList = accounts.map((account, idx) => (
      <li
        className={cc(["padding--y--xs", { "border--top": idx > 0 }])}
        key={account}
      >
        <Row>
          <Row.Item>View Statements for {account.name}</Row.Item>
          <Row.Item shrink>
            <Button
              kind="plain"
              onClick={() => {
                onSubmit(
                  `signed_urls/documents/list?account_number=${account.number}`
                );
              }}
              label={account.number}
            />
          </Row.Item>
        </Row>
      </li>
    ));

  return (
    <div>
      <div className="margin--bottom">
        <ContentCard>
          <ul className="list--reset padding--all--xs">{buttonList}</ul>
        </ContentCard>
      </div>
      {supportsSsoTaxDocument && (
        <ContentCard kind="elevated">
          <Row>
            <Row.Item shrink>
              <Button
                kind="plain"
                onClick={() => {
                  onSubmit(
                    "signed_urls/documents/list?tax_docs_requested=true"
                  );
                }}
                label="Tax Documents"
              />
            </Row.Item>
          </Row>
        </ContentCard>
      )}
      <form
        id="tokenlogin"
        name="TokenLoginForm"
        target="_blank"
        method="post"
        action={url}
        ref={tokenLoginForm}
      >
        <input type="hidden" name="Token" value={token} />
      </form>
    </div>
  );
}

SsoRedirectAccountList.propTypes = {
  accountNamesAndNumbersJson: PropTypes.string.isRequired,
  supportsSsoTaxDocumentJson: PropTypes.string.isRequired,
  setLoading: PropTypes.func,
};

export default SsoRedirectAccountList;
