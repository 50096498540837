import React from "react";
import PropTypes from "prop-types";
import { catcat, Row } from "cerulean";

const RowItem = ({ rowName, rowValue, isLeftAligned = false }) => (
  <div className="wire-transfer-row">
      <Row>
        <Row.Item>
          <span className="wire-transfer-row-name">{rowName}</span>
        </Row.Item>
        <div className={catcat("wire-transfer-row-value", { "wire-transfer-row-value-align-left": isLeftAligned })}>
          <Row.Item>{rowValue}</Row.Item>
        </div>
      </Row>
    </div>
  );
RowItem.propTypes = {
  rowName: PropTypes.string,
  rowValue: PropTypes.string,
  isLeftAligned: PropTypes.bool,
};

export default RowItem;
