import { useMemo } from "react";
import { useNotificationContext } from "cerulean";
var useWebToasts = function () {
    var _a = useNotificationContext(), sendNotification = _a.sendNotification, clearNotifications = _a.clearNotifications;
    return useMemo(function () { return ({
        throwToast: function (_a) {
            var kind = _a.kind, message = _a.message;
            sendNotification({
                type: kind === "error" ? "negative" : kind,
                text: message,
            });
        },
        clearAllToasts: clearNotifications,
    }); }, [sendNotification, clearNotifications]);
};
export default useWebToasts;
