import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import SCREEN_STATES from "../constants";

const ScreenStateContext = createContext({});

export const ScreenStateContextProvider = ({ children }) => {
  const [screenState, setScreenState] = useState(SCREEN_STATES.CHOOSE);
  return (
    <ScreenStateContext.Provider
      value={{
        screenState,
        setScreenState,
      }}
    >
      {children}
    </ScreenStateContext.Provider>
  );
};
ScreenStateContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useScreenStateContext = () => useContext(ScreenStateContext);

export default ScreenStateContext;
