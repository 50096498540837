var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable camelcase */
import { useCallback, useContext, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { useLocalization } from "@fluent/react";
import TransferSchedule from "byzantine/src/TransferSchedule";
import { FREQUENCIES } from "byzantine/src/Recurrence";
import utils from "byzantine/src/utils";
import { ContextForm, formatNumber, useFormData, useNotificationContext, } from "cerulean";
import LoanPaymentByCardForm from "./LoanPaymentByCardForm";
import LoanPaymentByCardActionReview from "./LoanPaymentByCardActionReview";
import useReviewableAction from "../../../useReviewableAction";
import AccountContext from "../../../contexts/AccountContext";
import { useUserFeatures } from "../../../contexts/UserFeaturesContext";
import { useCurrentUser } from "../../../contexts/CurrentUserContext";
var LoanPaymentByCard = function (_a) {
    var limits = _a.limits, loanId = _a.loanId, goToPrevious = _a.goToPrevious;
    var currentUser = useCurrentUser().currentUser;
    var l10n = useLocalization().l10n;
    var _b = useFormData({
        frequency: FREQUENCIES.ONCE,
        date: DateTime.now().toFormat("MM/dd/yyyy"),
        to_account_id: loanId,
        payment_type: "bank",
    }), formData = _b.formData, onChange = _b.onChange;
    var _c = useReviewableAction(), isInput = _c.isInput, goToInput = _c.goToInput, goToReview = _c.goToReview;
    var accounts = useContext(AccountContext).accounts;
    var loan_payment_by_card = useUserFeatures().loan_payment_by_card;
    var _d = __read(useState([]), 2), cards = _d[0], setCards = _d[1];
    var _e = __read(useState(uuidv4()), 2), itempotencyKey = _e[0], setItempotencyKey = _e[1];
    var sendNotification = useNotificationContext().sendNotification;
    if (!loan_payment_by_card || !currentUser) {
        return null;
    }
    useEffect(function () {
        if (loanId) {
            onChange({ to_account_id: loanId });
        }
    }, [loanId]);
    useEffect(function () {
        // if scheduling a transfer, cannot start today
        if (formData.frequency !== FREQUENCIES.ONCE) {
            var tomorrow = DateTime.now().plus({ days: 1 });
            if (DateTime.fromFormat(formData.date, "M/d/yyyy") < tomorrow) {
                onChange({ date: tomorrow.toFormat("MM/dd/yyyy") });
            }
        }
    }, [formData.frequency]);
    useEffect(function () {
        var fetchCards = function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        _a = setCards;
                        return [4 /*yield*/, (currentUser === null || currentUser === void 0 ? void 0 : currentUser.getFundingCards())];
                    case 1:
                        _a.apply(void 0, [_c.sent()]);
                        return [3 /*break*/, 3];
                    case 2:
                        _b = _c.sent();
                        sendNotification({
                            type: "negative",
                            text: l10n.getString("error-saved-cards", null, "There was an error retrieving your saved cards, please try again later"),
                        });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        fetchCards();
    }, []);
    var getAmountAndIsMinPayment = useCallback(function () {
        var _a;
        if (!(formData === null || formData === void 0 ? void 0 : formData.preset_amount) || formData.preset_amount === "custom") {
            return { amount: formData.amount, isMinPayment: false };
        }
        var loanAccount = accounts.find(function (account) { return account.id === (formData === null || formData === void 0 ? void 0 : formData.to_account_id); });
        var minimumPayment = (_a = loanAccount === null || loanAccount === void 0 ? void 0 : loanAccount.loan_details) === null || _a === void 0 ? void 0 : _a.minimum_payment;
        if (minimumPayment) {
            minimumPayment = formatNumber(minimumPayment);
        }
        return { amount: minimumPayment, isMinPayment: true };
    }, [formData === null || formData === void 0 ? void 0 : formData.amount, formData === null || formData === void 0 ? void 0 : formData.preset_amount, formData === null || formData === void 0 ? void 0 : formData.to_account_id]);
    var onCardSubmit = useCallback(function (callback) { return __awaiter(void 0, void 0, void 0, function () {
        var payload, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    payload = {
                        amount: utils.dollarsToPennies(getAmountAndIsMinPayment().amount.replace(/[^\d.]/g, "")),
                        account_uuid: formData.to_account_id,
                        userfunding_uuid: formData.card,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, currentUser.makeLoanPayment(payload)];
                case 2:
                    _a.sent();
                    goToPrevious("success=Payment+sent.");
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    callback(err_1);
                    if (typeof err_1 !== "string") {
                        goToInput();
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [formData]);
    var onBankSubmit = useCallback(function (callback) {
        var start_date = DateTime.fromFormat(formData.date, "M/d/yyyy").toFormat("yyyy-MM-dd");
        var transferVerb = TransferSchedule.isImmediateOneTimeTransfer(formData.frequency, start_date)
            ? "sent"
            : "scheduled";
        var schedule = new TransferSchedule({
            amount: getAmountAndIsMinPayment().amount,
            from_account_id: formData.from_account_id,
            to_account_id: formData.to_account_id,
            frequency: formData.frequency,
            start_date: start_date,
            memo: formData.memo,
            itempotencyKey: itempotencyKey,
        });
        schedule
            .submit()
            .then(function () {
            goToPrevious("success=Payment+".concat(transferVerb, "."));
        })
            .catch(function (error) {
            if (typeof error === "string") {
                goToPrevious("negative=".concat(encodeURIComponent(error)));
            }
            else {
                callback(error);
                goToInput();
            }
        })
            .finally(function () {
            setItempotencyKey(uuidv4());
        });
    }, [formData]);
    return (_jsx(ContextForm, { data: formData, onChange: onChange, children: isInput ? (_jsx(LoanPaymentByCardForm, { data: formData, onSubmit: goToReview, cancel: function () { return goToPrevious(); }, limits: limits, cards: cards, setCards: setCards })) : (_jsx(LoanPaymentByCardActionReview, { data: formData, onBankSubmit: onBankSubmit, onCardSubmit: onCardSubmit, getAmountAndIsMinPayment: getAmountAndIsMinPayment, goBack: goToInput, cancel: function () { return goToPrevious(); }, cards: cards })) }));
};
export default LoanPaymentByCard;
