import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import ApiHttp from "byzantine/src/ApiHttp";
import {
  Button,
  ContextForm,
  Dialog,
  useFormData,
  NotificationContext,
} from "cerulean";
import DateRangeFormField from "../form/DateRangeFormField";
import DropdownField from "../form/DropdownField";
import { presetPeriods } from "./TransactionSearchAndFilter";

const FILE_FORMATS = [
  { displayName: "Spreadsheet (CSV)", value: "csv" },
  { displayName: "Microsoft money (OFX v1)", value: "ofx" },
  { displayName: "Personal finance (QIF)", value: "qif" },
  { displayName: "Quickbooks (QBO)", value: "qbo" },
  { displayName: "Quicken (QFX)", value: "qfx" },
];

const DownloadForm = ({ closeDialog, endpoint }) => {
  const { formData, setFormData, onChange } = useFormData({});
  const { sendNotificationToParent } = useContext(NotificationContext);

  useEffect(() => {
    if (formData?.period !== "custom") {
      setFormData((prevState) => {
        const newState = { ...prevState };
        delete newState.min_date;
        delete newState.max_date;
        return newState;
      });
    }
  }, [formData?.period]);

  const onSubmit = (callback) => {
    closeDialog();

    const formatDateForApi = (date) =>
      DateTime.fromFormat(date || "", "M/d/yyyy")?.toFormat("yyyy-MM-dd");

    const payload = {
      file_format: formData.file_format,
      period: "custom",
      min_date: formatDateForApi(formData?.min_date),
      max_date: formatDateForApi(formData?.max_date),
    };
    if (formData.period !== "custom") {
      const [periodStart, periodEnd] = formData.period;
      payload.min_date = formatDateForApi(periodStart);
      payload.max_date = formatDateForApi(periodEnd);
    }

    ApiHttp.fetch(endpoint, { method: "GET" }, payload)
      .then((response) => {
        sendNotificationToParent({
          type: "success",
          text: "Download started.",
        });
        callback();

        // create temporary download link
        const tempDownloadUrl = URL.createObjectURL(new Blob([response.data]));
        const tempLink = document.createElement("a");
        tempLink.href = tempDownloadUrl;

        // e.g. `attachment; filename="Primary Checking-02_18_22.csv"`
        const fileName = decodeURI(
          response.headers["content-disposition"]
            .split(";")
            .find((n) => n.includes(`filename="`))
            .replace("filename=", "")
            .replace(/"/g, "") // remove quotation marks
            .trim()
        );
        tempLink.setAttribute("download", fileName);
        document.body.appendChild(tempLink);
        tempLink.click();

        // cleanup
        tempLink.parentNode.removeChild(tempLink);
        URL.revokeObjectURL(tempDownloadUrl);
      })
      .catch((error) => {
        callback(error);
      });
  };

  return (
    <ContextForm data={formData} onChange={onChange}>
      <ContextForm.Field required>
        <DropdownField
          options={FILE_FORMATS}
          field="file_format"
          label="File type"
        />
      </ContextForm.Field>
      <ContextForm.Field required>
        <DropdownField
          options={presetPeriods()}
          field="period"
          label="Date range"
        />
      </ContextForm.Field>
      <DateRangeFormField
        hidden={formData?.period !== "custom"}
        required={true}
      />
      <ContextForm.ActionBar>
        <ContextForm.Action
          noValidation
          onSubmit={closeDialog}
          dangerouslyDisableShowLoading
        >
          <div style={{ margin: "auto var(--space-m) auto auto" }}>
            <Button kind="negative" label="Cancel" />
          </div>
        </ContextForm.Action>
        <ContextForm.Action onSubmit={onSubmit}>
          <Button kind="primary" label="Download" />
        </ContextForm.Action>
      </ContextForm.ActionBar>
    </ContextForm>
  );
};
DownloadForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  endpoint: PropTypes.string.isRequired,
};

const TransactionsDownloader = ({ accountUuid }) => {
  const [isDownloadDialogOpen, setIsDownloadDialogOpen] = useState(false);
  const closeDialog = () => {
    setIsDownloadDialogOpen(false);
  };
  const openDialog = () => {
    setIsDownloadDialogOpen(true);
  };
  const endpoint = `accounts/${accountUuid}/transactions_download`;

  return (
    <>
      <Button kind="plain" label={`Download`} onClick={openDialog} />
      <Dialog
        isOpen={isDownloadDialogOpen}
        onUserDismiss={closeDialog}
        title={`Download transaction history`}
      >
        <div className="margin--top--s">
          <DownloadForm closeDialog={closeDialog} endpoint={endpoint} />
        </div>
      </Dialog>
    </>
  );
};
TransactionsDownloader.propTypes = {
  accountUuid: PropTypes.string.isRequired,
};

export default TransactionsDownloader;
