import { RESERVED_BANKING_WORD_LIST, PO_BOX_REGEX } from "./constants";
var functions = {
    appendQuerystringParam: function (url, key, value) {
        var updatedUrl = new URL(url);
        updatedUrl.searchParams.set(key, value);
        return updatedUrl;
    },
    sumObjArrayField: function (array, fieldName) {
        return array.reduce(function (accum, p) {
            return Number(accum) + (Number(p[fieldName]) ? Number(p[fieldName]) : 0);
        }, 0);
    },
    shardArrayByChunkSize: function (input, chunkSize) {
        var chunks = [];
        var items = input.slice();
        while (items.length) {
            chunks.push(items.splice(0, chunkSize));
        }
        return chunks;
    },
    //  in lieu of lodash or another utility, use this to deep copy a serializable object
    deepCopy: function (originalObj) {
        return JSON.parse(JSON.stringify(originalObj));
    },
    // This function is incompatible with atlas, hence the comments
    preventBrowserBack: function () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.history.pushState({}, "", window.location.pathname);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.onpopstate = function () {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.history.pushState({}, "", window.location.pathname);
        };
    },
    phoneFormatter: function (value) {
        if (value === null || value === undefined || value.length === 0) {
            return "";
        }
        var numerical = value.replace(/[^0-9]/g, "");
        var length = numerical.length;
        // formatting area code
        if (length < 4) {
            if (numerical[0] === "1" && length === 1) {
                return "";
            }
            return "(".concat(numerical.substring(0, length), ")");
        }
        // formatting the next three digits along with area code
        if (length < 7) {
            // Checks to see if country code is included
            if (numerical[0] === "1") {
                return "(".concat(numerical.substring(1, 4), ") ").concat(numerical.substring(4, length));
            }
            return "(".concat(numerical.substring(0, 3), ") ").concat(numerical.substring(3, length));
        }
        // formatting the full phone number
        if (length < 12) {
            // Checks to see if country code is included
            if (numerical[0] === "1") {
                return "(".concat(numerical.substring(1, 4), ") ").concat(numerical.substring(4, 7), "-").concat(numerical.substring(7, length));
            }
        }
        // Checks to see if country code is included
        if (numerical[0] === "1") {
            return "(".concat(numerical.substring(1, 4), ") ").concat(numerical.substring(4, 7), "-").concat(numerical.substring(7, 11));
        }
        return "(".concat(numerical.substring(0, 3), ") ").concat(numerical.substring(3, 6), "-").concat(numerical.substring(6, 10));
    },
    dollarsToPennies: function (amount) {
        var dollarAmount = Number(String(amount).replace(/[$,]/g, ""));
        return Math.round(dollarAmount * 100);
    },
    phoneUnformatter: function (value) {
        // strips all punctuation
        if (value === null || value === undefined || value.length === 0) {
            return "";
        }
        var numerical = value.replace(/[^0-9]/g, "");
        if (numerical.length === 10) {
            // if there are 10 digits and the first is not a 1
            // add country code
            return "+1".concat(numerical);
        }
        if (numerical.length > 10) {
            return "+".concat(numerical);
        }
        return numerical;
    },
    escapeRegExp: function (string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escapes the following chars: \ ^ $ * + ? . ( ) | { } [ ]
    },
    getSignedUrl: function (string) {
        // if we determine that something is a signed_url, return that url
        if (!string || !string.match)
            return null;
        var matches = string.match(/signed_urls\/.+\/.+/);
        if (!matches)
            return null;
        return matches[0];
    },
    ndsIconPrepend: function (string) {
        if (!string)
            return string;
        return string.includes("narmi-icon-") ? string : "narmi-icon-".concat(string);
    },
    arrayContainsDuplicates: function (array) {
        return !!array.filter(function (a, index) { return array.indexOf(a) !== index; }).length;
    },
    // amount values at different times can be: '$', '1', '1.00' '$1.00, or '$1,000.00'
    // copied over from azul
    parseValueAsFloat: function (value) {
        // cover falsy values just in case
        if (!value)
            return 0;
        if (typeof value === "number")
            return value;
        return parseFloat(value.replace(/[^\d.]/g, "")) || 0;
    },
    // Given a string, format banking words from a constant banking word list
    formatBankingWords: function (string) {
        RESERVED_BANKING_WORD_LIST.forEach(function (word) {
            string = string.replace(new RegExp("\\b" + word + "\\b", "gi"), word);
        });
        return string;
    },
    // Given a date string with a dob, return age
    getAge: function (dob) {
        if (!dob)
            return;
        var today = new Date();
        var birthDate = new Date(dob);
        var age = today.getUTCFullYear() - birthDate.getUTCFullYear();
        var month = today.getUTCMonth() - birthDate.getUTCMonth();
        if (month < 0 ||
            (month === 0 && today.getUTCDate() < birthDate.getUTCDate())) {
            return age - 1;
        }
        return age;
    },
    validateStreetAddress: function (streetAddress, allowPOBox) {
        if (allowPOBox === void 0) { allowPOBox = false; }
        if (!streetAddress) {
            return "Must be filled in";
        }
        var pattern = PO_BOX_REGEX;
        if (pattern.exec(streetAddress) !== null && !allowPOBox) {
            return "Address must not be a P.O. Box";
        }
    },
    validateStreetAddressTwo: function (streetAddressTwo, allowPOBox) {
        if (allowPOBox === void 0) { allowPOBox = false; }
        var pattern = new RegExp("\\b(?:!?(p.?s*o.?)|pos(t|tal)\\s+office)(\\s+)?(?:box|[0-9]*)?\\b", "i");
        if (pattern.exec(streetAddressTwo) !== null && !allowPOBox) {
            return "Line 2 must not be a P.O. Box";
        }
    },
};
export default functions;
