import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { Localized } from "@fluent/react";
import { Button } from "cerulean";
import { useInstitutionSettings } from "../contexts/InstitutionSettingsContext";
import SamplePDFLink from "./SamplePDFLink";
var PaperlessStatementFooter = function (_a) {
    var setConfirmedEstatementAccess = _a.setConfirmedEstatementAccess;
    var institutionSettings = useInstitutionSettings();
    return (_jsx(_Fragment, { children: _jsx(Localized, { id: "paperless-statement-terms", elems: {
                eSignLink: (_jsx(Button, { as: "a", kind: "plain", href: institutionSettings.esign_url, target: "_blank", rel: "noopener noreferrer" })),
                pdfLink: (_jsx(SamplePDFLink, { onConfirmedEstatementAccess: setConfirmedEstatementAccess })),
            }, children: _jsx(_Fragment, { children: "By enabling paperless statements, you acknowledge that you agree to the\n              terms of the <eSignLink>e-Sign Disclosure</eSignLink>, can access the\n              <pdfLink>sample PDF</pdfLink>, and that you consent to receive electronic\n              communications and other notices for your accounts, as described in the disclosure." }) }) }));
};
PaperlessStatementFooter.propTypes = {
    setConfirmedEstatementAccess: PropTypes.func,
};
export default PaperlessStatementFooter;
