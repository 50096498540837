import PropTypes from "prop-types";

export default function FormBase(props) {
  const [errors, setErrors] = props.React.useState(null);
  const [submitting, setSubmitting] = props.React.useState(false);
  const { fieldRequiredMsg, correctGlobalErrorsMsg, ...otherProps } = props;
  const FORM_GLOBAL_ERROR = "formGlobalError";
  let formInputElements = [];

  const validateChild = (data, errors = {}, child) => {
    if (!child || !child.props || child.props.field === undefined) return;
    // if we have a custom validation function, use that
    if (child.validate || child.props.validate) {
      let error;
      if (child.props.validate) {
        // note that this accepts prop-types validator functions!
        error = child.props.validate(
          data,
          child.props.field,
          child.constructor.name
        );
      } else {
        error = child.validate();
      }
      if (error) {
        if (error.message) {
          errors[child.props.field] = error.message;
        } else {
          errors[child.props.field] = error;
        }
        if (child.props.globalError) {
          // this could cause a bug if your form has a field named "formGlobalError"
          errors[FORM_GLOBAL_ERROR] = child.props.globalError;
        }
      }
      return;
    }
    // if no validation specified and not required, we're done
    if (child.props.required !== undefined && !child.props.required) return;
    // default to just checking if defined and not null
    const value = data[child.props.field];
    if (value === undefined || value === null || value === "") {
      errors[child.props.field] = fieldRequiredMsg;
      if (child.props.globalError) {
        // this could cause a bug if your form has a field named "formGlobalError"
        errors[FORM_GLOBAL_ERROR] = child.props.globalError;
      }
    }
  };

  const onChange = (props, update) => {
    // optionally send change events to parent component
    if (!props.onChange) return;
    props.onChange(update);
  };

  const onClearForm = (props) => {
    const update = {};
    // clear out all fields managed by us and send an update event
    props.React.Children.forEach(props.children, (child) => {
      if (!child || !child.props.field) return;
      update[child.props.field] = null;
    });
    onChange(props, update);
  };

  const validate = (data = {}) => {
    // run client-side validation
    const errors = {};
    // iterate fields
    formInputElements.forEach((child) => {
      validateChild(data, errors, child);
    });
    if (!Object.keys(errors).length) return null;
    return errors;
  };

  const onSubmit = (action) => {
    // bail if busy
    if (submitting) return;
    // optionally run client-side validation
    if (!action.noValidation) {
      const clientValidationErrors = validate(props.data);
      if (clientValidationErrors) {
        // display client-side validation errors and bail
        setErrors(() => clientValidationErrors);
        return;
      }
    }
    // setup callback for when our action is complete
    const submitCallback = (err) => {
      setErrors(err);
      setSubmitting(false);
      // if successful submit, optionally clear form
      if (!err && props.submitClear) onClearForm(props);
      if (props.onSubmit) props.onSubmit();
    };
    // flag that we're doing something and submit the action
    setErrors(null);
    setSubmitting(action.label);
    action.onSubmit(submitCallback);
  };

  const convertChild = (children, child) => {
    if (!child) return;
    if (!child.props) {
      children.push(child);
      return;
    }
    // if we're not bound to a specific field, pass through
    if (child.props.field === undefined) {
      // recurse descendants
      if (child.props.children) {
        const descendants = [];
        props.React.Children.forEach(child.props.children, (descendant) => {
          convertChild(descendants, descendant);
        });
        children.push(
          props.React.cloneElement(child, {
            key: children.length,
            children: descendants,
          })
        );
        return;
      }
      children.push(
        props.React.cloneElement(child, {
          key: children.length,
        })
      );
      return;
    }
    const data = props.data || {};
    const value = data[child.props.field];
    const { style } = child.props;
    // handle supported children
    const childErrors = props.errors || errors;
    const index = formInputElements.length;
    formInputElements[index] = undefined; // will get initialized on render
    // React complains when passing custom props to native dom element, so strip those
    const cleanChild = Object.assign({}, child);
    delete cleanChild.propType;
    children.push(
      props.React.cloneElement(child, {
        key: child.props.field,
        style,
        value,
        errors:
          childErrors && typeof childErrors[child.props.field] === "object"
            ? childErrors[child.props.field]
            : null,
        error: childErrors && childErrors[child.props.field],
        ref: (ref) => {
          formInputElements[index] = ref;
        },
        onChange: (event) => {
          if (child.props.onChange) child.props.onChange(event);
          if (!(event instanceof Event) && !event.nativeEvent) {
            return onChange(props, event);
          }
          onChange(props, { [child.props.field]: event.target.value });
        },
      })
    );
  };

  const renderContent = (props) => {
    const children = [];
    formInputElements = [];
    props.React.Children.forEach(props.children, (child) => {
      convertChild(children, child);
    });
    return children;
  };

  const findGlobalError = (errors) => {
    if (errors[FORM_GLOBAL_ERROR]) {
      return errors[FORM_GLOBAL_ERROR];
    }

    let globalError;
    Object.values(errors).forEach((error) => {
      if (typeof error === "object") {
        globalError = findGlobalError(error);
      }
    });

    return globalError;
  };

  const getErrorMessage = () => {
    if (!errors) return null;
    if (typeof errors === "string") {
      return errors;
    }
    if (Array.isArray(errors)) {
      const notObjectErrors = errors.filter((a) => typeof a !== "object");
      if (notObjectErrors.length) {
        return notObjectErrors.join(" ");
      }
    }
    // if our state.errors was an object, errors will already be drawn next to the fields
    const globalError = findGlobalError(errors);
    if (globalError) return globalError;
    return correctGlobalErrorsMsg;
  };

  const content = renderContent(props);
  const errorMessage = getErrorMessage();
  const actionBar = props.renderActionBar({
    ...otherProps,
    onSubmit,
    validate,
    errorMessage,
  });
  return props.React.createElement(props.renderContainer, {
    ...otherProps,
    validate,
    submitting,
    errors,
    setErrors,
    content,
    errorMessage,
    actionBar,
  });
}

FormBase.defaultProps = {
  correctGlobalErrorsMsg: "Please correct the above errors",
  fieldRequiredMsg: "Must be filled in",
};

FormBase.propTypes = {
  renderContainer: PropTypes.func.isRequired,
  renderActionBar: PropTypes.func,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  actions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
  correctGlobalErrorsMsg: PropTypes.string,
  fieldRequiredMsg: PropTypes.string,
};
