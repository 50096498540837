var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable camelcase */
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountContext from "../../contexts/AccountContext";
import { useInstitutionSettings } from "../../contexts/InstitutionSettingsContext";
import { useUserFeatures } from "../../contexts/UserFeaturesContext";
import useActions from "../../hooks/useActions";
import LoanPaymentByCard from "./payment_by_card/LoanPaymentByCard";
import LoanPaydown from "./paydown/LoanPaydown";
var LoanPaymentRouter = function (_a) {
    var limits = _a.limits;
    var accounts = useContext(AccountContext).accounts;
    var _b = useInstitutionSettings().ach_allows_pull, ach_allows_pull = _b === void 0 ? false : _b;
    var _c = useUserFeatures(), loan_payment_by_card = _c.loan_payment_by_card, loan_principal_payment = _c.loan_principal_payment;
    var _d = __read(useState(), 2), toAccountId = _d[0], setToAccountId = _d[1];
    var goToPrevious = useActions().goToPrevious;
    var navigate = useNavigate();
    useEffect(function () {
        var params = new URLSearchParams(window.location.search);
        if (!loan_payment_by_card && !loan_principal_payment) {
            navigate("/funds?".concat(params.toString()), { replace: true });
            return;
        }
        var loan_id = params.get("to_account_id");
        var loan = accounts.find(function (account) { return account.id === loan_id; });
        params.delete("to_account_id");
        params.delete("previous");
        window.history.replaceState({}, "", "".concat(document.location.href.split("?")[0], "?").concat(params.toString()));
        if (!loan ||
            !loan.isValidInternalOrExternalTransferDestination(ach_allows_pull)) {
            return;
        }
        if (loan.isPayableByCard() && loan_payment_by_card) {
            setToAccountId(loan_id);
        }
        else if (loan.isInternalLoan() && loan_principal_payment) {
            setToAccountId(loan_id);
        }
    }, [accounts]);
    if (loan_payment_by_card) {
        return (_jsx(LoanPaymentByCard, { limits: limits, loanId: toAccountId, goToPrevious: goToPrevious }));
    }
    if (loan_principal_payment) {
        return (_jsx(LoanPaydown, { limits: limits, loanId: toAccountId, goToPrevious: goToPrevious }));
    }
    return null;
};
export default LoanPaymentRouter;
