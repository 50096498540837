var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Input, TextInput } from "@narmi/design_system";
import Cleave from "cleave.js/react";
import React, { useImperativeHandle, useRef } from "react";
// server value is YYYY-MM-DD
// client display value is MM/DD/YYYY
export var getDisplayValue = function (storedValue) {
    if (!storedValue)
        return "";
    var dateAsArr = storedValue.split("-");
    var year = dateAsArr[0] || "";
    var cutOffYear = year.length > 4;
    var yearDisplay = cutOffYear ? "".concat(year.substring(0, 4)) : year;
    var month = dateAsArr[1] || "";
    var showMonthSlash = month.length > 1;
    var monthDisplay = showMonthSlash ? "".concat(month, "/") : month;
    var day = dateAsArr[2] || "";
    var showDaySlash = day.length > 1;
    var dayDisplay = showDaySlash ? "".concat(day, "/") : day;
    return "".concat(monthDisplay).concat(dayDisplay).concat(yearDisplay);
};
export var getStoredValue = function (displayValue) {
    if (!displayValue)
        return "";
    var dateAsArr = displayValue.split("/");
    var numSegments = dateAsArr[dateAsArr.length - 1] === ""
        ? dateAsArr.length - 1
        : dateAsArr.length;
    var shouldZeroPrefix = false;
    if (numSegments === 1) {
        if (displayValue.slice(-1) === "/" && dateAsArr[0].length < 2) {
            shouldZeroPrefix = true;
        }
        return "-".concat(shouldZeroPrefix ? "0" : "").concat(dateAsArr[0], "-");
    }
    else if (numSegments === 2) {
        if (displayValue.slice(-1) === "/" && dateAsArr[1].length < 2) {
            shouldZeroPrefix = true;
        }
        return "-".concat(dateAsArr[0], "-").concat(shouldZeroPrefix ? "0" : "").concat(dateAsArr[1]);
    }
    else {
        return "".concat(dateAsArr[2], "-").concat(dateAsArr[0], "-").concat(dateAsArr[1]);
    }
};
export var MaskedSimpleDateInput = function (_a) {
    var isInitiallyMasked = _a.isInitiallyMasked, label = _a.label, onChange = _a.onChange, value = _a.value, error = _a.error;
    var _b = __read(React.useState(isInitiallyMasked), 2), showMask = _b[0], setShowMask = _b[1];
    var ref = React.useRef(null);
    React.useEffect(function () {
        var _a;
        if (isInitiallyMasked && !showMask) {
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [isInitiallyMasked, showMask]);
    if (showMask) {
        return (_jsx(TextInput, { value: "**/**/****", label: label, readOnly: true, onFocus: function () { return setShowMask(false); } }));
    }
    return (_jsx(SimpleDateInput, { ref: ref, label: label, onChange: onChange, value: value, error: error }));
};
/**
 *
 * NDS TextInput configured for entering a date.
 *
 * `SimpleDateInput` is intended to be used as a controlled component.
 *
 * Only numeric characters are permitted.
 *
 * Use this instead of the NDS `DateInput` when the date picker is not desired.
 *
 */
var SimpleDateInput = React.forwardRef(function (_a, parentRef) {
    var label = _a.label, value = _a.value, onChange = _a.onChange, _b = _a.error, error = _b === void 0 ? null : _b, _c = _a.name, name = _c === void 0 ? "date_input" : _c, _d = _a.id, id = _d === void 0 ? "date_input" : _d, disabled = _a.disabled, otherTextInputProps = __rest(_a, ["label", "value", "onChange", "error", "name", "id", "disabled"]);
    var inputEl = useRef(null);
    useImperativeHandle(parentRef, function () {
        return {
            focus: function () {
                var _a;
                (_a = inputEl.current) === null || _a === void 0 ? void 0 : _a.focus();
            },
        };
    }, []);
    return (_jsx(Input, { name: name, id: id, label: label, error: error, disabled: disabled, children: _jsx(Cleave
        // lmao wtf https://github.com/nosir/cleave.js/blob/master/doc/reactjs-component-usage.md#how-to-get-ref-of-the-input-field
        , __assign({ 
            // lmao wtf https://github.com/nosir/cleave.js/blob/master/doc/reactjs-component-usage.md#how-to-get-ref-of-the-input-field
            htmlRef: function (ref) { return (inputEl.current = ref); }, value: getDisplayValue(value), options: {
                date: true,
                datePattern: ["m", "d", "Y"],
            }, onChange: function (event) {
                // only propagate the event if it's a valid date
                if (event.target.value.length == 10) {
                    onChange(getStoredValue(event.target.value));
                }
            }, name: name, id: id, placeholder: label, "aria-label": label, required: true, type: "tel" }, otherTextInputProps), "nds-text") }));
});
export default SimpleDateInput;
