import React from "react";

import TfaChoose from "./TfaChoose";
import TfaEnter from "./TfaEnter";
import SCREEN_STATES from "./constants";
import { useScreenStateContext } from "./contexts/ScreenStateContextProvider";

const TfaVerifyContainer = ({ totalSteps, step }) => {
  const { screenState } = useScreenStateContext();

  const renderContent = () => {
    switch (screenState) {
      case SCREEN_STATES.CHOOSE:
        return <TfaChoose totalSteps={totalSteps} step={step} />;
      case SCREEN_STATES.ENTER:
        return <TfaEnter totalSteps={totalSteps} step={step} />;
      default:
        return null;
    }
  };

  return renderContent();
};

export default TfaVerifyContainer;
