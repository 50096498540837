import { DEFAULT_STATE } from "byzantine";
import { cloneDeep, keyBy } from "lodash";
var preloadState = function (_a) {
    var user = _a.user, accounts = _a.accounts, _b = _a.limits, limits = _b === void 0 ? {} : _b;
    var defaultState = cloneDeep(DEFAULT_STATE);
    defaultState.user.user = user || null;
    defaultState.accounts.byId = keyBy(accounts, "id");
    defaultState.limits.data = limits;
    return defaultState;
};
export default preloadState;
