var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useDeepEqualMemo } from "../../../hooks/useDeepEqual.hook";
var HANDLERS = [];
export function registerErrorHandlerHook(handler) {
    if (!HANDLERS.includes(handler))
        HANDLERS.push(handler);
}
function resolveErrorResponse(err) {
    return __awaiter(this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!err.response) return [3 /*break*/, 2];
                    _a = {
                        status: err.response.status
                    };
                    return [4 /*yield*/, err.response.json()];
                case 1: return [2 /*return*/, (_a.payload = _b.sent(),
                        _a)];
                case 2: return [2 /*return*/, null];
            }
        });
    });
}
export default function useErrorHandling() {
    var handlers = HANDLERS.map(function (hook) { return hook(); });
    return useDeepEqualMemo(function () {
        /**
         * If a matching handler is found, attempt to handle the error, and then retry the request.
         * Toss the error back up to the caller if anything goes wrong.
         */
        return (function handleError(err, context, retry) {
            return __awaiter(this, void 0, void 0, function () {
                var error, handler, data, _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, resolveErrorResponse(err)];
                        case 1:
                            error = _b.sent();
                            if (!error)
                                throw err;
                            _b.label = 2;
                        case 2:
                            _b.trys.push([2, 5, , 6]);
                            handler = handlers.find(function (h) { return h.shouldHandle(error, context); });
                            if (!handler) return [3 /*break*/, 4];
                            return [4 /*yield*/, handler.handle(error, retry)];
                        case 3:
                            data = _b.sent();
                            return [2 /*return*/, data];
                        case 4: return [3 /*break*/, 6];
                        case 5:
                            _a = _b.sent();
                            console.warn("Exception while handling error");
                            return [3 /*break*/, 6];
                        case 6: throw err;
                    }
                });
            });
        });
    }, [handlers]);
}
