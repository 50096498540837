import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ContentCard } from "cerulean";
import { useCurrentUser } from "../../../contexts/CurrentUserContext";
import AddressSettings from "./Address/AddressSettings";
var BusinessInfoCard = function () {
    var currentUser = useCurrentUser().currentUser;
    var primaryAddress = currentUser === null || currentUser === void 0 ? void 0 : currentUser.getPrimaryAddress();
    var mailingAddress = currentUser === null || currentUser === void 0 ? void 0 : currentUser.getMailingAddress();
    // we can update this when we add additional fields to this section
    // currently, we should hide it if there are no addresses
    if (!primaryAddress && !mailingAddress) {
        return null;
    }
    return (_jsxs(ContentCard, { children: [_jsx("h4", { className: "contact-info-heading nds-sans fontSize--heading5", children: "Business info" }), _jsx(AddressSettings, {})] }));
};
export default BusinessInfoCard;
