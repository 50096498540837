import React from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import { Dialog, useFormData } from "cerulean";
import AddCardForm from "./AddCardForm";

const AddCardDialog = ({
  isDialogOpen,
  closeDialog,
  updateCardList,
  sendNotification,
}) => {
  const { l10n } = useLocalization();
  const { formData, setFormData, onChange } = useFormData({});
  return (
    <Dialog
      isOpen={isDialogOpen}
      onUserDismiss={closeDialog}
      title={l10n.getString("loan-paybycard-add-card-dialog-title")}
      width="500px"
    >
      <AddCardForm
        formData={formData}
        setFormData={setFormData}
        onChange={onChange}
        onCancel={() => {
          closeDialog();
          setFormData({});
        }}
        onSuccess={(newCard) => {
          closeDialog();
          sendNotification({ type: "success", text: "Debit card added." });
          updateCardList(newCard);
        }}
      />
    </Dialog>
  );
};

AddCardDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  updateCardList: PropTypes.func,
  sendNotification: PropTypes.func.isRequired,
};

export default AddCardDialog;
