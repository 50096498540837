var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useContext } from "react";
import { LoadingShim } from "@narmi/design_system";
import Portal from "../Portal";
var initialLoadingContextState = {
    isLoading: false,
    setIsLoading: function () { },
};
var LoadingContext = React.createContext(initialLoadingContextState);
export var useLoadingContext = function () { return useContext(LoadingContext); };
var LoadingPortal = function () {
    var isLoading = useLoadingContext().isLoading;
    return (_jsx(Portal, { rootId: "loading-container", children: isLoading && (_jsx(LoadingShim, { isLoading: isLoading, children: _jsx("div", {}) })) }));
};
/**
 *
 * Provider for a LoadingContext
 * Also includes the LoadingPortal itself
 *
 */
export var LoadingContextProvider = function (_a) {
    var children = _a.children;
    var _b = __read(useState(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    return (_jsxs(LoadingContext.Provider, { value: { isLoading: isLoading, setIsLoading: setIsLoading }, children: [_jsx(LoadingPortal, {}), children] }));
};
export default LoadingContext;
