import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../utils";
import { useObjectMemo } from "../../../hooks";
import { actions } from "./slice";
import { selectOneWireRecipient } from "./selectors";
export var useWireRecipients = function () {
    var dispatch = useAppDispatch();
    var upsertOne = useCallback(function (recipient) {
        dispatch(actions.upsertOneRecipient(recipient));
    }, [dispatch]);
    return useObjectMemo({
        upsertOne: upsertOne,
    });
};
export var useOneWireRecipient = function (recipientId) {
    return useSelector(function (state) { return selectOneWireRecipient(state, recipientId); });
};
