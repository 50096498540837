import React from "react";
import PropTypes from "prop-types";
import Account from "byzantine/src/Account";
import SavingsRuleCard from "./SavingsRuleCard";
import AmountSavedCard from "./AmountSavedCard";
import RecentActivityCard from "./RecentActivityCard";
import { AccountContextProvider } from "../contexts/AccountContext";

const AutomaticSavingsPageContainer = ({
  accountsJson,
  isActiveJson,
  isEligibleJson,
  accountOpeningUrl,
  initialSourceUuid,
  initialDestinationUuid,
  hasDigitalBankingAccountOpeningJson,
  appsJson,
  hasBeenEnrolledJson,
  metricsJson,
  recentTransactionsJson,
}) => {
  const accounts = JSON.parse(accountsJson);
  const isActive = !!JSON.parse(isActiveJson);
  const isEligible = !!JSON.parse(isEligibleJson);
  const hasDigitalBankingAccountOpening = !!JSON.parse(
    hasDigitalBankingAccountOpeningJson
  );
  const hasBeenEnrolled = !!JSON.parse(hasBeenEnrolledJson);
  const metrics = JSON.parse(metricsJson);
  const recentTransactions = JSON.parse(recentTransactionsJson);

  const flattenedAccounts = [
    ...accounts.automatic_savings_sources,
    ...accounts.automatic_savings_destinations,
  ].map((a) => Account.deserialize(a));

  return (
    <AccountContextProvider accounts={flattenedAccounts}>
      <div className="automatic-savings-page alignChild--center--top">
        <div className="left-column">
          <SavingsRuleCard
            accounts={accounts}
            isActive={isActive}
            isEligible={isEligible}
            accountOpeningUrl={accountOpeningUrl}
            initialSourceUuid={initialSourceUuid}
            initialDestinationUuid={initialDestinationUuid}
            hasDigitalBankingAccountOpening={hasDigitalBankingAccountOpening}
            appsJson={appsJson}
          />
          <AmountSavedCard
            hasBeenEnrolled={hasBeenEnrolled}
            totalSaved={metrics.total_saved}
          />
        </div>
        <div className="right-column">
          <RecentActivityCard
            isActive={isActive}
            accounts={flattenedAccounts}
            recentTransactions={recentTransactions}
          />
        </div>
      </div>
    </AccountContextProvider>
  );
};
AutomaticSavingsPageContainer.propTypes = {
  accountsJson: PropTypes.string,
  isActiveJson: PropTypes.string,
  isEligibleJson: PropTypes.string,
  accountOpeningUrl: PropTypes.string,
  initialSourceUuid: PropTypes.string,
  initialDestinationUuid: PropTypes.string,
  hasDigitalBankingAccountOpeningJson: PropTypes.string,
  appsJson: PropTypes.string,
  hasBeenEnrolledJson: PropTypes.string,
  metricsJson: PropTypes.string,
  recentTransactionsJson: PropTypes.string,
};

export default AutomaticSavingsPageContainer;
