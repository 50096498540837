var _a;
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
var name = "institution";
var initialState = {
    isFetching: false,
    lastUpdated: null,
    data: {},
    authenticatingApp: null,
};
var slice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        upsert: function (state, action) {
            state.data = action.payload;
        },
        updateAuthenticatingInstitutionApp: function (state, action) {
            state.authenticatingApp = action.payload;
        },
    },
});
export var actions = slice.actions;
export default (_a = {},
    _a[name] = slice.reducer,
    _a);
