export var cents = function (value) {
    return Math.round(Number(value));
};
export var dollars = function (value) { return Number(value); };
export var centsToDollars = function (c) { return dollars(c / 100); };
export var dollarsToCents = function (d) { return cents(d * 100); };
export var sanitizeTextCurrency = function (text) {
    return text.replace(/[^0-9]/g, "");
}; // text is pennies, so disallow decimal
export var currencyToCents = function (text) {
    return cents(sanitizeTextCurrency(text));
};
export var zeroDollars = dollars(0);
export var zeroCents = cents(0);
