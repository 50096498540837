import React from "react";
import PropTypes from "prop-types";
import "./tabs.css";
import { NotificationForm } from "../Form";

const TabHeaders = ({ headers, activeTabIndex, setActiveTabIndex }) => {
  const activeHeader = headers[activeTabIndex];

  return (
    <div className="headerRow">
      {headers.map((header, i) => (
        <TabHeader
          key={i}
          active={activeTabIndex === i}
          onClick={() => setActiveTabIndex(i)}
        >
          {typeof header === "string" ? header : header.text}
        </TabHeader>
      ))}
      {typeof activeHeader !== "string" && (
        <NotificationForm
          disableActionBarErrorMessage
          style={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}
          actionBarStyle={{ marginTop: "unset" }}
          actions={activeHeader.actions}
        />
      )}
    </div>
  );
};
TabHeaders.propTypes = {
  headers: PropTypes.array,
  activeTabIndex: PropTypes.number,
  setActiveTabIndex: PropTypes.func,
};

const TabHeader = ({ active, children, onClick }) => (
  <div
    role="button"
    tabIndex="0"
    onClick={onClick}
    onKeyUp={({ key }) => {
      if (key === "Enter") {
        onClick();
      }
    }}
    className={`header ${active ? "active" : ""}`}
  >
    {children}
  </div>
);
TabHeader.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

const Tabs = ({ panes }) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  return (
    <div>
      <TabHeaders
        headers={panes.map((p) => p.header)}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
      {panes[activeTabIndex].render()}
    </div>
  );
};
Tabs.propTypes = {
  panes: PropTypes.array.isRequired,
};

export default Tabs;
