var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect, useRef } from "react";
var useTimeout = function (callback, delay, deps) {
    /*
    custom hook that cleans up after every render so we don't have multiple ongoing timers
    courtesy of https://felixgerschau.com/react-hooks-settimeout/
    */
    if (deps === void 0) { deps = []; }
    // store the latest callback if it changes
    var savedCallback = useRef(callback);
    useEffect(function () {
        savedCallback.current = callback;
    }, [callback]);
    // sets up and tears down the timer if delay changes
    useEffect(function () {
        // if no delay is specified, don't start the timer
        if (delay === null) {
            return undefined;
        }
        var timerId = setTimeout(function () { return savedCallback.current(); }, delay);
        return function cleanup() {
            clearTimeout(timerId);
        };
    }, __spreadArray([delay], __read(deps), false));
};
export default useTimeout;
