export var BUSINESS = {
    WRITE: {
        DUAL_APPROVAL_REQUEST: {
            model_str: "indigo.DualApprovalRequest",
            operation: "write",
            uuid: "*",
        },
        TRANSACTION: {
            model_str: "indigo.Transaction",
            operation: "write",
            uuid: "*",
        },
        ORGANIZATION: {
            model_str: "indigo.Organization",
            operation: "write",
            uuid: "*",
        },
        WIRE_TRANSACTION: {
            model_str: "indigo.WireTransaction",
            operation: "write",
            uuid: "*",
        },
    },
};
