var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Filters from "byzantine/src/filters";
import Wire from "byzantine/src/Wire";
import { Tag, formatNumber, useNotificationContext } from "cerulean";
import SectionCard from "../SectionCard";
import UserFeaturesContext from "../contexts/UserFeaturesContext";
import AccountContext from "../contexts/AccountContext";
import WireStatusesDialog from "./WireStatusesDialog";
import { useCurrentUser } from "../contexts/CurrentUserContext";
var WireDescription = function (_a) {
    var wire = _a.wire;
    var title = "Wire to ".concat(wire.beneficiary_name);
    if (!wire.template_name) {
        return _jsx("div", { children: title });
    }
    return (_jsxs("div", { className: "alignChild--left--top", children: [title, _jsx("div", { className: "padding--x--xs", children: _jsx(Tag, { label: "Template", kind: "outline" }) })] }));
};
var PendingWireTable = function (_a) {
    var pendingWires = _a.pendingWires, infoIcon = _a.infoIcon;
    return (_jsxs("div", { className: "card-table three sparse", children: [_jsxs("div", { className: "row-wrapper", children: [_jsx("div", { className: "row-item-header", children: "DESCRIPTION" }), _jsxs("div", { className: "row-item-header", children: ["STATUS", infoIcon] }), _jsx("div", { className: "row-item-header align-right", children: "AMOUNT" })] }), pendingWires.map(function (wire) { return (_jsxs("div", { className: "row-wrapper", children: [_jsx("div", { className: "row-items-column", children: _jsxs("div", { className: "row-item", children: [_jsx(WireDescription, { wire: wire }), _jsx("div", { className: "font-size-s-medium-grey", children: Filters.longMonthDayYear(wire.created_at) })] }) }), _jsxs("div", { className: "row-items-column reverse", children: [_jsx("div", { className: "row-item responsive-text-styling", children: wire.display_state }), _jsx("div", { className: "row-item align-right number", children: formatNumber(wire.amount) })] })] }, wire.id)); })] }));
};
var PendingWiresCard = function (_a) {
    var accountUuid = _a.accountUuid;
    var currentUser = useCurrentUser().currentUser;
    var features = useContext(UserFeaturesContext);
    var sendNotification = useNotificationContext().sendNotification;
    var accounts = useContext(AccountContext).accounts;
    var _b = __read(useState(false), 2), isCardVisible = _b[0], setIsCardVisible = _b[1];
    var _c = __read(useState(false), 2), isLoading = _c[0], setIsLoading = _c[1];
    var _d = __read(useState([]), 2), pendingWires = _d[0], setPendingWires = _d[1];
    var _e = __read(useState(false), 2), isDialogOpen = _e[0], setIsDialogOpen = _e[1];
    var isValidWireSource = !!accounts
        .filter(function (a) { return a.isInternal() && a.isValidTransferSource(); })
        .find(function (a) { return a.id === accountUuid; });
    if (!isValidWireSource ||
        (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.isBusiness()) && !features.recent_wires_consumer) ||
        ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.isBusiness()) && !features.recent_wires_business)) {
        return null;
    }
    var infoIcon = function (className) { return (_jsx("span", { className: "".concat(className, " narmi-icon-info clickable"), onClick: function () { return setIsDialogOpen(true); }, onKeyUp: function (_a) {
            var key = _a.key;
            if (key === "Enter")
                setIsDialogOpen(true);
        }, "aria-label": "Open statuses dialog", role: "button", tabIndex: 0 })); };
    useEffect(function () {
        var getPendingWires = function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, 3, 4]);
                        setIsLoading(true);
                        _a = setPendingWires;
                        return [4 /*yield*/, Wire.getPendingWires(accountUuid)];
                    case 1:
                        _a.apply(void 0, [_c.sent()]);
                        return [3 /*break*/, 4];
                    case 2:
                        _b = _c.sent();
                        sendNotification({
                            type: "negative",
                            text: "Error fetching your pending wires. Please contact support.",
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        setIsLoading(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        getPendingWires();
    }, []);
    return (_jsx(SectionCard, { isLoading: isLoading, isExpanded: isCardVisible, setIsExpanded: setIsCardVisible, kind: isCardVisible ? "transactions" : undefined, title: "Recent wires", titleIcon: infoIcon("pending-wires-info-icon"), children: (pendingWires === null || pendingWires === void 0 ? void 0 : pendingWires.length) > 0 && (_jsxs(_Fragment, { children: [_jsx(PendingWireTable, { pendingWires: pendingWires, infoIcon: infoIcon("margin--left--xxs") }), _jsx(WireStatusesDialog, { isDialogOpen: isDialogOpen, closeDialog: function () { return setIsDialogOpen(false); } })] })) }));
};
PendingWiresCard.propTypes = {
    accountUuid: PropTypes.string.isRequired,
};
export default PendingWiresCard;
