import React, { useState } from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocalization } from "@fluent/react";
import { ContextForm } from "cerulean";

const ReCAPTCHAInput = ({ recaptchaPublicKey, onChange, isError }) => {
  const { l10n } = useLocalization();
  return (
    <>
      <ReCAPTCHA sitekey={recaptchaPublicKey} onChange={onChange} />
      {isError && (
        <div className="nds-input-error margin--top--xxs">
          <div className="fontSize--s margin--right--xxs narmi-icon-x-circle" />
          {l10n.getString(
            "label-captcha",
            null,
            "Please verify you're not a robot."
          )}
        </div>
      )}
    </>
  );
};

ReCAPTCHAInput.propTypes = {
  recaptchaPublicKey: PropTypes.string,
  onChange: PropTypes.func,
  isError: PropTypes.bool,
};

const validateRecaptcha = (e, setIsError) => {
  setIsError(false);
  if (!e) {
    setIsError(true);
    return true;
  }
  return null;
};

const ReCAPTCHAFormField = ({ showCaptcha, recaptchaPublicKey }) => {
  const [isError, setIsError] = useState(false);
  if (!showCaptcha) return null;
  return (
    <ContextForm.Field validate={(e) => validateRecaptcha(e, setIsError)}>
      <ReCAPTCHAInput
        field="recaptcha"
        recaptchaPublicKey={recaptchaPublicKey}
        onChange={() => {}}
        isError={isError}
      />
    </ContextForm.Field>
  );
};
ReCAPTCHAFormField.propTypes = {
  showCaptcha: PropTypes.bool,
  recaptchaPublicKey: PropTypes.string,
};

export default ReCAPTCHAFormField;
