var _a;
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    byId: {},
    recipients: {
        byId: {},
    },
};
var prepareWires = function (accountId, wireData) { return ({
    payload: {
        accountId: accountId,
        wires: wireData.wires,
    },
}); };
var prepareWireRecipients = function (response) { return ({
    payload: {
        wireRecipients: response.results,
    },
}); };
var prepareCreateWireRecipient = function (response) { return ({
    payload: {
        wireRecipient: response,
    },
}); };
var prepareDeleteWireRecipient = function (id) { return ({
    payload: {
        id: id,
    },
}); };
var name = "wires";
var slice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        receive: {
            prepare: prepareWires,
            reducer: function (state, action) {
                var _a = action.payload, wires = _a.wires, accountId = _a.accountId;
                var returnedWireIds = [];
                // add / update new wires received
                wires.forEach(function (wire) {
                    state.byId[wire.id] = wire;
                    returnedWireIds.push(wire.id);
                });
                Object.values(state.byId)
                    .filter(function (wire) {
                    return wire.from_account === accountId;
                })
                    .filter(function (wire) { return !returnedWireIds.includes(wire.id); })
                    .forEach(function (wire) { return delete state.byId[wire.id]; });
            },
        },
        createWireRecipient: {
            prepare: prepareCreateWireRecipient,
            reducer: function (state, action) {
                var wireRecipient = action.payload.wireRecipient;
                state.recipients.byId[wireRecipient.id] = wireRecipient;
            },
        },
        receiveWireRecipients: {
            prepare: prepareWireRecipients,
            reducer: function (state, action) {
                action.payload.wireRecipients.forEach(function (wireRecipient) {
                    state.recipients.byId[wireRecipient.id] = wireRecipient;
                });
            },
        },
        deleteWireRecipient: {
            prepare: prepareDeleteWireRecipient,
            reducer: function (state, action) {
                var id = action.payload.id;
                delete state.recipients.byId[id];
            },
        },
        upsertOneWire: function (state, action) {
            var wire = action.payload;
            state.byId[wire.id] = wire;
        },
        upsertManyWires: function (state, action) {
            var wires = action.payload;
            wires.forEach(function (wire) {
                state.byId[wire.id] = wire;
            });
        },
        upsertOneRecipient: function (state, action) {
            var recipient = action.payload;
            state.recipients.byId[recipient.id] = recipient;
        },
    },
});
export var actions = slice.actions, reducer = slice.reducer;
export default (_a = {},
    _a[name] = slice.reducer,
    _a);
