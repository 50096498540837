import React from "react";
import PropTypes from "prop-types";
import { Select } from "@narmi/design_system";
import { useLoadingContext } from "cerulean";
import MfaDevice from "byzantine/src/MfaDevice";

const AddAuthenticationDeviceSelector = ({
  devices,
  permittedDeviceTypes,
  setAddDeviceType,
  value,
}) => {
  const { setIsLoading } = useLoadingContext();

  const ADD_DEVICES_MAP = Object.freeze({
    [MfaDevice.TYPES.APP]: {
      onClick: () => {
        window.location.href = "/settings/security/devices/app";
      },
      description: "An authentication app (e.g. Google Authenticator)",
      isPermitted: () => permittedDeviceTypes.includes("app"),
    },
    [MfaDevice.TYPES.PHONE]: {
      onClick: () => {},
      description: "A mobile or landline phone",
      isPermitted: () => permittedDeviceTypes.includes("phone"),
    },
    [MfaDevice.TYPES.EMAIL]: {
      onClick: () => {
        window.location.href = "/settings/security/devices/email";
      },
      description: "The email address you have on file",
      isPermitted: () =>
        permittedDeviceTypes.includes("email") &&
        !devices.some((d) => d.name === "Email"),
    },
    [MfaDevice.TYPES.RECOVERY_CODES]: {
      onClick: () => {
        window.location.href = "/settings/security/devices/backup";
      },
      description: "Recovery codes",
      isPermitted: () => permittedDeviceTypes.includes("backup_codes"),
    },
  });

  return (
    <Select
      label="Add authentication device"
      value={value}
      onChange={(val) => {
        setAddDeviceType(val);
        setIsLoading(true);
        const deviceTypeProperties = ADD_DEVICES_MAP[val];
        deviceTypeProperties.onClick();
        if (val === MfaDevice.TYPES.PHONE) {
          setIsLoading(false);
        }
      }}
    >
      {Object.entries(ADD_DEVICES_MAP).map(
        ([deviceType, deviceTypeProperties]) => {
          if (deviceTypeProperties.isPermitted()) {
            return (
              <Select.Item
                value={deviceType}
                key={deviceTypeProperties.description}
              >
                {deviceTypeProperties.description}
              </Select.Item>
            );
          } else return null;
        }
      )}
    </Select>
  );
};
AddAuthenticationDeviceSelector.propTypes = {
  devices: PropTypes.array.isRequired,
  permittedDeviceTypes: PropTypes.array.isRequired,
  setAddDeviceType: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default AddAuthenticationDeviceSelector;
