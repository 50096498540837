import React from "react";

const ACTION_STATES = Object.freeze({
  INPUT: "input",
  SUDO: "sudo",
  REVIEW: "review",
});

const useReviewableAction = () => {
  const [screenState, setScreenState] = React.useState(ACTION_STATES.INPUT);
  const isInput = screenState === ACTION_STATES.INPUT;
  const isSudo = screenState === ACTION_STATES.SUDO;
  const isReview = screenState === ACTION_STATES.REVIEW;
  const goToInput = () => setScreenState(ACTION_STATES.INPUT);
  const goToSudo = () => setScreenState(ACTION_STATES.SUDO);
  const goToReview = () => setScreenState(ACTION_STATES.REVIEW);
  return {
    ACTION_STATES,
    screenState,
    setScreenState,
    isInput,
    isSudo,
    isReview,
    goToInput,
    goToSudo,
    goToReview,
  };
};

export default useReviewableAction;
