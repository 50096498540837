var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { apiExceptionErrorCode, } from "../../../types/models/v1/Error.types";
export function combineRunProps(innerOrFn, outer) {
    var inner = typeof innerOrFn === "function" ? innerOrFn() : innerOrFn;
    if (typeof outer === "function") {
        return outer(inner);
    }
    var onSuccess = inner.onSuccess || outer.onSuccess
        ? function () {
            if (inner.onSuccess)
                inner.onSuccess();
            if (outer.onSuccess)
                outer.onSuccess();
        }
        : undefined;
    var onData = inner.onData || outer.onData
        ? function (data) {
            if (inner.onData)
                inner.onData(data);
            if (outer.onData)
                outer.onData(data);
        }
        : undefined;
    var onError = inner.onError || outer.onError
        ? function (e) {
            if (inner.onError)
                inner.onError(e);
            if (outer.onError)
                outer.onError(e);
        }
        : undefined;
    var onFinally = inner.onFinally || outer.onFinally
        ? function () {
            if (inner.onFinally)
                inner.onFinally();
            if (outer.onFinally)
                outer.onFinally();
        }
        : undefined;
    return __assign(__assign(__assign({}, inner), outer), { onSuccess: onSuccess, onData: onData, onError: onError, onFinally: onFinally });
}
export var parseFormError = function (json) {
    var message = typeof (json === null || json === void 0 ? void 0 : json.message) === "string" ? [json === null || json === void 0 ? void 0 : json.message] : json === null || json === void 0 ? void 0 : json.message;
    var errors = Object.values(message || {}).flat();
    return errors;
};
export var parseFormErrorResponse = function (e) { return __awaiter(void 0, void 0, void 0, function () {
    var json, err_1;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ((_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.json())];
            case 1:
                json = _b.sent();
                return [2 /*return*/, parseFormError(json)];
            case 2:
                err_1 = _b.sent();
                return [2 /*return*/, []];
            case 3: return [2 /*return*/];
        }
    });
}); };
var GENERIC_ERROR_MESSAGE = "We're having trouble completing your request. Please try again later.";
var JSON_PARSING_ERROR = {
    errors: [
        {
            code: apiExceptionErrorCode("jsonParseError"),
            description: GENERIC_ERROR_MESSAGE,
        },
    ],
    warnings: [],
};
function isApiError(json) {
    return "id" in json && !!json.id && typeof json.message === "string";
}
var parseApiError = function (_a) {
    var id = _a.id, message = _a.message;
    return ({
        errors: [
            {
                code: id,
                description: message,
            },
        ],
        warnings: [],
    });
};
function isAPIClientException(json) {
    return (("errors" in json && !!json.errors) ||
        ("warnings" in json && !!json.warnings));
}
var parseApiClientException = function (_a) {
    var message = _a.message, reason = _a.reason, _b = _a.warnings, warnings = _b === void 0 ? [] : _b, _c = _a.errors, errors = _c === void 0 ? [] : _c;
    var description = (message === null || message === void 0 ? void 0 : message.message) || reason;
    if (warnings.length === 0 && errors.length === 0) {
        return {
            warnings: [],
            errors: [
                {
                    code: apiExceptionErrorCode("genericApiClientException"),
                    description: description,
                },
            ],
        };
    }
    return {
        warnings: warnings,
        errors: errors,
    };
};
export var parseErrorResponse = function (e) { return __awaiter(void 0, void 0, void 0, function () {
    var json, formErrors, err_2;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ((_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.json())];
            case 1:
                json = _b.sent();
                if (!json) {
                    return [2 /*return*/, JSON_PARSING_ERROR];
                }
                if (isAPIClientException(json)) {
                    return [2 /*return*/, parseApiClientException(json)];
                }
                if (isApiError(json)) {
                    return [2 /*return*/, parseApiError(json)];
                }
                formErrors = parseFormError(json);
                return [2 /*return*/, {
                        warnings: [],
                        errors: formErrors.map(function (formError) { return ({
                            code: apiExceptionErrorCode("formError"),
                            description: formError,
                        }); }),
                    }];
            case 2:
                err_2 = _b.sent();
                return [2 /*return*/, JSON_PARSING_ERROR];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var GENERIC_API_ERROR = {
    id: "generic_api_error",
    message: GENERIC_ERROR_MESSAGE,
};
export var getApiError = function (e) { return __awaiter(void 0, void 0, void 0, function () {
    var err_3;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ((_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.json())];
            case 1: return [2 /*return*/, (_b.sent()) || GENERIC_API_ERROR];
            case 2:
                err_3 = _b.sent();
                return [2 /*return*/, GENERIC_API_ERROR];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var notNull = function (x) { return !!x; };
