import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var Option = function (_a) {
    var icon = _a.icon, text = _a.text, onClick = _a.onClick, _b = _a.width, width = _b === void 0 ? "100%" : _b;
    return (_jsxs("div", { className: "padding--y--s padding--x--xs option", role: "button", onKeyUp: function (_a) {
            var key = _a.key;
            if (key === "Enter")
                onClick();
        }, tabIndex: 0, onClick: onClick, style: { width: width }, children: [_jsx("span", { className: icon }), _jsx("span", { className: "margin--left--xs", children: text })] }));
};
export default Option;
