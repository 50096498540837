import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { TextInput } from "@narmi/design_system";
import { StateSelector, ZipCodeTextInput, AutocompleteStreetAddress, ContextForm, } from "cerulean";
var BankingUSAddressField = function (_a) {
    var _b;
    var addressType = _a.addressType, onUpdate = _a.onUpdate, data = _a.data, _c = _a.errors, errors = _c === void 0 ? {} : _c, title = _a.title, _d = _a.showTitle, showTitle = _d === void 0 ? true : _d, _e = _a.streetAddressLabel, streetAddressLabel = _e === void 0 ? "Street Address" : _e, _f = _a.apartmentLabel, apartmentLabel = _f === void 0 ? "Apt/Fl/Suite" : _f, _g = _a.cityLabel, cityLabel = _g === void 0 ? "City" : _g, _h = _a.stateLabel, stateLabel = _h === void 0 ? "State" : _h, _j = _a.zipCodeLabel, zipCodeLabel = _j === void 0 ? "Zip code" : _j, _k = _a.marginTop, marginTop = _k === void 0 ? "margin--top--l" : _k, _l = _a.marginBottom, marginBottom = _l === void 0 ? "margin--bottom--l" : _l;
    var isAutocompleteApiAvailable = !!((_b = window.google) === null || _b === void 0 ? void 0 : _b.maps);
    return (_jsxs(_Fragment, { children: [addressType && showTitle && (_jsx("div", { className: "margin--top--xl fontWeight--bold", style: { color: "RGB(var(--nds-black))" }, children: title || addressType })), _jsxs("div", { className: "banking-address-grid ".concat(marginTop, " ").concat(marginBottom), children: [_jsx(ContextForm.Field, { required: true, style: { flex: 2, marginBottom: 0 }, children: isAutocompleteApiAvailable ? (_jsx(AutocompleteStreetAddress, { value: data.street_address || "", address_type: addressType, onUpdate: onUpdate, error: errors.street_address, streetAddressLabel: streetAddressLabel, field: "street_address" })) : (_jsx(TextInput, { label: streetAddressLabel, value: data.street_address || "", error: errors.street_address, field: "street_address" })) }), _jsx("div", { children: _jsx(ContextForm.Field, { style: { flex: 1, marginBottom: 0 }, children: _jsx(TextInput, { label: apartmentLabel, value: data.street_address_2, error: errors.street_address_2, field: "street_address_2" }) }) })] }), _jsx(ContextForm.Field, { required: true, children: _jsx(TextInput, { label: cityLabel, value: data.city, error: errors.city, field: "city" }) }), _jsxs("div", { className: "banking-address-grid ".concat(marginTop, " ").concat(marginBottom), children: [_jsx(ContextForm.Field, { required: true, style: { flex: 1, marginBottom: 0 }, children: _jsx(StateSelector, { label: stateLabel, value: data.region_code, error: errors.region_code, field: "region_code" }) }), _jsx(ContextForm.Field, { required: true, style: { flex: 2, marginBottom: 0 }, children: _jsx(ZipCodeTextInput, { label: zipCodeLabel, value: data.postal_code, error: errors.postal_code, field: "postal_code" }) })] })] }));
};
export default BankingUSAddressField;
