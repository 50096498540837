import React from "react";
import PropTypes from "prop-types";
import { ContextForm } from "cerulean";
import AmountTextInput from "../../../form/AmountTextInput";

const MinMaxAmountField = ({ header, type }) => (
    <>
      <div className="margin--top--m fontWeight--bold margin--bottom--s">
        {header}
      </div>
      <div style={{ display: "flex" }}>
        <ContextForm.Field style={{ marginBottom: 0 }}>
          <AmountTextInput field={`${type}_gt`} label="Greater than" />
        </ContextForm.Field>
        <div className="margin--x--xs fontSize--s" style={{ display: "flex" }}>
          <div style={{ margin: "auto" }}>and</div>
        </div>
        <ContextForm.Field style={{ marginBottom: 0 }}>
          <AmountTextInput field={`${type}_lt`} label="Less than" />
        </ContextForm.Field>
      </div>
    </>
  );
MinMaxAmountField.propTypes = {
  header: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default MinMaxAmountField;
